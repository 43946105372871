import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { DeleteActionMenuItem as DeleteActionMenuItemInj } from '../../../components';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { ExpertiseTableRow } from '../../../types';

export type ExpertiseRegistryActionsBtnsProps = {
    expertiseTableRow: ExpertiseTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const ExpertiseRegistryActionsBtns = observer((props: ExpertiseRegistryActionsBtnsProps): JSX.Element => {
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertiseRegistryActionsBtns);

    const { expertiseStore, coreRootStore } = useStore();
    const { formatMessage } = useIntl();
    const { expertiseTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id, customData } = expertiseTableRow;

    const { permissionsStore } = coreRootStore;
    const { expertiseConfig } = permissionsStore as PermissionsStore;
    const deleteExpertiseQuery = expertiseConfig.delete(id);

    const renderActionItems = useCallback(
        (hideMenu: () => void): ReactNode[] => {
            const onDeleteConfirm = async (): Promise<void> => {
                try {
                    toggleIsRowBlocked();
                    await expertiseStore.deleteExpertise(id, true);
                    reloadData();
                    hideMenu && hideMenu();
                } catch {
                    rowErrorHandler();
                }
            };

            const title = formatMessage({ id: 'common.confirmDeletion' });
            const message = formatMessage(
                { id: 'expertise.confirmDeletionInfoText' },
                { title: customData.number.title },
            );

            return [
                <DeleteActionMenuItem
                    key="deleteExpertise"
                    id="delete"
                    title={title}
                    message={message}
                    onConfirm={onDeleteConfirm}
                />,
            ];
        },
        [expertiseTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler],
    );

    return (
        <AuthorizationCheck {...deleteExpertiseQuery}>
            <MenuButton renderMenuItems={renderActionItems} />
        </AuthorizationCheck>
    );
});
