import { Grid } from '@mui/material';
import { sxHeight100 } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { borderSolid300 } from '../../../../../constants';
import { useExpertiseTaskViewPageContext } from '../../../../../hooks';
import { ExpertiseTaskViewSwitchVariant } from '../../../../../types';
import { ExpertiseTaskReport as ExpertiseTaskReportInj } from '../ExpertiseTaskReport';
import { ExpertiseTaskViewContentLeftSide as ExpertiseTaskViewContentLeftSideInj } from './ExpertiseTaskViewContentLeftSide';

const transitionSx: SxStyle = (theme) => ({
    transition: `${theme.transitions.create(['width', 'transform'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
    })}`,
});

export const ExpertiseTaskViewContent = observer((): JSX.Element => {
    const [ExpertiseTaskReport] = di([ExpertiseTaskReportInj], ExpertiseTaskViewContent);
    const [ExpertiseTaskViewContentLeftSide] = di([ExpertiseTaskViewContentLeftSideInj], ExpertiseTaskViewContent);

    const { model } = useExpertiseTaskViewPageContext();
    const { switchViewState } = model;

    const { task, report } = ExpertiseTaskViewSwitchVariant;

    const isTaskFullWidth = switchViewState === task;
    const isReportFullWidth = switchViewState === report;

    const taskWidth = isTaskFullWidth ? '100%' : isReportFullWidth ? '0%' : '50%';
    const reportWidth = isReportFullWidth ? '100%' : isTaskFullWidth ? '0%' : '50%';

    return (
        <Grid container wrap="nowrap" width="100%" sx={sxHeight100}>
            <Grid item width={taskWidth} sx={transitionSx} borderRight={borderSolid300}>
                <ExpertiseTaskViewContentLeftSide />
            </Grid>
            <Grid item width={reportWidth} sx={transitionSx} height="inherit">
                <ExpertiseTaskReport isTaskFullWidth={isTaskFullWidth} />
            </Grid>
        </Grid>
    );
});
