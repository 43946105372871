import { ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { SubjectPageContext, SubjectPageContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { SubjectModel } from '../../../models';
import { SubjectSyslogPage as SubjectSyslogPageInj } from '../subject-syslog';
import { SubjectControlPanel as SubjectControlPanelInj } from './subject-control-panel';
import { SubjectHeader as SubjectHeaderInj } from './subject-header';
import { SubjectPageContent as SubjectPageContentInj } from './SubjectPageContent';

export type SubjectPageRouteParams = {
    id: string;
};

export const SubjectPage = observer((): JSX.Element => {
    const [SubjectSyslogPage] = di([SubjectSyslogPageInj], SubjectPage);
    const [SubjectHeader] = di([SubjectHeaderInj], SubjectPage);
    const [SubjectControlPanel] = di([SubjectControlPanelInj], SubjectPage);
    const [SubjectPageContent] = di([SubjectPageContentInj], SubjectPage);

    const rootStore = useStore();
    const { subjectStore, history } = rootStore;
    const { id } = useParams<SubjectPageRouteParams>();
    const { locale } = useIntl();
    const model = useMemo(() => new SubjectModel(id, rootStore), [id]);
    const { load, commonFormApi, hiddenFormApi, setIsShowCommonValidation, setIsShowHiddenValidation } = model;

    useEffect(() => {
        load();
    }, [id, locale]);

    const onSaveSuccess = (): Promise<void> => {
        return load().then(() => history.push(generatePath(clientRoute.subject, { id })));
    };

    const onSubmit = (): Promise<void> => {
        const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
        const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
        if (commonFormApi && isCommonValid && isHiddenValid) {
            return commonFormApi
                .submit(false)
                .then(() => {
                    return hiddenFormApi?.submit(false);
                })
                .then(() => {
                    return subjectStore.saveForms(
                        id,
                        commonFormApi.getSubmissionWithAdditionalInfo(),
                        hiddenFormApi?.getSubmissionWithAdditionalInfo(),
                    );
                })
                .then(onSaveSuccess);
        }

        return Promise.resolve();
    };

    const validateForms = (): Promise<void> => {
        const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
        const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
        if (isCommonValid && isHiddenValid) {
            setIsShowCommonValidation(!isCommonValid);
            setIsShowHiddenValidation(!isHiddenValid);
            return Promise.resolve();
        } else if (commonFormApi?.readOnly) {
            setIsShowCommonValidation(!isCommonValid);
            setIsShowHiddenValidation(!isHiddenValid);
        }
        return Promise.reject();
    };

    const pageContextValue: SubjectPageContextType = {
        model,
        onSubmit,
        validateForms,
    };

    const pageLayoutProps: ObjectLayoutProps = {
        header: <SubjectHeader />,
        contentLayout: {
            content: <SubjectPageContent />,
            footer: <SubjectControlPanel />,
        },
    };

    return (
        <SubjectPageContext.Provider value={pageContextValue}>
            <Switch>
                <Route path={clientRoute.subjectSyslog}>
                    <SubjectSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout {...pageLayoutProps} />
                </Route>
            </Switch>
        </SubjectPageContext.Provider>
    );
});
