import { PromiseVoidFunction, promiseVoidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { SubjectModel } from '../models';

export type SubjectPageContextType = {
    model: SubjectModel;
    onSubmit: PromiseVoidFunction;
    validateForms: PromiseVoidFunction;
};

export const SubjectPageContext = createContext<SubjectPageContextType>({
    model: {} as SubjectModel,
    // todo: Выпилить, когда будет рефакторинг форм формио
    onSubmit: promiseVoidFunction,
    validateForms: promiseVoidFunction,
});
