import { Button, ButtonProps, Grid } from '@mui/material';
import { AuthorizationCheck, Transitions } from '@platform/front-core';
import { useAntiDoubleClick, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { ExpertiseCreateDialogContext, ExpertiseCreateDialogContextType } from '../../../../../contexts';
import { useCampaignPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { IdTitleNumber } from '../../../../../types';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../../../expertise';

export const CampaignControlPanelRight = observer((): JSX.Element => {
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], CampaignControlPanelRight);

    const { campaignStore, coreRootStore } = useStore();
    const history = useHistory();

    const [isDialogOpen, openDialog, closeDialog] = useFlag();
    const { model, onSubmit, validateForm } = useCampaignPageContext();
    const { id, title, identifier, metaInfo, formApi, load, loadCampaignStatistic } = model;
    const { stateTitle } = metaInfo;

    const campaignIdTitleNumber: IdTitleNumber = {
        id,
        title,
        number: identifier,
    };

    const handleExpertiseCreateDialogClose = (): void => {
        loadCampaignStatistic();
        closeDialog();
    };

    const contextValue: ExpertiseCreateDialogContextType = {
        open: isDialogOpen,
        byCampaign: true,
        campaign: campaignIdTitleNumber,
        onClose: handleExpertiseCreateDialogClose,
    };

    const { permissionsStore } = coreRootStore;
    const { expertiseCampaignConfig } = permissionsStore as PermissionsStore;
    const addExpertisesQuery = expertiseCampaignConfig.addExpertises(id);

    const onCreateCancel = (): void => {
        history.push(generatePath(clientRoute.campaigns));
    };

    const onEditCancel = (): void => {
        formApi?.setSubmissionFromStartSubmission();
        history.push(generatePath(clientRoute.campaign, { id }));
    };

    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    const submitButtonProps: ButtonProps = {
        endIcon,
        disabled: isLoading,
        variant: 'contained',
        onClick: onSubmitHandler,
    };

    return (
        <Grid item container alignItems="center" spacing={1} wrap="nowrap">
            <Switch>
                <Route path={clientRoute.campaignCreate} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onCreateCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button {...submitButtonProps}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaignEdit} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onEditCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button {...submitButtonProps}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaign} exact>
                    <Grid item>
                        <Grid container direction="row" spacing={3} wrap="nowrap">
                            <Grid item flexGrow={1}>
                                <AuthorizationCheck key={stateTitle} {...addExpertisesQuery}>
                                    <Button variant="contained" onClick={openDialog}>
                                        <FormattedMessage id="expertise.createExpertises" />
                                    </Button>
                                </AuthorizationCheck>
                                <ExpertiseCreateDialogContext.Provider value={contextValue}>
                                    <ExpertiseCreateDialog />
                                </ExpertiseCreateDialogContext.Provider>
                            </Grid>
                            <Grid item>
                                <Transitions
                                    objectId={id}
                                    onSuccessTransition={load}
                                    transitionsStore={campaignStore}
                                    validateFunction={validateForm}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
});
