import {
    CoreRootStore,
    CurrentUserStore as CoreCurrentUserStore,
    RedirectByRoleType,
    RolePriority,
} from '@platform/front-core';
import { makeObservable, override } from 'mobx';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';

export const loginRedirectByRole: RedirectByRoleType = {
    Expert: clientRoute.expertiseTasks,
    Curator: clientRoute.campaigns,
    User: clientRoute.campaigns,
};

export const rolePriorities: RolePriority = {
    Curator: 2,
    Expert: 1,
    User: 0,
};

export const сurrentUserStoreObservables = {
    getMainRoute: override,
    getRolePriorities: override,
    getLoginRedirectByRole: override,
};

export class CurrentUserStore extends CoreCurrentUserStore {
    constructor(rootStore: CoreRootStore) {
        super(rootStore);
        makeObservable(this, сurrentUserStoreObservables);
    }

    getMainRoute(): string {
        return clientRoute.campaigns;
    }

    getRolePriorities(): RolePriority {
        return rolePriorities;
    }

    getLoginRedirectByRole(): RedirectByRoleType {
        return loginRedirectByRole;
    }
}

export const getCurrentUserStore = (): any => {
    const [_CurrentUserStore] = di([CurrentUserStore], getCurrentUserStore);
    return _CurrentUserStore;
};
