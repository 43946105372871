import { UserNameModel } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { LinkDTO, MetaInfoDTO } from '../../types';
import { DateUtils } from '../../utils';

export const metaInfoModelObservables = {
    author: observable,
    created: observable,
    modified: observable,
    stateTitle: observable,
    processLink: observable,
    formLinks: observable,

    load: action.bound,
};

export class MetaInfoModel {
    author: UserNameModel = new UserNameModel();
    created?: Date;
    modified?: Date;
    stateTitle?: string;
    processLink?: LinkDTO;
    formLinks: LinkDTO[] = [];

    constructor() {
        makeObservable(this, metaInfoModelObservables);
    }

    load(dto: MetaInfoDTO): MetaInfoModel {
        this.author.load(dto.author);

        const created = new Date(dto.created || '');
        if (DateUtils.isValidDate(created)) {
            this.created = created;
        }

        const modified = new Date(dto.modified || '');
        if (DateUtils.isValidDate(modified)) {
            this.modified = modified;
        }
        this.stateTitle = dto.stateTitle;
        this.processLink = dto.processLink;
        this.formLinks = dto.formLinks;
        return this;
    }
}
