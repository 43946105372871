import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { DeleteActionMenuItem as DeleteActionMenuItemInj } from '../../../components';
import { useStore } from '../../../hooks';
import { CategoryOfSubjectsTableRow } from '../../../types';

export type CategoriesOfSubjectsRegistryActionsBtnsProps = {
    categoriesOfSubjectsTableRow: CategoryOfSubjectsTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const CategoriesOfSubjectsRegistryActionsBtns = observer(
    (props: CategoriesOfSubjectsRegistryActionsBtnsProps): JSX.Element => {
        const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], CategoriesOfSubjectsRegistryActionsBtns);

        const { categoriesOfSubjectsTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
        const { id, customData } = categoriesOfSubjectsTableRow;

        const { categoriesOfSubjectsStore } = useStore();

        const { formatMessage } = useIntl();
        const title = formatMessage({ id: 'common.confirmDeletion' });
        const message = formatMessage({ id: 'category.confirmDeletionInfoText' }, { title: customData.title.title });

        const renderActionItems = useCallback(
            (hideMenu: () => void): ReactNode[] => {
                const onDeleteConfirm = async (): Promise<void> => {
                    try {
                        toggleIsRowBlocked();
                        await categoriesOfSubjectsStore.deleteCategoryOfSubjects(id, true);
                        reloadData();
                        hideMenu && hideMenu();
                    } catch {
                        rowErrorHandler();
                    }
                };

                return [
                    <DeleteActionMenuItem
                        id="delete"
                        key="delete"
                        title={title}
                        message={message}
                        onConfirm={onDeleteConfirm}
                    />,
                ];
            },
            [categoriesOfSubjectsTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler],
        );

        return <MenuButton renderMenuItems={renderActionItems} />;
    },
);
