const pfTemplate: Record<string, string | Record<string, string>> = {
    actions: {
        download: 'Скачать шаблон',
    },
    create: 'Создать шаблон',
    edit: 'Редактировать шаблон',
    fields: {
        actions: 'Действия',
        created: 'Дата создания',
        file: 'Файл',
        title: 'Наименование',
    },
    listTitle: 'Шаблоны печатных форм',
};

export default pfTemplate;
