import EditOutlined from '@mui/icons-material/EditOutlined';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { TotObjectDeleteButton as TotObjectDeleteButtonInj } from '../../../../components';
import { useStore, useTemplateOfExpertisePageContext } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';

export const TemplateOfExpertiseControlPanelLeft = observer((): JSX.Element => {
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], TemplateOfExpertiseControlPanelLeft);

    const { formatMessage } = useIntl();
    const history = useHistory();
    const { model } = useTemplateOfExpertisePageContext();
    const { id, title, metaInfo, deleteTemplateOfExpertise } = model;
    const { stateTitle } = metaInfo;

    const { permissionsStore } = useStore().coreRootStore;
    const { templateExpertiseConfig } = permissionsStore as PermissionsStore;
    const deleteTemplateExpertiseQuery = templateExpertiseConfig.delete(id);
    const editTemplateExpertiseQuery = templateExpertiseConfig.edit(id);

    const handleDelete = (): Promise<void> => {
        return deleteTemplateOfExpertise().then(() => {
            history.push(generatePath(clientRoute.templatesOfExpertise));
        });
    };

    const confirmDeletion = formatMessage({ id: 'common.confirmDeletion' });
    const confirmDeletionInfoText = formatMessage({ id: 'templatesOfExpertise.confirmDeletionInfoText' }, { title });
    const tooltipTitle = formatMessage({ id: 'templatesOfExpertise.actions.edit' });

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={2} key={stateTitle}>
            <Switch>
                <Route exact path={clientRoute.templateOfExpertise}>
                    <AuthorizationCheck {...deleteTemplateExpertiseQuery}>
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-category"
                                title={confirmDeletion}
                                message={confirmDeletionInfoText}
                                onConfirm={handleDelete}
                                tooltipTitleId="templatesOfExpertise.actions.delete"
                            />
                        </Grid>
                    </AuthorizationCheck>
                    <AuthorizationCheck {...editTemplateExpertiseQuery}>
                        <Grid item>
                            <Tooltip title={tooltipTitle} placement="top-end">
                                <IconButton
                                    component={NavLink}
                                    to={generatePath(clientRoute.templateOfExpertiseEdit, {
                                        id,
                                    })}
                                >
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Route>
            </Switch>
        </Grid>
    );
});
