import { Box, BoxProps, Grid } from '@mui/material';
import { Form, FormApi } from '@platform/formiojs-react';
import { AuthorizationCheckAll, AuthorizationCheckAllResult, FormSavePanel } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { makeSxStyles } from '@platform/front-ui';
import useMutationObserver from '@rooks/use-mutation-observer';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { getFormTitle } from '../../../../utils';
import { ExpertiseTaskFormTitleInfo as ExpertiseTaskFormTitleInfoInj } from './components';

export type ExpertiseTaskReportProps = {
    withTitle?: boolean;
    isTaskFullWidth: boolean;
};

const sxStyles = makeSxStyles({
    formWithSaveButtonWrapper: {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        flex: '1 1 auto',
    },
    formContentWrapper: {
        position: 'absolute',
        overflow: 'hidden',
        top: '0',
        left: '0',
        right: '0',
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        pb: 2,
    },
});

const reportEditFormClassName = 'expertise-task-report-edit-form';
const scrollableElementSelector = '.expertise-task-report-edit-form .card-body';
const panelTitleSelector = '.expertise-task-report-edit-form .panel-title';
const hideReportTitleClassName = 'hide-report-title';

const formSavePanelWrapperProps: BoxProps = { pb: 1 };
const formSavePanelWrapperSx: SxStyle = { flexWrap: 'nowrap' };
const formWrapperMutationObserverOptions: MutationObserverInit = { attributes: false, childList: true, subtree: true };

export const ExpertiseTaskReport = observer((props: ExpertiseTaskReportProps): JSX.Element => {
    const [ExpertiseTaskFormTitleInfo] = di([ExpertiseTaskFormTitleInfoInj], ExpertiseTaskReport);

    const { withTitle = true, isTaskFullWidth } = props;

    const { model } = useExpertiseTaskViewPageContext();
    const {
        id,
        loadTaskReportForm,
        isReportFormChanged,
        reportFormDTO,
        setIsReportFormChanged,
        setReportFormApi,
        reportFormApi,
    } = model;

    const { coreRootStore, expertiseTaskStore } = useStore();
    const { setReportFormApi: setReportFormApiStore } = expertiseTaskStore;
    const { intlStore, permissionsStore } = coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const { formatMessage } = useIntl();

    const setFormIsChangedFalse = (): void => setIsReportFormChanged(false);

    const formWrapperRef = useRef<HTMLElement | null>(null);
    const scrollableElement = useRef<HTMLElement | null>(null);
    const panelTitleElement = useRef<HTMLElement | null>(null);

    const onScrollableElementScroll = useCallback(
        (event: Event): void => {
            const isFormScrolledNow = (event.currentTarget as HTMLElement).scrollTop !== 0;
            const panelTitleElementClassList = panelTitleElement.current?.classList;
            isFormScrolledNow
                ? panelTitleElementClassList?.add(hideReportTitleClassName)
                : panelTitleElementClassList?.remove(hideReportTitleClassName);
        },
        [scrollableElement.current, panelTitleElement.current],
    );

    const mutationObserverCallback = useCallback<MutationCallback>(() => {
        const formWrapperRefValue = formWrapperRef.current;
        if (formWrapperRefValue) {
            const scrollableElementFromQuerySelector =
                formWrapperRefValue.querySelector<HTMLElement>(scrollableElementSelector);
            const panelTitleElementFromQuerySelector =
                formWrapperRefValue.querySelector<HTMLElement>(panelTitleSelector);
            if (
                scrollableElement.current !== scrollableElementFromQuerySelector ||
                panelTitleElement.current !== panelTitleElementFromQuerySelector
            ) {
                scrollableElement.current = scrollableElementFromQuerySelector;
                panelTitleElement.current = panelTitleElementFromQuerySelector;
            }
        }
    }, [formWrapperRef.current, scrollableElement.current, panelTitleElement.current]);

    // Кейс следующий: при нажатии кнопки "Сохранить" происходит unmount компоненты формы,
    // из-за чего меняются ссылки на scrollableElement и panelTitleElement
    // нам нужны актуальные версии которые находятся в DOM,
    // но из-за того что я не нашел способа отслеживать когда форма
    // гарантированно срендерится (а нам нужно вешать обработчик скролла на актуальную версию scrollableElement)
    // решил прибегнуть к использованию MutationObserver API: он реагирует любое структурное изменение DOM дерева
    // внутри элемента formWrapperRef, в mutationObserverCallback мы выставляем актуальные ref-ы на scrollableElement
    // и panelTitleElement
    useMutationObserver(formWrapperRef, mutationObserverCallback, formWrapperMutationObserverOptions);

    const keepTrackOfFormScroll = useCallback((): VoidFunction => {
        const currentScrollableElement = scrollableElement.current;
        if (currentScrollableElement) {
            currentScrollableElement.addEventListener('scroll', onScrollableElementScroll);

            return () => {
                currentScrollableElement.removeEventListener('scroll', onScrollableElementScroll);
            };
        }
        return () => {};
    }, [onScrollableElementScroll]);

    useEffect(() => {
        return keepTrackOfFormScroll();
    }, [keepTrackOfFormScroll]);

    const queries = useMemo(() => {
        return {
            viewConclusion: expertiseTaskConfig.viewConclusion(id),
            editConclusion: expertiseTaskConfig.editConclusion(id),
        };
    }, [id, reportFormDTO]);

    const afterSubmit = (): Promise<boolean> => {
        if (reportFormApi && id) {
            const submData = reportFormApi.getSubmissionWithoutRerender();
            const expertiseInfo = reportFormApi.getExpertiseInfo();
            // здесь forceUpdate понадобился из-за того что изменение ref-ов scrollableElement и panelTitleElement
            // не вызывает ререндер компоненты, соответственно не срабатывает эффект для отслеживания скролла
            return expertiseTaskStore.updateTaskReportForm(id, submData, expertiseInfo).then(() => false);
        } else {
            return Promise.reject();
        }
    };

    useEffect(() => {
        loadTaskReportForm();
    }, []);

    const onFormReadyCallback = useCallback(
        (form: FormApi): void => {
            setReportFormApi(form);
            setReportFormApiStore(form);
        },
        [setReportFormApi],
    );

    const exceptionRoutes = useMemo<string[]>(
        () => [generatePath(clientRoute.expertiseTask, { id }), generatePath(clientRoute.expertiseTaskSubject, { id })],
        [id],
    );

    const formTitle = getFormTitle(reportFormDTO);

    const warningText = formatMessage({ id: 'expertiseTask.reportSaveReminder' });

    const getFormContent = (isReadOnly: boolean): React.ReactNode => {
        const setFormIsChanged = isReadOnly ? undefined : setIsReportFormChanged;

        return (
            !!reportFormDTO && (
                <Grid item container direction="column" height="100%">
                    <Grid item sx={sxStyles.formWithSaveButtonWrapper}>
                        <Box sx={sxStyles.formContentWrapper} ref={formWrapperRef}>
                            {withTitle && <ExpertiseTaskFormTitleInfo title={formTitle} />}
                            <Form
                                ownerEntityId={id}
                                formDTO={reportFormDTO}
                                intlStore={intlStore}
                                setFormIsChanged={setFormIsChanged}
                                onFormReady={onFormReadyCallback}
                                className={reportEditFormClassName}
                                readOnly={isReadOnly}
                            />
                        </Box>
                    </Grid>
                    {!isReadOnly && !isTaskFullWidth && (
                        <Grid item container sx={formSavePanelWrapperSx}>
                            <FormSavePanel
                                formApi={reportFormApi}
                                isWithValidation={false}
                                afterSubmit={afterSubmit}
                                formIsChanged={isReportFormChanged}
                                setFormIsChangedFalse={setFormIsChangedFalse}
                                isWithCancelButton={false}
                                isWithLabelText={true}
                                isWithAntiDoubleClick={true}
                                wrapperProps={formSavePanelWrapperProps}
                                warningText={warningText}
                                exceptionRoutes={exceptionRoutes}
                            />
                        </Grid>
                    )}
                </Grid>
            )
        );
    };

    return (
        <AuthorizationCheckAll queries={queries} or={true}>
            {(result: AuthorizationCheckAllResult): JSX.Element => {
                return <React.Fragment>{getFormContent(!result.editConclusion)}</React.Fragment>;
            }}
        </AuthorizationCheckAll>
    );
});
