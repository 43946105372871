import { UserStatus, UserStatusAction } from '@platform/front-types';
import { createLocalesWithoutCases, LocalesTree } from '@platform/front-utils';

const action: Record<UserStatusAction, string> = {
    activate: 'Activate',
    block: 'Block',
    unblock: 'Unblock',
};

const status: Record<UserStatus, string> = {
    active: 'Active',
    blocked: 'Blocked',
    inactive: 'Inactive',
};

const user: LocalesTree = {
    ...createLocalesWithoutCases('user', 'users'),
    action,
    blockReason: 'Reason for blocking',
    createPerson: 'Create person',
    editPasswordDialogTitle: 'Password assignment',
    fields: {
        login: 'Login',
        name: 'Full name',
        roles: 'Roles',
        status: 'Status',
    },
    fullNameFields: {
        firstName: 'First name',
        lastName: 'Last name',
        middleName: 'Middle name',
    },
    info: 'Person info',
    mainLanguage: 'Default language',
    notAllowed: 'It is impossible to perform this action on your account',
    passwordAssignment: 'Assign a password',
    phrases: {
        addUser: 'Add {user}',
        confirmBlockWithReasonInfoText: 'User {name} ({login}) will be blocked',
        confirmStatusActivate: 'Confirm {user} activation',
        confirmStatusBlock: 'Confirm blocking the {user}',
        confirmStatusTextActivate: 'Are you sure you want to activate the {user} {name} "{login}"?',
        confirmStatusTextBlock: 'Are you sure you want to block the {user} {name} "{login}"?',
        confirmStatusTextUnblock: 'Are you sure you want to unblock the {user} {name} "{login}"?',
        confirmStatusUnblock: 'Confirm unblocking the {user}',
        deleteUser: 'Delete {user}',
        deleteUserConfirmText: 'Are you sure you want to delete user {name} ({login})?',
        editPasswordDialogInfo: 'Enter a new password for the user {name} ({login})',
        editUser: 'Edit {user}',
        emailNotExist: '{user} with this email was not found',
        lang: 'English speaking {user}',
        loginAsUser: 'Login as {user}',
        loginHelperText:
            'After changing the login, the account will need to be activated. An email with an activation link will be sent to the new email address.',
        profile: '{user} profile',
        selectUser: 'Select an {user}',
        selectUsers: 'Select {user}',
    },
    plural: {
        nominative: 'Users',
    },
    register: 'Register',
    registryPagingInfo:
        'Total {count, number} user{count, plural, one {} other {s}}. ' +
        'Displayed from {from, number} to {to, number}',
    settings: {
        fullName: 'Edit name',
        language: 'Change main system language',
        login: 'Change login',
        roles: 'Assign roles',
        rolesTitle: 'Assign roles',
    },
    status,
};

export default user;
