import { Grid } from '@mui/material';
import { validateEditPage } from '@platform/formiojs-react';
import { RenderTransitionDialogProps, Transitions, TransitionsModel } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { TransitionDialog as TransitionDialogInj } from '../../../../../../components';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { scrollToElement } from '../../../../../../utils';
import { ExpertiseTaskHeaderSelectExpertDialogButton as ExpertiseTaskHeaderSelectExpertButtonInj } from './ExpertiseTaskHeaderSelectExpertDialogButton';

export const ExpertiseTaskHeaderLeftBottomBlock = observer((): JSX.Element => {
    const [ExpertiseTaskHeaderSelectExpertButton] = di(
        [ExpertiseTaskHeaderSelectExpertButtonInj],
        ExpertiseTaskHeaderLeftBottomBlock,
    );
    const [TransitionDialog] = di([TransitionDialogInj], ExpertiseTaskHeaderLeftBottomBlock);

    const { expertiseTaskStore, expertiseTaskInviteStore } = useStore();
    const { setShouldSaveReport } = expertiseTaskStore;
    const { model } = useExpertiseTaskViewPageContext();
    const { reloadTaskModel, id, taskModel, reportFormApi } = model;
    const { activeInviteId } = taskModel;

    const { formatMessage } = useIntl();

    const expertiseTaskTransitionsModel = useMemo(() => {
        return new TransitionsModel(expertiseTaskStore, id);
    }, [expertiseTaskStore, id]);

    const expertiseTaskInviteTransitionsModel: TransitionsModel | undefined = useMemo(() => {
        return activeInviteId ? new TransitionsModel(expertiseTaskInviteStore, activeInviteId) : undefined;
    }, [expertiseTaskInviteStore, activeInviteId]);

    const [reportElementWithErrorId, setReportElementWithErrorId] = useState<string>();
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>();

    const onExpertiseTaskSuccessTransition = (): void => {
        reloadTaskModel().then(() => expertiseTaskInviteTransitionsModel?.getTransitions());
    };

    const onExpertiseTaskInviteSuccessTransition = (): void => {
        reloadTaskModel().then(() => expertiseTaskTransitionsModel.getTransitions());
    };

    const renderExpertiseTaskInviteTransitionDialog = (props: RenderTransitionDialogProps): ReactNode => {
        return <TransitionDialog expertiseTaskTransitionsModel={expertiseTaskTransitionsModel} {...props} />;
    };

    const validateForm = async (): Promise<void> => {
        try {
            const isFormValid = (reportFormApi && reportFormApi.validate()) || false;
            await validateEditPage(reportFormApi);
            if (isFormValid) {
                setShouldSaveReport(true);
                return Promise.resolve();
            }
        } catch (errors) {
            const errorsIsArray = Array.isArray(errors);

            if (errorsIsArray) {
                const elementId = errors[0].component.id;
                setReportElementWithErrorId(elementId);

                const isContainsRequiredError = errors.some(
                    (error: { message?: string }) =>
                        error.message === 'Это поле обязательное' || error.message?.includes('is required'),
                );

                if (isContainsRequiredError) {
                    const messageId = 'expertiseTask.requiredFieldsAreNotFilled';
                    setValidationErrorMessage(messageId);
                    return Promise.reject({ messageId });
                } else {
                    setValidationErrorMessage('expertiseTask.taskReportError');
                }
            }

            return Promise.reject(errors);
        }
    };

    const onValidationErrorDialogButtonClick = (): void => {
        reportElementWithErrorId && scrollToElement(reportElementWithErrorId);
    };

    return (
        <Grid container alignItems="center" spacing={1}>
            <ExpertiseTaskHeaderSelectExpertButton />
            {activeInviteId && expertiseTaskInviteTransitionsModel && (
                <Grid item>
                    <Transitions
                        onSuccessTransition={onExpertiseTaskInviteSuccessTransition}
                        renderTransitionDialog={renderExpertiseTaskInviteTransitionDialog}
                        model={expertiseTaskInviteTransitionsModel}
                    />
                </Grid>
            )}
            <Grid item>
                <Transitions
                    renderTransitionDialog={renderExpertiseTaskInviteTransitionDialog}
                    onSuccessTransition={onExpertiseTaskSuccessTransition}
                    model={expertiseTaskTransitionsModel}
                    validateFunction={validateForm}
                    validationErrorMessage={validationErrorMessage && formatMessage({ id: validationErrorMessage })}
                    onValidationErrorDialogButtonClick={onValidationErrorDialogButtonClick}
                />
            </Grid>
        </Grid>
    );
});
