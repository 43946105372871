import { makeSxStyles } from '@platform/front-ui';

export const headerLeftStyles = makeSxStyles({
    container: {
        flexWrap: 'nowrap',
        marginTop: (theme) => theme.spacing(2.75),
        '& > *': {
            width: 'auto !important',
            marginRight: (theme) => theme.spacing(3) + ' !important',
        },
    },
});
