import { ApiStore } from '@platform/front-core';
import { FullSubmissionWithAdditionalInfo, TransitionDTO } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { CampaignDTO, CampaignLinksModelDTO, CampaignStatisticDTO } from '../types';
import { RootStore } from './RootStore';

export const campaignStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,

    getCampaignStatistic: action.bound,
    getCampaignLinks: action.bound,

    loadCampaign: action.bound,
    createCampaign: action.bound,
    editCampaign: action.bound,
    deleteCampaign: action.bound,
    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,
};

export class CampaignStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, campaignStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    getCampaignStatistic(id: string): Promise<CampaignStatisticDTO> {
        return this.api.client(this.apiConfigs.campaignStatistic(id)).then((r) => r.data);
    }

    getCampaignLinks(campaignId: string): Promise<CampaignLinksModelDTO> {
        return this.api.client(this.apiConfigs.campaignLinks(campaignId)).then((r) => r.data);
    }

    loadCampaign(id: string): Promise<CampaignDTO> {
        return this.api.mainInfoClient(this.apiConfigs.campaign(id)).then((r) => r.data);
    }

    createCampaign(): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createCampaign).then((r) => r.data.id);
    }

    editCampaign(id: string, submission: FullSubmissionWithAdditionalInfo): Promise<CampaignDTO> {
        return this.api.userActionClient(this.apiConfigs.editCampaign(id, submission)).then((r) => r.data);
    }

    deleteCampaign(id: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteCampaign(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    getTransitions(campaignId: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.campaignLifeCycleTransitions(campaignId)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, campaignId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.campaignLifeCycleTransition(transitionId, campaignId))
            .then((r) => r.data);
    }
}

export const getCampaignStore = (): any => {
    const [_CampaignStore] = di([CampaignStore], getCampaignStore);
    return _CampaignStore;
};
