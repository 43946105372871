import { Link, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CellFormatters, TTable, TTableRowActions, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../constants';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { PfTemplateTableRow, RegistryType } from '../../../types';

export type PfTemplateRegistryTableProps = {
    setRowActions?: TTableRowActions<PfTemplateTableRow>;
};

const registryCode = RegistryType.pfTemplate;

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
        isWithPresets: false,
    },
};

export const PfTemplateRegistryTable = observer((props: PfTemplateRegistryTableProps): JSX.Element => {
    const { setRowActions } = props;
    const { coreRootStore, pfTemplateStore, userStore } = useStore();
    const { catalogStore, api } = coreRootStore;

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'pfTemplate.listTitle' });

    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { setReloadRegistryData, downloadTemplateFile } = pfTemplateStore;
    const tooltipTitle = formatMessage({ id: 'pfTemplate.actions.download' });

    const fileCellFormatters: CellFormatters<PfTemplateTableRow> = {
        'customData.file': function extractFileNameLink(p): JSX.Element {
            const file = p.row.original;
            const handleDownloadTemplateFile = () => downloadTemplateFile(file.id, file.customData.file.title);
            return (
                <Tooltip title={tooltipTitle} placement="bottom-start">
                    <div>
                        <Link color="secondary" onClick={handleDownloadTemplateFile}>
                            {file.customData.file.title}
                        </Link>
                    </div>
                </Tooltip>
            );
        },
    };

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable<PfTemplateTableRow>
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={ttableDefaultPaths.pfTemplateList}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                    visibleSettings={visibleSettings}
                    setReloadData={setReloadRegistryData}
                    cellFormatters={fileCellFormatters}
                />
            )}
        </AuthorizationCheck>
    );
});
