import { Dialog } from '@mui/material';
import { DialogSubmitButtonConfig, DialogTitle } from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseCreateDialogContext, useStore } from '../../../../hooks';
import { ExpertiseCreateType } from '../../../../types';
import { ExpertiseCreateDialogForm as ExpertiseCreateDialogFormInj } from './ExpertiseCreateDialogForm';

export const ExpertiseCreateDialog = observer((): JSX.Element => {
    const [ExpertiseCreateDialogForm] = di([ExpertiseCreateDialogFormInj], ExpertiseCreateDialog);

    const { subject, byCampaign, campaign, onClose, open } = useExpertiseCreateDialogContext();
    const intl = useIntl();
    const { formatMessage } = intl;

    const { expertiseStore, subjectStore } = useStore();
    const expertiseCreateModel = useMemo(expertiseStore.getExpertiseCreateModel, [expertiseStore]);
    const { loadSubjects, onSubmitByCampaign, onSubmitByTemplate } = expertiseCreateModel;

    useEffect(() => {
        subject || subjectStore.loadSubjectsSelectList().then(loadSubjects);
    }, [subject]);

    const initialValues: ExpertiseCreateType = {
        expertiseSubjects: subject || null,
        expertiseTemplates: null,
        expertiseLifeCycles: null,
    };

    const { Yup } = useYup();
    const objectNullable = Yup.object().nullable();
    const validationSchema = Yup.object().shape({
        expertiseSubjects: byCampaign && campaign?.id ? objectNullable : objectNullable.required(),
        expertiseTemplates: byCampaign ? objectNullable.required() : objectNullable,
        expertiseLifeCycles: Yup.object()
            .when('expertiseTemplates', {
                is: null,
                then: objectNullable.required(),
            })
            .nullable(),
    });

    const onSubmitCallback = (formValues: ExpertiseCreateType): Promise<void> => {
        const submitPromise =
            byCampaign && campaign?.id ? onSubmitByCampaign(formValues, campaign.id) : onSubmitByTemplate(formValues);

        return submitPromise.finally(onClose);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCallback);

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    return (
        <React.Fragment>
            <Dialog open={open} fullWidth={true} maxWidth="md">
                <DialogTitle onCloseClick={onClose}>
                    <FormattedMessage id={byCampaign ? 'expertise.newExpertises' : 'expertise.newExpertise'} />
                </DialogTitle>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    disableClearable={true}
                >
                    <ExpertiseCreateDialogForm
                        expertiseCreateModel={expertiseCreateModel}
                        submitButton={submitButton}
                    />
                </Formik>
            </Dialog>
        </React.Fragment>
    );
});
