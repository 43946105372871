import { makeSxStyles } from '@platform/front-ui';

export const expertiseTaskViewSidebarWrappersSx = makeSxStyles({
    wrapper: {
        flexGrow: 'unset',
        width: '340px',
        padding: (theme) => theme.spacing(2),
    },
    title: {
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
});
