import { Form } from '@platform/formiojs-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskSubjectModel } from '../../../../models';

type RouteParams = {
    id: string;
};

export type ExpertiseTaskSubjectWithoutFilesProps = {
    taskId?: string;
};

export const ExpertiseTaskSubjectWithoutFiles = observer(
    (props: ExpertiseTaskSubjectWithoutFilesProps): JSX.Element => {
        const { taskId } = props;
        const { expertiseTaskStore, coreRootStore } = useStore();
        const { intlStore } = coreRootStore;
        const { id } = useParams<RouteParams>();
        const finalTaskId = taskId || id;
        const model = useMemo(() => new ExpertiseTaskSubjectModel(finalTaskId), [finalTaskId]);
        const { load, commonFormDTO, hiddenFormDTO, setCommonFormApi, setHiddenFormApi } = model;

        useEffect(() => {
            expertiseTaskStore.loadTaskSubjectView(id, false).then(load);
        }, [expertiseTaskStore, finalTaskId]);

        return (
            <React.Fragment>
                {!!commonFormDTO && (
                    <Form
                        formDTO={commonFormDTO}
                        multiLang
                        readOnly
                        intlStore={intlStore}
                        ownerEntityId={finalTaskId}
                        onFormReady={setCommonFormApi}
                    />
                )}
                {!!hiddenFormDTO && (
                    <Form
                        formDTO={hiddenFormDTO}
                        multiLang
                        readOnly
                        intlStore={intlStore}
                        ownerEntityId={finalTaskId}
                        onFormReady={setHiddenFormApi}
                    />
                )}
            </React.Fragment>
        );
    },
);
