import { Button, ButtonProps, Grid } from '@mui/material';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useCategoryOfSubjectsPageContext } from '../../../hooks';

export const CategoryOfSubjectsControlPanelRight = observer((): JSX.Element => {
    const history = useHistory();
    const { model, onSubmit } = useCategoryOfSubjectsPageContext();
    const { id, formApi } = model;

    const onCreateCancel = (): void => {
        history.push(generatePath(clientRoute.categories));
    };

    const onEditCancel = (): void => {
        formApi?.setSubmissionFromStartSubmission();
        history.push(generatePath(clientRoute.category, { id }));
    };

    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    const submitButtonProps: ButtonProps = {
        endIcon,
        disabled: isLoading,
        variant: 'contained',
        onClick: onSubmitHandler,
    };

    return (
        <Grid item container alignItems="center" spacing={1} wrap="nowrap">
            <Switch>
                <Route path={clientRoute.categoryCreate} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onCreateCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button {...submitButtonProps}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.categoryEdit} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onEditCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button {...submitButtonProps}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
});
