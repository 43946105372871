import { recordToLang, LocalesTree } from '@platform/front-utils';
import startPage from './startPage';

const lang: LocalesTree = recordToLang(
    {
        startPage,
    },
    false,
);

export default lang;
