import { LocalesTree } from '@platform/front-utils';

const homePage: LocalesTree = {
    headerLinks: {
        campaigns: 'Campaigns',
        console: 'Console',
        requests: 'Requests',
        users: 'Users',
    },
};

export default homePage;
