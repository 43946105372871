import { Autocomplete, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Form } from '@platform/formiojs-react';
import { DialogActions, DialogCancelButtonConfig, DialogSubmitButtonConfig } from '@platform/front-ui';
import { CodeTitle, CodeTitleNull, useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../../components';
import { useStore } from '../../../hooks';
import { PlanEntryListModel, PlanEntryModel } from '../../../models';

export type PlanEntryFormDialogProps = {
    planEntryModel: PlanEntryModel;
    listModel: PlanEntryListModel;
    ownerPagePath: string;
};

export type PlanEntryFormDialogRouteParams = {
    id: string;
};

export const PlanEntryFormDialog = observer((props: PlanEntryFormDialogProps): JSX.Element => {
    const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], PlanEntryFormDialog);

    const { planEntryModel, listModel, ownerPagePath } = props;
    const {
        id: entryModelId,
        formApi,
        setFormApi,
        taskFormApi,
        setTaskFormApi,
        formDTO,
        taskFormDTO,
        taskFormCode,
        setValidationStarted,
        taskFormSelectData,
    } = planEntryModel;

    const { id } = useParams<PlanEntryFormDialogRouteParams>();
    const history = useHistory();

    const { intlStore } = useStore().coreRootStore;

    const { formatMessage } = useIntl();

    const getFormSelectDefaultValue = (): CodeTitleNull => {
        if (taskFormCode) {
            const value = taskFormSelectData.find((val: CodeTitle) => val.code === taskFormCode);
            if (value) {
                return value;
            }
        }
        return null;
    };

    useEffect(() => {
        planEntryModel.setTaskForm(getFormSelectDefaultValue());
    }, [entryModelId, taskFormCode]);

    const onSubmit = async (): Promise<void> => {
        const { isValid } = planEntryModel;
        setValidationStarted(true);
        if (formApi && formApi.validate() && isValid && taskFormApi?.validate()) {
            try {
                await formApi.submit(false);
                await taskFormApi?.submit(false);
                await listModel.saveEntry(planEntryModel, formApi.getSubmission(), taskFormApi?.getSubmission());
                history.push(generatePath(ownerPagePath, { id }));
            } catch (error) {}
        }
    };

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(onSubmit);

    const onCancel = (): void => {
        history.push(generatePath(ownerPagePath, { id }));
    };

    const getAutocompleteOptionSelected = (option: CodeTitle, value: CodeTitle): boolean => {
        return option.code === value.code;
    };

    const getAutocompleteOptionLabel = (option: CodeTitle): string => {
        return option.title;
    };

    const renderInput = renderAutoCompleteInput(
        formatMessage({ id: 'templatesOfExpertise.plan.fields.taskForm' }),
        true,
        planEntryModel.errorTaskForm,
    );

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
        onClick: submitHandler,
    };

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    return (
        <Dialog maxWidth="lg" fullScreen open={true}>
            <DialogTitle>
                <FormattedMessage id="templatesOfExpertise.plan.entryTitle" />
            </DialogTitle>
            <DialogContent dividers>
                {!!formDTO && (
                    <Form ownerEntityId={id} formDTO={formDTO} intlStore={intlStore} onFormReady={setFormApi} />
                )}
                {formApi && (
                    <Autocomplete
                        value={planEntryModel.taskForm}
                        onChange={planEntryModel.onChangeSelect}
                        isOptionEqualToValue={getAutocompleteOptionSelected}
                        getOptionLabel={getAutocompleteOptionLabel}
                        options={planEntryModel.taskFormSelectData.slice()}
                        renderInput={renderInput}
                    />
                )}
                {!!(taskFormCode && taskFormDTO) && (
                    <Form ownerEntityId={id} intlStore={intlStore} onFormReady={setTaskFormApi} formDTO={taskFormDTO} />
                )}
            </DialogContent>
            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
        </Dialog>
    );
});
