import { MenuItem } from '@mui/material';
import { IdTitle, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ExpertiseCreateDialogContext, ExpertiseCreateDialogContextType } from '../../../../contexts';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../../expertise';

export type SubjectCreateExpertiseButtonProps = {
    subject: IdTitle;
};

export const SubjectCreateExpertiseButton = observer((props: SubjectCreateExpertiseButtonProps): JSX.Element => {
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], SubjectCreateExpertiseButton);

    const { subject } = props;
    const [isModalOpen, openModal, closeModal] = useFlag();

    const subjectIdTitle = {
        id: subject.id,
        title: subject.title,
    };

    const contextValue: ExpertiseCreateDialogContextType = {
        subject: subjectIdTitle,
        onClose: closeModal,
        open: isModalOpen,
    };

    return (
        <ExpertiseCreateDialogContext.Provider value={contextValue}>
            <MenuItem key="create-expertise" dense component="button" onClick={openModal}>
                <FormattedMessage id="expertise.createExpertise" />
            </MenuItem>
            <ExpertiseCreateDialog />
        </ExpertiseCreateDialogContext.Provider>
    );
});
