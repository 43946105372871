import { ApiStore, TransitionsStore, transitionsStoreObservables } from '@platform/front-core';
import { TransitionDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { RootStore } from './RootStore';

export const ExpertiseTaskInviteStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,

    ...transitionsStoreObservables,
    rejectTaskInvite: action.bound,
};

export class ExpertiseTaskInviteStore implements TransitionsStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;

    constructor(rootStore: RootStore) {
        makeObservable(this, ExpertiseTaskInviteStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
    }

    getTransitions(inviteId: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.loadTaskInviteTransitions(inviteId)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, inviteId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.taskInviteLifeCycleTransition(transitionId, inviteId))
            .then((r) => r.data);
    }

    rejectTaskInvite(transitionId: string, inviteId: string, reason: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.rejectTaskInvite(transitionId, inviteId, reason))
            .then((r) => r.data);
    }
}

export const getExpertiseTaskInviteStore = (): any => {
    const [_ExpertiseTaskInviteStore] = di([ExpertiseTaskInviteStore], getExpertiseTaskInviteStore);
    return _ExpertiseTaskInviteStore;
};
