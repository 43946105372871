import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getUserBreadcrumbs = (intl: IntlShape, userId: string, userTitle: string): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.users,
            routeTitle: formatMessage({ id: 'user.plural.nominative' }),
        },
        {
            to: userId ? generatePath(clientRoute.user, { id: userId }) : '',
            routeTitle: userTitle,
        },
        {
            to: clientRoute.userSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
