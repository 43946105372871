import { MenuItem } from '@mui/material';
import { ActionItemProps } from '@platform/front-types';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { PfTemplateTableRow } from '../../../types';
import { PfTemplateCreateDialog as PfTemplateCreateDialogInj } from '../pf-template-dialog';

export type PfTemplateRegistryEditActionMenuItemProps = ActionItemProps & {
    pfTemplateTableRow: PfTemplateTableRow;
};

export const PfTemplateRegistryEditActionMenuItem = observer(
    (props: PfTemplateRegistryEditActionMenuItemProps): JSX.Element => {
        const [PfTemplateCreateDialog] = di([PfTemplateCreateDialogInj], PfTemplateRegistryEditActionMenuItem);

        const { pfTemplateTableRow, hideMenu } = props;
        const { id } = pfTemplateTableRow;

        const [, , closePfTemplateUpdateDialog] = useFlag();

        return (
            <React.Fragment>
                <MenuItem dense key="edit" component={NavLink} to={generatePath(clientRoute.pfTemplateEdit, { id })}>
                    <FormattedMessage id="common.edit" />
                </MenuItem>
                <Route path={clientRoute.pfTemplateEdit}>
                    <PfTemplateCreateDialog
                        pageTitleId="pfTemplate.edit"
                        pfTemplateTableRow={pfTemplateTableRow}
                        onCancel={closePfTemplateUpdateDialog}
                        hideMenu={hideMenu}
                    />
                </Route>
            </React.Fragment>
        );
    },
);
