import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type ErrorDialogProps = {
    message?: string | ReactNode;
    onClose: () => void;
    open: boolean;
};

export const ErrorDialog = observer((props: ErrorDialogProps): JSX.Element => {
    const { message, onClose, open } = props;
    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="information-dialog" open={open}>
                <DialogTitle>
                    <FormattedMessage id="common.error" />
                </DialogTitle>
                <DialogContent>{message || ''}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" variant="contained">
                        <FormattedMessage id={'common.close'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
