import { ActionItemProps } from '@platform/front-types';
import { ActionMenuItem } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { UserTableRow } from '../../../../types';
import { actionCodeByUserStatus } from '../../../../utils';
import { UserBlockDialog as UserBlockDialogInj } from './UserBlockDialog';

export type UserStatusButtonProps = ActionItemProps & {
    userTableRow: UserTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
};

export const UserStatusButton = observer((props: UserStatusButtonProps): JSX.Element => {
    const [UserBlockDialog] = di([UserBlockDialogInj], UserStatusButton);
    const { userTableRow, reloadData, toggleIsRowBlocked, hideMenu } = props;

    const [isConfirmationDialogOpen, openDialog, closeDialog] = useFlag();

    const { customData, id } = userTableRow;
    const { name, login, state } = customData;
    const code = state.code;

    const closeDialogAndMenu = (): void => {
        closeDialog();
        hideMenu && hideMenu();
        reloadData();
    };

    return (
        <React.Fragment>
            <ActionMenuItem messageId={`user.action.${actionCodeByUserStatus[code]}`} onClick={openDialog} />
            <UserBlockDialog
                id={id}
                name={name.title}
                login={login.title}
                userStatusCode={code}
                open={isConfirmationDialogOpen}
                reloadData={reloadData}
                toggleIsRowBlocked={toggleIsRowBlocked}
                onCancel={closeDialogAndMenu}
            />
        </React.Fragment>
    );
});
