import { AuthorizationCheck } from '@platform/front-core';
import { TTable, TTableRowActions } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { CampaignTableRow, RegistryType } from '../../../../types';

export type CampaignRegistryTableProps = {
    setRowActions?: TTableRowActions<CampaignTableRow>;
};

const registryCode = RegistryType.campaign;

export const CampaignRegistryTable = observer((props: CampaignRegistryTableProps): JSX.Element => {
    const { setRowActions } = props;
    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api } = coreRootStore;

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'campaign.campaignListTitle' });

    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable<CampaignTableRow>
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={ttableDefaultPaths.campaigns}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                />
            )}
        </AuthorizationCheck>
    );
});
