import { ExpertiseFeatures } from '../../types';
import logo from '../../resources/images/sstp-logo.png';

const localeLogoConfig = {
    alt: 'SSTP logo',
    image: logo,
    style: { margin: '9px 0', maxHeight: '45px' },
};

const config: ExpertiseFeatures = {
    customLogo: false,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: localeLogoConfig,
            ru: localeLogoConfig,
        },
    },
    phoneNumber: true,
    userAdding: true,
    userAssignPassword: true,
    withSubjectFilesTab: false,
};

export default config;
