import { PromiseVoidFunction, promiseVoidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { TemplatesOfExpertiseModel } from '../models';

export type TemplateOfExpertisePageContextType = {
    model: TemplatesOfExpertiseModel;
    onSubmit: PromiseVoidFunction;
    validateForm: PromiseVoidFunction;
};

export const TemplateOfExpertisePageContext = createContext<TemplateOfExpertisePageContextType>({
    model: {} as TemplatesOfExpertiseModel,
    // todo: Выпилить, когда будет рефакторинг форм формио
    onSubmit: promiseVoidFunction,
    validateForm: promiseVoidFunction,
});
