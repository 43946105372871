import { UserNameModel } from '@platform/front-core';
import { IdTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { ExpertiseStore } from '../../stores';
import { ExpertiseDTO, IdTitleNumber } from '../../types';
import { MetaInfoModel } from '../metaInfo';

export const expertiseModelObservables = {
    expertiseStore: observable,

    id: observable,
    identifier: observable,
    subject: observable,
    campaign: observable,
    template: observable,
    curator: observable,
    metaInfo: observable,

    load: action.bound,
    deleteExpertise: action.bound,
    setMainFields: action.bound,
};

export class ExpertiseModel {
    protected expertiseStore: ExpertiseStore;

    id: string;
    identifier = '';
    subject?: IdTitleNumber;
    campaign?: IdTitle;
    template?: IdTitle;
    curator: UserNameModel = new UserNameModel();
    metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string, expertiseStore: ExpertiseStore) {
        makeObservable(this, expertiseModelObservables);
        this.id = id;
        this.expertiseStore = expertiseStore;
    }

    load(): void {
        this.expertiseStore.loadExpertise(this.id).then(this.setMainFields);
    }

    deleteExpertise(): Promise<void> {
        return this.expertiseStore.deleteExpertise(this.id);
    }

    setMainFields(data: ExpertiseDTO): void {
        const { curator, metaInfo, template, campaign, subject, identifier } = data;
        this.curator.load(curator);
        this.metaInfo.load(metaInfo);
        this.campaign = campaign;
        this.subject = subject;
        this.identifier = identifier;
        this.template = template;
    }
}
