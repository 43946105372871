import { ApiConfigs as CoreApiConfigs } from '@platform/front-core';
import { FullSubmission, FullSubmissionWithAdditionalInfo, TotLocale } from '@platform/front-types';
import { AxiosRequestConfig } from 'axios';
import {
    EditStatusDTO,
    ExpertiseByCampaignDTO,
    ExpertiseCreateDTO,
    FullNameSettingsDTO,
    LoginSettingsDTO,
    NewSubjectDTO,
    RolesSettingsDTO,
    UserForCreate,
    UserUpdateBody,
} from './types';

export class ApiConfigs extends CoreApiConfigs {
    createCampaign: AxiosRequestConfig = {
        url: '/campaign',
        method: 'POST',
    };

    campaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'GET',
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: '/roles',
        method: 'GET',
    };

    deleteCampaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'DELETE',
        };
    };

    campaignSelectOptions: AxiosRequestConfig = {
        url: '/select/campaigns',
        method: 'GET',
    };

    campaignLifeCycleTransitions: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/transitions`,
            method: 'GET',
        };
    };

    campaignLifeCycleTransition: (transitionId: string, campaignId: string) => AxiosRequestConfig = (
        transitionId,
        campaignId,
    ) => {
        return {
            url: `/campaign/${campaignId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    campaignStatistic: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}/statistic`,
            method: 'GET',
        };
    };

    campaignLinks: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/statistic/links`,
            method: 'GET',
        };
    };

    createCategoryOfSubjects: AxiosRequestConfig = {
        url: '/category',
        method: 'POST',
    };

    deleteCategoryOfSubjects: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'DELETE',
        };
    };

    loadCategoryOfSubjectsDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/category/${id}`,
            method: 'GET',
        };
    };

    editCampaign: (id: string, submission: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        id,
        submission,
    ) => {
        return {
            url: `/campaign/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    categorySelectOptions: AxiosRequestConfig = {
        url: '/select/categories',
        method: 'GET',
    };

    createSubject: (data: NewSubjectDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/subject',
            method: 'POST',
            data,
        };
    };

    deleteSubject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'DELETE',
        };
    };

    subject: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/subject/${id}`,
            method: 'GET',
        };
    };

    saveChangeCategoryOfSubjects: (id: string, submission: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        id,
        submission,
    ) => {
        return {
            url: `/category/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    subjectLifeCycleTransition: (transitionId: string, subjectId: string) => AxiosRequestConfig = (
        transitionId,
        subjectId,
    ) => {
        return {
            url: `/subject/${subjectId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    downloadSubjectFiles: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/files`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getSubjectLifeCycleTransitions: (subjectId: string) => AxiosRequestConfig = (subjectId) => {
        return {
            url: `/subject/${subjectId}/transitions`,
            method: 'GET',
        };
    };

    createTemplate: AxiosRequestConfig = {
        url: '/template',
        method: 'POST',
    };

    deleteTemplate: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'DELETE',
        };
    };

    loadTemplateDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'GET',
        };
    };

    saveSubjectForms: (
        subjectId: string,
        commonSubmission: FullSubmissionWithAdditionalInfo,
        hiddenSubmission?: FullSubmissionWithAdditionalInfo,
    ) => AxiosRequestConfig = (subjectId, commonSubmission, hiddenSubmission) => {
        return {
            url: `/subject/${subjectId}`,
            method: 'PUT',
            data: {
                commonFormDto: commonSubmission,
                hiddenFormDto: hiddenSubmission,
            },
        };
    };

    loadTemplatePlanEntries: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/plan-entries`,
            method: 'PUT',
        };
    };

    createTemplatePlanEntry: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/plan-entry`,
            method: 'POST',
        };
    };

    updateTemplatePlanEntry: (
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ) => AxiosRequestConfig = (id, submission, taskFormCode, taskSubmission) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'PUT',
            data: {
                submission,
                taskFormCode,
                taskSubmission,
            },
        };
    };

    loadTemplatePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'GET',
        };
    };

    deleteTemplatePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/plan-entry/${id}`,
            method: 'DELETE',
        };
    };

    createExpertiseTask: (planEntryId: string) => AxiosRequestConfig = (planEntryId) => {
        return {
            url: `/plan-entry/${planEntryId}/expertise-task`,
            method: 'POST',
        };
    };

    expertisePlanEntriesSelectOptions: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/select/expertise/${expertiseId}/plan-entries`,
            method: 'GET',
        };
    };

    loadExpertise: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise/${id}`,
            method: 'GET',
        };
    };

    deleteExpertise: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise/${id}`,
            method: 'DELETE',
        };
    };

    expertiseLifeCycleTransition: (transitionId: string, expertiseId: string) => AxiosRequestConfig = (
        transitionId,
        expertiseId,
    ) => {
        return {
            url: `/expertise/${expertiseId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    getExpertiseLifeCycleTransitions: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/transitions`,
            method: 'GET',
        };
    };

    loadExpertisePlanEntries: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/plan-entries`,
            method: 'PUT',
        };
    };

    createExpertisePlanEntry: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/plan-entry`,
            method: 'POST',
        };
    };

    updateExpertisePlanEntry: (
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ) => AxiosRequestConfig = (id, submission, taskFormCode, taskSubmission) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'PUT',
            data: {
                submission,
                taskFormCode,
                taskSubmission,
            },
        };
    };

    loadExpertisePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'GET',
        };
    };

    deleteExpertisePlanEntry: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/plan-entry/${id}`,
            method: 'DELETE',
        };
    };

    subjectsSelectList: AxiosRequestConfig = {
        url: '/select/expertise/subjects',
        method: 'GET',
    };

    subjectNumber: (campaignId?: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: '/subject/identifier',
            method: 'GET',
            params: {
                campaign: campaignId,
            },
        };
    };

    templatesSelectList: AxiosRequestConfig = {
        url: '/select/templates',
        method: 'GET',
    };

    lifeCyclesExpertiseList: AxiosRequestConfig = {
        url: '/lifecycles/expertise',
        method: 'GET',
    };

    templateDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}/details`,
            method: 'GET',
        };
    };

    createExpertise: (data: ExpertiseCreateDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/expertise',
            method: 'POST',
            data,
        };
    };

    createExpertiseByCampaign: (data: ExpertiseByCampaignDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/expertise',
            method: 'POST',
            params: {
                campaign: data.campaignId,
                template: data.templateId,
            },
            data,
        };
    };

    expertiseTasksListByExpertise: (expertiseId: string) => AxiosRequestConfig = (expertiseId) => {
        return {
            url: `/expertise/${expertiseId}/expertise-tasks`,
            method: 'PUT',
            data: {
                filter: {},
                sort: [],
            },
        };
    };

    editExpertiseCurator: (expertiseId: string, curatorUserId: string) => AxiosRequestConfig = (
        expertiseId,
        curatorUserId,
    ) => {
        return {
            url: `/expertise/${expertiseId}/curator`,
            method: 'PUT',
            params: {
                userId: curatorUserId,
            },
        };
    };

    expertiseCuratorSelectOptions: AxiosRequestConfig = {
        url: '/select/users',
        method: 'GET',
        params: {
            role: 'Curator',
        },
    };

    deleteExpertiseTask: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'DELETE',
        };
    };

    loadExpertiseTaskView: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/view`,
            method: 'GET',
        };
    };

    loadExpertiseTaskSubject: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/subject`,
            method: 'GET',
        };
    };

    loadExpertiseTaskTransitions: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/transitions`,
            method: 'GET',
        };
    };

    expertiseTaskLifeCycleTransition: (transitionId: string, taskId: string) => AxiosRequestConfig = (
        transitionId,
        taskId,
    ) => {
        return {
            url: `/expertise-task/${taskId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    loadTaskReportForm: (taskId: string) => AxiosRequestConfig = (taskId) => {
        return {
            url: `/expertise-task/${taskId}/report/form`,
        };
    };

    saveTemplate: (id: string, submission: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        id,
        submission,
    ) => {
        return {
            url: `/template/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    loadExpertiseTaskDTO: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'GET',
        };
    };

    updateTaskReportForm: (
        taskId: string,
        submission: FullSubmissionWithAdditionalInfo | FullSubmission,
        expertiseInfo?: object,
    ) => AxiosRequestConfig = (taskId, submission, expertiseInfo) => {
        return {
            url: `/expertise-task/${taskId}/report`,
            method: 'PUT',
            data: {
                submission,
                expertiseInfo,
            },
        };
    };

    // Получение данных для селекта пока не готово, поставил заглушку url: '/catalogItems/:code_catalog'
    scienceFieldsFilterData: AxiosRequestConfig = {
        url: '/catalogItems/CatalogCode1',
        method: 'GET',
    };

    saveExpertOnTask: (taskId: string, expertId: string) => AxiosRequestConfig = (taskId, expertId) => {
        return {
            url: `/expertise-task/${taskId}/expert?expertId=${expertId}`,
            method: 'PUT',
        };
    };

    loadStateFilterData: AxiosRequestConfig = {
        url: '/subjects/filters',
        method: 'GET',
    };

    expertiseTaskFormsSelectData: AxiosRequestConfig = {
        url: '/select/forms/search',
        method: 'GET',
        params: {
            code: 'ExpertiseTask_',
        },
    };

    expertiseTaskFormByCode: (code: string) => AxiosRequestConfig = (code) => {
        return {
            url: '/expertise-task/form',
            method: 'GET',
            params: {
                code,
            },
        };
    };

    loadTaskInviteTransitions: (inviteId: string) => AxiosRequestConfig = (inviteId) => {
        return {
            url: `/invite/${inviteId}/transitions`,
            method: 'GET',
        };
    };

    taskInviteLifeCycleTransition: (transitionId: string, inviteId: string) => AxiosRequestConfig = (
        transitionId,
        inviteId,
    ) => {
        return {
            url: `/invite/${inviteId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    rejectTaskInvite: (transitionId: string, inviteId: string, reason: string) => AxiosRequestConfig = (
        transitionId,
        inviteId,
        reason,
    ) => {
        return {
            url: `/invite/${inviteId}/reject`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
            data: {
                reason,
            },
        };
    };

    expertiseTaskPrintForms: (expertiseTaskId: string) => AxiosRequestConfig = (expertiseTaskId) => {
        return {
            url: `/expertise-task/${expertiseTaskId}/pf`,
            method: 'GET',
        };
    };

    createExpertiseTaskPrintForm: (expertiseTaskId: string, pfCode: string) => AxiosRequestConfig = (
        expertiseTaskId,
        pfCode,
    ) => {
        return {
            method: 'POST',
            url: `/expertise-task/${expertiseTaskId}/pf`,
            params: {
                pfCode: pfCode,
            },
        };
    };

    updateExpertiseTaskPrintForm: (taskPrintFormId: string) => AxiosRequestConfig = (taskPrintFormId) => {
        return {
            method: 'PUT',
            url: `/pf/${taskPrintFormId}`,
        };
    };

    downloadPrintFormFile: (pfId: string) => AxiosRequestConfig = (pfId) => {
        return {
            url: `/pf/${pfId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    saveExpertiseTask: (id: string, submission: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (
        id,
        submission,
    ) => {
        return {
            url: `/expertise-task/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmissionWithAdditionalInfo) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    editUserStatus: (id: string, data: EditStatusDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/status`,
            method: 'PUT',
            data,
        };
    };

    setLogin: (id: string, data: LoginSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/login`,
            method: 'PUT',
            data,
        };
    };

    setRoles: (id: string, data: RolesSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/roles`,
            method: 'PUT',
            data,
        };
    };

    setUserLang: (id: string, lang: TotLocale) => AxiosRequestConfig = (id, lang) => {
        return {
            url: `/administration/user/${id}/lang`,
            method: 'PUT',
            params: {
                lang,
            },
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    assignUserPassword: (id: string, password: string) => AxiosRequestConfig = (id, password) => {
        return {
            url: `/administration/user/${id}/password`,
            method: 'PUT',
            data: { password },
        };
    };

    saveUserHead: (id: string, body: UserUpdateBody) => AxiosRequestConfig = (id, body) => {
        return {
            url: `/administration/user/${id}/update`,
            method: 'PUT',
            data: body,
        };
    };

    userFullName: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'GET',
        };
    };

    setFullName: (id: string, data: FullNameSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'PUT',
            data,
        };
    };

    createUser: (user: UserForCreate) => AxiosRequestConfig = (user) => {
        return {
            url: '/administration/register',
            method: 'POST',
            data: user,
        };
    };

    getRoleListWithoutAdministration: AxiosRequestConfig = {
        url: '/administration/roles',
        method: 'GET',
    };
    // end todo

    loadExpertiseTasksFilterData: AxiosRequestConfig = {
        url: '/expertise-tasks/filters',
        method: 'GET',
    };

    loadExpertFilterData: AxiosRequestConfig = {
        url: '/select/experts',
        method: 'GET',
    };

    loadManagerFilterData: AxiosRequestConfig = {
        url: '/select/curators',
        method: 'GET',
    };

    getTemplateOfExpertiseLifeCycleTransitions: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/transitions`,
            method: 'GET',
        };
    };

    templateOfExpertiseLifeCycleTransition: (transitionId: string, templateId: string) => AxiosRequestConfig = (
        transitionId,
        templateId,
    ) => {
        return {
            url: `/template/${templateId}/changeState`,
            method: 'PUT',
            params: {
                transition: transitionId,
            },
        };
    };

    sendToRework: (transitionId: string, taskId: string, message: string) => AxiosRequestConfig = (
        transitionId: string,
        taskId,
        message,
    ) => {
        return {
            url: `/expertise-task/${taskId}/rework`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(message),
            params: {
                transition: transitionId,
            },
        };
    };

    sendToReject: (transitionId: string, taskId: string, message: string) => AxiosRequestConfig = (
        transitionId: string,
        taskId,
        message,
    ) => {
        return {
            url: `/expertise-task/${taskId}/reject`,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(message),
            params: {
                transition: transitionId,
            },
        };
    };

    pfTemplates: AxiosRequestConfig = {
        url: '/pf-template',
        method: 'GET',
    };

    createPfTemplate: (formData: FormData) => AxiosRequestConfig = (formData) => {
        return {
            url: '/pf-template',
            method: 'POST',
            data: formData,
        };
    };

    updatePfTemplate: (formData: FormData, templateId?: string) => AxiosRequestConfig = (formData, templateId) => {
        return {
            url: '/pf-template/update',
            method: 'PUT',
            params: {
                id: templateId,
            },
            data: formData,
        };
    };

    downloadPfTemplateFile: (pfTemplateId: string) => AxiosRequestConfig = (pfTemplateId) => {
        return {
            url: `/pf-template/${pfTemplateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getFileInfo: (pfTemplateId: string) => AxiosRequestConfig = (pfTemplateId) => {
        return {
            url: `/pf-template/${pfTemplateId}/info`,
            method: 'GET',
        };
    };

    changeExpertiseTaskCurator: (expertiseTaskId: string, curatorUserId: string) => AxiosRequestConfig = (
        expertiseTaskId,
        curatorUserId,
    ) => {
        return {
            url: `/expertise-task/${expertiseTaskId}/curator?userId=${curatorUserId}`,
            method: 'PUT',
        };
    };

    changeStartedDate: (expertiseTaskId: string, started: string) => AxiosRequestConfig = (
        expertiseTaskId,
        started,
    ) => {
        return {
            method: 'PUT',
            url: `/expertise-task/${expertiseTaskId}/started`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(started),
        };
    };

    changeDueDate: (expertiseTaskId: string, deadline: string) => AxiosRequestConfig = (expertiseTaskId, deadline) => {
        return {
            method: 'PUT',
            url: `/expertise-task/${expertiseTaskId}/deadline`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(deadline),
        };
    };
}

export const apiConfigs = new ApiConfigs();
