import { Grid, Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { headerLeftStyles } from '../../../../../constants';
import { useStore } from '../../../../../hooks';
import { CampaignModel } from '../../../../../models';
import { PermissionsStore } from '../../../../../stores';
import { getOrElse } from '../../../../../utils';

export type CampaignHeaderLeftProps = {
    identifier: CampaignModel['identifier'];
    created: CampaignModel['metaInfo']['created'];
    stateTitle: CampaignModel['metaInfo']['stateTitle'];
    managerName: CampaignModel['manager']['name'];
};

export const CampaignHeaderLeft = observer((props: CampaignHeaderLeftProps): JSX.Element => {
    const { identifier, created, stateTitle: stateValue, managerName } = props;
    const { formatMessage, formatDate } = useIntl();
    const { id } = useParams<RouteParamsDefault>();

    const identifierValue = identifier || formatMessage({ id: 'common.withoutNumber' });
    const createdValue = getOrElse(created, formatDate(created), '-');
    const idTitle = formatMessage({ id: 'campaign.fields.identifier' });
    const createdTitle = formatMessage({ id: 'campaign.fields.created' });
    const stateTitle = formatMessage({ id: 'campaign.fields.state' });
    const responsibleTitle = formatMessage({ id: 'campaign.fields.responsible' });
    const syslogLabel = formatMessage({ id: 'syslog.single.nominative' });
    const syslogPath = generatePath(clientRoute.campaignSyslog, {
        id,
    });

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    return (
        <Grid item container sx={headerLeftStyles.container}>
            <CommonInfoLine title={idTitle} value={identifierValue} />
            <CommonInfoLine title={createdTitle} value={createdValue} />
            <CommonInfoLine title={stateTitle} value={stateValue} />
            <CommonInfoLine title={responsibleTitle} value={managerName} />
            <AuthorizationCheck {...administrationQuery}>
                <Link component={NavLink} to={syslogPath}>
                    {syslogLabel}
                </Link>
            </AuthorizationCheck>
        </Grid>
    );
});
