import { ClientBreadcrumbsWithCustomLinks } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { SyslogPage as SyslogPageInj } from '../../../components';
import { ttableDefaultPaths } from '../../../constants';
import { useExpertiseTaskViewPageContext } from '../../../hooks';
import { getTaskBreadcrumbs } from '../../../utils';

export const ExpertiseTaskSyslogPage = observer((): JSX.Element => {
    const [SyslogPage] = di([SyslogPageInj], ExpertiseTaskSyslogPage);

    const intl = useIntl();
    const { id, taskModel } = useExpertiseTaskViewPageContext().model;
    const { identifier } = taskModel;

    const breadcrumbsLinksData = getTaskBreadcrumbs(intl, id, identifier);
    const breadcrumbs = <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />;
    const defaultPath = ttableDefaultPaths.getExpertiseTaskSyslog(id);

    return <SyslogPage breadcrumbs={breadcrumbs} entityType="" defaultPath={defaultPath} />;
});
