import { Dialog } from '@mui/material';
import { DialogSubmitButtonConfig, DialogTitle } from '@platform/front-ui';
import { CodeTitle, TotLocale, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Formik } from 'formik';
import { toJS } from 'mobx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { userMessages } from '../../../../customization';
import { UserForCreate } from '../../../../types';
import { userAddForm } from './UserAddForm';

export type UserAddDialogProps = {
    open: boolean;
    setModalIsClosed: () => void;
    createUser: (user: UserForCreate) => Promise<string | void>;
    userRoleList: CodeTitle[];
};

export type UserAddDialogFormValues = {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    enFirstName: string;
    enLastName: string;
    enMiddleName: string;
    englishUser: boolean;
    roles: CodeTitle[];
};

const initialValues: UserAddDialogFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    enFirstName: '',
    enLastName: '',
    enMiddleName: '',
    englishUser: false,
    roles: [],
};

export const UserAddDialog = (props: UserAddDialogProps): JSX.Element => {
    const { open, createUser, userRoleList, setModalIsClosed } = props;

    const { Yup } = useYup();
    const { formatMessage } = useIntl();

    const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        roles: Yup.array().min(1),
    });

    const handleSubmit = async (values: UserAddDialogFormValues): Promise<void> => {
        const { firstName, lastName, middleName, enFirstName, enLastName, enMiddleName, email, englishUser, roles } =
            values;

        const user: UserForCreate = {
            lastName,
            firstName,
            middleName,
            email,
            lang: englishUser ? TotLocale.en : TotLocale.ru,
            names: [
                {
                    lastName,
                    firstName,
                    middleName,
                    lang: TotLocale.ru,
                },
                {
                    lastName: enLastName,
                    firstName: enFirstName,
                    middleName: enMiddleName,
                    lang: TotLocale.en,
                },
            ],
            roles: roles.map((item) => item.code),
        };

        return createUser(user) as Promise<void>;
    };

    const [isLoading, endIcon, submitHandler] = useAntiDoubleClick(handleSubmit);

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isLoading,
        endIcon,
        text: formatMessage({ id: 'user.register' }),
    };

    const formikForm = userAddForm(toJS(userRoleList), setModalIsClosed, submitButton);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle onCloseClick={setModalIsClosed}>
                <FormattedMessage {...userMessages.add} />
            </DialogTitle>
            <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={submitHandler}>
                {formikForm}
            </Formik>
        </Dialog>
    );
};
