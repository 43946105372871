import { Typography } from '@mui/material';
import { ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskViewContent as ExpertiseTaskViewContentInj } from './expertise-task-content';
import { ExpertiseTaskHeader as ExpertiseTaskHeaderInj } from './expertise-task-header';
import { ExpertiseTaskSidebar as ExpertiseTaskSidebarInj } from './expertise-task-sidebar';
import { ExpertiseTaskSwitchView as ExpertiseTaskSwitchViewInj } from './ExpertiseTaskSwitchView';
import { expertiseTaskViewSidebarWrappersSx } from './ExpertiseTaskViewPageStyles';

export const ExpertiseTaskViewPage = observer((): JSX.Element => {
    const [ExpertiseTaskSwitchView] = di([ExpertiseTaskSwitchViewInj], ExpertiseTaskViewPage);
    const [ExpertiseTaskSidebar] = di([ExpertiseTaskSidebarInj], ExpertiseTaskViewPage);
    const [ExpertiseTaskHeader] = di([ExpertiseTaskHeaderInj], ExpertiseTaskViewPage);
    const [ExpertiseTaskViewContent] = di([ExpertiseTaskViewContentInj], ExpertiseTaskViewPage);

    const { objectLayoutStore } = useStore().coreRootStore;
    const { dropIsHeaderHidden } = objectLayoutStore;

    useEffect(() => dropIsHeaderHidden, []);

    const { formatMessage } = useIntl();

    const sidebarTitle = formatMessage({ id: 'expertiseTask.descriptionPanel.title' });

    const header: ObjectLayoutProps['header'] = <ExpertiseTaskHeader />;

    const infoSidebar: ObjectLayoutProps['infoSidebar'] = {
        isWithToggle: true,
        elements: {
            titleElement: <Typography variant="subtitle2">{sidebarTitle}</Typography>,
            contentElement: <ExpertiseTaskSidebar />,
            wrapperSx: expertiseTaskViewSidebarWrappersSx.wrapper,
            titleWrapperSx: expertiseTaskViewSidebarWrappersSx.title,
        },
    };

    const contentLayout: ObjectLayoutProps['contentLayout'] = {
        content: <ExpertiseTaskViewContent />,
    };

    const tabs: ObjectLayoutProps['tabs'] = {
        element: <ExpertiseTaskSwitchView />,
        isWithCollapseHeader: true,
    };

    return <ObjectLayout header={header} infoSidebar={infoSidebar} tabs={tabs} contentLayout={contentLayout} />;
});
