import { AuthorizationCheck, handleMainInfoError } from '@platform/front-core';
import { TTable, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../constants';
import { useStore } from '../../hooks';
import { PermissionsStore } from '../../stores';
import { RegistryType } from '../../types';

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
    },
};

export const KafkaEventsRegistry = observer((): JSX.Element => {
    const { coreRootStore, userStore } = useStore();
    const { api, catalogStore, history, permissionsStore } = coreRootStore;
    const { userRoleList } = userStore;

    const { locale, formatMessage } = useIntl();
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const tableTitle = formatMessage({ id: 'kafkaEvents.title' });

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable
                    registryCode={RegistryType.kafkaEvent}
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    lang={locale}
                    fetchUserRoleList={userRoleList}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    isAdmin={allowed}
                    visibleSettings={visibleSettings}
                    handleMainInfoError={handleMainInfoError(history)}
                    defaultPath={ttableDefaultPaths.KafkaEventsRegistry}
                />
            )}
        </AuthorizationCheck>
    );
});
