import { PromiseVoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../../components';
import { ExpertiseTaskControlPanelRight as ExpertiseTaskControlPanelRightInj } from './ExpertiseTaskControlPanelRight';

export type ExpertiseTaskControlPanelProps = {
    onSubmit: PromiseVoidFunction;
    goBack: () => void;
};

export const ExpertiseTaskControlPanel = observer((props: ExpertiseTaskControlPanelProps): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], ExpertiseTaskControlPanel);
    const [ExpertiseTaskControlPanelRight] = di([ExpertiseTaskControlPanelRightInj], ExpertiseTaskControlPanel);

    const { onSubmit, goBack } = props;
    const rightControlPanel = <ExpertiseTaskControlPanelRight goBack={goBack} onSubmit={onSubmit} />;
    return <BottomControlPanel right={rightControlPanel} />;
});
