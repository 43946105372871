import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { useFlag } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink, Route } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { PermissionsStore } from '../../stores';
import { PfTemplateTableRow } from '../../types';
import { PfTemplateCreateDialog as PfTemplateCreateDialogInj } from './pf-template-dialog';
import {
    PfTemplateRegistryActionsBtns as PfTemplateRegistryActionsBtnsInj,
    PfTemplateRegistryTable as PfTemplateRegistryTableInj,
} from './pf-template-registry';

export const pfTemplateListPageSetRowActionsInj: TTableRowActions<PfTemplateTableRow> = (row) => {
    const [PfTemplateRegistryActionsBtns] = di([PfTemplateRegistryActionsBtnsInj], PfTemplateListPage);
    return <PfTemplateRegistryActionsBtns pfTemplateTableRow={row} />;
};

export const PfTemplateListPage = observer((): JSX.Element => {
    const [PfTemplateCreateDialog] = di([PfTemplateCreateDialogInj], PfTemplateListPage);
    const [PfTemplateRegistryTable] = di([PfTemplateRegistryTableInj], PfTemplateListPage);
    const [pfTemplateListPageSetRowActions] = di([pfTemplateListPageSetRowActionsInj], PfTemplateListPage);

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const createPfTemplateQuery = systemConfig.createPfTemplate();

    const [, , closePfTemplateCreateDialog] = useFlag();

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="pfTemplate.listTitle" />
                        </Typography>
                        <AuthorizationCheck {...createPfTemplateQuery}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component={NavLink}
                                    to={clientRoute.pfTemplateCreate}
                                >
                                    <FormattedMessage id="pfTemplate.create" />
                                </Button>
                            </Grid>
                        </AuthorizationCheck>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <PfTemplateRegistryTable setRowActions={pfTemplateListPageSetRowActions} />
                    </Grid>
                </Grid>
            </Box>
            <Route path={clientRoute.pfTemplateCreate}>
                <PfTemplateCreateDialog pageTitleId="pfTemplate.create" onCancel={closePfTemplateCreateDialog} />
            </Route>
        </Container>
    );
});
