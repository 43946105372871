import { FormApi } from '@platform/formiojs-react';
import { UserNameModel } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { CodeTitle, IdTitle } from '@platform/front-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { Contacts, ExpertiseTaskDTO } from '../../types';
import { DateUtils } from '../../utils';
import { MetaInfoModel } from '../metaInfo';

export const expertiseTaskModelObservables = {
    id: observable,
    identifier: observable,
    expertise: observable,
    expert: observable,
    curator: observable,
    curatorContacts: observable,
    expertCandidate: observable,
    metaInfo: observable,
    formDTO: observable,
    deadline: observable,
    viewPoint: observable,
    activeInviteId: observable,
    started: observable,

    formApi: observable,
    isFormChanged: observable,

    isWithExperts: computed,
    expertId: computed,
    expertCandidateId: computed,

    load: action.bound,
    setCardData: action.bound,
    setFormData: action.bound,

    setFormApi: action.bound,
    setIsFormChanged: action.bound,
};

export class ExpertiseTaskModel {
    id: string;
    identifier = '';
    expertise: IdTitle = { id: '', title: '' };
    expert: UserNameModel = new UserNameModel();
    curator: UserNameModel = new UserNameModel();
    curatorContacts: Contacts = {};
    expertCandidate: UserNameModel = new UserNameModel();
    metaInfo: MetaInfoModel = new MetaInfoModel();
    formDTO?: FormDTO;
    deadline?: Date;
    viewPoint?: CodeTitle;
    activeInviteId?: string;
    started?: Date;

    formApi?: FormApi;
    isFormChanged = false;

    constructor(id: string) {
        makeObservable(this, expertiseTaskModelObservables);
        this.id = id;
    }

    get isWithExperts(): boolean {
        return !!this.expert.userId || !!this.expertCandidate.userId;
    }

    get expertId(): string | undefined {
        return this.expert.userId;
    }

    get expertCandidateId(): string | undefined {
        return this.expertCandidate.userId;
    }

    load(dto: ExpertiseTaskDTO): void {
        const { formInfo } = dto;
        this.setCardData(dto);
        this.setFormData(formInfo);
    }

    setCardData(dto: ExpertiseTaskDTO): void {
        const {
            expert,
            curator,
            expertCandidate,
            metaInfo,
            deadline,
            started,
            activeInviteId,
            viewPoint,
            expertise,
            identifier,
        } = dto;
        this.expert = new UserNameModel();
        if (expert) {
            this.expert.load(expert, true);
        }

        this.curator = new UserNameModel();
        if (curator) {
            this.curator.load(curator, true);
            this.curatorContacts = curator.contacts;
        }

        this.expertCandidate = new UserNameModel();
        if (expertCandidate) {
            this.expertCandidate.load(expertCandidate, true);
        }

        this.metaInfo.load(metaInfo);

        const deadlineDate = new Date(deadline || '');
        this.deadline = DateUtils.isValidDate(deadlineDate) ? deadlineDate : undefined;

        const startedDate = new Date(started || '');
        this.started = DateUtils.isValidDate(startedDate) ? startedDate : undefined;

        this.activeInviteId = activeInviteId;
        this.viewPoint = viewPoint;
        this.expertise = expertise;
        this.identifier = identifier;
    }

    setFormData(formDTO?: FormDTO): void {
        this.formDTO = formDTO;
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setIsFormChanged(isChanged: boolean): void {
        this.isFormChanged = isChanged;
    }
}
