import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Radio } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectionOfExpertListModel } from '../../../models';

export type SelectionOfExpertTableActionsProps = {
    tableModel: SelectionOfExpertListModel;
    row: TTableRow;
};

const checkCircleOutlineIcon = <CheckCircleOutlineIcon />;

const sxStyles = makeSxStyles({
    radio: {
        p: 0,
        visibility: 'hidden',
        '[class*="MuiTableRow"]:hover &': {
            visibility: 'visible',
        },
    },
    checkedRadio: {
        visibility: 'visible',
    },
});

export const SelectionOfExpertTableActions = observer((props: SelectionOfExpertTableActionsProps): JSX.Element => {
    const { tableModel, row } = props;
    const { expertId, onChecked } = tableModel;
    const { id } = row;

    const isChecked = expertId === id;

    const radioSx = { ...sxStyles.radio, ...(isChecked && sxStyles.checkedRadio) };

    return (
        <Box>
            <Radio
                sx={radioSx}
                value={id}
                onChange={onChecked}
                checked={isChecked}
                color="secondary"
                checkedIcon={checkCircleOutlineIcon}
                icon={checkCircleOutlineIcon}
            />
        </Box>
    );
});
