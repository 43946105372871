import { Box, Grid, Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine, DynamicEllipsisBox } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useStore, useSubjectPageContext } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { getOrElse } from '../../../../utils';

export const SubjectHeaderLeft = observer((): JSX.Element => {
    const { model } = useSubjectPageContext();
    const { id, metaInfo, identifier, campaign, expertise } = model;
    const { created, stateTitle } = metaInfo;
    const { formatMessage, formatDate } = useIntl();

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseConfig, expertiseCampaignConfig, systemConfig } = permissionsStore as PermissionsStore;
    const viewExpertiseCampaignQuery = campaign && expertiseCampaignConfig.view(campaign.id);
    const viewExpertiseQuery = expertise && expertiseConfig.view(expertise.id);
    const administrationQuery = systemConfig.administration();

    const identifierValue = identifier || formatMessage({ id: 'common.withoutNumber' });
    const createdValue = getOrElse(created, formatDate(created), '-');
    const campaignLink =
        campaign && viewExpertiseCampaignQuery ? (
            <AuthorizationCheck {...viewExpertiseCampaignQuery}>
                {(allowed) =>
                    allowed ? (
                        <Link
                            component={NavLink}
                            to={generatePath(clientRoute.campaign, {
                                id: campaign.id,
                            })}
                        >
                            {/* todo: не разобрался до конца с работой этого компонента, возможно при длинном названии кампании все поплывет */}
                            <DynamicEllipsisBox text={campaign.title} />
                        </Link>
                    ) : (
                        <DynamicEllipsisBox text={campaign.title} />
                    )
                }
            </AuthorizationCheck>
        ) : (
            '-'
        );
    const expertiseLink =
        expertise && viewExpertiseQuery ? (
            <AuthorizationCheck {...viewExpertiseQuery}>
                {(allowed) =>
                    allowed ? (
                        <Link
                            component={NavLink}
                            to={generatePath(clientRoute.expertise, {
                                id: expertise.id,
                            })}
                        >
                            <DynamicEllipsisBox text={expertise.title} />
                        </Link>
                    ) : (
                        <DynamicEllipsisBox text={expertise.title} />
                    )
                }
            </AuthorizationCheck>
        ) : (
            '-'
        );

    const identifierTitle = formatMessage({ id: 'subject.fields.identifier' });
    const createdTitle = formatMessage({ id: 'subject.fields.created' });
    const stateFieldTitle = formatMessage({ id: 'subject.fields.state' });
    const campaignTitle = formatMessage({ id: 'subject.fields.campaign' });
    const expertiseTitle = formatMessage({ id: 'subject.fields.expertise' });
    const syslogPath = generatePath(clientRoute.subjectSyslog, {
        id,
    });

    return (
        <Box paddingTop={2.75}>
            {/* Для контейнера со spacing нужна обертка-родитель, ибо контейнер без референтной обертки закрывает собой другие компоненты, не давая на них кликнуть. */}
            <Grid container spacing={3}>
                <Grid item>
                    <CommonInfoLine title={identifierTitle} value={identifierValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={createdTitle} value={createdValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={stateFieldTitle} value={stateTitle} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={campaignTitle} value={campaignLink} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={expertiseTitle} value={expertiseLink} />
                </Grid>
                <AuthorizationCheck {...administrationQuery}>
                    <Grid item>
                        <Link component={NavLink} to={syslogPath}>
                            <FormattedMessage id="syslog.single.nominative" />
                        </Link>
                    </Grid>
                </AuthorizationCheck>
            </Grid>
        </Box>
    );
});
