import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { SubjectListPage as SubjectListPageInj } from './subject-list';
import { SubjectPage as SubjectPageInj } from './subject-page';

export const SubjectPages = observer((): JSX.Element => {
    const [SubjectPage] = di([SubjectPageInj], SubjectPages);
    const [SubjectListPage] = di([SubjectListPageInj], SubjectPages);

    return (
        <Switch>
            <Route path={clientRoute.subject}>
                <SubjectPage />
            </Route>
            <Route>
                <SubjectListPage />
            </Route>
        </Switch>
    );
});
