import { Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../hooks';
import { ExpertiseTaskPrintFormsModel } from '../../../../../models';
import { ExpertiseTaskPrintFormsDTO, PrintFormFileDTO } from '../../../../../types';
import { ExpertiseTaskPrintFormsTable as ExpertiseTaskPrintFormsTableInj } from './expertise-task-print-forms-table/ExpertiseTaskPrintFormsTable';
import { TableToolbar as TableToolbarInj } from './ExpertiseTaskPrintForms.styled';

type RouteParams = {
    id: string;
};

export const ExpertiseTaskPrintForms = observer((): JSX.Element => {
    const [TableToolbar] = di([TableToolbarInj], ExpertiseTaskPrintForms);
    const [ExpertiseTaskPrintFormsTable] = di([ExpertiseTaskPrintFormsTableInj], ExpertiseTaskPrintForms);

    const [printForms] = useState<ExpertiseTaskPrintFormsModel>(new ExpertiseTaskPrintFormsModel());
    const { stateTitle } = useExpertiseTaskViewPageContext().model.taskModel.metaInfo;
    const { expertiseTaskStore } = useStore();
    const { id: expertiseTaskId } = useParams<RouteParams>();

    useEffect(() => {
        expertiseTaskStore.loadPrintForms(expertiseTaskId).then((printFormsDTO: ExpertiseTaskPrintFormsDTO) => {
            printForms.load(printFormsDTO);
        });
    }, [expertiseTaskId, stateTitle]);

    const downloadPf = (file: PrintFormFileDTO) => (): void => {
        expertiseTaskStore.downloadPrintFormFile(file);
    };

    return (
        <Paper elevation={1}>
            <TableToolbar>
                <Typography variant="subtitle1">
                    <FormattedMessage id="expertiseTask.printFormsTable.title" />
                </Typography>
            </TableToolbar>
            <ExpertiseTaskPrintFormsTable downloadPf={downloadPf} printForms={printForms} />
        </Paper>
    );
});
