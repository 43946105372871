import { FormApi } from '@platform/formiojs-react';
import { NotificationStore } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { IntlShape } from 'react-intl';
import { ExpertiseTaskStore, RootStore } from '../../stores';
import { ExpertiseTaskViewDTO, ExpertiseTaskViewSwitchVariant, ReportsRow, ReportsRowDTO } from '../../types';
import { DateUtils } from '../../utils';
import { ExpertiseTaskModel } from './ExpertiseTaskModel';

export const expertiseTaskViewModelObservables = {
    expertiseTaskStore: observable,
    notificationStore: observable,
    intl: observable,

    id: observable,
    taskModel: observable,
    reportsTable: observable,

    isHeaderCollapsed: observable,
    switchViewState: observable,

    reportFormDTO: observable,
    reportFormApi: observable,
    isReportFormChanged: observable,

    load: action.bound,
    mapReportsRowDTO: action.bound,
    loadTaskReportForm: action.bound,

    setReportsTableData: action.bound,
    setReportFormDTO: action.bound,
    setReportFormApi: action.bound,
    setIsReportFormChanged: action.bound,

    setIsHeaderCollapsed: action.bound,
    setSwitchViewState: action.bound,
    changeDueDate: action.bound,
    changeStartedDate: action.bound,
    reloadTaskModel: action.bound,
};

export class ExpertiseTaskViewModel {
    protected expertiseTaskStore: ExpertiseTaskStore;
    protected notificationStore: NotificationStore;
    protected intl: IntlShape;

    id: string;
    taskModel: ExpertiseTaskModel;
    reportsTable?: ReportsRow[];

    reportFormDTO?: FormDTO;
    reportFormApi?: FormApi;
    isReportFormChanged = false;

    isHeaderCollapsed = false;
    switchViewState: ExpertiseTaskViewSwitchVariant = ExpertiseTaskViewSwitchVariant.all;

    constructor(id: string, rootStore: RootStore) {
        const { coreRootStore, expertiseTaskStore } = rootStore;
        makeObservable(this, expertiseTaskViewModelObservables);
        this.id = id;
        this.notificationStore = coreRootStore.notificationStore;
        this.expertiseTaskStore = expertiseTaskStore;
        this.taskModel = new ExpertiseTaskModel(id);
        this.intl = coreRootStore.intlStore.intl;
    }

    load(dto: ExpertiseTaskViewDTO): void {
        const { taskInfo, reportsInfo } = dto;
        if (!taskInfo.formInfo) {
            this.notificationStore.onError(this.intl.formatMessage({ id: 'common.formNotFound' }));
        }

        this.taskModel.load(taskInfo);
        reportsInfo && this.setReportsTableData(reportsInfo.map(this.mapReportsRowDTO));
    }

    reloadTaskModel(): Promise<void> {
        return this.expertiseTaskStore.loadTaskView(this.id).then(this.load).then(this.loadTaskReportForm);
    }

    async changeStartedDate(newStartedDate: Date): Promise<void> {
        await this.expertiseTaskStore.changeStartedDate(this.id, newStartedDate);
        this.reloadTaskModel();
    }

    async changeDueDate(newDeadline: Date): Promise<void> {
        await this.expertiseTaskStore.changeDueDate(this.id, newDeadline);
        this.reloadTaskModel();
    }

    mapReportsRowDTO(dto: ReportsRowDTO): ReportsRow {
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');

        const dateDTO = {
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            report: dto.report,
            ...dateDTO,
        };
    }

    loadTaskReportForm(): Promise<void> {
        return this.expertiseTaskStore.loadTaskReportForm(this.id).then(this.setReportFormDTO);
    }

    setReportsTableData(reportsTableData: ReportsRow[]): void {
        this.reportsTable = reportsTableData;
    }

    setReportFormDTO(dto: FormDTO): void {
        this.reportFormDTO = dto;
    }

    setReportFormApi(formApi: FormApi): void {
        this.reportFormApi = formApi;
    }

    setIsReportFormChanged(isChanged: boolean): void {
        this.isReportFormChanged = isChanged;
    }

    setIsHeaderCollapsed(isCollapsed: boolean): void {
        this.isHeaderCollapsed = isCollapsed;
    }

    setSwitchViewState(event: React.SyntheticEvent, viewState: ExpertiseTaskViewSwitchVariant): void {
        this.switchViewState = viewState;
    }
}
