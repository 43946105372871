import { Grid } from '@mui/material';
import { ButtonTab, ButtonTabs } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useExpertiseTaskViewPageContext } from '../../../../hooks';
import { ExpertiseTaskViewSwitchVariant } from '../../../../types';

export const ExpertiseTaskSwitchView = observer(() => {
    const { model } = useExpertiseTaskViewPageContext();
    const { task, all, report } = ExpertiseTaskViewSwitchVariant;

    const { switchViewState, setSwitchViewState } = model;

    const taskDisabled = switchViewState === task;
    const reportDisabled = switchViewState === report;
    const allDisabled = switchViewState === all;

    return (
        <Grid container justifyContent="center" pt={2} pb={1}>
            <Grid item>
                <ButtonTabs value={switchViewState} onChange={setSwitchViewState}>
                    <ButtonTab value={task} disabled={taskDisabled}>
                        <FormattedMessage id="expertiseTask.switchView.task" />
                    </ButtonTab>
                    <ButtonTab value={all} disabled={allDisabled}>
                        <FormattedMessage id="expertiseTask.switchView.all" />
                    </ButtonTab>
                    <ButtonTab value={report} disabled={reportDisabled}>
                        <FormattedMessage id="expertiseTask.switchView.report" />
                    </ButtonTab>
                </ButtonTabs>
            </Grid>
        </Grid>
    );
});
