import { Grid, Typography, Link as MuiLink } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { CampaignPanelExpertiseSubject } from '../CampaignSubjectsPanel';

export const campaignPanelSubjectCountStyles = makeSxStyles({
    container: {
        width: '25%',
        height: '85px',
        paddingTop: (theme) => theme.spacing(1),
        flexDirection: 'column',
    },
    link: {
        display: 'inline',
        color: (theme) => theme.palette.primary.main,
        '&:hover, &:focus': {
            textDecoration: 'none',
            color: (theme) => theme.palette.grey[500],
        },
    },
    linkTypography: {
        color: 'inherit',
        display: 'inline',
    },
});

export const CampaignPanelSubjectCount = observer((props: CampaignPanelExpertiseSubject): JSX.Element => {
    const { labelText, path, count } = props;

    return (
        <Grid item container sx={campaignPanelSubjectCountStyles.container}>
            <Typography variant="body1" pb={0.5}>
                {labelText}
            </Typography>
            <Grid item>
                <MuiLink component={Link} to={path} sx={campaignPanelSubjectCountStyles.link}>
                    <Typography variant="h1" component="span" sx={campaignPanelSubjectCountStyles.linkTypography}>
                        {count}
                    </Typography>
                </MuiLink>
            </Grid>
        </Grid>
    );
});
