import EditOutlined from '@mui/icons-material/EditOutlined';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { TotObjectDeleteButton as TotObjectDeleteButtonInj } from '../../../../components';
import { useStore, useSubjectPageContext } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';

export const SubjectControlPanelLeft = observer((): JSX.Element => {
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], SubjectControlPanelLeft);

    const { model } = useSubjectPageContext();
    const { id, title, metaInfo, deleteSubject } = model;
    const { stateTitle } = metaInfo;
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseSubjectConfig } = permissionsStore as PermissionsStore;
    const deleteExpertiseSubject = expertiseSubjectConfig.deleteExpertiseSubject(id);
    const editExpertiseSubject = expertiseSubjectConfig.editExpertiseSubject(id);

    const handleDelete = (): Promise<void> => {
        return deleteSubject().then(() => {
            history.push(generatePath(clientRoute.subjects));
        });
    };

    const confirmDeletion = formatMessage({ id: 'common.confirmDeletion' });
    const confirmDeletionInfoText = formatMessage({ id: 'subject.confirmDeletionInfoText' }, { title });
    const tooltipTitle = formatMessage({ id: 'subject.actions.editSubject' });

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={2} key={stateTitle}>
            <Switch>
                <Route key={clientRoute.subject} path={clientRoute.subject} exact>
                    <AuthorizationCheck {...deleteExpertiseSubject}>
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-subject"
                                title={confirmDeletion}
                                message={confirmDeletionInfoText}
                                onConfirm={handleDelete}
                                tooltipTitleId="subject.actions.deleteSubject"
                            />
                        </Grid>
                    </AuthorizationCheck>
                    <AuthorizationCheck {...editExpertiseSubject}>
                        <Grid item>
                            <Tooltip title={tooltipTitle} placement="top-end">
                                <IconButton
                                    component={NavLink}
                                    to={generatePath(clientRoute.subjectEdit, {
                                        id,
                                    })}
                                >
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Route>
            </Switch>
        </Grid>
    );
});
