import { useState } from 'react';
import { di } from 'react-magnetic-di';
import {
    ExpertiseTaskConfirmationButtons,
    expertiseTaskSettingsConfirmDialog as expertiseTaskSettingsConfirmDialogInj,
    ExpertiseTaskSettingsConfirmDialogType,
    ExpertiseTaskSettingsPropsType,
} from '../pages';

export type UseExpertiseTaskSettingsConfirmDialog = [
    ExpertiseTaskSettingsConfirmDialogType,
    (actionButton: ExpertiseTaskConfirmationButtons) => void,
];

export const getExpertiseTaskSettingsConfirmDialog = (): any => {
    const [expertiseTaskSettingsConfirmDialog] = di(
        [expertiseTaskSettingsConfirmDialogInj],
        getExpertiseTaskSettingsConfirmDialog,
    );
    return expertiseTaskSettingsConfirmDialog;
};

export const useExpertiseTaskSettingsConfirmDialog = (
    props: ExpertiseTaskSettingsPropsType,
): UseExpertiseTaskSettingsConfirmDialog => {
    const expertiseTaskSettingsConfirmDialog = getExpertiseTaskSettingsConfirmDialog();
    const settings = expertiseTaskSettingsConfirmDialog(props);
    const [settingsConfirm, setSettingsConfirm] = useState<ExpertiseTaskSettingsConfirmDialogType>(
        settings[ExpertiseTaskConfirmationButtons.delete],
    );

    const setSettings = (actionButton: ExpertiseTaskConfirmationButtons): void => {
        setSettingsConfirm(settings[actionButton]);
    };

    return [settingsConfirm, setSettings];
};
