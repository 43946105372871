import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getTemplatesOfExpertiseBreadcrumbs = (
    intl: IntlShape,
    templateId: string,
    templateTitle: string,
): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.templatesOfExpertise,
            routeTitle: formatMessage({ id: 'templatesOfExpertise.listTitle' }),
        },
        {
            to: templateId ? generatePath(clientRoute.templateOfExpertise, { id: templateId }) : '',
            routeTitle: templateTitle,
        },
        {
            to: clientRoute.templateOfExpertiseSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
