import { grey, makeSxStyles } from '@platform/front-ui';

export const expertiseTaskSidebarStyles = makeSxStyles({
    container: {
        flexDirection: 'column',
        maxWidth: '360px',
        '& > *:not(:last-child)': {
            marginBottom: (theme) => theme.spacing(1),
        },
    },
    editableFieldContainer: {
        alignItems: 'flex-end',
        flexWrap: 'nowrap',
    },
    editableFieldToolbar: {
        width: '60%',
        '& > *': {
            color: grey['400'],
            '&:first-child': {
                marginRight: (theme) => theme.spacing(1.625),
            },
        },
    },
    tabPanel: {
        marginLeft: '-24px',
    },
    fullHeight: {
        height: 'calc(100% - 24px)',
    },
    infoWrapper: {
        position: 'relative',
    },
    infoInner: {
        position: 'absolute',
        overflowY: 'auto',
        height: '100%',
        top: 0,
        right: 0,
        left: 0,
    },
});
