export enum HeaderLinkType {
    campaigns = 'campaigns',
    subjectsOfExpertise = 'subjectsOfExpertise',
    categories = 'categories',
    expertises = 'expertises',
    pfTemplates = 'pfTemplates',
    templatesOfExpertise = 'templatesOfExpertise',
    expertiseTasks = 'expertiseTasks',
    users = 'users',
    console = 'console',
}

export type AppHeaderLinkData<Link extends string = HeaderLinkType> = {
    key: Link;
    to: string;
    messageId: string;
    startIcon: JSX.Element;
    exact?: boolean;
};
