import {
    Box,
    CircularProgress,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../../hooks';
import { ExpertiseTaskPrintFormsModel } from '../../../../../../models';
import { PermissionsStore } from '../../../../../../stores';
import { PrintFormFileDTO } from '../../../../../../types';
import { ExpertiseTaskPrintFormsTablePfRow as ExpertiseTaskPrintFormsTablePfRowInj } from './ExpertiseTaskPrintFormsTablePfRow';

export type ExpertiseTaskPrintFormsTableProps = {
    downloadPf(file: PrintFormFileDTO): () => void;
    printForms: ExpertiseTaskPrintFormsModel;
};

export type ExpertiseTaskPrintFormsTableRouteParams = {
    id: string;
};

const loaderWrapperSx: SxProps<Theme> = {
    textAlign: 'center',
};

export const ExpertiseTaskPrintFormsTable = observer((props: ExpertiseTaskPrintFormsTableProps): JSX.Element => {
    const [ExpertiseTaskPrintFormsTablePfRow] = di(
        [ExpertiseTaskPrintFormsTablePfRowInj],
        ExpertiseTaskPrintFormsTable,
    );

    const { downloadPf, printForms } = props;
    const { conclusion, loaded } = printForms;
    const { id } = useParams<ExpertiseTaskPrintFormsTableRouteParams>();

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const updateConclusionPrintFormQuery = expertiseTaskConfig.updateConclusionPrintForm(id);

    return (
        <TableContainer>
            {loaded ? (
                <React.Fragment>
                    {conclusion && (
                        <ExpertiseTaskPrintFormsTablePfRow
                            printForm={conclusion}
                            permission={updateConclusionPrintFormQuery}
                            pfTypeMsgId="expertiseTask.printFormsTable.conclusion"
                            downloadPf={downloadPf}
                        />
                    )}
                </React.Fragment>
            ) : (
                <Table>
                    <TableBody>
                        <TableRow key="loader">
                            <TableCell>
                                <Box sx={loaderWrapperSx}>
                                    <CircularProgress />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
});
