import { LocalesTree } from '@platform/front-utils';

const headerLinks: LocalesTree = {
    campaigns: 'Кампании',
    categories: 'Категории',
    console: 'Консоль',
    expertiseList: 'Экспертизы',
    expertiseTasks: 'Задания',
    pfTemplates: 'Шаблоны ПФ',
    subjects: 'Объекты',
    templatesOfExpertise: 'Шаблоны экспертиз',
    users: 'Пользователи',
};

export default headerLinks;
