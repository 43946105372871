import { Grid, Paper } from '@mui/material';
import { AuthorizationCheck, ObjectContentLayoutProps, ObjectLayout } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ServiceInfoPanel as ServiceInfoPanelInj, TotObjectForm as TotObjectFormInj } from '../../../components';
import { TemplateOfExpertisePageContext, TemplateOfExpertisePageContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { PlanEntryListModel, TemplatesOfExpertiseModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { ExpertisePlan as ExpertisePlanInj } from '../expertise-plan';
import { TemplatesOfExpertiseSyslogPage as TemplatesOfExpertiseSyslogPageInj } from '../templates-of-expertise-syslog';
import { TemplateOfExpertiseControlPanel as TemplateOfExpertiseControlPanelInj } from './template-of-expertise-control-panel';
import { TemplateOfExpertiseHeader as TemplateOfExpertiseHeaderInj } from './template-of-expertise-header';

const templateOfExpertiseRoutePath = [
    clientRoute.templateOfExpertise,
    clientRoute.templateOfExpertisePlanEntryCreate,
    clientRoute.templateOfExpertisePlanEntryEdit,
];

export const TemplateOfExpertisePage = observer((): JSX.Element => {
    const [TemplatesOfExpertiseSyslogPage] = di([TemplatesOfExpertiseSyslogPageInj], TemplateOfExpertisePage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], TemplateOfExpertisePage);
    const [TotObjectForm] = di([TotObjectFormInj], TemplateOfExpertisePage);
    const [ExpertisePlan] = di([ExpertisePlanInj], TemplateOfExpertisePage);
    const [TemplateOfExpertiseControlPanel] = di([TemplateOfExpertiseControlPanelInj], TemplateOfExpertisePage);
    const [TemplateOfExpertiseHeader] = di([TemplateOfExpertiseHeaderInj], TemplateOfExpertisePage);

    const rootStore = useStore();
    const { templatesOfExpertiseStore, history, coreRootStore } = rootStore;
    const { saveChangeTemplateOfExpertise } = templatesOfExpertiseStore;
    const { locale } = useIntl();
    const { id } = useParams<RouteParamsDefault>();

    const planEntryListModel = useMemo(
        () => new PlanEntryListModel(id, templatesOfExpertiseStore, rootStore),
        [id, templatesOfExpertiseStore, rootStore],
    );

    const model = useMemo(
        () => new TemplatesOfExpertiseModel(id, templatesOfExpertiseStore),
        [id, templatesOfExpertiseStore],
    );
    const { load, metaInfo, formApi, formDTO, setFormApi, isShowValidation, dropIsShowValidation } = model;

    const { permissionsStore } = coreRootStore;
    const { systemConfig, templateExpertiseConfig, permissions, entities } = permissionsStore as PermissionsStore;
    const editTemplateExpertiseQuery = templateExpertiseConfig.edit(id);
    const administrationConfig = systemConfig.administration();

    useEffect(() => {
        load();
    }, [id, locale]);

    const onSubmit = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            return formApi
                .submit(false)
                .then(() => {
                    return saveChangeTemplateOfExpertise(id, formApi.getSubmissionWithAdditionalInfo());
                })
                .then(() => {
                    load().then(() => history.push(generatePath(clientRoute.templateOfExpertise, { id })));
                });
        }
        return Promise.reject();
    };

    const validateForm = (): Promise<void> => {
        const isFormValid = (formApi && formApi.validate()) || false;
        if (isFormValid) {
            return Promise.resolve();
        }
        return Promise.reject();
    };

    const pageContextValue: TemplateOfExpertisePageContextType = {
        model,
        onSubmit,
        validateForm,
    };

    const header = <TemplateOfExpertiseHeader />;

    const content: JSX.Element = (
        <Grid container direction="column" wrap="nowrap" overflow="auto">
            <Grid item p={2}>
                <AuthorizationCheck {...administrationConfig}>
                    <ServiceInfoPanel metaInfoModel={metaInfo} withForm={false} elevation={1} />
                </AuthorizationCheck>
                <Grid item component={Paper} mb={3}>
                    {formDTO && (
                        <TotObjectForm
                            formDTO={formDTO}
                            formName="template"
                            editPath={clientRoute.templateOfExpertiseEdit}
                            createPath={clientRoute.templateOfExpertiseCreate}
                            readPath={clientRoute.templateOfExpertise}
                            onFormReady={setFormApi}
                            showReadonlyValidation={isShowValidation}
                            hideReadonlyValidation={dropIsShowValidation}
                            editAuthProps={editTemplateExpertiseQuery}
                        />
                    )}
                </Grid>
                <Route exact path={templateOfExpertiseRoutePath}>
                    <ExpertisePlan
                        createPath={clientRoute.templateOfExpertisePlanEntryCreate}
                        editPath={clientRoute.templateOfExpertisePlanEntryEdit}
                        ownerPagePath={clientRoute.templateOfExpertise}
                        ownerEntityCode={entities.templateExpertise}
                        editPermission={permissions.templateExpertise.EditExpertisePlan}
                        model={planEntryListModel}
                    />
                </Route>
            </Grid>
        </Grid>
    );

    const footer = <TemplateOfExpertiseControlPanel />;

    const contentLayout: ObjectContentLayoutProps = {
        content,
        footer,
    };

    return (
        <TemplateOfExpertisePageContext.Provider value={pageContextValue}>
            <Switch>
                <Route path={clientRoute.templateOfExpertiseSyslog}>
                    <TemplatesOfExpertiseSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout header={header} contentLayout={contentLayout} />
                </Route>
            </Switch>
        </TemplateOfExpertisePageContext.Provider>
    );
});
