import { IdTitleNull } from '@platform/front-utils';

export type NewTask = {
    expertisePlanEntry: IdTitleNull;
};

export enum SaveStatus {
    default = 'default',
    success = 'success',
    failed = 'failed',
}
