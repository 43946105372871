import { Box, Grid, Paper } from '@mui/material';
import { AuthorizationCheck, sxHeight100 } from '@platform/front-core';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../../../clientRoute';
import { TotObjectForm as TotObjectFormInj } from '../../../components';
import { campaignPanelAccordionStyles } from '../../../constants';
import { useStore, useSubjectPageContext } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { SubjectServiceInfo as SubjectServiceInfoInj } from './SubjectServiceInfo';

const contentSx = makeSxStyles({
    container: {
        padding: (theme) => theme.spacing(2),
        paddingBottom: 0,
        ...sxHeight100,
        overflow: 'auto',
    },
    paper: {
        ...campaignPanelAccordionStyles.accordion,
        padding: '16px',
    },
});

export const SubjectPageContent = observer((): JSX.Element => {
    const [SubjectServiceInfo] = di([SubjectServiceInfoInj], SubjectPageContent);
    const [TotObjectForm] = di([TotObjectFormInj], SubjectPageContent);

    const { model } = useSubjectPageContext();
    const {
        id,
        commonFormDTO,
        setCommonFormApi,
        setIsCommonFormChanged,
        hiddenFormDTO,
        setHiddenFormApi,
        setIsHiddenFormChanged,
        dropIsShowCommonValidation,
        dropIsShowHiddenValidation,
        isShowHiddenValidation,
        isShowCommonValidation,
    } = model;

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseSubjectConfig, systemConfig } = permissionsStore as PermissionsStore;
    const editExpertiseSubjectQuery = expertiseSubjectConfig.editExpertiseSubject(id);
    const administrationQuery = systemConfig.administration();

    return (
        <Grid container direction="column" wrap="nowrap" sx={contentSx.container}>
            <AuthorizationCheck {...administrationQuery}>
                <Grid item pb={2}>
                    <SubjectServiceInfo />
                </Grid>
            </AuthorizationCheck>
            {!!commonFormDTO && (
                <Grid item pb={2}>
                    <Box component={Paper} sx={contentSx.paper}>
                        <TotObjectForm
                            formName="common"
                            editPath={clientRoute.subjectEdit}
                            createPath={clientRoute.subjectCreate}
                            readPath={clientRoute.subject}
                            formDTO={commonFormDTO}
                            onFormReady={setCommonFormApi}
                            onFormChange={setIsCommonFormChanged}
                            showReadonlyValidation={isShowCommonValidation}
                            hideReadonlyValidation={dropIsShowCommonValidation}
                            editAuthProps={editExpertiseSubjectQuery}
                        />
                    </Box>
                </Grid>
            )}
            {!!hiddenFormDTO && (
                <Grid item pb={2}>
                    <Box component={Paper} sx={contentSx.paper}>
                        <TotObjectForm
                            formName="hidden"
                            editPath={clientRoute.subjectEdit}
                            createPath={clientRoute.subjectCreate}
                            readPath={clientRoute.subject}
                            formDTO={hiddenFormDTO}
                            onFormReady={setHiddenFormApi}
                            onFormChange={setIsHiddenFormChanged}
                            showReadonlyValidation={isShowHiddenValidation}
                            hideReadonlyValidation={dropIsShowHiddenValidation}
                            editAuthProps={editExpertiseSubjectQuery}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
});
