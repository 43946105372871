import { ActionItemProps } from '@platform/front-types';
import { ActionMenuItem, ConfirmationDialog } from '@platform/front-ui';
import { useFlag, VoidFunction } from '@platform/front-utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from '../../../../hooks';
import { CampaignTableRow } from '../../../../types';

export type CampaignDeleteButtonProps = ActionItemProps & {
    campaignTableRow: CampaignTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const CampaignDeleteButton = (props: CampaignDeleteButtonProps): JSX.Element => {
    const { campaignTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler, hideMenu } = props;
    const { id, customData } = campaignTableRow;

    const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useFlag();

    const { formatMessage } = useIntl();
    const { campaignStore } = useStore();

    const onCancel = (): void => {
        closeConfirmationDialog();
        hideMenu && hideMenu();
    };

    const onDeleteConfirm = useCallback((): Promise<void> => {
        onCancel();
        toggleIsRowBlocked && toggleIsRowBlocked();
        return campaignStore.deleteCampaign(id, true).catch(rowErrorHandler).then(reloadData);
    }, [id, campaignStore, reloadData]);

    const confirmationDialogTitle = formatMessage({ id: 'common.confirmDeletion' });
    const confirmationDialogMessage = formatMessage(
        { id: 'campaign.confirmDeletionInfoText' },
        { title: customData.title },
    );

    return (
        <React.Fragment>
            <ActionMenuItem messageId="common.delete" onClick={openConfirmationDialog} />
            <ConfirmationDialog
                id="confirm-delete-campaign"
                keepMounted
                open={isConfirmationDialogOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onCancel}
                title={confirmationDialogTitle}
                message={confirmationDialogMessage}
            />
        </React.Fragment>
    );
};
