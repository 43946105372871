import { SvgIcon } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { brand, grey } from '@platform/front-ui';
import React from 'react';

const svgIconSx: SxStyle = {
    width: '50px',
    height: '45px',
    '@keyframes fillChange': {
        '0%': {
            fill: grey[0],
        },
        '100%': {
            fill: brand[600],
        },
    },
    '[class*="lazysvg__item1"]': {
        animation: 'fillChange 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s infinite',
    },
    '[class*="lazysvg__item2"]': {
        animation: 'fillChange 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s infinite',
    },
    '[class*="lazysvg__item3"]': {
        animation: 'fillChange 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s infinite',
    },
    '[class*="lazysvg__item4"]': {
        animation: 'fillChange 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s infinite',
    },
};

// LazyLoader - компонент, необходимый для реализации Lazy страниц

export const LazyLoader = (): JSX.Element => {
    return (
        <SvgIcon className="lazysvg" sx={svgIconSx}>
            <path
                d="M0.0727074 0.677901C0.0727074 0.304252 0.487713 0.0755344 0.826704 0.262359L9.81013 5.2133C10.1551 5.40343 10.1626 5.87969 9.82354 6.06655L0.840117 11.0175C0.501048 11.2044 0.0727077 10.96 0.0727077 10.5798L0.0727074 0.677901Z"
                fill={brand[600]}
                className="lazysvg__item1"
            ></path>
            <path
                d="M1.36293 12.5136C1.01804 12.3235 1.01804 11.8279 1.36293 11.6378L10.3453 6.68748C10.6785 6.50382 11.0866 6.74488 11.0866 7.12538L11.0866 17.0261C11.0866 17.4065 10.6785 17.6476 10.3453 17.464L1.36293 12.5136Z"
                fill={brand[600]}
                className="lazysvg__item2"
            ></path>
            <path
                d="M11.7578 7.13396C11.7578 6.76031 12.1728 6.53159 12.5118 6.71841L21.4952 11.6694C21.8402 11.8595 21.8477 12.3357 21.5086 12.5226L12.5252 17.4735C12.1861 17.6604 11.7578 17.4161 11.7578 17.0358L11.7578 7.13396Z"
                fill={brand[600]}
                className="lazysvg__item3"
            ></path>
            <path
                d="M12.7702 6.16327C12.4253 5.9732 12.4253 5.47755 12.7702 5.28747L21.7525 0.337134C22.0857 0.153482 22.4938 0.394543 22.4938 0.775034L22.4938 10.6757C22.4938 11.0562 22.0857 11.2973 21.7525 11.1136L12.7702 6.16327Z"
                fill={brand[600]}
                className="lazysvg__item4"
            ></path>
        </SvgIcon>
    );
};
