import { Box, DialogContent, Grid, Link, Typography } from '@mui/material';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
} from '@platform/front-ui';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import {
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from '../../../../components';
import { useExpertiseCreateDialogContext, useStore } from '../../../../hooks';
import { ExpertiseCreateModel } from '../../../../models';
import { ExpertiseCreateType } from '../../../../types';
import { getOrElse } from '../../../../utils';
import { ExpertisePlanEntry as ExpertisePlanEntryInj } from '../../components';

export type ExpertiseCreateDialogFormProps = {
    expertiseCreateModel: ExpertiseCreateModel;
    submitButton: DialogSubmitButtonConfig;
};

const lightBorder = `1px solid #000000`;

export const ExpertiseCreateDialogForm = observer((props: ExpertiseCreateDialogFormProps): JSX.Element => {
    const [ExpertisePlanEntry] = di([ExpertisePlanEntryInj], ExpertiseCreateDialogForm);
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], ExpertiseCreateDialogForm);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], ExpertiseCreateDialogForm);

    const { expertiseCreateModel, submitButton } = props;
    const { subjects, templates, lifeCycles, planEntries, processTitle, loadPlanEntryDetails } = expertiseCreateModel;

    const { onClose, campaign, subject, byCampaign } = useExpertiseCreateDialogContext();

    const { values } = useFormikContext<ExpertiseCreateType>();
    const { expertiseTemplates } = values;

    const { formatMessage } = useIntl();

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const { templatesOfExpertiseStore } = useStore();

    useEffect(() => {
        expertiseTemplates &&
            templatesOfExpertiseStore.loadTemplateDTO(expertiseTemplates.id).then(loadPlanEntryDetails);
    }, [expertiseTemplates]);

    const expertiseSubjectLabel = formatMessage({
        id: 'expertise.createDialogFields.expertiseSubject',
    });
    const templateExpertiseLabel = formatMessage({
        id: 'expertise.createDialogFields.templateExpertise',
    });
    const lifeCycleLabel = formatMessage({
        id: 'expertise.createDialogFields.lifeCycle',
    });
    const noOptionsText = formatMessage({ id: 'common.noOptions' });

    const planEntryElements =
        expertiseTemplates &&
        planEntries.map((planEntry, index) => {
            const number = ++index;
            // TODO: редизайн карточки плана
            return (
                <ExpertisePlanEntry
                    key={number}
                    index={number}
                    taskTypeTitle={planEntry.taskTypeTitle}
                    taskMin={planEntry.tasksMin}
                    taskMax={planEntry.tasksMax}
                    acceptanceDays={planEntry.acceptanceDays}
                    acceptance={planEntry.acceptance}
                    deadlineDays={planEntry.deadlineDays}
                    deadline={planEntry.deadline}
                />
            );
        });

    return (
        <Form>
            <DialogContent>
                <Grid container spacing={2} direction="column" justifyContent="center">
                    <Grid item>
                        {byCampaign ? (
                            <Link component={NavLink} to={clientRoute.subjects}>
                                <FormattedMessage id="expertise.expertiseSubjectsOfCampaign" values={{ ...campaign }} />
                            </Link>
                        ) : (
                            <AutocompleteField
                                fieldName="expertiseSubjects"
                                options={subjects}
                                label={expertiseSubjectLabel}
                                disabled={!!subject}
                                required
                                noOptionsText={noOptionsText}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName="expertiseTemplates"
                            options={templates}
                            label={templateExpertiseLabel}
                            noOptionsText={noOptionsText}
                            required={byCampaign}
                        />
                    </Grid>
                    <Grid item>
                        {getOrElse(
                            expertiseTemplates,
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.createDialogFields.lifeCycle" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{processTitle || '-'}</TotObjectPanelValue>
                                </Grid>
                            </Grid>,
                            <AutocompleteField
                                fieldName="expertiseLifeCycles"
                                options={lifeCycles}
                                label={lifeCycleLabel}
                                required
                                noOptionsText={noOptionsText}
                            />,
                        )}
                    </Grid>
                </Grid>
                {expertiseTemplates && !!planEntries.length && (
                    <Box>
                        <Typography variant="subtitle2" className="t-new-expertise-title">
                            <Box borderBottom={lightBorder} pb={2} pt={3}>
                                <FormattedMessage id="expertise.plan.listTitle" />
                            </Box>
                        </Typography>
                        {planEntryElements}
                    </Box>
                )}
            </DialogContent>

            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
        </Form>
    );
});
