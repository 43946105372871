import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Portal } from '@mui/material';
import { RenderTransitionDialogProps } from '@platform/front-core';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    SxStyle,
    Typography,
} from '@platform/front-ui';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '../../../hooks';

const agreementTypographySx: SxStyle = {
    whiteSpace: 'pre-line',
    pb: 2,
};

export const ExpertiseTaskInviteSentToAcceptDialog = observer((props: RenderTransitionDialogProps): JSX.Element => {
    const { onConfirm, onClose, isOpen } = props;

    const { formatMessage } = useIntl();
    const [isAgree, toggleIsAgree] = useToggle();

    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onConfirm);

    const formControlLabelCheckbox = <Checkbox color="primary" checked={isAgree} onClick={toggleIsAgree} />;

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButton: DialogSubmitButtonConfig = {
        onClick: onSubmitHandler,
        disabled: isLoading || !isAgree,
        endIcon,
        text: formatMessage({ id: 'expertiseTask.invites.accept' }),
    };

    const agreeLabel = formatMessage({ id: 'expertiseTask.invites.agree' });

    return (
        <Portal>
            <Dialog fullWidth maxWidth="md" aria-labelledby="confirmation-dialog-title" open={isOpen}>
                <DialogTitle id="confirmation-dialog-title">
                    <FormattedMessage id="common.lifeCycleConfirmTitle" />
                </DialogTitle>
                <DialogContent>
                    <Typography sx={agreementTypographySx}>
                        <FormattedMessage id="expertiseTask.invites.agreement" />
                    </Typography>
                    <FormControlLabel
                        value="top"
                        control={formControlLabelCheckbox}
                        label={agreeLabel}
                        labelPlacement="end"
                    />
                </DialogContent>
                <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
            </Dialog>
        </Portal>
    );
});
