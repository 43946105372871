import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Link, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine, makeSxStyles } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useExpertisePageContext, useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { getOrElse } from '../../../../utils';

const expertiseHeaderLeftStyles = makeSxStyles({
    container: {
        flexWrap: 'nowrap',
        '& > *': {
            width: 'auto !important',
            marginRight: (theme) => theme.spacing(3) + ' !important',
        },
    },
});

export type ExpertiseHeaderLeftProps = {
    setModalIsOpen: VoidFunction;
};

export const ExpertiseHeaderLeft = observer((props: ExpertiseHeaderLeftProps): JSX.Element => {
    const { setModalIsOpen } = props;

    const { model } = useExpertisePageContext();
    const { identifier, metaInfo, template, campaign: expertiseCampaign, subject: subjectOfExpertise, curator } = model;
    const { stateTitle: state, created } = metaInfo;
    const { name: curatorName } = curator;

    const { formatMessage, formatDate } = useIntl();
    const { id } = useParams<RouteParamsDefault>();

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig, expertiseConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();
    const updateCuratorQuery = expertiseConfig.updateCurator(id);

    const identifierLabel = formatMessage({ id: 'expertise.fields.identifier' });
    const identifierValue = getOrElse(identifier);

    const stateLabel = formatMessage({ id: 'expertise.fields.state' });
    const stateValue = getOrElse(state);

    const createdLabel = formatMessage({ id: 'expertise.fields.created' });
    const createdValue = getOrElse(created, formatDate(created), '-');

    const syslogLabel = formatMessage({ id: 'syslog.single.nominative' });
    const syslogPath = generatePath(clientRoute.expertiseSyslog, {
        id,
    });

    const campaignLabel = formatMessage({ id: 'expertise.fields.expertiseCampaign' });
    const campaignTitle = expertiseCampaign?.title || '-';
    const campaignValue = expertiseCampaign && (
        <Link
            component={NavLink}
            to={generatePath(clientRoute.campaign, {
                id: expertiseCampaign?.id,
            })}
        >
            {campaignTitle}
        </Link>
    );

    const templateLabel = formatMessage({ id: 'expertise.fields.template' });
    const templateTitle = template?.title || '-';
    const templateValue = template && (
        <Link
            component={NavLink}
            to={generatePath(clientRoute.templateOfExpertise, {
                id: template.id,
            })}
        >
            {templateTitle}
        </Link>
    );

    const expertiseSubjectLabel = formatMessage({ id: 'expertise.fields.expertiseSubject' });
    const expertiseSubjectValue = subjectOfExpertise && (
        <Link
            component={NavLink}
            to={generatePath(clientRoute.subject, {
                id: subjectOfExpertise?.id,
            })}
        >
            {subjectOfExpertise.number}
        </Link>
    );

    const curatorLabel = formatMessage({ id: 'expertise.fields.curator' });
    const tooltipTitle = formatMessage({ id: 'expertise.changeCurator' });

    return (
        <Grid item container flexDirection="column" mt={3}>
            <Grid item container sx={expertiseHeaderLeftStyles.container}>
                <Grid item>
                    <CommonInfoLine title={identifierLabel} value={identifierValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={createdLabel} value={createdValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={stateLabel} value={stateValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={campaignLabel} value={campaignValue} />
                </Grid>
                <AuthorizationCheck {...administrationQuery}>
                    <Grid item>
                        <Link component={NavLink} to={syslogPath}>
                            {syslogLabel}
                        </Link>
                    </Grid>
                </AuthorizationCheck>
            </Grid>
            <Grid item container mt={2} sx={expertiseHeaderLeftStyles.container} alignItems="baseline">
                <Grid item>
                    <CommonInfoLine title={templateLabel} value={templateValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={expertiseSubjectLabel} value={expertiseSubjectValue} />
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="baseline"
                        justifyContent="flex-start"
                        spacing={2}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <CommonInfoLine title={curatorLabel} value={curatorName} />
                        </Grid>
                        <AuthorizationCheck {...updateCuratorQuery}>
                            <Grid item>
                                <Tooltip title={tooltipTitle} placement="top-end">
                                    <IconButton onClick={setModalIsOpen}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
