import { action, makeObservable, observable } from 'mobx';
import {
    CampaignExpertiseLinks,
    CampaignLink,
    CampaignLinksModelDTO,
    CampaignSubjectsLinks,
    CampaignTasksLinks,
} from '../../types';

const defaultLink: CampaignLink = {
    urn: '',
    queries: {},
};

export const campaignLinksModelObservables = {
    subjects: observable,
    expertises: observable,
    tasks: observable,

    load: action.bound,
};

export class CampaignLinksModel {
    subjects: CampaignSubjectsLinks = {
        total: defaultLink,
        active: defaultLink,
        withoutExpertise: defaultLink,
        withExpertise: defaultLink,
    };
    expertises: CampaignExpertiseLinks = {
        total: defaultLink,
        active: defaultLink,
        done: defaultLink,
        canceled: defaultLink,
    };
    tasks: CampaignTasksLinks = {
        total: defaultLink,
        active: defaultLink,
        overdue: defaultLink,
        done: defaultLink,
        notCompleted: defaultLink,
        responseWaiting: defaultLink,
        withoutExpert: defaultLink,
    };

    constructor() {
        makeObservable(this, campaignLinksModelObservables);
    }

    load(dto: CampaignLinksModelDTO): void {
        this.subjects = dto.subjects;
        this.expertises = dto.expertise;
        this.tasks = dto.tasks;
    }
}
