import { Grid } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../../hooks';
import { PermissionsStore } from '../../../../../../../stores';
import { ExpertiseTaskAuthEditField as ExpertiseTaskAuthEditFieldInj } from '../../../../expertise-task-edit-mode';
import { ExpertiseTaskEditDateDialog as ExpertiseTaskEditDateDialogInj } from '../../../../ExpertiseTaskEditDateDialog';
import { expertiseTaskSidebarStyles } from '../../expertiseTaskSidebarStyles';

export const ExpertiseTaskViewStartDateField = observer((): JSX.Element => {
    const [ExpertiseTaskEditDateDialog] = di([ExpertiseTaskEditDateDialogInj], ExpertiseTaskViewStartDateField);
    const [ExpertiseTaskAuthEditField] = di([ExpertiseTaskAuthEditFieldInj], ExpertiseTaskViewStartDateField);

    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel, changeStartedDate } = model;
    const { deadline, started, id } = taskModel;
    const { formatMessage, formatDate } = useIntl();

    const [isModalOpen, openModal, closeModal] = useFlag();

    const { permissionsStore } = useStore().coreRootStore;
    const { permissions } = permissionsStore as PermissionsStore;

    const startedValue = started ? formatDate(started) : undefined;
    const startedTitle = formatMessage({ id: 'expertiseTask.fields.started' });
    const changeStartDateTitle = formatMessage({ id: 'expertiseTask.changeStartDate' });

    const submitStartedDate = async (newStartDate: Date): Promise<void> => {
        await changeStartedDate(newStartDate);
        closeModal();
    };

    return (
        <Grid container sx={expertiseTaskSidebarStyles.editableFieldContainer}>
            <ExpertiseTaskEditDateDialog
                open={isModalOpen}
                onCancel={closeModal}
                onSubmit={submitStartedDate}
                currentDate={started}
                label={startedTitle}
                title={changeStartDateTitle}
                maxValue={deadline}
            />
            <CommonInfoLine title={startedTitle} value={startedValue} isColumnDirection />
            <Grid container sx={expertiseTaskSidebarStyles.editableFieldToolbar}>
                <ExpertiseTaskAuthEditField
                    toggleIsOpenDialog={openModal}
                    messageId="expertiseTask.editStarted"
                    permCode={permissions.expertiseTask.EditDeadlineAndStartedDates}
                    entityId={id}
                />
            </Grid>
        </Grid>
    );
});
