import { Grid, Link } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { MetaInfoModel } from '../../../models';
import { getOrElse } from '../../../utils';
import { ExpertiseTaskServiceInfoWrapper as ExpertiseTaskServiceInfoWrapperInj } from './ExpertiseTaskServiceInfoWrapper';

export type ExpertiseTaskServiceInfoProps = {
    metaInfoModel: MetaInfoModel;
    withAccordion?: boolean;
};

export const ExpertiseTaskServiceInfo = observer((props: ExpertiseTaskServiceInfoProps): JSX.Element => {
    const [ExpertiseTaskServiceInfoWrapper] = di([ExpertiseTaskServiceInfoWrapperInj], ExpertiseTaskServiceInfo);

    const { withAccordion = true, metaInfoModel } = props;
    const { formatMessage, formatDate } = useIntl();

    const { formLinks, processLink, modified, author, created } = metaInfoModel;

    const processLinkComponent = getOrElse(
        processLink,
        <Link target="_blank" href={processLink?.url}>
            {processLink?.label}
        </Link>,
    );

    const formLinkComponents = formLinks.map((item) => (
        <Link key={item.url} target="_blank" href={item.url}>
            {item.label}
        </Link>
    ));

    const formLinksOrNull = getOrElse(formLinks, formLinkComponents);
    const authorTitle = formatMessage({ id: 'common.serviceInfoFields.author' });
    const createdTitle = formatMessage({ id: 'common.serviceInfoFields.created' });
    const lastModifiedTitle = formatMessage({ id: 'common.serviceInfoFields.lastModified' });
    const lifeCycleTitle = formatMessage({ id: 'common.serviceInfoFields.lifeCycle' });
    const formTitle = formatMessage({ id: 'common.serviceInfoFields.form' });

    return (
        <ExpertiseTaskServiceInfoWrapper withAccordion={withAccordion}>
            <Grid container direction="column" spacing={1}>
                <CommonInfoLine title={authorTitle} value={author.name} isColumnDirection />
                <CommonInfoLine
                    title={createdTitle}
                    value={getOrElse(created, formatDate(created))}
                    isColumnDirection
                />
                <CommonInfoLine
                    title={lastModifiedTitle}
                    value={getOrElse(modified, formatDate(modified))}
                    isColumnDirection
                />
                <CommonInfoLine title={lifeCycleTitle} value={processLinkComponent} isColumnDirection />
                <CommonInfoLine title={formTitle} value={formLinksOrNull} isColumnDirection />
            </Grid>
        </ExpertiseTaskServiceInfoWrapper>
    );
});
