import { SkeletonProps } from '@mui/lab';
import { Divider, Grid, Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { SxStyle, UserStatus } from '@platform/front-types';
import { CommonInfoLine, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useStore, useUserPageContext } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { UserPageSidebarActions as UserPageSidebarActionsInj } from './user-page-sidebar-actions';

const linkSx: SxStyle = {
    display: 'inline-block',
    padding: 0,
};

const langSkeletonProps: SkeletonProps = {
    width: 60,
};

export const UserPageSidebar = observer((): JSX.Element => {
    const [UserPageSidebarActions] = di([UserPageSidebarActionsInj], UserPageSidebar);

    const { userModel } = useUserPageContext();
    const { formatMessage } = useIntl();
    const { lang, isLoading } = userModel;
    const { id, status, blockInfo } = userModel;
    const { permissionsStore } = useStore().coreRootStore;
    const syslogLinkPath = generatePath(clientRoute.userSyslog, { id });
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const mainLanguageTitle = formatMessage({ id: 'user.mainLanguage' });
    const mainLanguageValue = lang && formatMessage({ id: `locale.${lang}` });
    const isUserBlocked = status?.code === UserStatus.blocked;

    return (
        <Grid container direction="column">
            <Grid container item justifyContent="space-between" alignItems="center" mb={1}>
                <Grid item>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="user.info" />
                    </Typography>
                </Grid>
                <Grid item>
                    <UserPageSidebarActions />
                </Grid>
            </Grid>
            {isUserBlocked && (
                <Grid container item mb={2}>
                    <CommonInfoLine
                        title={formatMessage({ id: 'user.blockReason' })}
                        value={blockInfo?.blockReason}
                        isColumnDirection={true}
                        isLoading={isLoading}
                    />
                </Grid>
            )}
            <Grid container item>
                <CommonInfoLine
                    title={mainLanguageTitle}
                    value={mainLanguageValue}
                    isColumnDirection={true}
                    isLoading={isLoading}
                    skeletonProps={langSkeletonProps}
                />
            </Grid>
            <AuthorizationCheck {...administrationQuery}>
                <Grid item container direction="column">
                    <Grid item my={2}>
                        <Divider light />
                    </Grid>
                    <Grid item>
                        <Link component={NavLink} to={syslogLinkPath} sx={linkSx}>
                            <FormattedMessage id="syslog.single.nominative" />
                        </Link>
                    </Grid>
                </Grid>
            </AuthorizationCheck>
        </Grid>
    );
});
