import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';

export type ExpertiseTaskAuthEditFieldProps = {
    toggleIsOpenDialog?: () => void;
    messageId: string;
    permCode: string;
    entityId?: string;
};

const outlinedIconSx: SxStyle = { cursor: 'pointer' };

export const ExpertiseTaskAuthEditField = observer((props: ExpertiseTaskAuthEditFieldProps): JSX.Element => {
    const { toggleIsOpenDialog, messageId, entityId, permCode } = props;
    const { formatMessage } = useIntl();
    const { permissionsStore } = useStore().coreRootStore;
    const { entities } = permissionsStore as PermissionsStore;
    const { taskModel } = useExpertiseTaskViewPageContext().model;

    const tooltipTitle = formatMessage({ id: messageId });
    const { stateTitle } = taskModel.metaInfo;

    return (
        <React.Fragment>
            {toggleIsOpenDialog && (
                <AuthorizationCheck
                    key={stateTitle}
                    entityCode={entities.expertiseTask}
                    permCode={permCode}
                    entityId={entityId}
                >
                    <Tooltip title={tooltipTitle}>
                        <EditOutlinedIcon onClick={toggleIsOpenDialog} sx={outlinedIconSx} />
                    </Tooltip>
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
