import { UserStatusAction } from '@platform/front-types';
import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

export const userMessages = defineMessages({
    langUser: {
        id: 'user.phrases.lang',
        values: {
            user: <FormattedMessage id="user.single.nominative" />,
        },
    },
    add: {
        id: 'user.phrases.addUser',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    loginAsUser: {
        id: 'user.phrases.loginAsUser',
        values: {
            user: <FormattedMessage id="user.single.instrumental" />,
        },
    },
});

export const usersConfirmStatusMessages: Record<UserStatusAction, MessageDescriptor> = defineMessages({
    block: {
        id: 'user.phrases.confirmStatusBlock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    activate: {
        id: 'user.phrases.confirmStatusActivate',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    unblock: {
        id: 'user.phrases.confirmStatusUnblock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
});

export const usersConfirmStatusTextMessages: Record<UserStatusAction, MessageDescriptor> = defineMessages({
    block: {
        id: 'user.phrases.confirmStatusTextBlock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    activate: {
        id: 'user.phrases.confirmStatusTextActivate',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
    unblock: {
        id: 'user.phrases.confirmStatusTextUnblock',
        values: {
            user: <FormattedMessage id="user.single.accusative" />,
        },
    },
});
