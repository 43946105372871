import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getSubjectBreadcrumbs = (
    intl: IntlShape,
    subjectId: string,
    subjectTitle: string,
): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.subjects,
            routeTitle: formatMessage({ id: 'subject.subjects' }),
        },
        {
            to: subjectId ? generatePath(clientRoute.subject, { id: subjectId }) : '',
            routeTitle: subjectTitle,
        },
        {
            to: clientRoute.subjectSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
