import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../components';
import { CategoryOfSubjectsControlPanelLeft as CategoryOfSubjectsControlPanelLeftInj } from './CategoryOfSubjectsControlPanelLeft';
import { CategoryOfSubjectsControlPanelRight as CategoryOfSubjectsControlPanelRightInj } from './CategoryOfSubjectsControlPanelRight';

export const CategoryOfSubjectsControlPanel = observer((): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], CategoryOfSubjectsControlPanel);
    const [CategoryOfSubjectsControlPanelLeft] = di(
        [CategoryOfSubjectsControlPanelLeftInj],
        CategoryOfSubjectsControlPanel,
    );
    const [CategoryOfSubjectsControlPanelRight] = di(
        [CategoryOfSubjectsControlPanelRightInj],
        CategoryOfSubjectsControlPanel,
    );

    const leftSide = <CategoryOfSubjectsControlPanelLeft />;
    const rightSide = <CategoryOfSubjectsControlPanelRight />;

    return <BottomControlPanel left={leftSide} right={rightSide} />;
});
