import { Button, Grid, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { AuthorizationCheck, AuthorizationCheckQueryOrWithoutCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, RouteComponentProps, Switch, useParams } from 'react-router-dom';
import {
    AuthorizedRoute as AuthorizedRouteInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
} from '../../../components';
import { useExpertisePageContext, useStore } from '../../../hooks';
import { PlanEntryListModel } from '../../../models';
import { PlanEntryDTO } from '../../../types';
import { EditExpertisePlanEntryFormDialog as EditExpertisePlanEntryFormDialogInj } from './EditExpertisePlanEntryFormDialog';
import { ExpertisePlanTableHead as ExpertisePlanTableHeadInj } from './ExpertisePlanTableHead';
import { NewExpertisePlanEntryFormDialog as NewExpertisePlanEntryFormDialogInj } from './NewExpertisePlanEntryFormDialog';

export type ExpertisePlanProps = {
    createPath: string;
    editPath: string;
    ownerPagePath: string;
    ownerEntityCode: string;
    editPermission: string;
    model: PlanEntryListModel;
};

export type ExpertisePlanRouteParams = {
    id: string;
    entryId: string;
};

export const ExpertisePlan = observer((props: ExpertisePlanProps): JSX.Element => {
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertisePlan);
    const [AuthorizedRoute] = di([AuthorizedRouteInj], ExpertisePlan);
    const [NewExpertisePlanEntryFormDialog] = di([NewExpertisePlanEntryFormDialogInj], ExpertisePlan);
    const [EditExpertisePlanEntryFormDialog] = di([EditExpertisePlanEntryFormDialogInj], ExpertisePlan);
    const [ExpertisePlanTableHead] = di([ExpertisePlanTableHeadInj], ExpertisePlan);

    const { editPermission, editPath, ownerEntityCode, ownerPagePath, createPath, model } = props;
    const { deleteEntry, entries } = model;
    const { id: ownerId } = useParams<ExpertisePlanRouteParams>();
    const { coreRootStore } = useStore();
    const { authorizationStore } = coreRootStore;
    const { model: expertisePageModel } = useExpertisePageContext();
    const { metaInfo } = expertisePageModel;

    const [isPlanCreatingAuthorized, authorizePlanCreating, unauthorizePlanCreating] = useFlag();

    const planCreatingAuthQuery: AuthorizationCheckQueryOrWithoutCheck = {
        entityCode: ownerEntityCode,
        entityId: ownerId,
        permCode: editPermission,
    };

    useEffect(() => {
        authorizationStore
            .check(planCreatingAuthQuery)
            .then((allowed) => (allowed ? authorizePlanCreating() : unauthorizePlanCreating()));
        // Оставить metaInfo?.stateTitle, т.к. возможен случай, когда код попытается получить доступ к свойству stateTitle
        // в тот момент, когда MetaInfoModel ещё не инициализировалась.
    }, [metaInfo?.stateTitle]);

    const renderActionItems = (entry: PlanEntryDTO, index: number): (() => ReactNode[]) => {
        const onDeleteConfirm = (): Promise<void> => {
            return deleteEntry(entry.id);
        };

        return (): JSX.Element[] => [
            <MenuItem
                dense
                key="edit"
                tabIndex={0}
                component={NavLink}
                to={generatePath(editPath, { id: ownerId, entryId: entry.id })}
            >
                <FormattedMessage id="common.edit" />
            </MenuItem>,
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={<FormattedMessage id="templatesOfExpertise.plan.confirmDeletionInfoText" values={{ index }} />}
                onConfirm={onDeleteConfirm}
            />,
        ];
    };

    const renderActions = (entry: PlanEntryDTO, index: number): JSX.Element => {
        const renderMenuItems = renderActionItems(entry, index);

        return <MenuButton renderMenuItems={renderMenuItems} />;
    };

    const tableBody = entries.map((entry, index) => {
        const {
            id,
            viewPoint,
            taskType,
            deadlineDays,
            acceptanceDays,
            acceptance,
            deadline,
            reportFormCode,
            taskFormCode,
            processTitle,
        } = entry;

        const entryIndex = index + 1;

        const fromToNumberValues = {
            from: entry.tasksMin,
            to: entry.tasksMax,
        };
        const amountOfDaysValue = { count: deadlineDays };
        const acceptanceDaysValue = { count: acceptanceDays };

        return (
            <TableRow key={id} hover>
                <TableCell>{entryIndex}</TableCell>
                <TableCell>{viewPoint || taskType}</TableCell>
                <TableCell>
                    <FormattedMessage id="common.fromToNumber" values={fromToNumberValues} />
                </TableCell>
                <TableCell>
                    {(acceptanceDays && <FormattedMessage id="common.amountOfDays" values={acceptanceDaysValue} />) ||
                        (acceptance && <FormattedDate value={acceptance} />)}
                </TableCell>
                <TableCell>
                    {(deadlineDays && <FormattedMessage id="common.amountOfDays" values={amountOfDaysValue} />) ||
                        (deadline && <FormattedDate value={deadline} />)}
                </TableCell>
                <TableCell>{reportFormCode}</TableCell>
                <TableCell>{taskFormCode}</TableCell>
                <TableCell>{processTitle}</TableCell>
                <AuthorizationCheck entityCode={ownerEntityCode} entityId={ownerId} permCode={editPermission}>
                    <TableCell>{renderActions(entry, entryIndex)}</TableCell>
                </AuthorizationCheck>
            </TableRow>
        );
    });

    return (
        <Grid container direction="column">
            <Switch>
                <AuthorizedRoute
                    entityCode={ownerEntityCode}
                    entityId={ownerId}
                    permCode={editPermission}
                    path={createPath}
                >
                    <NewExpertisePlanEntryFormDialog listModel={model} ownerPagePath={ownerPagePath} />
                </AuthorizedRoute>
                <AuthorizedRoute
                    entityCode={ownerEntityCode}
                    entityId={ownerId}
                    permCode={editPermission}
                    path={editPath}
                    // @ts-ignore
                    render={(route: RouteComponentProps<ExpertisePlanRouteParams>): JSX.Element => (
                        <EditExpertisePlanEntryFormDialog
                            planEntryId={route.match.params.entryId}
                            listModel={model}
                            ownerPagePath={ownerPagePath}
                        />
                    )}
                />
            </Switch>
            <Grid item container direction="row" justifyContent="space-between" marginBottom={3}>
                <Grid item>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="templatesOfExpertise.plan.listTitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    {isPlanCreatingAuthorized && (
                        <Button
                            color="primary"
                            variant="contained"
                            component={NavLink}
                            to={generatePath(createPath, { id: ownerId })}
                        >
                            <FormattedMessage id="templatesOfExpertise.plan.createEntry" />
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item marginBottom={4}>
                <Table>
                    <ExpertisePlanTableHead />
                    <TableBody>{tableBody}</TableBody>
                </Table>
            </Grid>
        </Grid>
    );
});
