import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { Theme, ThemeProvider } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import '@platform/crypto-ui/lib/index.css';
import '@platform/formiojs-react/lib/index.css';
import { RootProps, RootStoreProps, TotIntlProvider, YMetrika } from '@platform/front-core';
import { createTotTheme } from '@platform/front-ui';
import '@platform/front-ui/lib/index.css';
import { CoreStoreContext, TotLocale, useCoreStore } from '@platform/front-utils';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { FeaturesProvider, useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di, DiProvider } from 'react-magnetic-di';
import { Router } from 'react-router-dom';
import { App } from './App';
import { StoreContext } from './contexts';
import { useStore } from './hooks';
import { RootStore as RootStoreInj } from './stores';

const ruDefault = require(`./customization/default/locales/ru`).default || {};
const enDefault = require(`./customization/default/locales/en`).default || {};

const generateClassName = createGenerateClassName({
    productionPrefix: 'mexp-front-core',
    seed: 'mexp-front-core',
});

const RootUnwrapped = observer((): JSX.Element => {
    const { theme } = useStore();
    const { intlStore } = useCoreStore();
    const [yaMetrika] = useFeature('yaMetrika');
    return (
        <React.Fragment>
            {yaMetrika && <YMetrika />}
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme as Theme}>
                    <TotIntlProvider>
                        <LocalizationProvider
                            dateAdapter={DateAdapter}
                            locale={intlStore.locale === TotLocale.ru ? ruLocale : enLocale}
                        >
                            <App />
                        </LocalizationProvider>
                    </TotIntlProvider>
                </ThemeProvider>
            </StylesProvider>
        </React.Fragment>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

const RootWithStore = (props: RootStoreProps): JSX.Element => {
    const [RootStore] = di([RootStoreInj], RootWithStore);
    const { theme } = props;
    const store = new RootStore(props);
    store.setAppTheme(theme);

    return (
        <Router history={store.history}>
            <StoreContext.Provider value={store}>
                <CoreStoreContext.Provider value={store.coreRootStore}>
                    <RootWithFeatures />
                </CoreStoreContext.Provider>
            </StoreContext.Provider>
        </Router>
    );
};

export const Root = (props: RootProps): JSX.Element => {
    const { palette, locales: propsLocales, use = [] } = props;
    const theme = createTotTheme(palette);
    const locales = {
        ru: { ...ruDefault, ...propsLocales?.ru },
        en: { ...enDefault, ...propsLocales?.en },
    };

    return (
        <DiProvider use={use}>
            <RootWithStore {...props} theme={theme} locales={locales} />
        </DiProvider>
    );
};
