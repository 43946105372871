import { Box, Button, Container, Grid } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { useAntiDoubleClick } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { CampaignTableRow } from '../../../types';
import {
    CampaignRegistryActionsBtns as CampaignRegistryActionsBtnsInj,
    CampaignRegistryTable as CampaignRegistryTableInj,
} from './campaign-registry';

export const campaignListPageSetRowActionsInj: TTableRowActions<CampaignTableRow> = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [CampaignRegistryActionsBtns] = di([CampaignRegistryActionsBtnsInj], CampaignListPage);

    return (
        <CampaignRegistryActionsBtns
            campaignTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const CampaignListPage = observer((): JSX.Element => {
    const [CampaignRegistryTable] = di([CampaignRegistryTableInj], CampaignListPage);
    const [campaignListPageSetRowActions] = di([campaignListPageSetRowActionsInj], CampaignListPage);

    const rootStore = useStore();
    const { campaignStore, coreRootStore } = rootStore;
    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const addExpertiseCampaignQuery = systemConfig.addExpertiseCampaign();
    const history = useHistory();

    const createCampaign = useCallback(async (): Promise<void> => {
        const id = await campaignStore.createCampaign();
        history.push(generatePath(clientRoute.campaignCreate, { id }));
    }, [history, generatePath]);

    const [isLoading, endIcon, handleSubmit] = useAntiDoubleClick(createCampaign);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="campaign.campaignListTitle" />
                        </Typography>
                        <AuthorizationCheck {...addExpertiseCampaignQuery}>
                            <Grid item>
                                <Button
                                    disabled={isLoading}
                                    endIcon={endIcon}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    <FormattedMessage id="campaign.createCampaign" />
                                </Button>
                            </Grid>
                        </AuthorizationCheck>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <CampaignRegistryTable setRowActions={campaignListPageSetRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
