import { Typography } from '@mui/material';
import { grey } from '@platform/front-ui';
import React, { PropsWithChildren } from 'react';

export const TotObjectPanelLabel = (props: PropsWithChildren<{}>): JSX.Element => {
    const { children } = props;

    return (
        <Typography variant="body1" component="span" color={grey['400']}>
            {children}
        </Typography>
    );
};

export const TotObjectPanelValue = (props: PropsWithChildren<{}>): JSX.Element => {
    const { children } = props;

    return (
        <Typography variant="body1" component="span">
            {children}
        </Typography>
    );
};
