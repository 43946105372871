import { AuthorizationCheck } from '@platform/front-core';
import { TTable, TTableRowActions } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { RegistryType, UserTableRow } from '../../../../types';

export type UserRegistryTableProps = {
    setRowActions?: TTableRowActions<UserTableRow>;
};

const registryCode = RegistryType.account;

export const UserRegistryTable = observer((props: UserRegistryTableProps): JSX.Element => {
    const { setRowActions } = props;
    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api, permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'user.plural.nominative' });

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable<UserTableRow>
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={ttableDefaultPaths.users}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                />
            )}
        </AuthorizationCheck>
    );
});
