import { ApiStore, NotificationStore } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlShape } from 'react-intl';
import { ApiConfigs } from '../../apiConfigs';
import { ExpertiseTaskStore, RootStore } from '../../stores';

export const selectionOfExpertListModelObservables = {
    rootStore: observable,
    expertiseTaskStore: observable,
    api: observable,
    apiConfigs: observable,
    id: observable,
    expertId: observable,

    onSubmitCreator: action.bound,
    onChecked: action.bound,
    setExpertId: action.bound,
};

export class SelectionOfExpertListModel {
    protected rootStore: RootStore;
    protected expertiseTaskStore: ExpertiseTaskStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected notificationStore: NotificationStore;
    protected intl: IntlShape;
    id = '';
    expertId = '';

    constructor(id: string, rootStore: RootStore) {
        makeObservable(this, selectionOfExpertListModelObservables);
        this.rootStore = rootStore;
        this.expertiseTaskStore = rootStore.expertiseTaskStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.notificationStore = rootStore.coreRootStore.notificationStore;
        this.intl = rootStore.coreRootStore.intlStore.intl;
        this.id = id;
    }

    onSubmitCreator(onClose: () => void): () => Promise<void> {
        return () => {
            if (this.expertId) {
                return this.expertiseTaskStore.saveExpertOnTask(this.id, this.expertId).then(() => {
                    onClose();
                });
            }

            this.notificationStore.onError(this.intl.formatMessage({ id: 'expertiseTask.errorText' }));
            return Promise.reject();
        };
    }

    onChecked(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        if (checked) {
            this.setExpertId(event.target.value);
        }
    }

    setExpertId(expertId: string): void {
        this.expertId = expertId;
    }
}
