import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../../../../components';
import { campaignPanelAccordionStyles } from '../../../../../constants';
import { CampaignExpertise, CampaignExpertiseLinks } from '../../../../../types';
import { createLinkWithQueries } from '../../../../../utils';
import { CampaignPanelSubjectCount as CampaignPanelSubjectCountInj } from './components';
import { CampaignPanelExpertiseSubject } from './CampaignSubjectsPanel';

export type CampaignExpertisesPanelProps = {
    expertises: CampaignExpertise;
    links: CampaignExpertiseLinks;
};

const expandIcon = <ExpandMoreIcon />;

export const CampaignExpertisesPanel = observer((props: CampaignExpertisesPanelProps): JSX.Element => {
    const [CampaignPanelSubjectCount] = di([CampaignPanelSubjectCountInj], CampaignExpertisesPanel);
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], CampaignExpertisesPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], CampaignExpertisesPanel);

    const { expertises, links } = props;

    const { formatMessage } = useIntl();

    const { total, active, done, canceled } = links;

    const expertiseSubjects: CampaignPanelExpertiseSubject[] = [
        {
            labelText: formatMessage({ id: 'campaign.expertises.total' }),
            path: createLinkWithQueries(total.urn, total.queries),
            count: expertises.total,
        },
        {
            labelText: formatMessage({ id: 'campaign.expertises.active' }),
            path: createLinkWithQueries(active.urn, active.queries),
            count: expertises.active,
        },
        {
            labelText: formatMessage({ id: 'campaign.expertises.done' }),
            path: createLinkWithQueries(done.urn, done.queries),
            count: expertises.done,
        },
        {
            labelText: formatMessage({ id: 'campaign.expertises.canceled' }),
            path: createLinkWithQueries(canceled.urn, canceled.queries),
            count: expertises.canceled,
        },
    ];

    const expertiseSubjectCounts: JSX.Element[] = expertiseSubjects.map((subject, index) => {
        return <CampaignPanelSubjectCount {...subject} key={subject.labelText + index} />;
    });

    return (
        <Box sx={campaignPanelAccordionStyles.accordionContainer}>
            <Accordion defaultExpanded sx={campaignPanelAccordionStyles.accordion}>
                <TotObjectPanelSummary expandIcon={expandIcon}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="campaign.expertises.title" />
                    </Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid item container pb={2}>
                        {expertiseSubjectCounts}
                    </Grid>
                </TotObjectPanelDetails>
            </Accordion>
        </Box>
    );
});
