import { Dialog } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import { DialogCancelButtonConfig, DialogSubmitButtonConfig, DialogTitle } from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../hooks';
import { CreateSubjectModel } from '../../../../models';
import { NewSubject, NewSubjectDTO } from '../../../../types';
import { SubjectCreateDialogForm as SubjectCreateDialogFormInj } from './SubjectCreateDialogForm';

export type SubjectCreateDialogProps = ModalProps & {
    onSubmit: (dto: NewSubjectDTO) => Promise<void>;
};

const bannedSymbols = /[\/\\:*?<>|]/g;

export const SubjectCreateDialog = observer((props: SubjectCreateDialogProps): JSX.Element => {
    const { open, onCancel, onSubmit } = props;
    const [SubjectCreateDialogForm] = di([SubjectCreateDialogFormInj], SubjectCreateDialog);

    const rootStore = useStore();
    const intl = useIntl();
    const { formatMessage } = intl;

    const model = useMemo(() => new CreateSubjectModel(rootStore), [rootStore]);
    const { identifier: initialIdentifier } = model;

    const { Yup } = useYup();
    const schema = Yup.object().shape({
        category: Yup.object().nullable().required(),
        campaign: Yup.object().nullable(),
        identifier: Yup.string()
            .required()
            .test('', formatMessage({ id: 'validation.bannedSymbols' }, { symbols: '/ \\ : * ? < > |' }), (value) => {
                const clearValue = value ? value.replace(bannedSymbols, '') : '';
                return value === clearValue;
            }),
    });

    const initialValues: NewSubject = {
        category: null,
        campaign: null,
        identifier: initialIdentifier,
    };

    const onSubmitCallback = (values: NewSubject): Promise<void> => {
        const { category, campaign, identifier } = values;
        if (category) {
            const dto: NewSubjectDTO = {
                categoryId: category.id,
                identifier,
            };

            campaign && (dto.campaignId = campaign.id);

            return onSubmit(dto);
        }
        return Promise.reject();
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCallback);

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'subject.createSubject' }),
    };

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} scroll="body">
            <DialogTitle onCloseClick={onCancel}>
                <FormattedMessage id="subject.newSubject" />
            </DialogTitle>
            <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={handleSubmit}>
                <SubjectCreateDialogForm submitButton={submitButton} cancelButton={cancelButton} model={model} />
            </Formik>
        </Dialog>
    );
});
