import { ClientBreadcrumbs } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../../hooks';

export type ExpertiseTaskHeaderBreadcrumbs = { taskIdentifier: string };

export const ExpertiseTaskHeaderBreadcrumbs = observer((props: ExpertiseTaskHeaderBreadcrumbs): JSX.Element => {
    const { taskIdentifier } = props;
    const { id } = useParams<RouteParamsDefault>();

    const { breadcrumbsStore } = useStore().coreRootStore;
    const { breadCrumbsSettings } = breadcrumbsStore;

    useEffect(() => {
        breadcrumbsStore.setPageParams({ id });
    }, [id]);

    useEffect(() => {
        breadcrumbsStore.setPageTitles({ taskIdentifier });
    }, [taskIdentifier]);

    return <ClientBreadcrumbs breadcrumbsSettings={breadCrumbsSettings} />;
});
