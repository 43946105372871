import { Box, Container, Grid, Typography } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { TTable, TTableRowActions, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ttableDefaultPaths } from '../../constants';
import { useStore } from '../../hooks';
import { PermissionsStore } from '../../stores';
import { ExpertiseTaskRegistryRow, RegistryType } from '../../types';
import { ExpertiseTaskRegistryActionsBtns as ExpertiseTaskRegistryActionsBtnsInj } from './ExpertiseTaskRegistryActionsBtns';

export const ExpertiseTaskListPage = observer((): JSX.Element => {
    const [ExpertiseTaskRegistryActionsBtns] = di([ExpertiseTaskRegistryActionsBtnsInj], ExpertiseTaskListPage);

    const { coreRootStore, userStore } = useStore();
    const { authorizationStore, catalogStore, api, permissionsStore } = coreRootStore;
    const { formatMessage } = useIntl();
    const [isWithAddCustomColumns, setIsWithAddCustomColumns] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();
    const taskRegistryColumnsManagementQuery = systemConfig.taskRegistryColumnsManagement();

    useEffect((): void => {
        authorizationStore.check(taskRegistryColumnsManagementQuery).then(setIsWithAddCustomColumns);
    }, [authorizationStore]);

    const visibleSettings: TTableVisibilitySettings = {
        toolbar: {
            isWithAddCustomColumns,
        },
    };

    const setRowActions: TTableRowActions<ExpertiseTaskRegistryRow> = useCallback(
        (
            row: ExpertiseTaskRegistryRow,
            reloadData: VoidFunction,
            toggleIsRowBlocked: VoidFunction,
            rowErrorHandler: VoidFunction,
        ): JSX.Element => {
            return (
                <ExpertiseTaskRegistryActionsBtns
                    expertiseTaskRow={row}
                    reloadData={reloadData}
                    isConfirm={isConfirm}
                    setIsConfirm={setIsConfirm}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    rowErrorHandler={rowErrorHandler}
                />
            );
        },
        [isConfirm],
    );

    const registryTitle = formatMessage({ id: 'expertiseTask.listTitle' });

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                        <Typography variant="subtitle2" component="h1">
                            {registryTitle}
                        </Typography>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <AuthorizationCheck {...administrationQuery}>
                            {(allowed: boolean): JSX.Element => (
                                <TTable<ExpertiseTaskRegistryRow>
                                    tableTitle={registryTitle}
                                    registryCode={RegistryType.task}
                                    visibleSettings={visibleSettings}
                                    fetchCatalog={catalogStore.getCatalog}
                                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                                    defaultPath={ttableDefaultPaths.expertiseTasks}
                                    fetchUserRoleList={userStore.userRoleList}
                                    isAdmin={allowed}
                                    rowActions={setRowActions}
                                    client={api.mainInfoClient as AxiosInstance}
                                />
                            )}
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
