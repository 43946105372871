import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';

export const ttableDefaultPaths = {
    campaigns: generatePath(clientRoute.campaigns),
    subjects: generatePath(clientRoute.subjects),
    expertiseList: generatePath(clientRoute.expertiseList),
    expertiseTasks: generatePath(clientRoute.expertiseTasks),
    users: generatePath(clientRoute.users),
    categories: generatePath(clientRoute.categories),
    pfTemplateList: generatePath(clientRoute.pfTemplateList),
    templatesOfExpertise: generatePath(clientRoute.templatesOfExpertise),
    KafkaEventsRegistry: generatePath(clientRoute.kafkaEvents),
    getExpertiseTask: (id: string) => generatePath(clientRoute.expertiseTask, { id }),
    getCampaignSyslog: (id: string) => generatePath(clientRoute.campaignSyslog, { id }),
    getCategoriesOfSubjectsSyslog: (id: string) => generatePath(clientRoute.categorySyslog, { id }),
    getExpertiseSyslog: (id: string) => generatePath(clientRoute.expertiseSyslog, { id }),
    getSubjectSyslog: (id: string) => generatePath(clientRoute.subjectSyslog, { id }),
    getExpertiseTaskSyslog: (id: string) => generatePath(clientRoute.expertiseTaskSyslog, { id }),
    getTemplatesOfExpertiseSyslog: (id: string) => generatePath(clientRoute.templateOfExpertiseSyslog, { id }),
    getUserSyslog: (id: string) => generatePath(clientRoute.userSyslog, { id }),
};
