import { GridProps } from '@mui/material';
import { ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { IdTitle, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { EditCuratorModal as EditCuratorModalInj } from '../../../../components';
import { useExpertisePageContext, useStore } from '../../../../hooks';
import { objectWithoutBreadcrumbsHeaderContainerSx } from '../../../../utils';
import { ExpertiseHeaderLeft as ExpertiseHeaderLeftInj } from './ExpertiseHeaderLeft';
import { ExpertisePageHeaderTitle as ExpertisePageHeaderTitleInj } from './ExpertisePageHeaderTitle';

export const ExpertiseHeader = observer(() => {
    const [EditCuratorModal] = di([EditCuratorModalInj], ExpertiseHeader);
    const [ExpertiseHeaderLeft] = di([ExpertiseHeaderLeftInj], ExpertiseHeader);
    const [ExpertisePageHeaderTitle] = di([ExpertisePageHeaderTitleInj], ExpertiseHeader);

    const { model } = useExpertisePageContext();
    const { id, curator, load } = model;
    const { expertiseStore } = useStore();

    const [isCuratorDialogOpen, openEditCuratorDialog, closeEditCuratorDialog] = useFlag();

    const editExpertiseCurator = (currentCurator: IdTitle): Promise<void> => {
        return expertiseStore.editCurator(id, currentCurator.id).then(load);
    };

    const titleBlock = <ExpertisePageHeaderTitle />;

    const pageLeftSide = <ExpertiseHeaderLeft setModalIsOpen={openEditCuratorDialog} />;

    const titleBlockAndMenu: ObjectHeaderLayoutProps['titleBlockAndMenu'] = {
        titleBlock,
    };

    const leftBottomBlock: ObjectHeaderLayoutProps['leftBottomBlock'] = {
        element: pageLeftSide,
    };

    const mainContentContainerProps: GridProps = {
        sx: objectWithoutBreadcrumbsHeaderContainerSx,
    };

    return (
        <React.Fragment>
            <EditCuratorModal
                open={isCuratorDialogOpen}
                onCancel={closeEditCuratorDialog}
                onSubmit={editExpertiseCurator}
                curatorUserId={curator.userId}
            />
            <ObjectHeaderLayout
                mainContentContainerProps={mainContentContainerProps}
                titleBlockAndMenu={titleBlockAndMenu}
                leftBottomBlock={leftBottomBlock}
            />
        </React.Fragment>
    );
});
