import { MenuItem } from '@mui/material';
import { AuthorizationCheckQueryOrWithoutCheck, useFetchAllPermissions } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { DeleteActionMenuItem } from '../../../../../../components';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { PermissionsStore } from '../../../../../../stores';

export const ExpertiseTaskHeaderActionsButton = observer((): JSX.Element => {
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const match = useRouteMatch();

    const { expertiseTaskStore, coreRootStore } = useStore();
    const { permissionsStore } = coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;

    const { model, backUrl } = useExpertiseTaskViewPageContext();
    const { taskModel } = model;
    const { identifier } = taskModel;

    const allPermissionsQueries = useMemo<AuthorizationCheckQueryOrWithoutCheck[]>(() => {
        return [expertiseTaskConfig.deleteExpertiseTask(id), expertiseTaskConfig.editExpertiseTask(id)];
    }, [id]);

    const [permissionsData] = useFetchAllPermissions({ allPermissionsQueries });
    const [isAllowedToDelete, isAllowToEdit] = permissionsData;

    const deleteConfirmText = formatMessage(
        { id: 'expertiseTask.confirmDeletionInfoText' },
        {
            number: identifier,
        },
    );

    const deleteConfirmDialogTitle = formatMessage({ id: 'common.confirmDeletion' });

    const deleteExpertiseTask = (): Promise<void> => {
        return expertiseTaskStore.delete(id).then(() => {
            history.push(backUrl);
        });
    };

    const renderMenuActionItems = (): ReactNode[] => {
        const editPath = `${generatePath(clientRoute.expertiseTaskEdit, {
            id,
        })}?backUrl=${match.url}`;

        const nodes: ReactNode[] = [
            isAllowedToDelete && (
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    title={deleteConfirmDialogTitle}
                    message={deleteConfirmText}
                    onConfirm={deleteExpertiseTask}
                />
            ),
            isAllowToEdit && (
                <MenuItem dense tabIndex={1} component={NavLink} key="edit" to={editPath}>
                    <FormattedMessage id="expertiseTask.actions.edit" />
                </MenuItem>
            ),
        ];
        return nodes;
    };

    return isAllowedToDelete || isAllowToEdit ? (
        <MenuButton renderMenuItems={renderMenuActionItems} />
    ) : (
        <React.Fragment />
    );
});
