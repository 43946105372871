import { Box, Button, Container, Grid } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ExpertiseCreateDialogContext, ExpertiseCreateDialogContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { ExpertiseTableRow } from '../../../types';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from './expertise-dialog';
import { ExpertiseRegistryActionsBtns as ExpertiseRegistryActionsBtnsInj } from './ExpertiseRegistryActionsBtns';
import { ExpertiseRegistryTable as ExpertiseRegistryTableInj } from './ExpertiseRegistryTable';

export const expertiseListPageSetRowActionsInj: TTableRowActions<ExpertiseTableRow> = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [ExpertiseRegistryActionsBtns] = di([ExpertiseRegistryActionsBtnsInj], ExpertiseListPage);

    return (
        <ExpertiseRegistryActionsBtns
            expertiseTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const ExpertiseListPage = observer((): JSX.Element => {
    const [ExpertiseRegistryTable] = di([ExpertiseRegistryTableInj], ExpertiseListPage);
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], ExpertiseListPage);
    const [expertiseListPageSetRowActions] = di([expertiseListPageSetRowActionsInj], ExpertiseListPage);

    const [isDialogOpen, openDialog, closeDialog] = useFlag();
    const contextValue: ExpertiseCreateDialogContextType = {
        open: isDialogOpen,
        onClose: closeDialog,
    };

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const addExpertiseQuery = systemConfig.addExpertise();

    return (
        <React.Fragment>
            <ExpertiseCreateDialogContext.Provider value={contextValue}>
                <ExpertiseCreateDialog />
            </ExpertiseCreateDialogContext.Provider>
            <Container maxWidth={false} sx={registryPageStyles.container}>
                <Box pt={2}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container justifyContent="space-between">
                            <Typography variant="subtitle2" component="h1" alignSelf="center">
                                <FormattedMessage id="expertise.expertiseListTitle" />
                            </Typography>
                            <Grid item>
                                <AuthorizationCheck {...addExpertiseQuery}>
                                    <Grid item>
                                        <Button color="primary" variant="contained" onClick={openDialog}>
                                            <FormattedMessage id="expertise.createExpertise" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                        <Grid item sx={registryPageStyles.tableWrapper}>
                            <ExpertiseRegistryTable setRowActions={expertiseListPageSetRowActions} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
