import { Grid, SxProps } from '@mui/material';
import { StickyWrapper } from '@platform/front-core';
import { grey } from '@platform/front-ui';
import React, { ReactNode } from 'react';

export type BottomControlPanelProps = {
    left?: ReactNode;
    right: ReactNode;
};

const sxPanelContainer: SxProps = {
    background: 'white',
    width: '100%',
    maxWidth: '100%',
    bottom: 0,
    borderTop: `1px solid ${grey['100']}`,
};

const sxGridContainer = {
    paddingX: 2,
    paddingY: 1.5,
};

export const BottomControlPanel = (props: BottomControlPanelProps): JSX.Element => {
    const { left, right } = props;

    return (
        // todo: Убрать sticky, когда приведем в порядок все страницы, на которых есть эта панелька
        <StickyWrapper sx={sxPanelContainer}>
            <Grid alignItems="center" justifyContent="space-between" container width="100%" sx={sxGridContainer}>
                <Grid item>{left}</Grid>
                <Grid item>{right}</Grid>
            </Grid>
        </StickyWrapper>
    );
};
