import { TableCell, TableHead, TableRow } from '@mui/material';
import { Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ExpertiseTaskTableHead = observer((): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.number" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.planEntry" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.expert" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.responseTime" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.deadline" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.actualDateOfTheExecution" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.stateTitle" />
                    </Typography>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
});
