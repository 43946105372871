import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Form, FormApi } from '@platform/formiojs-react';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../hooks';
import { ReportsRow } from '../../../../types';
import { ExpertiseTaskPrintForms as ExpertiseTaskPrintFormsInj } from './expertise-task-print-forms';

const accordionSx: SxStyle = { padding: 0 };
const tableCellSx: SxStyle = { width: '33.3%' };

export const ExpertiseTaskView = observer((): JSX.Element => {
    const [ExpertiseTaskPrintForms] = di([ExpertiseTaskPrintFormsInj], ExpertiseTaskView);

    const { coreRootStore } = useStore();
    const { intlStore } = coreRootStore;
    const { model: expertiseTaskViewModel } = useExpertiseTaskViewPageContext();
    const { taskModel, reportsTable } = expertiseTaskViewModel;
    const { id, formDTO, setFormApi } = taskModel;

    const renderReportsBody = (reportsTable: ReportsRow[]): JSX.Element[] => {
        return reportsTable.map((reportItem) => {
            const { deadline, completed, report } = reportItem;
            return (
                <TableRow key={report.id} hover>
                    <TableCell>
                        <Link underline="none">{report.title}</Link>
                    </TableCell>
                    <TableCell>{deadline && <FormattedDate value={deadline} />}</TableCell>
                    <TableCell>{completed && <FormattedDate value={completed} />}</TableCell>
                </TableRow>
            );
        });
    };

    const [isFormReady, setIsFormReady] = useState(false);

    const onFormReady = (formApi: FormApi): void => {
        setFormApi(formApi);
        setIsFormReady(true);
    };

    return (
        <Grid item container pl={2} flexDirection="column">
            <Grid item>
                {!!formDTO && (
                    <Form
                        ownerEntityId={id}
                        formDTO={formDTO}
                        intlStore={intlStore}
                        readOnly
                        onFormReady={onFormReady}
                    />
                )}
            </Grid>
            <Grid item width="100%">
                {isFormReady && reportsTable && (
                    <Accordion defaultExpanded elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="expertiseTask.localConclusions.title" />
                            </Typography>
                        </AccordionSummary>
                        <Accordion sx={accordionSx}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={tableCellSx}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.report" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={tableCellSx}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.deadline" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={tableCellSx}>
                                            <Typography>
                                                <FormattedMessage id="expertiseTask.localConclusions.fields.completed" />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderReportsBody(reportsTable)}</TableBody>
                            </Table>
                        </Accordion>
                    </Accordion>
                )}
            </Grid>
            {isFormReady && (
                <Grid item width="100%" mt={1} mb={3}>
                    <ExpertiseTaskPrintForms />
                </Grid>
            )}
        </Grid>
    );
});
