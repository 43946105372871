import { FormApi } from '@platform/formiojs-react';
import { ApiStore } from '@platform/front-core';
import { FormDTO, FullSubmission, FullSubmissionWithAdditionalInfo, TransitionDTO } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore, IdTitle } from '@platform/front-utils';
import { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import downloadFile from 'js-file-download';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import {
    ExpertiseTaskDTO,
    ExpertiseTaskPrintFormDTO,
    ExpertiseTaskPrintFormsDTO,
    ExpertiseTaskSubjectDTO,
    ExpertiseTaskViewDTO,
    PrintFormFileDTO,
} from '../types';
import { RootStore } from './RootStore';

const filePanelCLass = 'file-tab';

export const expertiseTaskStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,

    shouldSaveReport: observable,
    reportFormApi: observable,

    deleteExpertiseTask: action.bound,
    createExpertiseTask: action.bound,
    loadExpertisePlanEntriesSelectOptions: action.bound,
    loadExpertiseTaskDTO: action.bound,
    saveExpertiseTask: action.bound,
    delete: action.bound,
    loadTaskView: action.bound,
    updateTaskReportForm: action.bound,
    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    transition: action.bound,
    sendToRework: action.bound,
    sendToReject: action.bound,
    loadTaskReportForm: action.bound,
    loadTaskSubject: action.bound,
    loadTaskSubjectView: action.bound,
    loadPrintForms: action.bound,
    createPrintForm: action.bound,
    updatePrintForm: action.bound,
    downloadPrintFormFile: action.bound,
    changeCurator: action.bound,
    changeStartedDate: action.bound,
    changeDueDate: action.bound,
    saveExpertOnTask: action.bound,
    setShouldSaveReport: action.bound,
    setReportFormApi: action.bound,
};

export class ExpertiseTaskStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    reportFormApi = {} as FormApi;
    shouldSaveReport = false;

    constructor(rootStore: RootStore) {
        makeObservable(this, expertiseTaskStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    deleteExpertiseTask(taskId: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteExpertiseTask(taskId))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    createExpertiseTask(planEntryId: string): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createExpertiseTask(planEntryId)).then((r) => r.data.id);
    }

    loadExpertisePlanEntriesSelectOptions(expertiseId: string): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.expertisePlanEntriesSelectOptions(expertiseId)).then((r) => r.data);
    }

    loadExpertiseTaskDTO(id: string): Promise<ExpertiseTaskDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadExpertiseTaskDTO(id)).then((r) => r.data);
    }

    saveExpertiseTask(id: string, submission: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.saveExpertiseTask(id, submission)).then((r) => r.data);
    }

    delete(id: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteExpertiseTask(id)).then((r) => r.data);
    }

    loadTaskView(taskId: string): Promise<ExpertiseTaskViewDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadExpertiseTaskView(taskId)).then((r) => r.data);
    }

    updateTaskReportForm(
        taskId: string,
        submission: FullSubmissionWithAdditionalInfo | FullSubmission,
        expertiseInfo?: object,
    ): Promise<void> {
        const { intlStore, notificationStore } = this.rootStore.coreRootStore;
        const { formatMessage } = intlStore.intl;
        const successMessage = formatMessage({ id: 'notifications.success' });
        return this.api
            .userActionClient(this.apiConfigs.updateTaskReportForm(taskId, submission, expertiseInfo))
            .then((r) => r.data)
            .then(() => notificationStore.onSuccess(successMessage));
    }

    getTransitions(taskId: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.loadExpertiseTaskTransitions(taskId)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, taskId: string): Promise<void> {
        const submission = this.reportFormApi.getSubmission();
        const expertiseInfo = this.reportFormApi.getExpertiseInfo();
        const resultPromise = this.shouldSaveReport
            ? this.updateTaskReportForm(taskId, submission, expertiseInfo).then(() =>
                  this.transition(transitionId, taskId),
              )
            : this.transition(transitionId, taskId);
        return resultPromise.finally(() => this.setShouldSaveReport(false));
    }

    transition(transitionId: string, taskId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.expertiseTaskLifeCycleTransition(transitionId, taskId))
            .then((r) => r.data);
    }

    sendToRework(transitionId: string, taskId: string, message: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.sendToRework(transitionId, taskId, message))
            .then((r) => r.data);
    }

    sendToReject(transitionId: string, taskId: string, message: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.sendToReject(transitionId, taskId, message))
            .then((r) => r.data);
    }

    loadTaskReportForm(taskId: string): Promise<FormDTO> {
        return this.api.client(this.apiConfigs.loadTaskReportForm(taskId)).then((r) => r.data);
    }

    loadTaskSubject(taskId: string): Promise<ExpertiseTaskSubjectDTO> {
        return this.api.client(this.apiConfigs.loadExpertiseTaskSubject(taskId)).then((r) => r.data);
    }

    loadTaskSubjectView(taskId: string, isFiles: boolean): Promise<ExpertiseTaskSubjectDTO> {
        return this.api.client(this.apiConfigs.loadExpertiseTaskSubject(taskId)).then((r: AxiosResponse) => {
            const tasksSubjectWithoutFiles = r.data;
            const commonFormInfoForms = tasksSubjectWithoutFiles.commonFormInfo.form;

            commonFormInfoForms.components = [
                ...commonFormInfoForms.components.filter((component: { customClass: string }) => {
                    if (isFiles) {
                        return component.customClass === filePanelCLass;
                    }
                    return component.customClass !== filePanelCLass;
                }),
            ];

            if (tasksSubjectWithoutFiles.hiddenFormInfo) {
                const hiddenFormInfoForms = tasksSubjectWithoutFiles.hiddenFormInfo.form;

                hiddenFormInfoForms.components = [
                    ...hiddenFormInfoForms.components.filter((component: { customClass: string }) => {
                        if (isFiles) {
                            return component.customClass === filePanelCLass;
                        }
                        return component.customClass !== filePanelCLass;
                    }),
                ];
            }

            return tasksSubjectWithoutFiles;
        });
    }

    loadPrintForms(expertiseTaskId: string): Promise<ExpertiseTaskPrintFormsDTO> {
        return this.api.client(this.apiConfigs.expertiseTaskPrintForms(expertiseTaskId)).then((r) => r.data);
    }

    createPrintForm(taskId: string, pfCode: string): Promise<ExpertiseTaskPrintFormDTO> {
        return this.api
            .userActionClient(this.apiConfigs.createExpertiseTaskPrintForm(taskId, pfCode))
            .then((r) => r.data);
    }

    updatePrintForm(taskPrintFormId: string): Promise<ExpertiseTaskPrintFormDTO> {
        return this.api
            .userActionClient(this.apiConfigs.updateExpertiseTaskPrintForm(taskPrintFormId))
            .then((r) => r.data);
    }

    downloadPrintFormFile(fileDTO: PrintFormFileDTO): void {
        this.api
            .userActionClient(this.apiConfigs.downloadPrintFormFile(fileDTO.pfId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    changeCurator(expertiseTaskId: string, curatorUserId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.changeExpertiseTaskCurator(expertiseTaskId, curatorUserId))
            .then((r) => r.data);
    }

    async changeStartedDate(expertiseTaskId: string, started: Date): Promise<void> {
        await this.api.userActionClient(this.apiConfigs.changeStartedDate(expertiseTaskId, formatISO(started)));
    }

    async changeDueDate(expertiseTaskId: string, deadline: Date): Promise<void> {
        await this.api.userActionClient(this.apiConfigs.changeDueDate(expertiseTaskId, formatISO(deadline)));
    }

    saveExpertOnTask(taskId: string, expertId: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.saveExpertOnTask(taskId, expertId)).then((r) => r.data);
    }

    setShouldSaveReport(shouldSaveReport: boolean): void {
        this.shouldSaveReport = shouldSaveReport;
    }

    setReportFormApi(reportFormApi: FormApi) {
        this.reportFormApi = reportFormApi;
    }
}

export const getExpertiseTaskStore = (): any => {
    const [_ExpertiseTaskStore] = di([ExpertiseTaskStore], getExpertiseTaskStore);
    return _ExpertiseTaskStore;
};
