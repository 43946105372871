import { promiseVoidFunction, PromiseVoidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { CategoryOfSubjectsModel } from '../models';

export type CategoryOfSubjectsPageContextType = {
    model: CategoryOfSubjectsModel;
    onSubmit: PromiseVoidFunction;
};

export const CategoryOfSubjectsPageContext = createContext<CategoryOfSubjectsPageContextType>({
    model: {} as CategoryOfSubjectsModel,
    // todo: Выпилить, когда будет рефакторинг форм формио
    onSubmit: promiseVoidFunction,
});
