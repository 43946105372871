import { Box, Container, Grid } from '@mui/material';
import { AuthorizationCheck, handleMainInfoError, registryPageStyles, useLocale } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { TTable, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import { RegistryType, SyslogRegistryParams } from '../../types';

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
    },
    column: {
        isWithDraggableColumns: false,
    },
};

export type SyslogPageProps = {
    defaultPath: string;
    breadcrumbs: JSX.Element;
    entityType: string;
    registryCode?: string;
};

export const SyslogPage = observer((props: SyslogPageProps): JSX.Element => {
    const { breadcrumbs, entityType, registryCode = RegistryType.logging, defaultPath } = props;
    const tableTitle = useLocale('syslog.single.nominative', true)[0];
    const { coreRootStore, userStore } = useStore();
    const { intlStore, api, catalogStore, history, permissionsStore } = coreRootStore;
    const { userRoleList } = userStore;
    const errorHandler = useMemo(() => handleMainInfoError(history), [history]);
    const { id } = useParams<RouteParamsDefault>();

    const mainApiParams: SyslogRegistryParams = useMemo(
        () => ({
            entityId: id,
            entityType,
        }),
        [id, entityType],
    );

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={1.5}>
                <Grid container direction="column" spacing={4.25}>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>{breadcrumbs}</Grid>
                        <Grid item>
                            <Typography variant="subtitle2">{tableTitle}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <AuthorizationCheck
                            entityCode={permissionsStore.entities.system}
                            permCode={permissionsStore.permissions.system.Administration}
                        >
                            {(allowed: boolean): JSX.Element => (
                                <TTable
                                    mainApiParams={mainApiParams}
                                    registryCode={registryCode}
                                    lang={intlStore.locale}
                                    tableTitle={tableTitle}
                                    client={api.client}
                                    fetchUserRoleList={userRoleList}
                                    fetchCatalog={catalogStore.getCatalog}
                                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                                    isAdmin={allowed}
                                    handleMainInfoError={errorHandler}
                                    visibleSettings={visibleSettings}
                                    defaultPath={defaultPath}
                                />
                            )}
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
