import { Dialog, DialogContent, DialogTitle, Portal } from '@mui/material';
import { RenderTransitionDialogProps } from '@platform/front-core';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    FieldLabel,
    makeSxStyles,
} from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { ComponentProps, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const expertiseTaskRejectWithReasonDialogSxStyles = makeSxStyles({
    formWrapper: {
        '&:first-child': {
            paddingTop: '10px',
        },
    },
});

export type ExpertiseTaskRejectWithReasonDialogProps = RenderTransitionDialogProps & {
    expertiseTaskFormatMessage: ReactNode;
    sendMethod: (id: string, objectId: string, message: string) => Promise<void>;
    getTransitions: (isOnLocaleChange?: boolean | undefined) => void;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const ExpertiseTaskRejectWithReasonDialog = observer(
    (props: ExpertiseTaskRejectWithReasonDialogProps): JSX.Element => {
        const {
            objectId,
            transition,
            isOpen,
            onClose,
            onSuccessTransition,
            expertiseTaskFormatMessage,
            sendMethod,
            getTransitions,
        } = props;
        const intl = useIntl();
        const { formatMessage } = intl;
        const { Yup } = useYup();
        const { toStateTitle, id } = transition;
        const labelField = <FieldLabel required text={formatMessage({ id: 'expertiseTask.invites.rejectReason' })} />;

        const schema = Yup.object().shape({
            message: Yup.string().required(),
        });

        const onSubmit = (values: FormikValues): Promise<void> => {
            return sendMethod(id, objectId, values.message)
                .then(onSuccessTransition)
                .then(() => getTransitions())
                .finally(onClose);
        };

        const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

        const dialogContentValues: ComponentProps<typeof FormattedMessage>['values'] = { toState: toStateTitle };

        const cancelButton: DialogCancelButtonConfig = {
            onClick: onClose,
        };

        const submitButton: DialogSubmitButtonConfig = {
            type: 'submit',
            disabled: isLoading,
            endIcon,
            text: expertiseTaskFormatMessage,
        };

        return (
            <Portal>
                <Dialog fullWidth maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isOpen}>
                    <DialogTitle id="confirmation-dialog-title">
                        <FormattedMessage id="expertiseTask.invites.rejectReason" />
                    </DialogTitle>
                    <DialogContent>
                        <FormattedMessage id="common.lifeCycleConfirmText" values={dialogContentValues} />
                    </DialogContent>
                    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmitHandler}>
                        <Form>
                            <DialogContent sx={expertiseTaskRejectWithReasonDialogSxStyles.formWrapper}>
                                <Field
                                    component={TextField}
                                    label={labelField}
                                    rows={3}
                                    name="message"
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                        </Form>
                    </Formik>
                </Dialog>
            </Portal>
        );
    },
);
