import { ExpertiseFeatures } from '../../types';
import skolkovoLogo from './skolkovo-logo.svg';

const logoStyle = {
    height: '32px',
    width: 'auto',
};

const features: ExpertiseFeatures = {
    footerLogo: false,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: 'Skolkovo',
                image: skolkovoLogo,
                style: logoStyle,
            },
            ru: {
                alt: 'Сколково',
                image: skolkovoLogo,
                style: logoStyle,
            },
        },
    },
    isWithoutStartPageLogo: true,
    modularNavigation: true,
    phoneNumber: true,
    userAdding: true,
};

export default features;
