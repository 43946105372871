import { FormApi } from '@platform/formiojs-react';
import { FormDTO } from '@platform/front-types';
import { CodeTitle, CodeTitleNull } from '@platform/front-utils';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlShape } from 'react-intl';
import { RootStore } from '../../stores';
import { EntityCreatedDTO, PlanEntryDTO } from '../../types';

export const planEntryModelObservables = {
    intl: observable,
    id: observable,
    formDTO: observable,
    taskFormCode: observable,
    taskFormDTO: observable,
    taskForm: observable,
    taskFormSelectData: observable,
    formByCode: observable,
    validationStarted: observable,

    formApi: observable,
    taskFormApi: observable,

    errorTaskForm: computed,
    isValid: computed,

    load: action.bound,
    loadCreated: action.bound,
    onChangeSelect: action.bound,
    setTaskForm: action.bound,
    setValidationStarted: action.bound,

    setFormApi: action.bound,
    setTaskFormApi: action.bound,
};

export class PlanEntryModel {
    intl: IntlShape;

    id = '';
    formDTO?: FormDTO;
    taskFormCode?: string;
    taskFormDTO?: FormDTO;
    taskForm: CodeTitleNull = null;

    taskFormSelectData: IObservableArray<CodeTitle>;
    formByCode: (code: string) => Promise<FormDTO>;

    formApi?: FormApi;
    taskFormApi?: FormApi;

    validationStarted = false;

    constructor(
        taskFormSelectData: IObservableArray<CodeTitle>,
        formByCode: (code: string) => Promise<FormDTO>,
        rootStore: RootStore,
    ) {
        makeObservable(this, planEntryModelObservables);
        this.taskFormSelectData = taskFormSelectData;
        this.formByCode = formByCode;
        this.intl = rootStore.coreRootStore.intlStore.intl;
    }

    get errorTaskForm(): string {
        if (this.validationStarted && !this.taskFormCode) {
            return this.intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }

    get isValid(): boolean {
        return !this.errorTaskForm;
    }

    load(dto: PlanEntryDTO): PlanEntryModel {
        const { id, formInfo, taskFormInfo, taskFormCode } = dto;
        this.id = id;
        this.formDTO = formInfo;
        this.taskFormDTO = taskFormInfo;
        this.taskFormCode = taskFormCode;

        return this;
    }

    loadCreated(dto: EntityCreatedDTO): PlanEntryModel {
        this.id = dto.id;
        if (dto.form) {
            this.formDTO = dto.form;
        }
        return this;
    }

    onChangeSelect(event: ChangeEvent<{}>, value: CodeTitleNull): void {
        const newCode = value?.code;
        if (!!newCode && this.taskFormCode !== newCode) {
            this.formByCode(newCode).then((form) => {
                this.taskFormDTO = form;
            });
        }
        this.taskFormCode = newCode;
        this.taskForm = value;
    }

    setValidationStarted(isStarted: boolean): void {
        this.validationStarted = isStarted;
    }

    setTaskForm(form: CodeTitleNull): void {
        this.taskForm = form;
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setTaskFormApi(formApi: FormApi): void {
        this.taskFormApi = formApi;
    }
}
