import { ApiStore, CoreRootStore } from '@platform/front-core';
import { IdTitle, IdTitleNull } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { ApiConfigs } from '../../apiConfigs';

export const ExpertiseEditCuratorModelObservables = {
    api: observable,
    apiConfigs: observable,
    currentCurator: observable,
    curatorOptions: observable,

    loadCuratorSelectOptions: action.bound,
    setCurrentCurator: action.bound,
    setCuratorOptions: action.bound,
};

export class ExpertiseEditCuratorModel {
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;

    currentCurator: IdTitleNull = null;
    curatorOptions: IdTitle[] = [];

    constructor(coreRootStore: CoreRootStore) {
        makeObservable(this, ExpertiseEditCuratorModelObservables);
        this.api = coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.loadCuratorSelectOptions().then(this.setCuratorOptions);
    }

    loadCuratorSelectOptions(): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.expertiseCuratorSelectOptions).then((r) => r.data);
    }

    setCurrentCurator(curatorUserId: string): void {
        const currentCurator = this.curatorOptions.find((curator) => curator.id === curatorUserId);
        this.currentCurator = currentCurator ? currentCurator : null;
    }

    setCuratorOptions(curatorOptions: IdTitle[]): void {
        this.curatorOptions = curatorOptions;
    }
}
