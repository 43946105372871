import { AppHeaderLinkData, HeaderLinksStore as HeaderLinksStoreInj } from '@platform/front-core';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';
import { PermissionEntities, Permissions } from '../types';
import { PermissionsStore } from './permissions-store';

export const mexpHeaderMainLinks = (entities: PermissionEntities, permissions: Permissions): AppHeaderLinkData[] => [
    {
        key: clientRoute.campaigns,
        to: clientRoute.campaigns,
        messageDescriptor: { id: 'headerLinks.campaigns' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewRegistryCampaignList,
        },
    },
    {
        key: clientRoute.subjects,
        to: clientRoute.subjects,
        messageDescriptor: { id: 'headerLinks.subjects' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewRegistrySubjectList,
        },
    },
    {
        key: clientRoute.expertiseList,
        to: clientRoute.expertiseList,
        messageDescriptor: { id: 'headerLinks.expertiseList' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewExpertiseList,
        },
    },
    {
        key: clientRoute.expertiseTasks,
        to: clientRoute.expertiseTasks,
        messageDescriptor: { id: 'headerLinks.expertiseTasks' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewRegistryTaskList,
        },
    },
    {
        key: clientRoute.users,
        to: clientRoute.users,
        messageDescriptor: { id: 'headerLinks.users' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewUserList,
        },
    },
];

export const mexpHeaderAdditionalLinks = (
    entities: PermissionEntities,
    permissions: Permissions,
): AppHeaderLinkData[] => [
    {
        key: clientRoute.categories,
        to: clientRoute.categories,
        messageDescriptor: { id: 'headerLinks.categories' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.Administration,
        },
    },
    {
        key: clientRoute.pfTemplateList,
        to: clientRoute.pfTemplateList,
        messageDescriptor: { id: 'headerLinks.pfTemplates' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewPfTemplatesList,
        },
    },
    {
        key: clientRoute.templatesOfExpertise,
        to: clientRoute.templatesOfExpertise,
        messageDescriptor: { id: 'headerLinks.templatesOfExpertise' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewTemplateExpertiseList,
        },
    },
    {
        key: clientRoute.console,
        to: clientRoute.console,
        messageDescriptor: { id: 'headerLinks.console' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.Administration,
        },
    },
    {
        key: clientRoute.kafkaEvents,
        to: clientRoute.kafkaEvents,
        messageDescriptor: { id: 'kafkaEvents.title' },
        query: {
            entityCode: entities.system,
            permCode: permissions.system.ViewKafkaEventList,
        },
    },
];

export class HeaderLinksStore extends HeaderLinksStoreInj {
    get mainLinks(): AppHeaderLinkData[] {
        const { entities, permissions } = this.permissionsStore as PermissionsStore;
        return mexpHeaderMainLinks(entities, permissions);
    }

    get additionalLinks(): AppHeaderLinkData[] {
        const { entities, permissions } = this.permissionsStore as PermissionsStore;
        return mexpHeaderAdditionalLinks(entities, permissions);
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};
