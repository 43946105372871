import { entities as coreEntities, permissions as corePermissions } from '@platform/front-core';

export const entities = {
    ...coreEntities,
    expertiseCampaign: 'ExpertiseCampaign',
    templateExpertise: 'TemplateExpertise',
    expertise: 'Expertise',
    expertiseSubject: 'ExpertiseSubject',
    expertiseTask: 'ExpertiseTask',
    expertiseTaskInvite: 'ExpertiseTaskInvite',
};

export const permissions = {
    ...corePermissions,
    account: {
        ...corePermissions.account,
        UpdateLogin: 'UpdateLogin',
    },
    system: {
        ...corePermissions.system,

        AccountAdministration: 'AccountAdministration',

        AddExpertiseSubject: 'AddExpertiseSubject',
        ViewRegistrySubjectList: 'ViewRegistrySubjectList',

        AddExpertiseCampaign: 'AddExpertiseCampaign',
        ViewRegistryCampaignList: 'ViewRegistryCampaignList',

        AddTemplateExpertise: 'AddTemplateExpertise',
        ViewTemplateExpertiseList: 'ViewTemplateExpertiseList',

        ViewRegistryTaskList: 'ViewRegistryTaskList',
        ViewExpertiseTaskListLegacy: 'ViewExpertiseTaskListLegacy',

        ViewExpertiseList: 'ViewExpertiseList',
        AddExpertise: 'AddExpertise',

        ViewPfTemplatesList: 'ViewPfTemplatesList',
        CreatePfTemplate: 'CreatePfTemplate',
        EditPfTemplate: 'EditPfTemplate',

        TaskRegistryColumnsManagement: 'TaskRegistryColumnsManagement',

        ViewLogs: 'ViewLogs',

        ViewKafkaEventList: 'ViewKafkaEventList',
    },

    expertiseCampaign: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        AddExpertises: 'AddExpertises',
    },

    expertiseSubject: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        CreateExpertise: 'CreateExpertise',
        DownloadFiles: 'DownloadFiles',
        DownloadHiddenFile: 'DownloadHiddenFile',
    },

    templateExpertise: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        ViewExtraInfo: 'ViewExtraInfo',
        EditExpertisePlan: 'EditExpertisePlan',
    },

    expertise: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        EditPlan: 'EditPlan',
        CreateTask: 'CreateTask',
        UpdateCurator: 'UpdateCurator',
    },

    expertiseTask: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        SetExpert: 'SetExpert',
        ViewConclusion: 'ViewConclusion',
        EditConclusion: 'EditConclusion',
        UpdateConclusionPrintForm: 'UpdateConclusionPrintForm',
        UpdateTaskCurator: 'UpdateTaskCurator',
        ViewSubject: 'ViewSubject',
        EditDeadlineAndStartedDates: 'EditDeadlineAndStartedDates',
    },

    expertiseTaskInvite: {
        View: 'View',
    },
};
