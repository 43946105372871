import { Typography } from '@mui/material';
import React from 'react';
import { CampaignModel } from '../../../../../models';

export type CampaignHeaderTitleProps = {
    title: CampaignModel['title'];
};

export const CampaignHeaderTitle = (props: CampaignHeaderTitleProps): JSX.Element => {
    const { title } = props;

    return <Typography variant="subtitle2">{title}</Typography>;
};
