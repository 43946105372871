import { AuthorizationCheckQueryOrWithoutCheck, useFetchAllPermissions } from '@platform/front-core';
import { KebabButton, MenuButton } from '@platform/front-ui';
import { identity, IdTitle } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { DeleteActionMenuItem as DeleteActionMenuItemInj } from '../../../components';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { SubjectTableRow } from '../../../types';
import { SubjectCreateExpertiseButton as SubjectCreateExpertiseButtonInj } from './subject-expertise-create-dialog';

export type SubjectRegistryActionsBtnsProps = {
    subjectTableRow: SubjectTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const SubjectRegistryActionsBtns = observer((props: SubjectRegistryActionsBtnsProps): JSX.Element => {
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], SubjectRegistryActionsBtns);
    const [SubjectCreateExpertiseButton] = di([SubjectCreateExpertiseButtonInj], SubjectRegistryActionsBtns);

    const { subjectStore, coreRootStore } = useStore();
    const { formatMessage } = useIntl();

    const { subjectTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id, customData } = subjectTableRow;
    const { permissionsStore } = coreRootStore;
    const { expertiseSubjectConfig } = permissionsStore as PermissionsStore;

    const allPermissionsQueries = useMemo<AuthorizationCheckQueryOrWithoutCheck[]>(() => {
        return [expertiseSubjectConfig.createExpertise(id), expertiseSubjectConfig.deleteExpertiseSubject(id)];
    }, [id]);

    const [permissionsData] = useFetchAllPermissions({ allPermissionsQueries });
    const [createExpertise, deleteExpertiseSubject] = permissionsData;

    const renderActionItems = useCallback(
        (hideMenu: () => void): ReactNode[] => {
            const subject: IdTitle = {
                id,
                title: `${customData.number.title} ${customData.title}`,
            };

            const onDeleteConfirm = async (): Promise<void> => {
                try {
                    toggleIsRowBlocked();
                    await subjectStore.deleteSubject(id, true);
                    reloadData();
                    hideMenu && hideMenu();
                } catch {
                    rowErrorHandler();
                }
            };

            const title = formatMessage({ id: 'common.confirmDeletion' });
            const message = formatMessage(
                { id: 'subject.confirmDeletionInfoText' },
                { title: subjectTableRow.customData.title },
            );
            return [
                createExpertise && <SubjectCreateExpertiseButton subject={subject} />,
                deleteExpertiseSubject && (
                    <DeleteActionMenuItem id="delete" title={title} message={message} onConfirm={onDeleteConfirm} />
                ),
            ];
        },
        [permissionsData, subjectTableRow],
    );

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some(identity);
        return atLeastOneAllowed ? <KebabButton onClick={onClick} /> : <React.Fragment />;
    };

    return <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems} />;
});
