import { FormApi } from '@platform/formiojs-react';
import { UserNameModel } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { CampaignStore } from '../../stores';
import { CampaignDTO } from '../../types';
import { MetaInfoModel } from '../metaInfo';
import { CampaignLinksModel } from './CampaignLinksModel';
import { CampaignStatisticModel } from './CampaignStatisticModel';

export const campaignModelObservables = {
    campaignStore: observable,

    id: observable,
    title: observable,
    identifier: observable,
    manager: observable,
    metaInfo: observable,
    campaignStatisticModel: observable,
    campaignLinksModel: observable,

    formApi: observable,
    isFormChanged: observable,
    isShowValidation: observable,
    formDTO: observable,

    load: action.bound,
    loadCard: action.bound,
    loadCampaignStatistic: action.bound,
    loadCampaignLinks: action.bound,
    deleteCampaign: action.bound,

    setMainFields: action.bound,
    setFormApi: action.bound,
    setIsFormChanged: action.bound,
    setIsShowValidation: action.bound,
    dropIsShowValidation: action.bound,
};

export class CampaignModel {
    protected campaignStore: CampaignStore;

    id = '';
    title = '';
    identifier = '';
    manager: UserNameModel = new UserNameModel();
    metaInfo: MetaInfoModel = new MetaInfoModel();

    formDTO?: FormDTO;
    formApi?: FormApi;
    isFormChanged = false;
    isShowValidation = false;

    campaignStatisticModel: CampaignStatisticModel;
    campaignLinksModel: CampaignLinksModel;

    constructor(id: string, campaignStore: CampaignStore) {
        makeObservable(this, campaignModelObservables);
        this.id = id;
        this.campaignStore = campaignStore;
        this.campaignStatisticModel = new CampaignStatisticModel();
        this.campaignLinksModel = new CampaignLinksModel();
    }

    load(): Promise<void> {
        return this.campaignStore.loadCampaign(this.id).then(this.setMainFields);
    }

    loadCampaignStatistic(): void {
        this.campaignStore.getCampaignStatistic(this.id).then(this.campaignStatisticModel.load);
    }

    loadCampaignLinks(): void {
        this.campaignStore.getCampaignLinks(this.id).then(this.campaignLinksModel.load);
    }

    deleteCampaign(): Promise<void> {
        return this.campaignStore.deleteCampaign(this.id);
    }

    loadCard(dto: CampaignDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.manager.load(dto.manager);
        this.metaInfo.load(dto.metaInfo);
    }

    setMainFields(dto: CampaignDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.formDTO = dto.formInfo;
        this.manager.load(dto.manager);
        this.metaInfo.load(dto.metaInfo);
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setIsFormChanged(formIsChanged: boolean): void {
        this.isFormChanged = formIsChanged;
    }

    setIsShowValidation(isShowValidation: boolean): void {
        this.isShowValidation = isShowValidation;
    }

    dropIsShowValidation(): void {
        this.isShowValidation = false;
    }
}
