import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AuthorizationCheck, AuthorizationCheckQueryOrWithoutCheck } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { grey, Typography } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { ExpertiseTaskPrintFormModel } from '../../../../../../models';
import { ExpertiseTaskPrintFormDTO, PrintFormFileDTO } from '../../../../../../types';
import { ExpertiseTaskPrintFormsTableErrorTooltip as ExpertiseTaskPrintFormsTableErrorTooltipInj } from './ExpertiseTaskPrintFormsTableErrorTooltip';
import { ExpertiseTaskPrintFormsTableTooltipFile as ExpertiseTaskPrintFormsTableTooltipFileInj } from './ExpertiseTaskPrintFormsTableTooltipFile';

const tableCellSx: SxStyle = {
    maxWidth: '300px',
};

const buttonSx: SxStyle = {
    ml: '20px',
    mr: 2,
};

export type ExpertiseTaskPrintFormsTablePfRowProps = {
    printForm: ExpertiseTaskPrintFormModel;
    permission: AuthorizationCheckQueryOrWithoutCheck;
    pfTypeMsgId: string;
    downloadPf: (file: PrintFormFileDTO) => VoidFunction;
};

export const ExpertiseTaskPrintFormsTablePfRow = observer(
    (props: ExpertiseTaskPrintFormsTablePfRowProps): JSX.Element => {
        const [ExpertiseTaskPrintFormsTableTooltipFile] = di(
            [ExpertiseTaskPrintFormsTableTooltipFileInj],
            ExpertiseTaskPrintFormsTablePfRow,
        );
        const [ExpertiseTaskPrintFormsTableErrorTooltip] = di(
            [ExpertiseTaskPrintFormsTableErrorTooltipInj],
            ExpertiseTaskPrintFormsTablePfRow,
        );

        const { permission, pfTypeMsgId, printForm, downloadPf } = props;
        const { id, title, file, errorCode, isGenerated } = printForm;
        const { expertiseTaskStore } = useStore();
        const { stateTitle } = useExpertiseTaskViewPageContext().model.taskModel.metaInfo;

        const startIcon = isGenerated ? (
            <CircularProgress size={20} />
        ) : (
            !!errorCode && <ExpertiseTaskPrintFormsTableErrorTooltip errorCode={errorCode} />
        );
        const generateMsgId = file
            ? 'expertiseTask.printFormsTable.updateAction'
            : 'expertiseTask.printFormsTable.createAction';

        const handleUpdateAction = (printForm: ExpertiseTaskPrintFormModel) => () => {
            const { id: taskPrintFormId, taskId, code: pfCode } = printForm;
            printForm.isGenerated = true;
            return (
                printForm.file
                    ? expertiseTaskStore.updatePrintForm(taskPrintFormId)
                    : expertiseTaskStore.createPrintForm(taskId, pfCode)
            )
                .then((dto: ExpertiseTaskPrintFormDTO) => {
                    printForm.load(dto);
                    printForm.errorCode = '';
                })
                .catch(() => {
                    printForm.errorCode = 'expertiseTask.printFormsTable.generationError';
                })
                .finally(() => {
                    printForm.isGenerated = false;
                });
        };

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" width="90%">
                            <Typography variant="body1" color={grey[400]} pl={2}>
                                <FormattedMessage id="pfTemplate.fields.file" />
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant="body1" color={grey[400]} ml="20px">
                                <FormattedMessage id="pfTemplate.fields.actions" />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={id}>
                        <TableCell align="left" sx={tableCellSx}>
                            {file ? (
                                <ExpertiseTaskPrintFormsTableTooltipFile
                                    file={file}
                                    downloadPf={downloadPf}
                                    templateTitle={title}
                                />
                            ) : (
                                <Typography pl={2}>
                                    <FormattedMessage id={pfTypeMsgId} />
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell align="left">
                            <AuthorizationCheck key={stateTitle} {...permission}>
                                <Button
                                    disabled={isGenerated}
                                    onClick={handleUpdateAction(printForm)}
                                    color="secondary"
                                    variant="contained"
                                    startIcon={startIcon}
                                    sx={buttonSx}
                                >
                                    <FormattedMessage id={generateMsgId} />
                                </Button>
                            </AuthorizationCheck>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    },
);
