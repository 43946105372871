import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../components';
import { SubjectControlPanelLeft as SubjectControlPanelLeftInj } from './SubjectControlPanelLeft';
import { SubjectControlPanelRight as SubjectControlPanelRightInj } from './SubjectControlPanelRight';

export const SubjectControlPanel = observer((): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], SubjectControlPanel);
    const [SubjectControlPanelRight] = di([SubjectControlPanelRightInj], SubjectControlPanel);
    const [SubjectControlPanelLeft] = di([SubjectControlPanelLeftInj], SubjectControlPanel);

    const leftSide = <SubjectControlPanelLeft />;
    const rightSide = <SubjectControlPanelRight />;

    return <BottomControlPanel left={leftSide} right={rightSide} />;
});
