import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { useAntiDoubleClick } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { TemplatesOfExpertiseTableRow } from '../../../types';
import {
    TemplatesOfExpertiseRegistryActionsBtns as TemplatesOfExpertiseRegistryActionsBtnsInj,
    TemplatesOfExpertiseRegistryTable as TemplatesOfExpertiseRegistryTableInj,
} from './templates-of-expertise-registry';

export const templatesOfExpertiseListPageSetRowActionsInj: TTableRowActions<TemplatesOfExpertiseTableRow> = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [TemplatesOfExpertiseRegistryActionsBtns] = di(
        [TemplatesOfExpertiseRegistryActionsBtnsInj],
        TemplatesOfExpertiseListPage,
    );
    return (
        <TemplatesOfExpertiseRegistryActionsBtns
            templatesOfExpertiseTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const TemplatesOfExpertiseListPage = observer((): JSX.Element => {
    const [TemplatesOfExpertiseRegistryTable] = di(
        [TemplatesOfExpertiseRegistryTableInj],
        TemplatesOfExpertiseListPage,
    );
    const [templatesOfExpertiseListPageSetRowActions] = di(
        [templatesOfExpertiseListPageSetRowActionsInj],
        TemplatesOfExpertiseListPage,
    );

    const rootStore = useStore();
    const { history, templatesOfExpertiseStore, coreRootStore } = rootStore;
    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const addTemplateExpertiseQuery = systemConfig.addTemplateExpertise();

    const createTemplate = async (): Promise<void> => {
        const id = await templatesOfExpertiseStore.create();
        history.push(generatePath(clientRoute.templateOfExpertiseCreate, { id }));
    };

    const [isLoading, endIcon, handleSubmit] = useAntiDoubleClick(createTemplate);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="templatesOfExpertise.listTitle" />
                        </Typography>
                        <AuthorizationCheck {...addTemplateExpertiseQuery}>
                            <Grid item>
                                <Button
                                    disabled={isLoading}
                                    endIcon={endIcon}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    <FormattedMessage id="templatesOfExpertise.create" />
                                </Button>
                            </Grid>
                        </AuthorizationCheck>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <TemplatesOfExpertiseRegistryTable setRowActions={templatesOfExpertiseListPageSetRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
