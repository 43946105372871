import { Grid, Link } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { headerLeftStyles } from '../../../constants';
import { getOrElse } from '../../../utils';

export type CategoryOfSubjectsPageHeaderLeftProps = {
    created?: Date;
    modified?: Date;
};

export const CategoryOfSubjectsPageHeaderLeft = observer(
    (props: CategoryOfSubjectsPageHeaderLeftProps): JSX.Element => {
        const { created, modified } = props;
        const { formatMessage, formatDate } = useIntl();
        const { id } = useParams<RouteParamsDefault>();

        const categoryValue = getOrElse(created, formatDate(created), '-');
        const lastModifiedValue = getOrElse(modified, formatDate(modified), '-');
        const createdTitle = formatMessage({ id: 'category.fields.created' });
        const lastModifiedTitle = formatMessage({ id: 'category.fields.lastModified' });
        const syslogLabel = formatMessage({ id: 'syslog.single.nominative' });
        const syslogPath = generatePath(clientRoute.categorySyslog, {
            id,
        });

        return (
            <Grid item container sx={headerLeftStyles.container}>
                <Grid item>
                    <CommonInfoLine title={createdTitle} value={categoryValue} />
                </Grid>
                <Grid item>
                    <CommonInfoLine title={lastModifiedTitle} value={lastModifiedValue} />
                </Grid>
                <Grid item>
                    <Link component={NavLink} to={syslogPath}>
                        {syslogLabel}
                    </Link>
                </Grid>
            </Grid>
        );
    },
);
