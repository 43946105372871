import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { getExpertiseTaskViewRoutes as getExpertiseTaskViewRoutesInj } from '../../utils';
import {
    ExpertiseTaskViewPage as ExpertiseTaskViewPageInj,
    ExpertiseTaskViewPageContextProvider as ExpertiseTaskViewPageContextProviderInj,
} from './expertise-task-page';
import { ExpertiseTaskSyslogPage as ExpertiseTaskSyslogPageInj } from './expertise-task-syslog';

export type ExpertiseTaskViewRoutesPagesProps = {
    withSubjectFilesTab: boolean;
};

export const ExpertiseTaskViewRoutesPages = (props: ExpertiseTaskViewRoutesPagesProps): JSX.Element => {
    const [ExpertiseTaskSyslogPage] = di([ExpertiseTaskSyslogPageInj], ExpertiseTaskViewRoutesPages);
    const [getExpertiseTaskViewRoutes] = di([getExpertiseTaskViewRoutesInj], ExpertiseTaskViewRoutesPages);
    const [ExpertiseTaskViewPageContextProvider] = di(
        [ExpertiseTaskViewPageContextProviderInj],
        ExpertiseTaskViewRoutesPages,
    );
    const [ExpertiseTaskViewPage] = di([ExpertiseTaskViewPageInj], ExpertiseTaskViewRoutesPages);

    const { withSubjectFilesTab } = props;
    const taskViewRoutes = getExpertiseTaskViewRoutes(withSubjectFilesTab);

    return (
        <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
            <ExpertiseTaskViewPageContextProvider>
                <Switch>
                    <Route path={clientRoute.expertiseTaskSyslog}>
                        <ExpertiseTaskSyslogPage />
                    </Route>
                    <Route>
                        <ExpertiseTaskViewPage />
                    </Route>
                </Switch>
            </ExpertiseTaskViewPageContextProvider>
        </Route>
    );
};
