import { action, makeObservable, observable } from 'mobx';
import { ExpertiseTaskPrintFormDTO, PrintFormFileDTO } from '../../types';

export const expertiseTaskPrintFormModelObservables = {
    id: observable,
    title: observable,
    code: observable,
    taskId: observable,
    file: observable,
    isGenerated: observable,
    errorCode: observable,

    load: action.bound,
};

export class ExpertiseTaskPrintFormModel {
    id = '';
    title = '';
    code = '';
    taskId = '';
    file?: PrintFormFileDTO;

    isGenerated = false;
    errorCode = '';

    constructor() {
        makeObservable(this, expertiseTaskPrintFormModelObservables);
    }

    load(dto: ExpertiseTaskPrintFormDTO): ExpertiseTaskPrintFormModel {
        Object.assign(this, dto);
        return this;
    }
}
