import { Grid, Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { PermissionsStore } from '../../../../../../stores';
import { getOrElse } from '../../../../../../utils';
import { expertiseTaskSidebarStyles } from '../expertiseTaskSidebarStyles';

export const ExpertiseTaskSidebarServiceTabValue = observer((): JSX.Element => {
    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel } = model;
    const { metaInfo } = taskModel;
    const { author, created, formLinks, processLink, modified } = metaInfo;

    const { formatMessage } = useIntl();
    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const createdValue = getOrElse(created, <FormattedDate value={created} />);
    const modifiedValue = getOrElse(modified, <FormattedDate value={modified} />);
    const lifecycleValue = getOrElse(
        processLink,
        <Link target="_blank" href={processLink?.url}>
            {processLink?.label}
        </Link>,
    );
    const formValue = getOrElse(
        formLinks,
        formLinks.map((item) => (
            <Link key={item.url} target="_blank" href={item.url}>
                {item.label}
            </Link>
        )),
    );

    const authorTitle = formatMessage({ id: 'common.serviceInfoFields.author' });
    const createdTitle = formatMessage({ id: 'common.serviceInfoFields.created' });
    const lastModifiedTitle = formatMessage({ id: 'common.serviceInfoFields.lastModified' });
    const lifeCycleTitle = formatMessage({ id: 'common.serviceInfoFields.lifeCycle' });
    const formTitle = formatMessage({ id: 'common.serviceInfoFields.form' });

    return (
        <Grid item container sx={expertiseTaskSidebarStyles.container}>
            <AuthorizationCheck {...administrationQuery}>
                <CommonInfoLine title={authorTitle} value={author.name} isColumnDirection />
                <CommonInfoLine title={createdTitle} value={createdValue} isColumnDirection />
                <CommonInfoLine title={lastModifiedTitle} value={modifiedValue} isColumnDirection />
                <CommonInfoLine title={lifeCycleTitle} value={lifecycleValue} isColumnDirection />
                <CommonInfoLine title={formTitle} value={formValue} isColumnDirection />
            </AuthorizationCheck>
        </Grid>
    );
});
