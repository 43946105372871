import {
    Button,
    Grid,
    Link,
    MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { TotLocale, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { DeleteActionMenuItem as DeleteActionMenuItemInj, ErrorDialog as ErrorDialogInj } from '../../../components';
import { useError, useExpertisePageContext, useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { ExpertiseTasksRow } from '../../../types';
import { getTaskRoute as getTaskRouteInj } from '../../../utils';
import { ExpertiseTaskCreateDialog as ExpertiseTaskCreateDialogInj } from '../../task';
import { ExpertiseTaskTableHead as ExpertiseTaskTableHeadInj } from './ExpertiseTaskTableHead';

const pendingElementRu: JSX.Element = (
    <MenuItem>
        <Skeleton width={130} />
    </MenuItem>
);

const pendingElementEn: JSX.Element = (
    <MenuItem>
        <Skeleton width={70} />
    </MenuItem>
);

export const ExpertiseTasksTable = observer((): JSX.Element => {
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertiseTasksTable);
    const [ErrorDialog] = di([ErrorDialogInj], ExpertiseTasksTable);
    const [getTaskRoute] = di([getTaskRouteInj], ExpertiseTasksTable);
    const [ExpertiseTaskCreateDialog] = di([ExpertiseTaskCreateDialogInj], ExpertiseTasksTable);
    const [ExpertiseTaskTableHead] = di([ExpertiseTaskTableHeadInj], ExpertiseTasksTable);

    const { expertiseTasksListByExpertiseModel, planEntryListModel } = useExpertisePageContext();
    const { loadExpertiseTasksList, deleteTask, id, expertiseTasks } = expertiseTasksListByExpertiseModel;

    const { expertiseTaskStore, coreRootStore } = useStore();
    const { permissionsStore } = coreRootStore;
    const { locale } = useIntl();

    const pendingElement = locale === TotLocale.en ? pendingElementEn : pendingElementRu;
    const { expertiseTaskConfig, expertiseConfig } = permissionsStore as PermissionsStore;
    const createTaskQuery = expertiseConfig.createTask(id);

    const { isError, errorText, setErrorIsClosed } = useError();
    const [isDialogOpen, openDialog, closeDialog] = useFlag();
    const match = useRouteMatch();

    const createExpertiseTask = (planEntryId: string): Promise<void> => {
        return expertiseTaskStore
            .createExpertiseTask(planEntryId)
            .then(() => {
                loadExpertiseTasksList();
            })
            .finally(() => {
                closeDialog();
            });
    };

    useEffect(() => {
        loadExpertiseTasksList();
    }, [planEntryListModel.entries]);

    const renderActionItems = (task: ExpertiseTasksRow): (() => ReactNode[]) => {
        const backUrl = match.url;
        const taskId = task.id;
        const editExpertiseTask = expertiseTaskConfig.editExpertiseTask(taskId);
        const deleteExpertiseTask = expertiseTaskConfig.deleteExpertiseTask(taskId);

        return (): ReactNode[] => [
            <AuthorizationCheck {...editExpertiseTask} key="edit" pendingElement={pendingElement}>
                <MenuItem
                    dense
                    tabIndex={0}
                    component={NavLink}
                    to={`${generatePath(clientRoute.expertiseTaskEdit, { id: taskId })}?backUrl=${backUrl}`}
                >
                    <FormattedMessage id="common.edit" />
                </MenuItem>
            </AuthorizationCheck>,
            <AuthorizationCheck {...deleteExpertiseTask} key="delete" pendingElement={pendingElement}>
                <DeleteActionMenuItem
                    id="delete"
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={<FormattedMessage id="expertiseTask.listConfirmDeletionInfoText" />}
                    onConfirm={deleteTask(taskId)}
                />
            </AuthorizationCheck>,
        ];
    };

    const renderActions = (task: ExpertiseTasksRow): JSX.Element => {
        const renderMenuItems = renderActionItems(task);

        return <MenuButton renderMenuItems={renderMenuItems} />;
    };

    const getExpertCell = (row: ExpertiseTasksRow): React.ReactNode => {
        return row.expertName ? (
            row.expertName
        ) : row.expertCandidateName ? (
            <FormattedMessage
                id="expertiseTask.expertCandidate"
                values={{
                    expert: row.expertCandidateName,
                }}
            />
        ) : (
            ''
        );
    };

    const renderTableBody = (): JSX.Element[] => {
        return expertiseTasks.map((task: ExpertiseTasksRow) => {
            const taskRoute = getTaskRoute(task.id, task.taskType?.code);
            return (
                <TableRow key={task.id} hover>
                    <TableCell>
                        <Link component={NavLink} to={taskRoute}>
                            {task.identifier}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {task.planEntry.title}{' '}
                        {task.viewPoint && (
                            <Typography>
                                <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                                <span>: {task.viewPoint}</span>
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>{getExpertCell(task)}</TableCell>
                    <TableCell>
                        {(task.acceptance && <FormattedDate value={task.acceptance} />) ||
                            (task.planAcceptanceDays && (
                                <Typography>
                                    {task.planAcceptanceDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )) ||
                            (task.planAcceptance && (
                                <Typography>
                                    <FormattedDate value={task.planAcceptance} />
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.datePlan" />
                                </Typography>
                            ))}
                    </TableCell>
                    <TableCell>
                        {(task.deadline && <FormattedDate value={task.deadline} />) ||
                            (task.planDeadlineDays && (
                                <Typography>
                                    {task.planDeadlineDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )) ||
                            (task.planDeadline && (
                                <Typography>
                                    <FormattedDate value={task.planDeadline} />
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.datePlan" />
                                </Typography>
                            ))}
                    </TableCell>
                    <TableCell>{task.completed && <FormattedDate value={task.completed} />}</TableCell>
                    <TableCell>{task.state}</TableCell>
                    <TableCell>{renderActions(task)}</TableCell>
                </TableRow>
            );
        });
    };

    const tableBody = renderTableBody();

    return (
        <React.Fragment>
            <ExpertiseTaskCreateDialog open={isDialogOpen} onCancel={closeDialog} onSubmit={createExpertiseTask} />
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Grid container direction="column">
                <Grid item container direction="row" justifyContent="space-between" marginBottom={3}>
                    <Grid item>
                        <Typography variant="subtitle2">
                            <FormattedMessage id="expertiseTask.listTitle" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck {...createTaskQuery}>
                            <Button color="primary" variant="contained" onClick={openDialog}>
                                <FormattedMessage id="expertiseTask.create" />
                            </Button>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <Table>
                        <ExpertiseTaskTableHead />
                        <TableBody>{tableBody}</TableBody>
                    </Table>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
