import { LocalesTree } from '@platform/front-utils';

const headerLinks: LocalesTree = {
    campaigns: 'Campaigns',
    categories: 'Categories',
    console: 'Console',
    expertiseList: 'Expertise',
    expertiseTasks: 'Tasks',
    pfTemplates: 'Templates of PF',
    subjects: 'Objects',
    templatesOfExpertise: 'Templates of expertise',
    users: 'Users',
};

export default headerLinks;
