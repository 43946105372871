import { FormDTO, PersonDTO, TitlesGroupDTO, UserState, UserStatusAction } from '@platform/front-types';
import { CodeTitle, FullName, TotLocale } from '@platform/front-utils';
import { FieldValues as LangTitle } from '@platform/multi-lang-field';
import { UserCardModel } from '../models';

export type EditStatusDTO = {
    action: UserStatusAction;
    reason?: string;
};

export type UserInfoMessageValues = {
    name: string;
    login: string;
};

export type UserBlockInfo = {
    author: PersonDTO;
    blockReason: string;
    created: Date;
};

export type UserDTO = {
    fullName: string;
    userId: string;
    lang: TotLocale;
    login: string;
    status: UserState;
    roles: CodeTitle[];
    formInfo: FormDTO;
    blockInfo: UserBlockInfo;
};

export type LangUserDTO = FullName & {
    lang: string;
};

export type UserForCreate = {
    email: string;
    names: [LangUserDTO, LangUserDTO];
    roles: string[];
} & LangUserDTO;

export type UserPageContextType = {
    userModel: UserCardModel;
};

export type LoginSettings = {
    login: string;
};

export type LoginSettingsDTO = {
    login: string;
};

export type RolesSettings = {
    roles: CodeTitle[];
};

export type RolesSettingsDTO = {
    roles: string[];
};

export type FullNameSettings = {
    lastName: LangTitle[];
    firstName: LangTitle[];
    middleName: LangTitle[];
};

export enum FullNameGroupFields {
    lastName = 'lastName',
    firstName = 'firstName',
    middleName = 'middleName',
}

export type FullNameSettingsDTO = TitlesGroupDTO<FullNameGroupFields>;

export type UserUpdateBody = {
    email: string;
    lang: TotLocale;
    roles: string[];
};

export enum RolesFields {
    roles = 'roles',
}

export enum FullNameFields {
    fullName = 'fullName',
}

export enum LoginFields {
    login = 'login',
}

export enum UserRole {
    admin = 'Admin',
    moderator = 'Moderator',
    seniorEmployee = 'SeniorEmployee',
    employee = 'Employee',
    user = 'User',
}

export type RolePriority = Partial<Record<UserRole, number>>;
export type RedirectByRoleType = Partial<Record<UserRole, string>>;

export type UserPageFields = RolesFields | FullNameFields;
