import { Checkbox, Grid, FormControlLabel } from '@mui/material';
import { TTableFilter, LikeFilter } from '@platform/ttable';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TTableFilterType } from '../../../types';

export const SubjectRegistryTableCustomCategoryFilter = (props: TTableFilter): JSX.Element => {
    const { column } = props;
    const { filterValue, setFilter, columnType, filterField } = column;
    const { formatMessage } = useIntl();

    const isChecked = filterValue?.filterType === TTableFilterType.notExists;

    const handleCheckboxChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const checkedFromEvent = event.target.checked;
            const filterType = checkedFromEvent ? TTableFilterType.notExists : TTableFilterType.like;
            const isFilterTypeNotExists = filterType === TTableFilterType.notExists;

            const newFilterFieldValue = isFilterTypeNotExists ? '' : filterValue?.value;
            const isFilterEmpty = isFilterTypeNotExists ? false : !filterValue?.value;
            const newValue = isFilterEmpty
                ? undefined
                : { filterType, columnType, value: newFilterFieldValue, filterField };

            setFilter(isFilterEmpty ? undefined : newValue);
        },
        [setFilter],
    );

    const checkboxComponent = <Checkbox checked={isChecked} onChange={handleCheckboxChange} />;
    return (
        <Grid direction="column">
            {!isChecked && (
                <Grid item>
                    <LikeFilter {...props} />
                </Grid>
            )}
            <Grid item pt={1}>
                <FormControlLabel
                    control={checkboxComponent}
                    label={formatMessage({ id: 'subject.withoutExpertise' })}
                />
            </Grid>
        </Grid>
    );
};
