import { Button, Grid } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userMessages } from '../../../../../customization';
import { useUserPageContext } from '../../../../../hooks';

const userPageLoginAsButtonSx: SxStyle = { whiteSpace: 'nowrap' };

export const UserPageLoginAsButton = observer((): JSX.Element => {
    const { userModel } = useUserPageContext();
    const { loginAsUser } = userModel;

    return (
        <Grid item>
            <Button onClick={loginAsUser} sx={userPageLoginAsButtonSx} size="medium" variant="contained">
                <FormattedMessage {...userMessages.loginAsUser} />
            </Button>
        </Grid>
    );
});
