import { Transitions } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useExpertisePageContext, useStore } from '../../../../hooks';

export const ExpertiseControlPanelRight = observer((): JSX.Element => {
    const { expertiseStore } = useStore();
    const { model } = useExpertisePageContext();
    const { id, load } = model;

    return <Transitions onSuccessTransition={load} transitionsStore={expertiseStore} objectId={id} />;
});
