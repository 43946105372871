import { FullSubmission } from '@platform/front-types';
import { CodeTitle } from '@platform/front-utils';
import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { ExpertisePlanStore, RootStore } from '../../stores';
import { PlanEntryDTO } from '../../types';
import { PlanEntryModel } from './PlanEntryModel';

export const planEntryListModelObservables = {
    rootStore: observable,
    expertisePlanStore: observable,

    ownerId: observable,
    entries: observable,
    taskFormSelectData: observable,
    isAlertOpen: observable,

    loadPlan: action.bound,
    buildNewEntry: action.bound,
    newEntry: action.bound,
    getEntry: action.bound,
    succesMessage: action.bound,
    saveEntry: action.bound,
    deleteEntry: action.bound,
    setEntries: action.bound,
};

export class PlanEntryListModel {
    rootStore: RootStore;
    expertisePlanStore: ExpertisePlanStore;

    ownerId: string;
    entries: PlanEntryDTO[] = [];
    taskFormSelectData: IObservableArray<CodeTitle> = observable.array();
    isAlertOpen = false;

    constructor(ownerId: string, store: ExpertisePlanStore, rootStore: RootStore) {
        makeObservable(this, planEntryListModelObservables);
        this.ownerId = ownerId;
        this.expertisePlanStore = store;
        this.rootStore = rootStore;
        this.loadPlan();
        this.expertisePlanStore.expertiseTaskFormsSelectData().then((data) => this.taskFormSelectData.replace(data));
    }

    loadPlan(): Promise<void> {
        return this.expertisePlanStore.loadPlanEntryList(this.ownerId).then(this.setEntries);
    }

    buildNewEntry(): PlanEntryModel {
        return new PlanEntryModel(
            this.taskFormSelectData,
            this.expertisePlanStore.expertiseTaskFormByCode,
            this.expertisePlanStore.rootStore,
        );
    }

    newEntry(): PlanEntryModel {
        const model = this.buildNewEntry();
        this.expertisePlanStore.createPlanEntry(this.ownerId).then(model.loadCreated);
        return model;
    }

    getEntry(id: string): PlanEntryModel {
        const model = this.buildNewEntry();
        this.expertisePlanStore.loadPlanEntry(id).then(model.load);
        return model;
    }

    succesMessage(): void {
        const { notificationStore, intlStore } = this.rootStore.coreRootStore;
        const successMessage = intlStore.intl.formatMessage({ id: 'expertise.updatedTasks' });
        notificationStore.onSuccess(successMessage);
    }

    async saveEntry(entry: PlanEntryModel, submission: FullSubmission, taskSubmission?: FullSubmission): Promise<void> {
        await this.expertisePlanStore.updatePlanEntry(entry.id, submission, entry.taskFormCode, taskSubmission);
        await this.loadPlan();
        this.succesMessage();
    }

    async deleteEntry(id: string): Promise<void> {
        await this.expertisePlanStore.deletePlanEntry(id);
        await this.loadPlan();
        this.succesMessage();
    }

    setEntries(entries: PlanEntryDTO[]): void {
        this.entries = entries;
    }
}
