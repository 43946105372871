import { LocalesTree } from '@platform/front-utils';

const campaign: LocalesTree = {
    actions: {
        deleteCampaign: 'Delete campaign',
    },
    blocks: {
        expertise: 'Expertise',
        expertiseObjects: 'Objects',
        serviceInfo: 'Service information',
        tasks: 'Tasks',
    },
    campaignListTitle: 'Campaigns',
    confirmDeletionInfoText: 'Are you sure you want to delete the campaign "{title}"?',
    createCampaign: 'Create campaign',
    editCampaign: 'Edit campaign',
    expertises: {
        active: 'In progress',
        canceled: 'Canceled',
        done: 'Done',
        title: 'Expertises',
        total: 'Total',
    },
    fields: {
        author: 'Author',
        created: 'Created',
        identifier: 'Number',
        lastModified: 'Last modified',
        lifecycle: 'Lifecycle',
        responsible: 'Responsible',
        state: 'State',
        title: 'Title',
    },
    newCampaign: 'New campaign',
    registryPagingInfo: 'Total {count, number} campaigns. Displayed from {from, number} to {to, number}',
    subjects: {
        active: 'Current',
        title: 'Subjects of expertise',
        total: 'Total',
        withExpertise: 'With expertise',
        withoutExpertise: 'Without expertise',
    },
    tasks: {
        active: 'In progress',
        done: 'Done',
        notCompleted: 'Not completed',
        overdue: 'Overdue',
        responseWaiting: 'Expect an answer',
        startWaiting: 'Expect an start',
        title: 'Tasks of expertise',
        total: 'Total',
        withoutExpert: 'Without expertise',
    },
};

export default campaign;
