import { FormApi } from '@platform/formiojs-react';
import { FormDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { TemplatesOfExpertiseStore } from '../../stores';
import { TemplatesOfExpertiseDTO } from '../../types';
import { MetaInfoModel } from '../metaInfo';

export const templatesOfExpertiseModelObservables = {
    templateOfExpertiseStore: observable,

    id: observable,
    title: observable,
    formInfo: observable,
    formDTO: observable,
    metaInfo: observable,
    formApi: observable,
    isShowValidation: observable,

    load: action.bound,
    loadCard: action.bound,
    deleteTemplateOfExpertise: action.bound,
    dropIsShowValidation: action.bound,
    setMainFields: action.bound,
    setFormApi: action.bound,
    setIsShowValidation: action.bound,
};

export class TemplatesOfExpertiseModel {
    protected templateOfExpertiseStore: TemplatesOfExpertiseStore;

    id: string;
    title = '';
    formInfo = '';
    formDTO?: FormDTO;
    metaInfo: MetaInfoModel = new MetaInfoModel();
    formApi?: FormApi;
    isShowValidation = false;

    constructor(id: string, templateOfExpertiseStore: TemplatesOfExpertiseStore) {
        makeObservable(this, templatesOfExpertiseModelObservables);
        this.id = id;
        this.templateOfExpertiseStore = templateOfExpertiseStore;
    }

    load(): Promise<void> {
        return this.templateOfExpertiseStore.loadTemplateOfExpertiseDTO(this.id).then(this.setMainFields);
    }

    loadCard(dto: TemplatesOfExpertiseDTO): void {
        this.title = dto.title;
        this.metaInfo.load(dto.metaInfo);
    }

    deleteTemplateOfExpertise(): Promise<void> {
        return this.templateOfExpertiseStore.delete(this.id);
    }

    dropIsShowValidation(): void {
        this.isShowValidation = false;
    }

    setMainFields(dto: TemplatesOfExpertiseDTO): void {
        this.title = dto.title;
        this.formDTO = dto.formInfo;
        this.metaInfo.load(dto.metaInfo);
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setIsShowValidation(state: boolean): void {
        this.isShowValidation = state;
    }
}
