import { Grid } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../../hooks';
import { PermissionsStore } from '../../../../../../../stores';
import { ExpertiseTaskAuthEditField as ExpertiseTaskAuthEditFieldInj } from '../../../../expertise-task-edit-mode';
import { ExpertiseTaskEditDateDialog as ExpertiseTaskEditDateDialogInj } from '../../../../ExpertiseTaskEditDateDialog';
import { expertiseTaskSidebarStyles } from '../../expertiseTaskSidebarStyles';

export const ExpertiseTaskViewDeadlineField = observer((): JSX.Element => {
    const [ExpertiseTaskEditDateDialog] = di([ExpertiseTaskEditDateDialogInj], ExpertiseTaskViewDeadlineField);
    const [ExpertiseTaskAuthEditField] = di([ExpertiseTaskAuthEditFieldInj], ExpertiseTaskViewDeadlineField);

    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel, changeDueDate } = model;
    const { deadline, started, id } = taskModel;
    const { formatMessage, formatDate } = useIntl();
    const { permissionsStore } = useStore().coreRootStore;
    const { permissions } = permissionsStore as PermissionsStore;

    const [isModalOpen, openModal, closeModal] = useFlag();

    const deadlineValue = deadline ? formatDate(deadline) : undefined;
    const deadlineTitle = formatMessage({ id: 'expertiseTask.fields.deadline' });
    const changeOfDueDateTitle = formatMessage({ id: 'expertiseTask.changeOfDueDate' });

    const submitDueDate = async (newDeadline: Date): Promise<void> => {
        await changeDueDate(newDeadline);
        closeModal();
    };

    return (
        <Grid container sx={expertiseTaskSidebarStyles.editableFieldContainer}>
            <ExpertiseTaskEditDateDialog
                open={isModalOpen}
                onCancel={closeModal}
                onSubmit={submitDueDate}
                currentDate={deadline}
                label={deadlineTitle}
                title={changeOfDueDateTitle}
                minValue={started}
            />
            <CommonInfoLine title={deadlineTitle} value={deadlineValue} isColumnDirection />
            <Grid container sx={expertiseTaskSidebarStyles.editableFieldToolbar}>
                <ExpertiseTaskAuthEditField
                    toggleIsOpenDialog={openModal}
                    messageId="expertiseTask.editDeadline"
                    permCode={permissions.expertiseTask.EditDeadlineAndStartedDates}
                    entityId={id}
                />
            </Grid>
        </Grid>
    );
});
