import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CategoriesOfSubjectsListPage as CategoriesOfSubjectsListPageInj } from './categories-of-subjects-registry';
import { CategoryOfSubjectsPage as CategoryOfSubjectsPageInj } from './CategoryOfSubjectsPage';

export const CategoriesOfSubjectsPages = (): JSX.Element => {
    const [CategoriesOfSubjectsListPage] = di([CategoriesOfSubjectsListPageInj], CategoriesOfSubjectsPages);
    const [CategoryOfSubjectsPage] = di([CategoryOfSubjectsPageInj], CategoriesOfSubjectsPages);

    return (
        <Switch>
            <Route path={clientRoute.category}>
                <CategoryOfSubjectsPage />
            </Route>
            <Route>
                <CategoriesOfSubjectsListPage />
            </Route>
        </Switch>
    );
};
