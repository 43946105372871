import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PopoverOrigin } from '@mui/material';
import { ActionItemProps, SxStyle } from '@platform/front-types';
import { ActionMenuItem, MenuButton } from '@platform/front-ui';
import { TotLocale, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { useStore, useUserPageContext } from '../../../../../../hooks';

const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

const arrowIconSx: SxStyle = { height: '12px' };
const localeActionItemSx: SxStyle = { minWidth: '144px' };
export type UserPageLanguageButtonProps = ActionItemProps;

export const UserPageLanguageButton = observer((props: UserPageLanguageButtonProps): JSX.Element => {
    const { hideMenu } = props;
    const { intlStore } = useStore().coreRootStore;
    const { locales } = intlStore;
    const { userModel } = useUserPageContext();
    const { lang, setUserLang } = userModel;

    const onLangChangeCreator =
        (lang: TotLocale): VoidFunction =>
        () => {
            hideMenu && hideMenu();
            setUserLang(lang);
        };

    const renderMenuItems = useCallback((): ReactNode[] => {
        return locales.map((locale: TotLocale) => {
            const isActive = lang === locale;
            const onClick = { onClick: onLangChangeCreator(locale) };

            return (
                <ActionMenuItem
                    key={locale}
                    messageId={`locale.${locale}`}
                    {...(!isActive && onClick)}
                    selected={isActive}
                    sx={localeActionItemSx}
                />
            );
        });
    }, [locales, lang]);

    const renderButton = (
        onClick: (event: React.MouseEvent<HTMLElement>) => void,
        isMenuOpen?: boolean,
    ): JSX.Element => {
        const arrowIcon = <ArrowForwardIosIcon sx={arrowIconSx} />;
        return (
            <ActionMenuItem
                messageId="user.settings.language"
                selected={isMenuOpen}
                onClick={onClick}
                icon={arrowIcon}
                isWithIcon={true}
            />
        );
    };

    return <MenuButton renderButton={renderButton} renderMenuItems={renderMenuItems} anchorOrigin={anchorOrigin} />;
});
