import { UserNameModel } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { ExpertiseStore, ExpertiseTaskStore, RootStore } from '../../stores';
import { ExpertiseTasksRow, ExpertiseTasksRowDTO } from '../../types';
import { DateUtils } from '../../utils';

export const expertiseTasksListByExpertiseModelObservables = {
    expertiseStore: observable,
    expertiseTaskStore: observable,
    id: observable,
    expertiseTasks: observable,

    loadExpertiseTasksList: action.bound,
    deleteTask: action.bound,
    mapExpertiseTasksDTO: action.bound,
};

export class ExpertiseTasksListByExpertiseModel {
    expertiseStore: ExpertiseStore;
    expertiseTaskStore: ExpertiseTaskStore;

    id: string;
    expertiseTasks: ExpertiseTasksRow[] = [];

    constructor(id: string, rootStore: RootStore) {
        makeObservable(this, expertiseTasksListByExpertiseModelObservables);
        this.id = id;
        this.expertiseStore = rootStore.expertiseStore;
        this.expertiseTaskStore = rootStore.expertiseTaskStore;
        this.loadExpertiseTasksList();
    }

    loadExpertiseTasksList(): void {
        this.expertiseStore
            .loadExpertiseTasksListByExpertise(this.id)
            .then((tasks) => (this.expertiseTasks = tasks.map(this.mapExpertiseTasksDTO)));
    }

    deleteTask(id: string): () => Promise<void> {
        return async (): Promise<void> => {
            await this.expertiseTaskStore.deleteExpertiseTask(id);
            await this.loadExpertiseTasksList();
        };
    }

    mapExpertiseTasksDTO(dto: ExpertiseTasksRowDTO): ExpertiseTasksRow {
        //todo: это зачем? от бэка прилетают явные Date
        const acceptance = new Date(dto.acceptance || '');
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');
        const planAcceptance = new Date(dto.planAcceptance || '');
        const planDeadline = new Date(dto.planDeadline || '');
        const expertName = dto.expert ? new UserNameModel().load(dto.expert).name : '';
        const expertCandidateName = dto.expertCandidate ? new UserNameModel().load(dto.expertCandidate).name : '';

        const dateDTO = {
            ...(DateUtils.isValidDate(acceptance) && { acceptance }),
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            planEntry: dto.planEntry,
            viewPoint: dto.viewPoint,
            planAcceptanceDays: dto.planAcceptanceDays,
            planAcceptance: planAcceptance,
            planDeadlineDays: dto.planDeadlineDays,
            planDeadline: planDeadline,
            state: dto.state,
            taskType: dto.taskType,
            expertName,
            expertCandidateName,
            ...dateDTO,
        };
    }
}
