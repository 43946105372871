import { Button, Grid } from '@mui/material';
import { PromiseVoidFunction, useAntiDoubleClick } from '@platform/front-utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type ExpertiseTaskControlPanelRightProps = {
    goBack: () => void;
    onSubmit: PromiseVoidFunction;
};

export const ExpertiseTaskControlPanelRight = (props: ExpertiseTaskControlPanelRightProps): JSX.Element => {
    const { goBack, onSubmit } = props;
    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Grid spacing={3} alignItems="center" justifyContent="center" container wrap="nowrap">
            <Grid item>
                <Button variant="text" color="secondary" onClick={goBack}>
                    <FormattedMessage id="common.cancel" />
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={onSubmitHandler} endIcon={endIcon} disabled={isLoading}>
                    <FormattedMessage id="common.save" />
                </Button>
            </Grid>
        </Grid>
    );
};
