import { FullSubmission, FullSubmissionWithAdditionalInfo, TransitionDTO } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore, IdTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { EntityCreatedDTO, PlanEntryDTO, TemplateDetailsDTO, TemplatesOfExpertiseDTO } from '../types';
import { ExpertisePlanStore, expertisePlanStoreAbstractObservables } from './ExpertisePlanStore';
import { RootStore } from './RootStore';

export const templatesOfExpertiseObservables = {
    ...expertisePlanStoreAbstractObservables,

    asyncCheckStore: observable,

    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    loadTemplateDTO: action.bound,
    loadTemplatesSelectList: action.bound,
    loadTemplateOfExpertiseDTO: action.bound,
    saveChangeTemplateOfExpertise: action.bound,
    create: action.bound,
    delete: action.bound,
    loadPlanEntryList: action.bound,
    createPlanEntry: action.bound,
    updatePlanEntry: action.bound,
    loadPlanEntry: action.bound,
    deletePlanEntry: action.bound,
};

export class TemplatesOfExpertiseStore extends ExpertisePlanStore {
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, templatesOfExpertiseObservables);
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    getTransitions(templateId: string): Promise<TransitionDTO[]> {
        return this.api
            .client(this.apiConfigs.getTemplateOfExpertiseLifeCycleTransitions(templateId))
            .then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, templateId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.templateOfExpertiseLifeCycleTransition(transitionId, templateId))
            .then((r) => r.data);
    }

    loadTemplateDTO(id: string): Promise<TemplateDetailsDTO> {
        return this.api.client(this.apiConfigs.templateDTO(id)).then((r) => r.data);
    }

    loadTemplatesSelectList(): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.templatesSelectList).then((r) => r.data);
    }

    loadTemplateOfExpertiseDTO(id: string): Promise<TemplatesOfExpertiseDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadTemplateDTO(id)).then((r) => r.data);
    }

    saveChangeTemplateOfExpertise(id: string, submission: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.saveTemplate(id, submission)).then((r) => r.data);
    }

    create(): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createTemplate).then((r) => r.data.id);
    }

    delete(id: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteTemplate(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    loadPlanEntryList(templateId: string): Promise<PlanEntryDTO[]> {
        return this.api.client(this.apiConfigs.loadTemplatePlanEntries(templateId)).then((r) => r.data);
    }

    createPlanEntry(templateId: string): Promise<EntityCreatedDTO> {
        return this.api.userActionClient(this.apiConfigs.createTemplatePlanEntry(templateId)).then((r) => r.data);
    }

    updatePlanEntry(
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.updateTemplatePlanEntry(id, submission, taskFormCode, taskSubmission))
            .then((r) => r.data);
    }

    loadPlanEntry(id: string): Promise<PlanEntryDTO> {
        return this.api.client(this.apiConfigs.loadTemplatePlanEntry(id)).then((r) => r.data);
    }

    deletePlanEntry(id: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteTemplatePlanEntry(id)).then((r) => r.data);
    }
}

export const getTemplatesOfExpertiseStore = (): any => {
    const [_TemplatesOfExpertiseStore] = di([TemplatesOfExpertiseStore], getTemplatesOfExpertiseStore);
    return _TemplatesOfExpertiseStore;
};
