import enLogo from '../../resources/images/misis-logo_en.png';
import ruLogo from '../../resources/images/misis-logo_ru.svg';
import { ExpertiseFeatures } from '../../types';

const headerLogoStyles = {
    height: '60px',
};

const config: ExpertiseFeatures = {
    headerLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: 'National University of Science and Technology MISIS',
                image: enLogo,
                style: headerLogoStyles,
            },
            ru: {
                alt: 'НИТУ "МИСиС"',
                image: ruLogo,
                style: headerLogoStyles,
            },
        },
    },
    globalSearch: true,
    isWithoutStartPageLogo: true,
    modularNavigation: true,
    phoneNumber: false,
    userAdding: true,
    userAssignPassword: true,
    withSubjectFilesTab: false,
};

export default config;
