import { Dialog, DialogContent, FormLabel } from '@mui/material';
import { createDropzoneFileInstance, DropzoneAreaField, inputWithPlaceholderProps } from '@platform/front-core';
import { ActionItemProps } from '@platform/front-types';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    FieldLabel,
    FieldWithServerError,
    SxStyle,
} from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { PfTemplateModel } from '../../../models';
import { PfTemplateTableRow, TemplateFormValues } from '../../../types';

type RouteParams = { id?: string };

const formLabelSx: SxStyle = {
    marginBottom: '8px',
    marginTop: '16px',
};

export type PfTemplateCreateDialogProps = ActionItemProps & {
    pageTitleId: string;
    onCancel: VoidFunction;
    pfTemplateTableRow?: PfTemplateTableRow;
};

export const PfTemplateCreateDialog = observer((props: PfTemplateCreateDialogProps) => {
    const { pageTitleId, pfTemplateTableRow, onCancel, hideMenu } = props;

    const intl = useIntl();
    const { formatMessage } = intl;

    const history = useHistory();
    const { id: templateId } = useParams<RouteParams>();

    const { pfTemplateStore, coreRootStore } = useStore();
    const { notificationStore } = coreRootStore;
    const { reloadRegistryData, downloadServerFile, create, update } = pfTemplateStore;

    const pfTemplateModel = useMemo(
        () => new PfTemplateModel(pfTemplateStore, templateId),
        [pfTemplateStore, templateId],
    );
    const { fileDTO, acceptedFiles } = pfTemplateModel;

    const onClose = (): void => {
        history.push(generatePath(clientRoute.pfTemplateList));
        hideMenu && hideMenu();
        onCancel();
    };

    const onSubmit = (values: TemplateFormValues): Promise<void> => {
        const submitFunction = templateId ? update(values, templateId, true) : create(values, true);

        return submitFunction
            .then(reloadRegistryData)
            .catch((error) => notificationStore.onError(error))
            .finally(onClose);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const initialValues: TemplateFormValues = {
        title: pfTemplateTableRow?.customData.title || '',
        file: fileDTO ? [createDropzoneFileInstance(fileDTO)] : [],
    };

    const initialFiles = toJS(initialValues['file']);

    const { Yup } = useYup();

    const schema = Yup.object().shape({
        title: Yup.string().nullable().required(),
        file: Yup.array().min(1),
    });

    const titlePlaceholder = formatMessage({ id: 'pfTemplate.fields.title' });
    const titleLabel = formatMessage({ id: 'pfTemplate.fields.title' });
    const fileRequiredLabel = formatMessage({ id: 'pfTemplate.fields.file' });

    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogTitle onCloseClick={onClose}>
                <FormattedMessage id={pageTitleId} />
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                disableClearable={true}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <DialogContent>
                        <FieldWithServerError
                            name="title"
                            required
                            placeholder={titlePlaceholder}
                            label={titleLabel}
                            {...inputWithPlaceholderProps}
                        />
                        <FormLabel sx={formLabelSx}>
                            <FieldLabel required text={fileRequiredLabel} />
                        </FormLabel>
                        <DropzoneAreaField
                            fieldName="file"
                            acceptedFiles={acceptedFiles}
                            initialFiles={initialFiles}
                            downloadServerFile={downloadServerFile}
                        />
                    </DialogContent>

                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
