import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from '../../../components';
import { getOrElse } from '../../../utils';

const StyledBox = styled(Box)`
    min-width: 75px;
    box-sizing: border-box;
    text-align: center;
`;

export type ExpertisePlanEntryProps = {
    index: number;
    taskTypeTitle: string;
    taskMin: number;
    taskMax: number;
    acceptanceDays?: number;
    acceptance?: Date;
    deadlineDays?: number;
    deadline?: Date;
};

export const ExpertisePlanEntry = (props: ExpertisePlanEntryProps) => {
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], ExpertisePlanEntry);
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], ExpertisePlanEntry);

    const { index, taskTypeTitle, taskMin, taskMax, acceptanceDays, acceptance, deadlineDays, deadline } = props;
    const numberOfConclusionsText = getOrElse(
        taskMin !== undefined && taskMax !== undefined && taskMin !== null && taskMax !== null,
        <FormattedMessage id="expertise.plan.fields.numberOfConclusionsText" values={{ min: taskMin, max: taskMax }} />,
        '-',
    );
    const acceptanceDaysValue =
        (acceptanceDays && (
            <FormattedMessage id="expertise.plan.fields.responseTimeForAJobText" values={{ count: acceptanceDays }} />
        )) ||
        (acceptance && <FormattedDate value={acceptance} />) ||
        '-';
    const deadlineValue =
        (deadlineDays && (
            <FormattedMessage id="expertise.plan.fields.deadlineText" values={{ count: deadlineDays }} />
        )) ||
        (deadline && <FormattedDate value={deadline} />) ||
        '-';

    return (
        <React.Fragment>
            <Box pt={3}>
                <Grid container alignItems="baseline">
                    <Grid item>
                        <StyledBox pt={2.5} pb={2}>
                            <Typography variant="body2" className="t-new-expertise-title">
                                <Box component="span" fontWeight="fontWeightBold">
                                    {index}
                                </Box>
                            </Typography>
                        </StyledBox>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography variant="body2" className="t-new-expertise-title">
                                <Box component="span" fontWeight="fontWeightBold">
                                    {taskTypeTitle || '-'}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={1} pl={8}>
                <Grid container alignItems="baseline">
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.numberOfConclusions" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{numberOfConclusionsText}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.responseTimeForAJob" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{acceptanceDaysValue}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.deadline" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{deadlineValue}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};
