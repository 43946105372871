import { ApiStore } from '@platform/front-core';
import { FullSubmissionWithAdditionalInfo } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { CategoryOfSubjectsDTO } from '../types';
import { RootStore } from './RootStore';

export const categoriesOfSubjectsStoreObservables = {
    api: observable,
    apiConfigs: observable,
    rootStore: observable,
    asyncCheckStore: observable,

    saveChangeCategoryOfSubjects: action.bound,
    loadCategoryOfSubjectsDTO: action.bound,
    createCategoryOfSubjects: action.bound,
    deleteCategoryOfSubjects: action.bound,
};

export class CategoriesOfSubjectsStore {
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected rootStore: RootStore;
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, categoriesOfSubjectsStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    saveChangeCategoryOfSubjects(id: string, submission: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.saveChangeCategoryOfSubjects(id, submission))
            .then((r) => r.data);
    }

    loadCategoryOfSubjectsDTO(id: string): Promise<CategoryOfSubjectsDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadCategoryOfSubjectsDTO(id)).then((r) => r.data);
    }

    createCategoryOfSubjects(): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createCategoryOfSubjects).then((r) => r.data.id);
    }

    deleteCategoryOfSubjects(id: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteCategoryOfSubjects(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }
}

export const getCategoriesOfSubjectsStore = (): any => {
    const [_CategoriesOfSubjectsStore] = di([CategoriesOfSubjectsStore], getCategoriesOfSubjectsStore);
    return _CategoriesOfSubjectsStore;
};
