import { IdTitle, voidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { IdTitleNumber } from '../types';

export type ExpertiseCreateDialogContextType = {
    onClose: VoidFunction;
    subject?: IdTitle;
    byCampaign?: boolean;
    campaign?: IdTitleNumber;
    open: boolean;
};

export const ExpertiseCreateDialogContext = createContext<ExpertiseCreateDialogContextType>({
    onClose: voidFunction,
    subject: {} as IdTitle,
    byCampaign: false,
    campaign: {} as IdTitleNumber,
    open: false,
});
