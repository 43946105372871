import { LocalesTree } from '@platform/front-utils';

const expertiseTask: LocalesTree = {
    actions: {
        delete: 'Удалить задание',
        edit: 'Редактировать задание',
        returnForRevision: 'Вернуть на доработку',
    },
    changeOfDueDate: 'Изменение срока выполнения',
    changeStartDate: 'Изменение даты начала',
    confirmDeletionInfoText: 'Вы действительно хотите удалить задание на экспертизу № {number}?',
    create: 'Создать задание',
    curator: {
        email: 'Email',
        name: 'ФИО',
        phone: 'Телефон',
    },
    descriptionPanel: {
        commonTabLabel: 'Общее',
        serviceTabLabel: 'Служебное',
        title: 'Описание задания',
    },
    editDeadline: 'Изменить срок выполнения',
    editStarted: 'Изменить дату начала',
    errorText: 'Для продолжения нужно выбрать эксперта из списка',
    expertCandidate: '{expert} (подобран)',
    expertiseTasksTable: {
        datePlan: ' (план)',
        daysPlan: 'д (план)',
        fields: {
            actualDateOfTheExecution: 'Фактическая дата выполнения',
            deadline: 'Срок выполнения',
            expert: 'Эксперт',
            number: 'Номер задания',
            planEntry: 'Запись плана',
            responseTime: 'Срок ответа',
            stateTitle: 'Состояние',
        },
    },
    fields: {
        campaign: 'Кампания',
        created: 'Дата создания',
        curator: 'Куратор',
        deadline: 'Срок выполнения',
        expert: 'Эксперт',
        expertise: 'Экспертиза',
        expertiseNumber: 'Номер экспертизы',
        expertisePlanEntry: 'Запись плана экспертизы',
        lifecycleStages: 'Этапы ЖЦ',
        number: 'Номер',
        started: 'Дата начала',
        state: 'Состояние',
        taskType: 'Тип задания',
        viewPoint: 'Точка зрения экспертизы',
        withoutExpert: 'Задания без эксперта',
    },
    headerActions: {
        collapseDescriptionTooltip: 'Свернуть описание',
        collapseHeader: 'Свернуть шапку',
        expandDescriptionTooltip: 'Развернуть описание',
        expandHeader: 'Развернуть шапку',
        toggleDescription: 'Описание',
    },
    invites: {
        accept: 'Принять задание',
        agree: 'Согласен с условиями проведения экспертизы',
        agreement:
            'При проведении экспертизы эксперт должен руководствоваться следующими принципами:\n' +
            '• компетентность (соглашаясь на проведение экспертизы эксперт подтверждает, что обладает необходимыми знаниями и опытом для проведения качественной экспертизы в предметной области);\n' +
            '• отсутствие конфликтов интересов и объективность (если эксперт обнаружил, что является аффилированным с исполнителями работ по рецензируемому проекту, то должен незамедлительно уведомить об этом куратора экспертизы);\n' +
            '• конфиденциальность (эксперт должен обеспечивать конфиденциальность любой информации, содержащейся в документах представленных к оценке. Эксперт не вправе разглашать любую информацию, касающуюся оценки заявок третьим лицам);\n' +
            '• независимость (эксперт не может обсуждать процесс проведения экспертизы с любыми третьими лицами (включая исполнителей проектов), кроме кураторов экспертизы).\n' +
            '\n' +
            'Конфликт интересов, в частности, возникает в случаях, если эксперт:\n' +
            '• принимал участие в подготовке заявки на участие в конкурсе, представленной для проведения экспертизы;\n' +
            '• состоял в трудовых отношениях, привлекался к выполнению работ, оказанию услуг участником конкурса, заявка на участие в конкурсе которого представлена для проведения экспертизы;\n' +
            '• может получить непосредственную выгоду в случае, если заявка на участие в конкурсе будет одобрена к финансированию;\n' +
            '• является членом семьи, близким родственником лица, состоящего в трудовых отношениях с участником конкурса, заявка на участие в конкурсе которого представлена для проведения экспертизы;\n' +
            '• находится под влиянием иных обстоятельств, препятствующих объективной оценке заявки на участие в конкурсе, представленной для проведения экспертизы.',
        otherReason: 'Другое',
        reject: 'Отклонить задание',
        rejectReason: 'Причина отклонения',
    },
    listConfirmDeletionInfoText: 'Вы действительно хотите удалить задание на экспертизу?',
    listTitle: 'Задания на экспертизу',
    localConclusions: {
        fields: {
            completed: 'Факт. дата выполнения',
            deadline: 'Срок выполнения',
            report: 'Заключение',
        },
        title: 'Частные заключения',
    },
    new: 'Новое задание',
    printFormsTable: {
        conclusion: 'Экспертное заключение',
        createAction: 'Сгенерировать',
        generationError: 'При генерации печатной формы произошла ошибка',
        noPrintForms: 'Печатные формы отсутствуют',
        title: 'Печатные формы документов',
        updateAction: 'Обновить',
    },
    registryPagingInfo:
        'Всего {count, number} задани{count, plural, one {е} few {я} other {й}}. ' +
        'Отображены c {from, number} по {to, number}',
    reportSaveReminder: 'Не забудьте сохранить прогресс вашей работы.',
    requiredFieldsAreNotFilled: 'Пожалуйста, заполните все обязательные поля',
    saveWarning: 'Не забудьте нажать кнопку “Сохранить”!',
    selectExpertButton: 'Назначить эксперта',
    selectionOfExpert: {
        select: 'Подобрать',
        title: 'Подбор экспертов',
    },
    switchView: {
        all: 'Задание и анкета',
        report: 'Анкета',
        task: 'Задание',
    },
    tabs: {
        subject: 'Объект',
        task: 'Задание',
    },
    taskReportError: 'Анкета заполнена некорректно',
    title: 'Задание на экспертизу № {number}',
};

export default expertiseTask;
