import { PopoverOrigin } from '@mui/material';

export type PopoverPlacement = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export type PopoverPlacementConfig = { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin };

export const placements: Record<PopoverPlacement, PopoverPlacementConfig> = {
    'bottom-right': {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    },
    'bottom-left': {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
    },
    'top-right': {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
    },
    'top-left': {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
    },
};

export const getPopoverOriginConfig = (placementKey: PopoverPlacement): PopoverPlacementConfig =>
    placements[placementKey];
