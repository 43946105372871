import { UserStatus, UserStatusAction } from '@platform/front-types';
import { LocalesTree } from '@platform/front-utils';

const action: Record<UserStatusAction, string> = {
    activate: 'Активировать',
    block: 'Заблокировать',
    unblock: 'Разблокировать',
};

const status: Record<UserStatus, string> = {
    active: 'Активный',
    blocked: 'Заблокирован',
    inactive: 'Неактивный',
};

const user: LocalesTree = {
    action,
    blockReason: 'Причина блокировки',
    createPerson: 'Создать персону',
    editPasswordDialogTitle: 'Назначение пароля',
    fields: {
        login: 'Логин',
        name: 'ФИО',
        roles: 'Роли',
        status: 'Статус',
    },
    fullNameFields: {
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
    },
    info: 'Сведения о персоне',
    mainLanguage: 'Основной язык системы',
    notAllowed: 'Невозможно произвести данное действие над своей учетной записью',
    passwordAssignment: 'Назначить пароль',
    phrases: {
        addUser: 'Добавить {user}',
        confirmBlockWithReasonInfoText: 'Пользователь {name} ({login}) будет заблокирован',
        confirmStatusActivate: 'Подтвердите активацию {user}',
        confirmStatusBlock: 'Подтвердите блокировку {user}',
        confirmStatusTextActivate: 'Вы действительно хотите активировать {user} {name} "{login}"?',
        confirmStatusTextBlock: 'Вы действительно хотите заблокировать {user} {name} "{login}"?',
        confirmStatusTextUnblock: 'Вы действительно хотите разблокировать {user} {name} "{login}"?',
        confirmStatusUnblock: 'Подтвердите разблокировку {user}',
        deleteUser: 'Удаление {user}',
        deleteUserConfirmText: 'Вы действительно хотите удалить пользователя {name} ({login})?',
        editPasswordDialogInfo: 'Введите новый пароль для пользователя {name} ({login})',
        editUser: 'Редактировать {user}',
        emailNotExist: '{user} с таким email не найден',
        lang: 'Англоязычный {user}',
        loginAsUser: 'Авторизоваться под {user}',
        loginHelperText:
            'После смены логина учётную запись нужно будет активировать. На новый адрес электронной почты будет отправлено письмо со ссылкой активации.',
        profile: 'Профиль {user}',
        selectUser: 'Выберите {user}',
        selectUsers: 'Выберите {user}',
    },
    plural: {
        accusative: 'пользователей',
        nominative: 'Пользователи',
    },
    register: 'Зарегистрировать',
    registryPagingInfo:
        'Всего {count, number} пользовател{count, plural, one {ь} few {я} other {ей}}. ' +
        'Отображены c {from, number} по {to, number}',
    settings: {
        fullName: 'Изменить Ф.И.О.',
        language: 'Изменить основной язык системы',
        login: 'Изменить логин',
        roles: 'Настроить роли',
        rolesTitle: 'Настройка ролей',
    },
    single: {
        accusative: 'пользователя',
        instrumental: 'пользователем',
        nominative: 'пользователь',
    },
    status,
};

export default user;
