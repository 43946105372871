import { Button, Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { UserStatus } from '@platform/front-types';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore, useUserPageContext } from '../../../../../hooks';
import { actionCodeByUserStatus } from '../../../../../utils';
import { UserBlockDialog as UserBlockDialogInj } from '../../../user-list';

export type UserPageStatusButtonProps = {
    userStatusCode: UserStatus;
};

export const UserPageStatusButton = observer((props: UserPageStatusButtonProps): JSX.Element => {
    const [UserBlockDialog] = di([UserBlockDialogInj], UserPageStatusButton);
    const { userStatusCode } = props;

    const { userModel } = useUserPageContext();
    const { id, fullName, login, load } = userModel;
    const { accountConfig } = useStore().coreRootStore.permissionsStore;
    const changeUserStatusCheckQuery = accountConfig.changeUserStatus(id);
    const [isDialogOpen, setIsDialogOpen, setIsDialogClosed] = useFlag();

    return (
        <React.Fragment>
            <Grid item>
                <AuthorizationCheck {...changeUserStatusCheckQuery}>
                    <Button onClick={setIsDialogOpen} size="medium" variant="contained" color="secondary">
                        <FormattedMessage id={`user.action.${actionCodeByUserStatus[userStatusCode]}`} />
                    </Button>
                </AuthorizationCheck>
            </Grid>
            <UserBlockDialog
                id={id}
                name={fullName}
                login={login}
                userStatusCode={userStatusCode}
                reloadData={load}
                open={isDialogOpen}
                onCancel={setIsDialogClosed}
            />
        </React.Fragment>
    );
});
