import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { ExpertiseListPage as ExpertiseListPageInj } from './expertise-list';
import { ExpertisePage as ExpertisePageInj } from './expertise-page';

export const ExpertisePages = observer((): JSX.Element => {
    const [ExpertiseListPage] = di([ExpertiseListPageInj], ExpertisePages);
    const [ExpertisePage] = di([ExpertisePageInj], ExpertisePages);

    return (
        <Switch>
            <Route path={clientRoute.expertise}>
                <ExpertisePage />
            </Route>
            <Route>
                <ExpertiseListPage />
            </Route>
        </Switch>
    );
});
