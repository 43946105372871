import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../../../../components';
import { campaignPanelAccordionStyles } from '../../../../../constants';
import { CampaignSubjects, CampaignSubjectsLinks } from '../../../../../types';
import { createLinkWithQueries } from '../../../../../utils';
import { CampaignPanelSubjectCount as CampaignPanelSubjectCountInj } from './components';

export type CampaignSubjectsPanelProps = {
    subjects: CampaignSubjects;
    links: CampaignSubjectsLinks;
};

export type CampaignPanelExpertiseSubject = {
    labelText: string;
    path: string;
    count: number;
};

const expandIcon = <ExpandMoreIcon />;

export const CampaignSubjectsPanel = observer((props: CampaignSubjectsPanelProps): JSX.Element => {
    const [CampaignPanelSubjectCount] = di([CampaignPanelSubjectCountInj], CampaignSubjectsPanel);
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], CampaignSubjectsPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], CampaignSubjectsPanel);

    const { subjects, links } = props;
    const { formatMessage } = useIntl();

    const { active, withExpertise, withoutExpertise, total } = links;

    const expertiseSubjects: CampaignPanelExpertiseSubject[] = [
        {
            labelText: formatMessage({ id: 'campaign.subjects.total' }),
            path: createLinkWithQueries(total.urn, total.queries),
            count: subjects.total,
        },
        {
            labelText: formatMessage({ id: 'campaign.subjects.active' }),
            path: createLinkWithQueries(active.urn, active.queries),
            count: subjects.active,
        },
        {
            labelText: formatMessage({ id: 'campaign.subjects.withExpertise' }),
            path: createLinkWithQueries(withExpertise.urn, withExpertise.queries),
            count: subjects.withExpertise,
        },
        {
            labelText: formatMessage({ id: 'campaign.subjects.withoutExpertise' }),
            path: createLinkWithQueries(withoutExpertise.urn, withoutExpertise.queries),
            count: subjects.withoutExpertise,
        },
    ];

    const expertiseSubjectCounts: JSX.Element[] = expertiseSubjects.map((subject, index) => {
        return <CampaignPanelSubjectCount {...subject} key={subject.labelText + index} />;
    });

    return (
        <Box sx={campaignPanelAccordionStyles.accordionContainer}>
            <Accordion defaultExpanded sx={campaignPanelAccordionStyles.accordion}>
                <TotObjectPanelSummary expandIcon={expandIcon}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="campaign.subjects.title" />
                    </Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid container pb={2}>
                        {expertiseSubjectCounts}
                    </Grid>
                </TotObjectPanelDetails>
            </Accordion>
        </Box>
    );
});
