import { Grid } from '@mui/material';
import { Typography } from '@platform/front-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useExpertisePageContext } from '../../../../hooks';
import { getOrElse } from '../../../../utils';

export const ExpertisePageHeaderTitle = (): JSX.Element => {
    const { model } = useExpertisePageContext();
    const { subject } = model;

    const expertiseTitle = getOrElse(
        subject?.title,
        <FormattedMessage id="expertise.expertiseTitle" values={{ title: subject?.title }} />,
    );

    return (
        <Grid item>
            <Typography variant="subtitle2">{expertiseTitle}</Typography>
        </Grid>
    );
};
