import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate'; // setimmediate нужно для работы с react-scripts@5.0.0
import { apiConfigs } from './apiConfigs';
import { env, features, history, locales, palette } from './initRootProps';
import { Root } from './Root';

ReactDOM.render(
    <React.StrictMode>
        <Root
            env={env}
            features={features}
            locales={locales}
            history={history}
            palette={palette}
            apiConfigs={apiConfigs}
        />
    </React.StrictMode>,
    document.getElementById('root'),
);
