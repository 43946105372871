import { AuthorizationCheck } from '@platform/front-core';
import { TTable, TTableRowActions, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { RegistryType, TemplatesOfExpertiseTableRow } from '../../../../types';

export type TemplatesOfExpertiseRegistryTableProps = {
    setRowActions?: TTableRowActions<TemplatesOfExpertiseTableRow>;
};

const registryCode = RegistryType.templateExpertise;

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
        isWithPresets: false,
    },
};

export const TemplatesOfExpertiseRegistryTable = observer(
    (props: TemplatesOfExpertiseRegistryTableProps): JSX.Element => {
        const { setRowActions } = props;

        const { coreRootStore, userStore } = useStore();
        const { catalogStore, api, permissionsStore } = coreRootStore;

        const { formatMessage } = useIntl();
        const tableTitle = formatMessage({ id: 'templatesOfExpertise.listTitle' });

        const { systemConfig } = permissionsStore as PermissionsStore;
        const administrationQuery = systemConfig.administration();

        return (
            <AuthorizationCheck {...administrationQuery}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<TemplatesOfExpertiseTableRow>
                        tableTitle={tableTitle}
                        client={api.mainInfoClient as AxiosInstance}
                        registryCode={registryCode}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        defaultPath={ttableDefaultPaths.templatesOfExpertise}
                        fetchUserRoleList={userStore.userRoleList}
                        isAdmin={allowed}
                        rowActions={setRowActions}
                        visibleSettings={visibleSettings}
                    />
                )}
            </AuthorizationCheck>
        );
    },
);
