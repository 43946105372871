import { RouteParamsDefault } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { ExpertiseTaskViewPageContext, ExpertiseTaskViewPageContextType } from '../../../../contexts';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskViewModel } from '../../../../models';

const queryString = require('query-string');

export const ExpertiseTaskViewPageContextProvider = observer((props: PropsWithChildren<{}>): JSX.Element => {
    const { id } = useParams<RouteParamsDefault>();
    const { locale } = useIntl();
    const rootStore = useStore();
    const { expertiseTaskStore } = rootStore;

    const expertiseTaskViewModel = useMemo(() => new ExpertiseTaskViewModel(id, rootStore), [id]);
    const { reloadTaskModel, load } = expertiseTaskViewModel;

    const location = useLocation();
    const queries = queryString.parse(location.search);
    const backUrl = queries.backUrl || generatePath(clientRoute.expertiseTasks);

    useEffect(() => {
        reloadTaskModel();
    }, [locale, id]);

    useEffect(() => {
        expertiseTaskStore.loadTaskView(id).then(load);
    }, [expertiseTaskStore, id, locale, backUrl]);

    const expertiseTaskViewPageContextValue: ExpertiseTaskViewPageContextType = {
        model: expertiseTaskViewModel,
        backUrl,
    };

    return (
        <ExpertiseTaskViewPageContext.Provider value={expertiseTaskViewPageContextValue}>
            {props.children}
        </ExpertiseTaskViewPageContext.Provider>
    );
});
