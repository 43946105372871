import { AuthorizationCheckQueryOrWithoutCheck, useFetchAllPermissions } from '@platform/front-core';
import { ActionMenuItem, ConfirmationDialog, KebabButton, MenuButton } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    useExpertiseTaskSettingsConfirmDialog as useExpertiseTaskSettingsConfirmDialogInj,
    useStore,
} from '../../hooks';
import { PermissionsStore } from '../../stores';
import { ExpertiseTaskRegistryRow } from '../../types';
import { getPopoverOriginConfig } from '../../utils';
import { ExpertiseTaskConfirmationButtons } from './ExpertiseTaskConfirmationButtons';

export type ExpertiseTaskRegistryActionsBtnsProps = {
    expertiseTaskRow: ExpertiseTaskRegistryRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    isRowActionsFirst?: boolean;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const ExpertiseTaskRegistryActionsBtns = observer(
    (props: ExpertiseTaskRegistryActionsBtnsProps): JSX.Element => {
        const [useExpertiseTaskSettingsConfirmDialog] = di(
            [useExpertiseTaskSettingsConfirmDialogInj],
            ExpertiseTaskRegistryActionsBtns,
        );

        const {
            expertiseTaskRow,
            reloadData,
            isConfirm,
            setIsConfirm,
            isRowActionsFirst,
            toggleIsRowBlocked,
            rowErrorHandler,
        } = props;
        const { id } = expertiseTaskRow;
        const { formatMessage } = useIntl();

        const { expertiseTaskStore, coreRootStore } = useStore();
        const { permissionsStore } = coreRootStore;
        const { expertiseTaskConfig } = permissionsStore as PermissionsStore;

        const [isModalOpen, openModal, closeModal] = useFlag();

        const allPermissionsQueries = useMemo<AuthorizationCheckQueryOrWithoutCheck[]>(() => {
            return [expertiseTaskConfig.deleteExpertiseTask(id)];
        }, [id]);

        const [permissionsData, reloadPermission] = useFetchAllPermissions({ allPermissionsQueries });
        const [deleteExpertiseTaskRequest] = permissionsData;

        useEffect(() => {
            if (setIsConfirm && isConfirm) {
                setIsConfirm(false);
                toggleIsRowBlocked();
                reloadData();
                reloadPermission();
            }
        }, [isConfirm]);

        const onDeleteConfirm = (): Promise<void> => {
            return expertiseTaskStore
                .deleteExpertiseTask(id, true)
                .catch(rowErrorHandler)
                .finally(() => {
                    toggleIsRowBlocked();
                    reloadData();
                    closeModal();
                });
        };

        const [settings, setSettings] = useExpertiseTaskSettingsConfirmDialog({
            delete: onDeleteConfirm,
        });

        const renderActionItems = useCallback(
            (hideMenu: () => void): ReactNode[] => {
                const onDeleteClick = (): void => {
                    setSettings(ExpertiseTaskConfirmationButtons.delete);
                    hideMenu && hideMenu();
                    openModal();
                };

                return [
                    <React.Fragment key="actionBtns">
                        {deleteExpertiseTaskRequest && (
                            <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />
                        )}
                    </React.Fragment>,
                ];
            },
            [permissionsData, expertiseTaskRow],
        );

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            const atLeastOneAllowed = permissionsData.some((isPermissionAllowed) => isPermissionAllowed);
            return atLeastOneAllowed ? <KebabButton onClick={onClick} /> : <React.Fragment />;
        };

        const { anchorOrigin, transformOrigin } = isRowActionsFirst
            ? getPopoverOriginConfig('bottom-right')
            : getPopoverOriginConfig('bottom-left');

        return (
            <React.Fragment>
                <MenuButton
                    renderButton={renderActionsButton}
                    renderMenuItems={renderActionItems}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                />
                <ConfirmationDialog
                    id={settings.id}
                    open={isModalOpen}
                    title={formatMessage({ id: settings.title })}
                    message={formatMessage(
                        { id: settings.message },
                        { number: expertiseTaskRow.customData.number.title },
                    )}
                    onConfirm={settings.onConfirm}
                    onCancel={closeModal}
                    keepMounted
                />
            </React.Fragment>
        );
    },
);
