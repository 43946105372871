import { Divider, Grid, Link as MUILink } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { PermissionsStore } from '../../../../../../stores';
import { expertiseTaskSidebarStyles } from '../expertiseTaskSidebarStyles';
import { ExpertiseTaskViewCuratorField as ExpertiseTaskViewCuratorFieldInj } from './expertise-task-view-curator-field';
import { ExpertiseTaskViewDeadlineField as ExpertiseTaskViewDeadlineFieldInj } from './expertise-task-view-deadline-field';
import { ExpertiseTaskViewStartDateField as ExpertiseTaskViewStartDateFieldInj } from './expertise-task-view-start-date-field';

export const ExpertiseTaskSidebarCommonTabValue = observer((): JSX.Element => {
    const [ExpertiseTaskViewCuratorField] = di([ExpertiseTaskViewCuratorFieldInj], ExpertiseTaskSidebarCommonTabValue);
    const [ExpertiseTaskViewDeadlineField] = di(
        [ExpertiseTaskViewDeadlineFieldInj],
        ExpertiseTaskSidebarCommonTabValue,
    );
    const [ExpertiseTaskViewStartDateField] = di(
        [ExpertiseTaskViewStartDateFieldInj],
        ExpertiseTaskSidebarCommonTabValue,
    );

    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel } = model;
    const { expertise, viewPoint, expert, expertCandidate, expertId, expertCandidateId, identifier, isWithExperts } =
        taskModel;
    const { formatMessage } = useIntl();
    const { id } = useParams<RouteParamsDefault>();

    const expertValue = expertId ? expert.name : expertCandidateId ? expertCandidate.name : '-';
    const expertiseValue = expertise?.title || '-';
    const viewPointValue = viewPoint?.title;

    const taskNumberTitle = formatMessage({ id: 'expertiseTask.expertiseTasksTable.fields.number' });
    const deadlineTitle = formatMessage({ id: 'expertiseTask.fields.deadline' });
    const startedTitle = formatMessage({ id: 'expertiseTask.fields.started' });
    const expertTitle = formatMessage({ id: 'expertiseTask.fields.expert' });
    const expertiseTitle = formatMessage({ id: 'expertiseTask.fields.expertise' });
    const viewPointTitle = formatMessage({ id: 'expertiseTask.fields.viewPoint' });
    const syslogLabel = formatMessage({ id: 'syslog.single.nominative' });

    const syslogPath = generatePath(clientRoute.expertiseTaskSyslog, {
        id,
    });

    const expertPath = isWithExperts
        ? generatePath(clientRoute.user, {
              id: expertId || expertCandidateId,
          })
        : '';
    const expertisePath =
        expertise.id &&
        generatePath(clientRoute.expertise, {
            id: expertise.id,
        });

    const linkOrString = (path: string, value: string): string | JSX.Element => {
        return path ? (
            <MUILink component={Link} to={path}>
                {value}
            </MUILink>
        ) : (
            value
        );
    };

    const { permissionsStore } = useStore().coreRootStore;
    const { accountConfig, expertiseTaskConfig, systemConfig } = permissionsStore as PermissionsStore;
    const expertiseTaskViewQuery = expertiseTaskConfig.viewExpertiseTask(id);
    const accountViewQuery = accountConfig.viewProfile(expertId || expertCandidateId);
    const administrationQuery = systemConfig.administration();

    return (
        <Grid container sx={expertiseTaskSidebarStyles.container}>
            <CommonInfoLine title={taskNumberTitle} value={'№ ' + identifier} isColumnDirection />
            <AuthorizationCheck {...expertiseTaskViewQuery}>
                {(allowed: boolean) =>
                    allowed ? (
                        <React.Fragment>
                            <ExpertiseTaskViewDeadlineField />
                            <ExpertiseTaskViewStartDateField />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <CommonInfoLine title={deadlineTitle} isColumnDirection />
                            <CommonInfoLine title={startedTitle} isColumnDirection />
                        </React.Fragment>
                    )
                }
            </AuthorizationCheck>
            {isWithExperts ? (
                <AuthorizationCheck {...accountViewQuery}>
                    {(allowed: boolean) =>
                        allowed ? (
                            <CommonInfoLine
                                title={expertTitle}
                                value={linkOrString(expertPath, expertValue)}
                                isColumnDirection
                            />
                        ) : (
                            <CommonInfoLine title={expertTitle} isColumnDirection />
                        )
                    }
                </AuthorizationCheck>
            ) : (
                <CommonInfoLine title={expertTitle} isColumnDirection />
            )}
            <ExpertiseTaskViewCuratorField />
            <CommonInfoLine
                title={expertiseTitle}
                value={linkOrString(expertisePath, expertiseValue)}
                isColumnDirection
            />
            <CommonInfoLine title={viewPointTitle} value={viewPointValue} isColumnDirection />
            <AuthorizationCheck {...administrationQuery}>
                <Divider light />
                <MUILink component={Link} to={syslogPath}>
                    {syslogLabel}
                </MUILink>
            </AuthorizationCheck>
        </Grid>
    );
});
