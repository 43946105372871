import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getExpertiseBreadcrumbs = (
    intl: IntlShape,
    expertiseId: string,
    expertiseIdentifier: string,
): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.expertiseList,
            routeTitle: formatMessage({ id: 'expertise.expertiseListTitle' }),
        },
        {
            to: expertiseId ? generatePath(clientRoute.expertise, { id: expertiseId }) : '',
            routeTitle: expertiseIdentifier,
        },
        {
            to: clientRoute.expertiseSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
