import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { TemplatesOfExpertiseListPage as TemplatesOfExpertiseListPageInj } from './templates-of-expertise-list';
import { TemplateOfExpertisePage as TemplateOfExpertisePageInj } from './templates-of-expertise-page';

export const TemplatesOfExpertisePages = (): JSX.Element => {
    const [TemplateOfExpertisePage] = di([TemplateOfExpertisePageInj], TemplatesOfExpertisePages);
    const [TemplatesOfExpertiseListPage] = di([TemplatesOfExpertiseListPageInj], TemplatesOfExpertisePages);

    return (
        <Switch>
            <Route path={clientRoute.templateOfExpertise}>
                <TemplateOfExpertisePage />
            </Route>
            <Route>
                <TemplatesOfExpertiseListPage />
            </Route>
        </Switch>
    );
};
