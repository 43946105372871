import { Theme } from '@mui/material';
import { FormioSidebarStore } from '@platform/formiojs-react';
import {
    CoreRootStore,
    Env,
    getApiStore,
    getAuthenticationStore,
    getBreadcrumbsStore,
    getCoreRootStore,
    getGlobalSearchStore,
    RootStoreProps,
    serviceConfigs as coreServiceConfigs,
    WebSocketApiConfigs,
} from '@platform/front-core';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { currentModule } from '../constants';
import { getBreadcrumbsNames, getBreadcrumbsRoutes } from './BreadcrumbsStore';
import { CampaignStore, getCampaignStore } from './CampaignStore';
import { CategoriesOfSubjectsStore, getCategoriesOfSubjectsStore } from './CategoriesOfSubjectsStore';
import { getCurrentUserStore } from './CurrentUserStore';
import { ExpertiseStore, getExpertiseStore } from './ExpertiseStore';
import { ExpertiseTaskInviteStore, getExpertiseTaskInviteStore } from './ExpertiseTaskInviteStore';
import { ExpertiseTaskStore, getExpertiseTaskStore } from './ExpertiseTaskStore';
import { FormioStore, getFormioStore } from './FormioStore';
import { getHeaderLinksStore } from './HeaderLinksStore';
import { getPermissionsStore } from './permissions-store';
import { getPfTemplateStore, PfTemplateStore } from './PfTemplateStore';
import { getSubjectStore, SubjectStore } from './SubjectStore';
import { getTemplatesOfExpertiseStore, TemplatesOfExpertiseStore } from './TemplatesOfExpertiseStore';
import { getUserStore, UserStore } from './UserStore';

export const rootStoreObservables = {
    coreRootStore: observable,

    formioStore: observable,
    campaignStore: observable,
    subjectStore: observable,
    categoriesOfSubjectsStore: observable,
    templatesOfExpertiseStore: observable,
    expertiseStore: observable,
    expertiseTaskStore: observable,
    pfTemplateStore: observable,
    formioSidebarStore: observable,
    userStore: observable,

    history: observable,
    features: observable,
    env: observable,
    theme: observable,

    setObservables: action.bound,
    setAppTheme: action.bound,
};

export class RootStore {
    coreRootStore: CoreRootStore;

    formioStore: FormioStore;
    formioSidebarStore: FormioSidebarStore;
    campaignStore: CampaignStore;
    subjectStore: SubjectStore;
    categoriesOfSubjectsStore: CategoriesOfSubjectsStore;
    templatesOfExpertiseStore: TemplatesOfExpertiseStore;
    expertiseStore: ExpertiseStore;
    expertiseTaskStore: ExpertiseTaskStore;
    expertiseTaskInviteStore: ExpertiseTaskInviteStore;
    pfTemplateStore: PfTemplateStore;
    userStore: UserStore;

    history: History = {} as History;
    features: RootStoreProps['features'] = {};
    env: Env = {} as Env;
    theme: Theme = {} as Theme;

    constructor(props: RootStoreProps) {
        makeObservable(this, rootStoreObservables);
        const { WebSocketApiConfigsClass = WebSocketApiConfigs } = props;

        const CoreRootStore = getCoreRootStore();
        const ApiStore = getApiStore();
        const AuthenticationStore = getAuthenticationStore();
        const CurrentUserStore = getCurrentUserStore();
        const HeaderLinksStore = getHeaderLinksStore();
        const BreadcrumbsStore = getBreadcrumbsStore();

        const FormioStore = getFormioStore();
        const CampaignStore = getCampaignStore();
        const SubjectStore = getSubjectStore();
        const CategoriesOfSubjectsStore = getCategoriesOfSubjectsStore();
        const TemplatesOfExpertiseStore = getTemplatesOfExpertiseStore();
        const ExpertiseStore = getExpertiseStore();
        const ExpertiseTaskStore = getExpertiseTaskStore();
        const ExpertiseTaskInviteStore = getExpertiseTaskInviteStore();
        const PfTemplateStore = getPfTemplateStore();
        const UserStore = getUserStore();
        const PermissionsStore = getPermissionsStore();
        const GlobalSearchStore = getGlobalSearchStore();

        this.setObservables(props);

        const { serviceConfigs = coreServiceConfigs } = props;

        this.coreRootStore = new CoreRootStore(props);
        this.coreRootStore.api = new ApiStore(this.coreRootStore, apiConfigs, serviceConfigs, WebSocketApiConfigsClass);
        this.coreRootStore.authenticationStore = new AuthenticationStore(this.coreRootStore);
        this.coreRootStore.currentUserStore = new CurrentUserStore(this.coreRootStore);
        this.coreRootStore.permissionsStore = new PermissionsStore();
        this.coreRootStore.headerLinksStore = new HeaderLinksStore(this.coreRootStore);
        this.coreRootStore.breadcrumbsStore = new BreadcrumbsStore(
            this.coreRootStore,
            getBreadcrumbsRoutes,
            getBreadcrumbsNames,
        );
        this.coreRootStore.globalSearchStore = new GlobalSearchStore(this.coreRootStore, currentModule);

        this.formioStore = new FormioStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.campaignStore = new CampaignStore(this);
        this.subjectStore = new SubjectStore(this);
        this.categoriesOfSubjectsStore = new CategoriesOfSubjectsStore(this);
        this.templatesOfExpertiseStore = new TemplatesOfExpertiseStore(this);
        this.expertiseStore = new ExpertiseStore(this);
        this.expertiseTaskStore = new ExpertiseTaskStore(this);
        this.expertiseTaskInviteStore = new ExpertiseTaskInviteStore(this);
        this.pfTemplateStore = new PfTemplateStore(this);
        this.userStore = new UserStore(this.coreRootStore);
    }

    setObservables(props: RootStoreProps): void {
        const { env, features, history } = props;
        this.env = env;
        this.features = features;
        this.history = history;
    }

    setAppTheme(theme: Theme): void {
        this.theme = theme;
        this.formioStore.addComponentsWithMaterialTheme();
        this.formioStore.addMultilevelCatalog();
        this.formioStore.addStaticProperties();
    }
}
