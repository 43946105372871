import { Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { TotObjectDeleteButton as TotObjectDeleteButtonInj } from '../../../../components';
import { useExpertisePageContext, useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';

export const ExpertiseControlPanelLeft = observer((): JSX.Element => {
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], ExpertiseControlPanelLeft);

    const { model } = useExpertisePageContext();
    const { id, identifier, metaInfo, deleteExpertise } = model;
    const { stateTitle } = metaInfo;
    const { formatMessage } = useIntl();
    const history = useHistory();

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseConfig } = permissionsStore as PermissionsStore;
    const deleteExpertiseQuery = expertiseConfig.delete(id);

    const handleDeleteExpertise = (): Promise<void> => {
        return deleteExpertise().then(() => {
            history.push(generatePath(clientRoute.expertiseList));
        });
    };

    return (
        <Grid item>
            <AuthorizationCheck key={stateTitle} {...deleteExpertiseQuery}>
                <TotObjectDeleteButton
                    id="delete-expertise"
                    title={formatMessage({ id: 'common.confirmDeletion' })}
                    message={formatMessage({ id: 'expertise.confirmDeletionInfoText' }, { title: identifier })}
                    onConfirm={handleDeleteExpertise}
                    tooltipTitleId="expertise.actions.deleteExpertise"
                />
            </AuthorizationCheck>
        </Grid>
    );
});
