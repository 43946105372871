import { Tab } from '@mui/material';
import {
    AuthorizationCheckQueryOrWithoutCheck,
    RoutedTabs,
    RouteSetting,
    useFetchAllPermissions,
} from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { ExpertiseTaskViewTab, ExpertiseTaskViewTabSettings } from '../../../../../types';

export const getExpertiseTaskBodyDescriptionTabsInj = (): ExpertiseTaskViewTabSettings[] => [
    {
        tabCode: ExpertiseTaskViewTab.task,
        title: <FormattedMessage id="expertiseTask.tabs.task" />,
        path: clientRoute.expertiseTask,
    },
    {
        tabCode: ExpertiseTaskViewTab.subject,
        title: <FormattedMessage id="expertiseTask.tabs.subject" />,
        path: clientRoute.expertiseTaskSubject,
    },
];

export const renderExpertiseTaskViewTabInj = (setting: RouteSetting): JSX.Element => {
    const { tab, path, title } = setting;
    // @ts-ignore
    return <Tab component={Link} label={title} value={tab} key={tab} to={path} />;
};

export const ExpertiseTaskViewContentLeftSideTabs = observer((): JSX.Element => {
    const [getExpertiseTaskBodyDescriptionTabs] = di(
        [getExpertiseTaskBodyDescriptionTabsInj],
        ExpertiseTaskViewContentLeftSideTabs,
    );
    const [renderExpertiseTaskViewTab] = di([renderExpertiseTaskViewTabInj], ExpertiseTaskViewContentLeftSideTabs);

    const { model } = useExpertiseTaskViewPageContext();
    const { id } = model;
    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;

    const allPermissionsQueries = useMemo((): AuthorizationCheckQueryOrWithoutCheck[] => {
        return [expertiseTaskConfig.viewSubject(id)];
    }, [id]);

    const [allPermissions] = useFetchAllPermissions({ allPermissionsQueries });
    const [viewSubject] = allPermissions;

    const settings: RouteSetting[] = getExpertiseTaskBodyDescriptionTabs().map((tab) => {
        const { tabCode, path, title } = tab;

        return {
            tab: tabCode,
            title,
            path: generatePath(path, { id }),
            exact: true,
        };
    });

    const tabs = settings.map((setting) => {
        if (setting.tab === ExpertiseTaskViewTab.subject) {
            return viewSubject ? renderExpertiseTaskViewTab(setting) : <React.Fragment />;
        } else {
            return renderExpertiseTaskViewTab(setting);
        }
    });

    return <RoutedTabs settings={settings}>{tabs}</RoutedTabs>;
});
