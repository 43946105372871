import { GridProps } from '@mui/material';
import { ObjectHeaderLayout } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useCampaignPageContext } from '../../../../../hooks';
import { objectWithoutBreadcrumbsHeaderContainerSx } from '../../../../../utils';
import { CampaignHeaderLeft as CampaignHeaderLeftInj } from './CampaignHeaderLeft';
import { CampaignHeaderTitle as CampaignHeaderTitleInj } from './CampaignHeaderTitle';

export const CampaignHeader = observer((): JSX.Element => {
    const [CampaignHeaderTitle] = di([CampaignHeaderTitleInj], CampaignHeader);
    const [CampaignHeaderLeft] = di([CampaignHeaderLeftInj], CampaignHeader);

    const { model } = useCampaignPageContext();
    const { title, identifier, metaInfo, manager } = model;
    const { created, stateTitle } = metaInfo;
    const { name } = manager;

    const titleBlock = <CampaignHeaderTitle title={title} />;
    const leftBottomBlockElement = (
        <CampaignHeaderLeft identifier={identifier} created={created} stateTitle={stateTitle} managerName={name} />
    );
    const titleBlockAndMenu = {
        titleBlock,
    };
    const leftBottomBlock = {
        element: leftBottomBlockElement,
    };

    const mainContentContainerProps: GridProps = {
        sx: objectWithoutBreadcrumbsHeaderContainerSx,
    };

    return (
        <ObjectHeaderLayout
            mainContentContainerProps={mainContentContainerProps}
            titleBlockAndMenu={titleBlockAndMenu}
            leftBottomBlock={leftBottomBlock}
        />
    );
});
