import { RootProps } from '@platform/front-core';
import { getPalette, isPaletteEnabled, TotPalette } from '@platform/front-ui';
import { createBrowserHistory } from 'history';

const projectName = process.env.REACT_APP_PROJECT_NAME;
const apiUrl = process.env.REACT_APP_API_URL;

const env: RootProps['env'] = {
    apiUrl: process.env.REACT_APP_API_URL,
    serviceUrl:
        process.env.REACT_APP_SERVICE_URL || typeof apiUrl === 'string' ? apiUrl?.replace('api', 'service') : undefined,
    projectName,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    totCopyright: process.env.REACT_APP_TOT_COPYRIGHT,
    totCopyrightStartYear: process.env.REACT_APP_TOT_COPYRIGHT_START_YEAR,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    yaMetrikaId: Number(process.env.REACT_APP_METRIKA_ID),
    asyncCheckDelay: Number(process.env.REACT_APP_ASYNC_CHECK_DELAY) || 200,
    asyncCheckTimeout: Number(process.env.REACT_APP_ASYNC_CHECK_TIMEOUT) || 2000,
};

let features: RootProps['features'];
let ru = {};
let en = {};

try {
    features = require(`./customization/${projectName}/features`).default;
} catch (error) {
    features = require(`./customization/default/features`).default;
}

try {
    ru = require(`./customization/${projectName}/locales/ru`).default;
    en = require(`./customization/${projectName}/locales/en`).default;
} catch (error) {}

const locales: RootProps['locales'] = { ru, en };
const history = createBrowserHistory();

let palette: TotPalette | undefined;
if (isPaletteEnabled(process.env.REACT_APP_ENABLE_PALETTE)) {
    palette = getPalette();
}

export { env, features, locales, history, palette };
