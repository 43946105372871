import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getCategoriesOfSubjectsBreadcrumbs = (
    intl: IntlShape,
    categoryId: string,
    categoryTitle: string,
): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.categories,
            routeTitle: formatMessage({ id: 'category.categories' }),
        },
        {
            to: categoryId ? generatePath(clientRoute.category, { id: categoryId }) : '',
            routeTitle: categoryTitle,
        },
        {
            to: clientRoute.categorySyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
