import { Grid } from '@mui/material';
import { AuthorizationCheck, sxHeight100 } from '@platform/front-core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { borderSolid300 } from '../../../../../constants';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { ExpertiseTaskSubject as ExpertiseTaskSubjectInj } from '../ExpertiseTaskSubject';
import { ExpertiseTaskSubjectWithoutFiles } from '../ExpertiseTaskSubjectWithoutFiles';
import { ExpertiseTaskView as ExpertiseTaskViewInj } from '../ExpertiseTaskView';
import { ExpertiseTaskViewContentLeftSideTabs as ExpertiseTaskViewContentLeftSideTabsInj } from './ExpertiseTaskViewContentLeftSideTabs';

export const getAdditionalTaskViewRoutesPagesInj = (): JSX.Element => <React.Fragment />;

export const ExpertiseTaskViewContentLeftSide = observer((): JSX.Element => {
    const [ExpertiseTaskView] = di([ExpertiseTaskViewInj], ExpertiseTaskViewContentLeftSide);
    const [ExpertiseTaskSubject] = di([ExpertiseTaskSubjectInj], ExpertiseTaskViewContentLeftSide);
    const [ExpertiseTaskViewContentLeftSideTabs] = di(
        [ExpertiseTaskViewContentLeftSideTabsInj],
        ExpertiseTaskViewContentLeftSide,
    );
    const [getAdditionalTaskViewRoutesPages] = di(
        [getAdditionalTaskViewRoutesPagesInj],
        ExpertiseTaskViewContentLeftSide,
    );

    const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');
    const { model } = useExpertiseTaskViewPageContext();
    const { id } = model;

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const viewSubjectQuery = expertiseTaskConfig.viewSubject(id);

    const subjectTabContent = (allowed: boolean): JSX.Element =>
        allowed ? (
            withSubjectFilesTab ? (
                <React.Fragment>
                    <Route path={clientRoute.expertiseTaskSubject} exact>
                        <ExpertiseTaskSubjectWithoutFiles />
                    </Route>
                    <Route path={clientRoute.expertiseTaskFiles} exact>
                        <ExpertiseTaskSubject isOnlyFiles={true} />
                    </Route>
                </React.Fragment>
            ) : (
                <Route path={clientRoute.expertiseTaskSubject} exact>
                    <ExpertiseTaskSubject />
                </Route>
            )
        ) : (
            <Redirect to={generatePath(clientRoute.expertiseTask, { id })} />
        );

    return (
        <Grid container direction="column" wrap="nowrap" width="100%" sx={sxHeight100} overflow="hidden">
            <Grid item borderBottom={borderSolid300}>
                <ExpertiseTaskViewContentLeftSideTabs />
            </Grid>
            <Grid item pt={1} pr={2} sx={sxHeight100} overflow="auto">
                <Switch>
                    <Route path={clientRoute.expertiseTaskSubject}>
                        <AuthorizationCheck {...viewSubjectQuery}>{subjectTabContent}</AuthorizationCheck>
                    </Route>
                    <Route path={clientRoute.expertiseTask} exact>
                        <ExpertiseTaskView />
                    </Route>
                    {getAdditionalTaskViewRoutesPages()}
                </Switch>
            </Grid>
        </Grid>
    );
});
