import { Button, Grid, styled } from '@mui/material';
import { AuthorizationCheck, Transitions } from '@platform/front-core';
import { useAntiDoubleClick, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { FilesDownload as FilesDownloadInj } from '../../../../components';
import { ExpertiseCreateDialogContext, ExpertiseCreateDialogContextType } from '../../../../contexts';
import { useStore, useSubjectPageContext } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../../expertise';

const ButtonNoWrap = styled(Button)`
    white-space: nowrap;
`;

export const SubjectControlPanelRight = observer((): JSX.Element => {
    const [FilesDownload] = di([FilesDownloadInj], SubjectControlPanelRight);
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], SubjectControlPanelRight);

    const history = useHistory();
    const { onSubmit, model, validateForms } = useSubjectPageContext();
    const { id, identifier, title, downloadAllFiles, load, metaInfo } = model;
    const [isModalOpen, openModal, closeModal] = useFlag();
    const { subjectStore, coreRootStore } = useStore();

    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    const { formatMessage } = useIntl();
    const filename = `${formatMessage({ id: 'subject.allFiles' }, { title: identifier })}.zip`;

    const subjectIdTitle = {
        id,
        title: `${identifier} ${title}`,
    };

    const { permissionsStore } = coreRootStore;
    const { expertiseSubjectConfig } = permissionsStore as PermissionsStore;
    const downloadFilesQuery = expertiseSubjectConfig.downloadFiles(id);
    const createExpertiseQuery = expertiseSubjectConfig.createExpertise(id);

    const contextValue: ExpertiseCreateDialogContextType = {
        subject: subjectIdTitle,
        onClose: closeModal,
        open: isModalOpen,
    };

    const onCreateCancel = (): void => {
        history.push(generatePath(clientRoute.subjects));
    };

    const onEditCancel = (): void => {
        history.push(generatePath(clientRoute.subject, { id }));
    };

    return (
        <Grid item container alignItems="center" spacing={1} wrap="nowrap">
            <Switch>
                <Route path={clientRoute.subjectCreate} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onCreateCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={isLoading} endIcon={endIcon} onClick={onSubmitHandler}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subjectEdit} exact>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={onEditCancel}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={isLoading} endIcon={endIcon} onClick={onSubmitHandler}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subject} exact>
                    <AuthorizationCheck {...downloadFilesQuery}>
                        <Grid item>
                            <FilesDownload
                                onDownloadClick={downloadAllFiles}
                                filename={filename}
                                buttonText={formatMessage({ id: 'subject.downloadAllFiles' })}
                            />
                        </Grid>
                    </AuthorizationCheck>
                    <AuthorizationCheck key={metaInfo.stateTitle} {...createExpertiseQuery}>
                        <Grid item>
                            <ButtonNoWrap variant="contained" onClick={openModal}>
                                <FormattedMessage id="expertise.createExpertise" />
                            </ButtonNoWrap>
                            <ExpertiseCreateDialogContext.Provider value={contextValue}>
                                <ExpertiseCreateDialog />
                            </ExpertiseCreateDialogContext.Provider>
                        </Grid>
                    </AuthorizationCheck>
                    <Grid item>
                        <Transitions
                            onSuccessTransition={load}
                            objectId={id}
                            transitionsStore={subjectStore}
                            validateFunction={validateForms}
                        />
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
});
