import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { useAntiDoubleClick, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../hooks';
import { SelectionOfExpertListModel } from '../../../models';
import { SelectionOfExpertTable as SelectionOfExpertTableInj } from './SelectionOfExpertTable';

type RouterProps = {
    id: string;
};

export type SelectionOfExpertiseDialogProps = {
    isOpen: boolean;
    onClose: VoidFunction;
    onSuccessSubmit: VoidFunction;
};

const sxStyles = makeSxStyles({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        px: 4,
    },
    bottomButton: {
        width: '35%',
    },
    title: {
        py: 2,
    },
});

export const SelectionOfExpertDialog = observer((props: SelectionOfExpertiseDialogProps): JSX.Element => {
    const [SelectionOfExpertTable] = di([SelectionOfExpertTableInj], SelectionOfExpertDialog);

    const { onClose, onSuccessSubmit, isOpen } = props;
    const { id } = useParams<RouterProps>();
    const rootStore = useStore();
    const tableModel = useMemo(() => new SelectionOfExpertListModel(id, rootStore), [id, rootStore]);
    const { onSubmitCreator } = tableModel;

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCreator(onSuccessSubmit));

    return (
        <Dialog maxWidth="xl" fullWidth open={isOpen}>
            <DialogContent sx={sxStyles.dialogContent}>
                <Typography sx={sxStyles.title} variant="h3" className="t-new-expertise-title">
                    <FormattedMessage id="expertiseTask.selectionOfExpert.title" />
                </Typography>
                <SelectionOfExpertTable tableModel={tableModel} />
                <Box pt={2} pb={3} pl={30.75} pr={30.75}>
                    <Grid container spacing={10} justifyContent="center">
                        <Grid sx={sxStyles.bottomButton} item>
                            <Button size="large" fullWidth variant="text" color="secondary" onClick={onClose}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                        <Grid sx={sxStyles.bottomButton} item>
                            <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSending}
                                endIcon={endIcon}
                                onClick={handleSubmit}
                            >
                                <FormattedMessage id="expertiseTask.selectionOfExpert.select" />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
});
