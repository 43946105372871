import { action, makeObservable, observable } from 'mobx';
import { ExpertiseTaskPrintFormsDTO } from '../../types';
import { ExpertiseTaskPrintFormModel } from './ExpertiseTaskPrintFormModel';

export const expertiseTaskPrintFormsModelObservables = {
    conclusion: observable,
    loaded: observable,

    load: action.bound,
};

export class ExpertiseTaskPrintFormsModel {
    conclusion?: ExpertiseTaskPrintFormModel;
    loaded = false;

    constructor() {
        makeObservable(this, expertiseTaskPrintFormsModelObservables);
    }

    load(dto: ExpertiseTaskPrintFormsDTO): ExpertiseTaskPrintFormsModel {
        const { conclusion } = dto;
        conclusion && (this.conclusion = new ExpertiseTaskPrintFormModel().load(conclusion));
        this.loaded = true;
        return this;
    }
}
