import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { UserInfoMessageValues, UserTableRow } from '../../../../types';
import { getPopoverOriginConfig } from '../../../../utils';
import { UserAssignPasswordDialog as UserAssignPasswordDialogInj } from './UserAssignPasswordDialog';
import { UserStatusButton as UserStatusButtonInj } from './UserStatusButton';

export type UserRegistryActionsBtnsProps = {
    userTableRow: UserTableRow;
    reloadData: () => void;
    toggleIsRowBlocked: () => void;
    isRowActionsFirst?: boolean;
};

export const UserRegistryActionsBtns = observer((props: UserRegistryActionsBtnsProps): JSX.Element => {
    const [UserAssignPasswordDialog] = di([UserAssignPasswordDialogInj], UserRegistryActionsBtns);
    const [UserStatusButton] = di([UserStatusButtonInj], UserRegistryActionsBtns);

    const { userTableRow, reloadData, toggleIsRowBlocked, isRowActionsFirst } = props;
    const [userAssignPassword] = useFeature('userAssignPassword');
    const [, setIsWithAssignPassword] = useState<boolean>(false);
    const { id, customData } = userTableRow;
    const { login, name } = customData;
    const { authorizationStore, permissionsStore } = useStore().coreRootStore;
    const { systemConfig, accountConfig } = permissionsStore as PermissionsStore;
    const accountAdministrationQuery = systemConfig.accountAdministration();
    const updateUserQuery = accountConfig.updateUser(id);

    const userInfoMessageValues: UserInfoMessageValues = {
        login: login.title,
        name: name.title,
    };

    useEffect((): void => {
        userAssignPassword && authorizationStore.check(accountAdministrationQuery).then(setIsWithAssignPassword);
    }, [userAssignPassword]);

    const renderActionItems = useCallback(
        (hideMenu: () => void): ReactNode[] => {
            return [
                <UserStatusButton
                    key="status"
                    userTableRow={userTableRow}
                    reloadData={reloadData}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    hideMenu={hideMenu}
                />,
                <UserAssignPasswordDialog
                    key="password"
                    id={id}
                    tabIndex={1}
                    userInfoMessageValues={userInfoMessageValues}
                    hideMenu={hideMenu}
                />,
            ];
        },
        [userTableRow, userInfoMessageValues, id, reloadData],
    );

    const { anchorOrigin, transformOrigin } = isRowActionsFirst
        ? getPopoverOriginConfig('bottom-right')
        : getPopoverOriginConfig('bottom-left');

    return (
        <AuthorizationCheck {...updateUserQuery}>
            <MenuButton
                renderMenuItems={renderActionItems}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            />
        </AuthorizationCheck>
    );
});
