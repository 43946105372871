import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box, Divider, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../../../../components';
import { campaignPanelAccordionStyles } from '../../../../../constants';
import { CampaignTasks, CampaignTasksLinks } from '../../../../../types';
import { createLinkWithQueries } from '../../../../../utils';
import { CampaignPanelExpertiseSubject } from './CampaignSubjectsPanel';
import { CampaignPanelSubjectCount as CampaignPanelSubjectCountInj } from './components';

export type CampaignTasksPanelProps = {
    tasks: CampaignTasks;
    links: CampaignTasksLinks;
};

const expandIcon = <ExpandMoreIcon />;

export const CampaignTasksPanel = observer((props: CampaignTasksPanelProps): JSX.Element => {
    const [CampaignPanelSubjectCount] = di([CampaignPanelSubjectCountInj], CampaignTasksPanel);
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], CampaignTasksPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], CampaignTasksPanel);

    const { tasks, links } = props;
    const { formatMessage } = useIntl();

    const { overdue, active, total, done, notCompleted, responseWaiting, withoutExpert } = links;

    const expertiseSubjects: CampaignPanelExpertiseSubject[] = [
        {
            labelText: formatMessage({ id: 'campaign.tasks.total' }),
            path: createLinkWithQueries(total.urn, total.queries),
            count: tasks.total,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.withoutExpert' }),
            path: createLinkWithQueries(withoutExpert.urn, withoutExpert.queries),
            count: tasks.withoutExpert,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.responseWaiting' }),
            path: createLinkWithQueries(responseWaiting.urn, responseWaiting.queries),
            count: tasks.responseWaiting,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.active' }),
            path: createLinkWithQueries(active.urn, active.queries),
            count: tasks.active,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.overdue' }),
            path: createLinkWithQueries(overdue.urn, overdue.queries),
            count: tasks.overdue,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.done' }),
            path: createLinkWithQueries(done.urn, done.queries),
            count: tasks.done,
        },
        {
            labelText: formatMessage({ id: 'campaign.tasks.notCompleted' }),
            path: createLinkWithQueries(notCompleted.urn, notCompleted.queries),
            count: tasks.notCompleted,
        },
    ];

    const expertiseSubjectComponentsUpperRow: JSX.Element[] = expertiseSubjects.slice(0, 3).map((subject, index) => {
        return <CampaignPanelSubjectCount {...subject} key={subject.path + index} />;
    });

    const expertiseSubjectComponentsLowerRow: JSX.Element[] = expertiseSubjects.slice(3).map((subject, index) => {
        return <CampaignPanelSubjectCount {...subject} key={subject.path + index} />;
    });

    return (
        <Box sx={campaignPanelAccordionStyles.accordionContainer}>
            <Accordion defaultExpanded sx={campaignPanelAccordionStyles.accordion}>
                <TotObjectPanelSummary expandIcon={expandIcon}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="campaign.tasks.title" />
                    </Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid container direction="column">
                        <Grid item container direction="row" pb={1}>
                            {expertiseSubjectComponentsUpperRow}
                        </Grid>
                        <Divider />
                        <Grid item container direction="row" pt={1} pb={2}>
                            {expertiseSubjectComponentsLowerRow}
                        </Grid>
                    </Grid>
                </TotObjectPanelDetails>
            </Accordion>
        </Box>
    );
});
