import { FormApi } from '@platform/formiojs-react';
import { FormDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { ExpertiseTaskSubjectDTO } from '../../types';

export const expertiseTaskSubjectModelObservables = {
    taskId: observable,
    subjectId: observable,
    subjectIdentifier: observable,

    commonFormDTO: observable,
    commonFormApi: observable,
    hiddenFormDTO: observable,
    hiddenFormApi: observable,

    load: action.bound,
    setCommonFormApi: action.bound,
    setHiddenFormApi: action.bound,
};

export class ExpertiseTaskSubjectModel {
    taskId: string;
    subjectId = '';
    subjectIdentifier = '';

    commonFormDTO?: FormDTO;
    commonFormApi?: FormApi;

    hiddenFormDTO?: FormDTO;
    hiddenFormApi?: FormApi;

    constructor(id: string) {
        makeObservable(this, expertiseTaskSubjectModelObservables);
        this.taskId = id;
    }

    load(dto: ExpertiseTaskSubjectDTO): void {
        const { commonFormInfo, hiddenFormInfo } = dto;
        this.subjectId = dto.subjectId;
        this.subjectIdentifier = dto.subjectIdentifier;
        this.commonFormDTO = commonFormInfo;
        this.hiddenFormDTO = hiddenFormInfo;
    }

    setCommonFormApi(formApi: FormApi): void {
        this.commonFormApi = formApi;
    }

    setHiddenFormApi(formApi: FormApi): void {
        this.hiddenFormApi = formApi;
    }
}
