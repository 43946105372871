import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignListPage as CampaignListPageInj } from './campaign-list';
import { CampaignPage as CampaignPageInj } from './campaign-page';

export const CampaignPages = (): JSX.Element => {
    const [CampaignPage] = di([CampaignPageInj], CampaignPages);
    const [CampaignListPage] = di([CampaignListPageInj], CampaignPages);

    return (
        <Switch>
            <Route
                path={[
                    clientRoute.campaign,
                    clientRoute.campaignEdit,
                    clientRoute.campaignCreate,
                    clientRoute.campaignSyslog,
                ]}
            >
                <CampaignPage />
            </Route>
            <Route>
                <CampaignListPage />
            </Route>
        </Switch>
    );
};
