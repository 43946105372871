import { LocalesTree } from '@platform/front-utils';

const subject: LocalesTree = {
    actions: {
        deleteSubject: 'Delete object',
        editSubject: 'Edit object',
    },
    allFiles: 'All documents for {title}',
    blocks: {
        serviceInfo: 'Service information',
    },
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise object "{title}"?',
    createSubject: 'Create object',
    downloadAllFiles: 'Download all files',
    fields: {
        author: 'Author',
        campaign: 'Campaign',
        category: 'Category',
        created: 'Created',
        expertise: 'Expertise',
        form: 'Form',
        identifier: 'Number',
        lastModified: 'Last modified',
        lifecycle: 'Lifecycle',
        state: 'State',
        title: 'Title',
        withoutExpertise: 'Objects without expertise',
    },
    newSubject: 'New object',
    registryPagingInfo:
        'Total {count, number} object{count, plural, one {} other {s}}. ' +
        'Displayed from {from, number} to {to, number}',
    subjectListTitle: 'Expertise objects',
    subjects: 'Objects',
    withoutExpertise: 'Without expertise',
};

export default subject;
