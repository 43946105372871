import { Grid } from '@mui/material';
import { Form } from '@platform/formiojs-react';
import { ObjectLayout, ObjectLayoutProps, sxHeight100 } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { AuthorizedRoute as AuthorizedRouteInj } from '../../../../components';
import { ExpertiseTaskViewPageContext, ExpertiseTaskViewPageContextType } from '../../../../contexts';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskViewModel } from '../../../../models';
import { PermissionsStore } from '../../../../stores';
import {
    ExpertiseTaskSidebar as ExpertiseTaskSidebarInj,
    expertiseTaskViewSidebarWrappersSx,
} from '../expertise-task-view-mode';
import { ExpertiseTaskControlPanel as ExpertiseTaskControlPanelInj } from './expertise-task-control-panel';
import { ExpertiseTaskEditHeader as ExpertiseTaskEditHeaderInj } from './ExpertiseTaskEditHeader';

const queryString = require('query-string');

export type ExpertiseTaskPageRouteParams = {
    id: string;
    backUrl: string;
};

export const ExpertiseTaskEditPage = observer((): JSX.Element => {
    const [AuthorizedRoute] = di([AuthorizedRouteInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskControlPanel] = di([ExpertiseTaskControlPanelInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskEditHeader] = di([ExpertiseTaskEditHeaderInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskSidebar] = di([ExpertiseTaskSidebarInj], ExpertiseTaskEditPage);

    const rootStore = useStore();
    const { expertiseTaskStore, history, coreRootStore } = rootStore;
    const { intlStore } = coreRootStore;
    const { id } = useParams<ExpertiseTaskPageRouteParams>();

    const expertiseTaskViewModel = useMemo(() => new ExpertiseTaskViewModel(id, rootStore), [id]);
    const { taskModel } = expertiseTaskViewModel;
    const { load, setCardData, setFormApi, formDTO, formApi, setIsFormChanged } = taskModel;

    const { formatMessage, locale } = useIntl();

    const location = useLocation();
    const queries = queryString.parse(location.search);
    const backUrl = queries.backUrl || generatePath(clientRoute.expertiseTasks);

    useEffect(() => {
        expertiseTaskStore.loadExpertiseTaskDTO(id).then(load);
    }, [id]);

    const updateCard = (): void => {
        expertiseTaskStore.loadExpertiseTaskDTO(id).then(setCardData);
    };

    useEffect(() => {
        updateCard();
    }, [locale]);

    const onSubmit = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            return formApi
                .submit(false)
                .then(() => {
                    return expertiseTaskStore.saveExpertiseTask(id, formApi.getSubmissionWithAdditionalInfo());
                })
                .then(() => {
                    history.push(generatePath(clientRoute.expertiseTask, { id }));
                });
        }
        return Promise.reject();
    };

    const goBack = (): void => {
        backUrl && history.push(backUrl);
    };

    const sidebarTitle = formatMessage({ id: 'expertiseTask.descriptionPanel.title' });

    const header: ObjectLayoutProps['header'] = <ExpertiseTaskEditHeader />;

    const infoSidebar: ObjectLayoutProps['infoSidebar'] = {
        elements: {
            titleElement: <Typography variant="subtitle2">{sidebarTitle}</Typography>,
            contentElement: <ExpertiseTaskSidebar />,
            wrapperSx: expertiseTaskViewSidebarWrappersSx.wrapper,
            titleWrapperSx: expertiseTaskViewSidebarWrappersSx.title,
        },
    };

    const contentLayout: ObjectLayoutProps['contentLayout'] = {
        content: (
            <Grid container wrap="nowrap" width="100%" sx={sxHeight100} direction="column" overflow="auto">
                <Grid item flexGrow={1}>
                    {!!formDTO && (
                        <Form
                            formDTO={formDTO}
                            multiLang={true}
                            readOnly={false}
                            intlStore={intlStore}
                            ownerEntityId={id}
                            onFormReady={setFormApi}
                            setFormIsChanged={setIsFormChanged}
                        />
                    )}
                </Grid>
                <ExpertiseTaskControlPanel onSubmit={onSubmit} goBack={goBack} />
            </Grid>
        ),
    };

    const expertiseTaskEditPageContextValue: ExpertiseTaskViewPageContextType = {
        model: expertiseTaskViewModel,
        backUrl,
    };

    const { permissionsStore } = coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const editExpertiseTaskQuery = expertiseTaskConfig.editExpertiseTask(id);

    return (
        <AuthorizedRoute
            path={clientRoute.expertiseTaskEdit}
            key={clientRoute.expertiseTaskEdit}
            exact
            {...editExpertiseTaskQuery}
        >
            <ExpertiseTaskViewPageContext.Provider value={expertiseTaskEditPageContextValue}>
                <ObjectLayout header={header} contentLayout={contentLayout} infoSidebar={infoSidebar} />
            </ExpertiseTaskViewPageContext.Provider>
        </AuthorizedRoute>
    );
});
