import React from 'react';
import { ExpertiseTaskViewModel } from '../models';

export type ExpertiseTaskViewPageContextType = {
    model: ExpertiseTaskViewModel;
    backUrl: string;
};

export const ExpertiseTaskViewPageContext = React.createContext<ExpertiseTaskViewPageContextType>({
    model: {} as ExpertiseTaskViewModel,
    backUrl: '',
});
