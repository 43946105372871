import { makeSxStyles } from '@platform/front-ui';

export const campaignPanelStyles = makeSxStyles({
    panelDetailsList: {
        '& > *': {
            '&:not(:last-child)': {
                marginBottom: (theme) => theme.spacing(1),
            },
        },
        paddingBottom: (theme) => theme.spacing(2.25),
    },
});
