import { AuthorizationCheck } from '@platform/front-core';
import { CustomFilters, TTable, TTableRowActions } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ttableDefaultPaths } from '../../../constants';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { RegistryType, SubjectTableRow } from '../../../types';
import { SubjectRegistryTableCustomCategoryFilter as SubjectRegistryTableCustomCategoryFilterInj } from './SubjectRegistryTableCustomCategoryFilter';

export type SubjectRegistryTableProps = {
    setRowActions?: TTableRowActions<SubjectTableRow>;
};

const registryCode = RegistryType.subject;

export const SubjectRegistryTable = observer((props: SubjectRegistryTableProps): JSX.Element => {
    const [SubjectRegistryTableCustomCategoryFilter] = di(
        [SubjectRegistryTableCustomCategoryFilterInj],
        SubjectRegistryTable,
    );
    const { setRowActions } = props;
    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api, permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'subject.subjectListTitle' });

    const customFilters: CustomFilters = {
        'customData.expertise.title': (props) => <SubjectRegistryTableCustomCategoryFilter {...props} />,
    };

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable<SubjectTableRow>
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={ttableDefaultPaths.subjects}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                    customFilters={customFilters}
                />
            )}
        </AuthorizationCheck>
    );
});
