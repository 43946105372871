import { FullSubmission, TransitionDTO } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore, CodeTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ExpertiseCreateModel } from '../models';
import {
    EntityCreatedDTO,
    ExpertiseByCampaignDTO,
    ExpertiseCreateDTO,
    ExpertiseDTO,
    ExpertiseTasksRowDTO,
    PlanEntryDTO,
} from '../types';
import { ExpertisePlanStore, expertisePlanStoreAbstractObservables } from './ExpertisePlanStore';
import { RootStore } from './RootStore';

export const expertiseStoreObservables = {
    ...expertisePlanStoreAbstractObservables,

    loadExpertise: action.bound,
    deleteExpertise: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    getTransitions: action.bound,
    getExpertiseCreateModel: action.bound,
    loadLifeCyclesSelectList: action.bound,
    createExpertise: action.bound,
    createExpertiseByCampaign: action.bound,
    editCurator: action.bound,
    loadExpertiseTasksListByExpertise: action.bound,
};

export class ExpertiseStore extends ExpertisePlanStore {
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, expertiseStoreObservables);
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    getExpertiseCreateModel(): ExpertiseCreateModel {
        const model = new ExpertiseCreateModel(this.rootStore);
        this.rootStore.templatesOfExpertiseStore.loadTemplatesSelectList().then(model.loadTemplates);
        this.loadLifeCyclesSelectList().then(model.loadLifeCycles);
        return observable(model);
    }

    loadExpertise(id: string): Promise<ExpertiseDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadExpertise(id)).then((r) => r.data);
    }

    deleteExpertise(id: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteExpertise(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, expertiseId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.expertiseLifeCycleTransition(transitionId, expertiseId))
            .then((r) => r.data);
    }

    getTransitions(expertiseId: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.getExpertiseLifeCycleTransitions(expertiseId)).then((r) => r.data);
    }

    loadLifeCyclesSelectList(): Promise<CodeTitle[]> {
        return this.api.client(this.apiConfigs.lifeCyclesExpertiseList).then((r) => r.data);
    }

    createExpertise(data: ExpertiseCreateDTO): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createExpertise(data)).then((r) => r.data);
    }

    createExpertiseByCampaign(data: ExpertiseByCampaignDTO): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createExpertiseByCampaign(data)).then((r) => r.data);
    }

    loadPlanEntryList(expertiseId: string): Promise<PlanEntryDTO[]> {
        return this.api.client(this.apiConfigs.loadExpertisePlanEntries(expertiseId)).then((r) => r.data);
    }

    createPlanEntry(expertiseId: string): Promise<EntityCreatedDTO> {
        return this.api.userActionClient(this.apiConfigs.createExpertisePlanEntry(expertiseId)).then((r) => r.data);
    }

    updatePlanEntry(
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.updateExpertisePlanEntry(id, submission, taskFormCode, taskSubmission))
            .then((r) => r.data);
    }

    loadPlanEntry(id: string): Promise<PlanEntryDTO> {
        return this.api.client(this.apiConfigs.loadExpertisePlanEntry(id)).then((r) => r.data);
    }

    deletePlanEntry(id: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteExpertisePlanEntry(id)).then((r) => r.data);
    }

    editCurator(expertiseId: string, curatorUserId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.editExpertiseCurator(expertiseId, curatorUserId))
            .then((r) => r.data);
    }

    loadExpertiseTasksListByExpertise(expertiseId: string): Promise<ExpertiseTasksRowDTO[]> {
        return this.api.client(this.apiConfigs.expertiseTasksListByExpertise(expertiseId)).then((r) => r.data.rows);
    }
}

export const getExpertiseStore = (): any => {
    const [_ExpertiseStore] = di([ExpertiseStore], getExpertiseStore);
    return _ExpertiseStore;
};
