import { ApiStore } from '@platform/front-core';
import { AsyncCheckActionType, AsyncCheckStore, voidFunction } from '@platform/front-utils';
import downloadFile from 'js-file-download';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { PfTemplateInfo, TemplateFormValues } from '../types';
import { RootStore } from './RootStore';

export const pfTemplateStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,

    reloadRegistryData: observable,

    getFileInfo: action.bound,
    downloadServerFile: action.bound,
    downloadTemplateFile: action.bound,
    getFormData: action.bound,
    create: action.bound,
    update: action.bound,
    setReloadRegistryData: action.bound,
};

export class PfTemplateStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    reloadRegistryData = voidFunction;

    constructor(rootStore: RootStore) {
        makeObservable(this, pfTemplateStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    getFileInfo(id: string): Promise<PfTemplateInfo> {
        return this.api.userActionClient(this.apiConfigs.getFileInfo(id)).then((r) => r.data);
    }

    downloadTemplateFile(id: string, fileName: string): void {
        this.api
            .userActionClient(this.apiConfigs.downloadPfTemplateFile(id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileName));
    }

    downloadServerFile(fileId: string): Promise<Blob> {
        return this.api.client(this.apiConfigs.downloadPfTemplateFile(fileId)).then((r) => r.data);
    }

    getFormData(values: TemplateFormValues): FormData {
        const { title, file } = values;

        const data = new FormData();
        data.append('title', title);
        data.append('file', file[0]);

        return data;
    }

    create(values: TemplateFormValues, isAsyncCheckRequired = false): Promise<string> {
        const formData = this.getFormData(values);
        return this.api
            .userActionClient(this.apiConfigs.createPfTemplate(formData))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    update(values: TemplateFormValues, templateId?: string, isAsyncCheckRequired = false): Promise<string> {
        const formData = this.getFormData(values);
        return this.api
            .userActionClient(this.apiConfigs.updatePfTemplate(formData, templateId))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    setReloadRegistryData(reloadRegistryData: VoidFunction): void {
        this.reloadRegistryData = reloadRegistryData;
    }
}

export const getPfTemplateStore = (): any => {
    const [_PfTemplateStore] = di([PfTemplateStore], getPfTemplateStore);
    return _PfTemplateStore;
};
