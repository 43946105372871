import { ApiStore } from '@platform/front-core';
import { FormDTO, FullSubmission } from '@platform/front-types';
import { CodeTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { ApiConfigs } from '../apiConfigs';
import { EntityCreatedDTO, PlanEntryDTO } from '../types';
import { RootStore } from './RootStore';

export const expertisePlanStoreAbstractObservables = {
    loadPlanEntryList: action.bound,
    createPlanEntry: action.bound,
    loadPlanEntry: action.bound,
    deletePlanEntry: action.bound,
    updatePlanEntry: action.bound,
};

export const expertisePlanStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    expertiseTaskFormsSelectData: action.bound,
    expertiseTaskFormByCode: action.bound,
};

export abstract class ExpertisePlanStore {
    rootStore: RootStore;
    api: ApiStore;
    apiConfigs: ApiConfigs;

    protected constructor(rootStore: RootStore) {
        makeObservable(this, expertisePlanStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
    }

    abstract loadPlanEntryList(ownerId: string): Promise<PlanEntryDTO[]>;

    abstract createPlanEntry(ownerId: string): Promise<EntityCreatedDTO>;

    abstract loadPlanEntry(entryId: string): Promise<PlanEntryDTO>;

    abstract deletePlanEntry(entryId: string): Promise<void>;

    abstract updatePlanEntry(
        entryId: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void>;

    expertiseTaskFormsSelectData(): Promise<CodeTitle[]> {
        return this.api.client(this.apiConfigs.expertiseTaskFormsSelectData).then((r) => r.data);
    }

    expertiseTaskFormByCode(code: string): Promise<FormDTO> {
        return this.api.client(this.apiConfigs.expertiseTaskFormByCode(code)).then((r) => r.data);
    }
}
