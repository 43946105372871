import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { PfTemplateTableRow } from '../../../types';
import { PfTemplateRegistryEditActionMenuItem as PfTemplateRegistryEditActionMenuItemInj } from './PfTemplateRegistryEditActionMenuItem';

export type PfTemplateRegistryActionsBtnsProps = {
    pfTemplateTableRow: PfTemplateTableRow;
};

export const PfTemplateRegistryActionsBtns = observer((props: PfTemplateRegistryActionsBtnsProps): JSX.Element => {
    const [PfTemplateRegistryEditActionMenuItem] = di(
        [PfTemplateRegistryEditActionMenuItemInj],
        PfTemplateRegistryActionsBtns,
    );

    const { pfTemplateTableRow } = props;

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const editPfTemplateQuery = systemConfig.editPfTemplate();

    const renderActionItems = useCallback(
        (hideMenu: () => void): ReactNode[] => {
            return [
                <PfTemplateRegistryEditActionMenuItem pfTemplateTableRow={pfTemplateTableRow} hideMenu={hideMenu} />,
            ];
        },
        [pfTemplateTableRow],
    );

    return (
        <AuthorizationCheck {...editPfTemplateQuery}>
            <MenuButton renderMenuItems={renderActionItems} />
        </AuthorizationCheck>
    );
});
