import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { CampaignTableRow } from '../../../../types';
import { CampaignDeleteButton as CampaignDeleteButtonIng } from './CampaignDeleteButton';

export type CampaignRegistryActionsBtnsProps = {
    campaignTableRow: CampaignTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const CampaignRegistryActionsBtns = observer((props: CampaignRegistryActionsBtnsProps): JSX.Element => {
    const [CampaignDeleteButton] = di([CampaignDeleteButtonIng], CampaignRegistryActionsBtns);

    const { campaignTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseCampaignConfig } = permissionsStore as PermissionsStore;
    const { id } = campaignTableRow;
    const deleteExpertiseCampaignQuery = expertiseCampaignConfig.delete(id);

    const renderActionItems = useCallback(
        (hideMenu?: () => void): ReactNode[] => {
            return [
                <CampaignDeleteButton
                    key="actionBtns"
                    campaignTableRow={campaignTableRow}
                    reloadData={reloadData}
                    hideMenu={hideMenu}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    rowErrorHandler={rowErrorHandler}
                />,
            ];
        },
        [campaignTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler],
    );

    return (
        <AuthorizationCheck {...deleteExpertiseCampaignQuery}>
            <MenuButton renderMenuItems={renderActionItems} />
        </AuthorizationCheck>
    );
});
