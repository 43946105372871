import { LocalesTree } from '@platform/front-utils';

const pfTemplate: LocalesTree = {
    actions: {
        download: 'Download template',
    },
    create: 'Create template',
    edit: 'Edit template',
    fields: {
        actions: 'Actions',
        created: 'Created',
        file: 'File',
        title: 'Title',
    },
    listTitle: 'Templates of printed forms',
};

export default pfTemplate;
