import { ExpertiseFeatures } from '../../types';
import tszdLogo from '../../resources/images/tszd-logo.svg';

const logoLocaleConfig = {
    alt: 'Растим детей',
    image: tszdLogo,
    style: { minHeight: 70 },
};

const config: ExpertiseFeatures = {
    headerLogo: {
        isEnabled: true,
        locales: {
            en: logoLocaleConfig,
            ru: logoLocaleConfig,
        },
    },
    phoneNumber: false,
    userAdding: false,
    userAssignPassword: false,
    withSubjectFilesTab: false,
};

export default config;
