import { Box, Fade, Paper, Popper, styled } from '@mui/material';
import React, { useState } from 'react';

const FormTitleTooltipArea = styled(Box)`
    background: transparent;
    height: 38px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    padding: ${({ theme }): string => `${theme.spacing(4)}px 0 0 ${theme.spacing(2)}px`};
    right: 0;
    left: 0;
`;

export type ExpertiseTaskFormTitleInfoProps = {
    title: string;
};

export const ExpertiseTaskFormTitleInfo = (props: ExpertiseTaskFormTitleInfoProps): JSX.Element => {
    const { title } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const setAnchorElCallback = (e: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(e.currentTarget);
    };

    const deleteAnchorEl = (): void => {
        setAnchorEl(null);
    };

    return (
        <FormTitleTooltipArea onPointerEnter={setAnchorElCallback} onPointerLeave={deleteAnchorEl}>
            <Popper
                open={!!title && !!anchorEl}
                anchorEl={anchorEl}
                transition
                style={{ zIndex: 1102, maxWidth: '400px' }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Paper>
                            <Box padding={3}>{title}</Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </FormTitleTooltipArea>
    );
};
