import { DialogContent, Grid } from '@mui/material';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    FieldWithServerError,
} from '@platform/front-ui';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from '../../../../hooks';
import { CreateSubjectModel } from '../../../../models';
import { NewSubject } from '../../../../types';

export type SubjectCreateDialogFormProps = {
    submitButton: DialogSubmitButtonConfig;
    cancelButton: DialogCancelButtonConfig;
    model: CreateSubjectModel;
};

export const SubjectCreateDialogForm = observer((props: SubjectCreateDialogFormProps): JSX.Element => {
    const { submitButton, cancelButton, model } = props;

    const { values, setFieldValue } = useFormikContext<NewSubject>();
    const { campaign } = values;

    useEffect(() => {
        campaign &&
            subjectStore.getSubjectNumber(campaign.id).then((identifier) => {
                setFieldValue('identifier', identifier);
            });
    }, [campaign]);

    const { subjectStore } = useStore();

    const { formatMessage } = useIntl();

    const { categoryOptions, campaignOptions, loadCampaignOptions } = model;

    const categoryLabel = formatMessage({ id: 'subject.fields.category' });
    const campaignLabel = formatMessage({ id: 'subject.fields.campaign' });
    const identifierLabel = formatMessage({ id: 'subject.fields.identifier' });
    const noOptionsText = formatMessage({ id: 'common.noOptions' });

    return (
        <Form>
            <DialogContent>
                <Grid container spacing={3} direction="column" justifyContent="center">
                    <Grid item>
                        <AutocompleteField
                            fieldName="category"
                            required={true}
                            disableClearable={true}
                            options={categoryOptions}
                            label={categoryLabel}
                            noOptionsText={noOptionsText}
                        />
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName="campaign"
                            options={campaignOptions}
                            onOpen={loadCampaignOptions}
                            label={campaignLabel}
                            noOptionsText={noOptionsText}
                        />
                    </Grid>
                    <Grid item>
                        <FieldWithServerError name="identifier" label={identifierLabel} required={true} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
        </Form>
    );
});
