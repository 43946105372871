import { ClientBreadcrumbsWithCustomLinks } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { SyslogPage as SyslogPageInj } from '../../../components';
import { ttableDefaultPaths } from '../../../constants';
import { useCampaignPageContext } from '../../../hooks';
import { getCampaignBreadcrumbs } from '../../../utils';

export const CampaignSyslogPage = observer((): JSX.Element => {
    const [SyslogPage] = di([SyslogPageInj], CampaignSyslogPage);

    const intl = useIntl();
    const { id, title } = useCampaignPageContext().model;

    const breadcrumbsLinksData = getCampaignBreadcrumbs(intl, id, title);
    const breadcrumbs = <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />;
    const defaultPath = ttableDefaultPaths.getCampaignSyslog(id);

    return <SyslogPage breadcrumbs={breadcrumbs} entityType="" defaultPath={defaultPath} />;
});
