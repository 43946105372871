import {
    createGetAdminQuery,
    getAccountPermissionsConfig as getAccountPermissionsConfigCore,
    getSystemPermissionsConfig as getSystemPermissionsConfigCore,
    makePermissionsConfigGetter,
} from '@platform/front-core';
import { PermissionEntities, Permissions } from '../../types';

export const getSystemPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => {
        const getAdminQuery = createGetAdminQuery(entities, permissions);
        return {
            ...getSystemPermissionsConfigCore(entities, permissions),
            administration: getAdminQuery,
            accountAdministration: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.AccountAdministration,
                entityId: id,
            }),
            addTemplateExpertise: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.AddTemplateExpertise,
                entityId: id,
            }),
            taskRegistryColumnsManagement: (subjectId?: string) => ({
                permCode: permissions.system.TaskRegistryColumnsManagement,
                entityCode: entities.system,
                entityId: subjectId,
            }),
            addExpertiseSubject: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.AddExpertiseSubject,
                entityId: id,
            }),
            addExpertise: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.AddExpertise,
                entityId: id,
            }),
            editPfTemplate: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.EditPfTemplate,
                entityId: id,
            }),
            createPfTemplate: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.CreatePfTemplate,
                entityId: id,
            }),
            addExpertiseCampaign: (id?: string) => ({
                entityCode: entities.system,
                permCode: permissions.system.AddExpertiseCampaign,
                entityId: id,
            }),
        };
    },
);
export type SystemPermissions = ReturnType<typeof getSystemPermissionsConfig>;

export const getAccountPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        ...getAccountPermissionsConfigCore(entities, permissions),
        updateLogin: (id?: string) => ({
            entityCode: entities.account,
            permCode: permissions.account.UpdateLogin,
            entityId: id,
        }),
        updateUser: (id?: string) => ({
            entityCode: entities.account,
            permCode: permissions.account.Update,
            entityId: id,
        }),
    }),
);
export type AccountPermissions = ReturnType<typeof getAccountPermissionsConfig>;

export const getExpertiseSubjectPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        deleteExpertiseSubject: (subjectId?: string) => ({
            permCode: permissions.expertiseSubject.Delete,
            entityCode: entities.expertiseSubject,
            entityId: subjectId,
        }),
        createExpertise: (subjectId?: string) => ({
            permCode: permissions.expertiseSubject.CreateExpertise,
            entityCode: entities.expertiseSubject,
            entityId: subjectId,
        }),
        downloadFiles: (subjectId?: string) => ({
            permCode: permissions.expertiseSubject.DownloadFiles,
            entityCode: entities.expertiseSubject,
            entityId: subjectId,
        }),
        editExpertiseSubject: (subjectId?: string) => ({
            permCode: permissions.expertiseSubject.Edit,
            entityCode: entities.expertiseSubject,
            entityId: subjectId,
        }),
    }),
);
export type ExpertiseSubjectPermissions = ReturnType<typeof getExpertiseSubjectPermissionsConfig>;

export const getExpertiseTaskPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        updateConclusionPrintForm: (expertiseTaskId?: string) => ({
            permCode: permissions.expertiseTask.UpdateConclusionPrintForm,
            entityCode: entities.expertiseTask,
            entityId: expertiseTaskId,
        }),
        viewConclusion: (subjectId?: string) => ({
            permCode: permissions.expertiseTask.ViewConclusion,
            entityCode: entities.expertiseTask,
            entityId: subjectId,
        }),
        editConclusion: (subjectId?: string) => ({
            permCode: permissions.expertiseTask.EditConclusion,
            entityCode: entities.expertiseTask,
            entityId: subjectId,
        }),
        deleteExpertiseTask: (taskId?: string) => ({
            permCode: permissions.expertiseTask.Delete,
            entityCode: entities.expertiseTask,
            entityId: taskId,
        }),
        editExpertiseTask: (taskId?: string) => ({
            permCode: permissions.expertiseTask.Edit,
            entityCode: entities.expertiseTask,
            entityId: taskId,
        }),
        viewExpertiseTask: (taskId?: string) => ({
            permCode: permissions.expertiseTask.View,
            entityCode: entities.expertiseTask,
            entityId: taskId,
        }),
        setExpert: (taskId?: string) => ({
            permCode: permissions.expertiseTask.SetExpert,
            entityCode: entities.expertiseTask,
            entityId: taskId,
        }),
        viewSubject: (taskId?: string) => ({
            permCode: permissions.expertiseTask.ViewSubject,
            entityCode: entities.expertiseTask,
            entityId: taskId,
        }),
    }),
);
export type ExpertiseTaskPermissions = ReturnType<typeof getExpertiseTaskPermissionsConfig>;

export const getTemplateExpertisePermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        delete: (id?: string) => ({
            entityCode: entities.templateExpertise,
            permCode: permissions.templateExpertise.Delete,
            entityId: id,
        }),
        edit: (id?: string) => ({
            entityCode: entities.templateExpertise,
            permCode: permissions.templateExpertise.Edit,
            entityId: id,
        }),
        editExpertisePlan: (id?: string) => ({
            entityCode: entities.templateExpertise,
            permCode: permissions.templateExpertise.EditExpertisePlan,
            entityId: id,
        }),
    }),
);
export type TemplateExpertisePermissions = ReturnType<typeof getTemplateExpertisePermissionsConfig>;

export const getExpertiseCampaignPermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        addExpertises: (id?: string) => ({
            entityCode: entities.expertiseCampaign,
            permCode: permissions.expertiseCampaign.AddExpertises,
            entityId: id,
        }),
        delete: (id?: string) => ({
            entityCode: entities.expertiseCampaign,
            permCode: permissions.expertiseCampaign.Delete,
            entityId: id,
        }),
        edit: (id?: string) => ({
            entityCode: entities.expertiseCampaign,
            permCode: permissions.expertiseCampaign.Edit,
            entityId: id,
        }),
        view: (id?: string) => ({
            entityCode: entities.expertiseCampaign,
            permCode: permissions.expertiseCampaign.View,
            entityId: id,
        }),
    }),
);
export type ExpertiseCampaignPermissions = ReturnType<typeof getExpertiseCampaignPermissionsConfig>;

export const getExpertisePermissionsConfig = makePermissionsConfigGetter(
    (entities: PermissionEntities, permissions: Permissions) => ({
        view: (id?: string) => ({
            entityCode: entities.expertise,
            permCode: permissions.expertise.View,
            entityId: id,
        }),
        updateCurator: (id?: string) => ({
            entityCode: entities.expertise,
            permCode: permissions.expertise.UpdateCurator,
            entityId: id,
        }),
        delete: (id?: string) => ({
            entityCode: entities.expertise,
            permCode: permissions.expertise.Delete,
            entityId: id,
        }),
        createTask: (id?: string) => ({
            entityCode: entities.expertise,
            permCode: permissions.expertise.CreateTask,
            entityId: id,
        }),
    }),
);
export type ExpertisePermissions = ReturnType<typeof getExpertisePermissionsConfig>;
