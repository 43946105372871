import { AuthorizationCheck } from '@platform/front-core';
import { TTable, TTableRowActions, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../constants';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { CategoryOfSubjectsTableRow, RegistryType } from '../../../types';

export type CategoryOfSubjectsRegistryTableProps = {
    setRowActions?: TTableRowActions<CategoryOfSubjectsTableRow>;
};

const registryCode = RegistryType.category;

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
        isWithPresets: false,
    },
};

export const CategoryOfSubjectsRegistryTable = observer((props: CategoryOfSubjectsRegistryTableProps): JSX.Element => {
    const { setRowActions } = props;

    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api, permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'category.categoryListTitle' });

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable<CategoryOfSubjectsTableRow>
                    tableTitle={tableTitle}
                    client={api.mainInfoClient as AxiosInstance}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={ttableDefaultPaths.categories}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                    visibleSettings={visibleSettings}
                />
            )}
        </AuthorizationCheck>
    );
});
