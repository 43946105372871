import { LocalesTree } from '@platform/front-utils';

const expertise: LocalesTree = {
    actions: {
        deleteExpertise: 'Delete expertise',
        editExpertise: 'Edit expertise',
    },
    changeCurator: 'Change the task manager',
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise "{title}"?',
    createDialogFields: {
        expertiseSubject: 'Subject of expertise',
        lifeCycle: 'Expertise lifecycle',
        templateExpertise: 'Template of expertise',
    },
    createExpertise: 'Create expertise',
    createExpertises: 'Create expertises',
    expertiseListTitle: 'Expertise',
    expertiseSubjectsOfCampaign: 'Expertise objects of campaign "{title}" ({number})',
    expertiseTitle: 'Expertise: "{title}"',
    fields: {
        campaign: 'Campaign',
        created: 'Created',
        curator: 'Task manager',
        deadline: 'Deadline',
        expertiseCampaign: 'Expertise campaign',
        expertiseSubject: 'Expertise object',
        expertiseSubjectIdentifier: 'Expertise object number',
        identifier: 'Number',
        state: 'State',
        template: 'Template',
    },
    newExpertise: 'New expertise',
    newExpertises: 'New expertises',
    pagingInfo: 'Total {count, number} expertise. Displayed from {from, number} to {to, number}',
    plan: {
        fields: {
            deadline: 'Deadline',
            deadlineText: '{count, number} {count, plural, one {day} other {days}}',
            numberOfConclusions: 'Number of reports',
            numberOfConclusionsText: 'From {min} to {max} tasks',
            responseTimeForAJob: 'Response time for a job',
            responseTimeForAJobText: '{count, number} {count, plural, one {day} other {days}}',
        },
        listTitle: 'Plan of expertise',
    },
    updatedTasks: 'The list of tasks has been updated',
};

export default expertise;
