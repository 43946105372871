import { AuthorizationCheckQueryOrWithoutCheck, useFetchAllPermissions } from '@platform/front-core';
import { ActionMenuItem, MenuButton } from '@platform/front-ui';
import { RouteParamsDefault, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../../hooks';
import { PermissionsStore } from '../../../../../../stores';
import {
    UserPageFullNameModal as UserPageFullNameModalInj,
    UserPageLoginModal as UserPageLoginModalInj,
    UserPageRolesModal as UserPageRolesModalInj,
} from './components';

export const UserPageHeaderActions = observer((): JSX.Element => {
    const [UserPageFullNameModal] = di([UserPageFullNameModalInj], UserPageHeaderActions);
    const [UserPageLoginModal] = di([UserPageLoginModalInj], UserPageHeaderActions);
    const [UserPageRolesModal] = di([UserPageRolesModalInj], UserPageHeaderActions);

    const { permissionsStore } = useStore().coreRootStore;
    const { accountConfig } = permissionsStore as PermissionsStore;
    const { id } = useParams<RouteParamsDefault>();

    const allPermissionsQueries = useMemo((): AuthorizationCheckQueryOrWithoutCheck[] => {
        return [accountConfig.updateUser(id), accountConfig.updateLogin(id)];
    }, [accountConfig, id]);

    const [allPermissions] = useFetchAllPermissions({ allPermissionsQueries });

    const [isAllowedToUpdateUser, isAllowedToUpdateLogin] = allPermissions;

    const [isFullNameDialogOpen, openFullNameDialog, closeFullNameDialog] = useFlag();
    const [isLoginDialogOpen, openLoginDialog, closeLoginDialog] = useFlag();
    const [isRolesDialogOpen, openRolesDialog, closeRolesDialog] = useFlag();

    const renderMenuItems = useCallback(
        (hideMenu?: VoidFunction): ReactNode[] => {
            return [
                <ActionMenuItem
                    key="editFullName"
                    messageId="user.settings.fullName"
                    hideMenu={hideMenu}
                    onClick={openFullNameDialog}
                />,
                isAllowedToUpdateLogin && (
                    <ActionMenuItem
                        key="editLogin"
                        messageId="user.settings.login"
                        hideMenu={hideMenu}
                        onClick={openLoginDialog}
                    />
                ),
                isAllowedToUpdateUser && (
                    <ActionMenuItem
                        key="editFullRoles"
                        messageId="user.settings.roles"
                        hideMenu={hideMenu}
                        onClick={openRolesDialog}
                    />
                ),
            ];
        },
        [isAllowedToUpdateUser, isAllowedToUpdateLogin, openLoginDialog, openRolesDialog],
    );

    return (
        <React.Fragment>
            <MenuButton renderMenuItems={renderMenuItems} />
            <UserPageFullNameModal open={isFullNameDialogOpen} onCancel={closeFullNameDialog} />
            <UserPageLoginModal open={isLoginDialogOpen} onCancel={closeLoginDialog} />
            <UserPageRolesModal open={isRolesDialogOpen} onCancel={closeRolesDialog} />
        </React.Fragment>
    );
});
