import { FormApi } from '@platform/formiojs-react';
import { FormDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { CategoriesOfSubjectsStore } from '../../stores';
import { CategoryOfSubjectsDTO } from '../../types';
import { MetaInfoModel } from '../metaInfo';

export const categoryOfSubjectsModelObservables = {
    categoriesOfSubjectsStore: observable,

    id: observable,
    title: observable,
    formDTO: observable,
    formApi: observable,
    metaInfo: observable,
    isShowValidation: observable,

    load: action.bound,
    loadCard: action.bound,
    deleteCategory: action.bound,
    setFormApi: action.bound,

    setMainFields: action.bound,
    setIsShowValidation: action.bound,
    dropIsShowValidation: action.bound,
};

export class CategoryOfSubjectsModel {
    protected categoriesOfSubjectsStore: CategoriesOfSubjectsStore;

    id: string;
    title = '';
    formDTO?: FormDTO;
    formApi?: FormApi;
    metaInfo: MetaInfoModel = new MetaInfoModel();
    isShowValidation = false;

    constructor(id: string, categoriesOfSubjectsStore: CategoriesOfSubjectsStore) {
        makeObservable(this, categoryOfSubjectsModelObservables);
        this.id = id;
        this.categoriesOfSubjectsStore = categoriesOfSubjectsStore;
    }

    load(): Promise<void> {
        return this.categoriesOfSubjectsStore.loadCategoryOfSubjectsDTO(this.id).then(this.setMainFields);
    }

    loadCard(dto: CategoryOfSubjectsDTO): void {
        this.title = dto.title;
        this.metaInfo.load(dto.metaInfo);
    }

    deleteCategory(): Promise<void> {
        return this.categoriesOfSubjectsStore.deleteCategoryOfSubjects(this.id);
    }

    setMainFields(dto: CategoryOfSubjectsDTO): void {
        const { formInfo } = dto;
        this.loadCard(dto);
        this.formDTO = formInfo;
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setIsShowValidation(isShowValidation: boolean): void {
        this.isShowValidation = isShowValidation;
    }

    dropIsShowValidation(): void {
        this.isShowValidation = false;
    }
}
