import { EmailOutlined, NotInterested, Update } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
    AppContentContainer,
    Authentication,
    AuthPage,
    ConfirmingEmailNotFoundPageButtons,
    ConsolePage,
    coreClientRoute,
    Footer,
    Header,
    NewPasswordPage,
    NotificationComponent,
    NotificationPage,
    RedirectByRole,
    RegistrationConfirmPage,
} from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { ErrorCode } from '@platform/front-utils';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import {
    CampaignPages as CampaignPagesInj,
    CategoriesOfSubjectsPages as CategoriesOfSubjectsPagesInj,
    ExpertisePages as ExpertisePagesInj,
    ExpertiseTasksPages as ExpertiseTasksPagesInj,
    ExpertiseTaskViewRoutesPages as ExpertiseTaskViewRoutesPagesInj,
    KafkaEventsRegistryPage as KafkaEventsRegistryPageInj,
    PfTemplateListPage as PfTemplateListPageInj,
    SubjectPages as SubjectPagesInj,
    TemplatesOfExpertisePages as TemplatesOfExpertisePagesInj,
    UserListPage as UserListPageInj,
    UserPage as UserPageInj,
} from './pages';
import mexpIcon from './resources/images/mexp-icon.svg';
import { getExpertiseTaskViewRoutes as getExpertiseTaskViewRoutesInj } from './utils';

export const LeadExpertExpertiseTaskViewPageInj = (): JSX.Element => <React.Fragment />;

export const getLeadExpertExpertiseTaskRoutesInj = (): string[] => [];

const wrapperSx: SxStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'nowrap',
};

export const App = observer((): JSX.Element => {
    const [ExpertiseTasksPages] = di([ExpertiseTasksPagesInj], App);
    const [getExpertiseTaskViewRoutes] = di([getExpertiseTaskViewRoutesInj], App);
    const [CampaignPages] = di([CampaignPagesInj], App);
    const [ExpertisePages] = di([ExpertisePagesInj], App);
    const [PfTemplateListPage] = di([PfTemplateListPageInj], App);
    const [SubjectPages] = di([SubjectPagesInj], App);
    const [ExpertiseTaskViewRoutesPages] = di([ExpertiseTaskViewRoutesPagesInj], App);
    const [TemplatesOfExpertisePages] = di([TemplatesOfExpertisePagesInj], App);
    const [CategoriesOfSubjectsPages] = di([CategoriesOfSubjectsPagesInj], App);
    const [UserListPage] = di([UserListPageInj], App);
    const [UserPage] = di([UserPageInj], App);
    const [KafkaEventsRegistryPage] = di([KafkaEventsRegistryPageInj], App);

    const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

    const taskViewRoutes = getExpertiseTaskViewRoutes(withSubjectFilesTab);

    const { formatMessage } = useIntl();

    const notFoundErrorMessage = formatMessage({ id: 'notFoundPage.notExist' });
    const notAllowedErrorMessage = formatMessage({ id: 'common.actionNotAllowed' });
    const loginEditingNotificationMessage = formatMessage({ id: 'authentication.loginEditingRequest' });
    const registrationInfoExpiredMessage = formatMessage({ id: 'authentication.confirmingEmailExpired' });
    const registrationConfirmationMessage = formatMessage({ id: 'authentication.registrationConfirmation' });
    const recoveryLinkSentMessage = formatMessage({ id: 'authentication.recoveryLinkSent' });
    const confirmingEmailNotFoundInfo = formatMessage({ id: 'authentication.confirmingEmailNotFound' });

    const emailOutlinedIcon = <EmailOutlined />;
    const updateIcon = <Update />;
    const notInterestedIcon = <NotInterested />;

    const ConfirmingEmailNotFoundPageBtn = <ConfirmingEmailNotFoundPageButtons />;

    return (
        <Box sx={wrapperSx}>
            <Switch>
                <Route path={coreClientRoute.authPrefix}>
                    <AuthPage logo={mexpIcon} />
                </Route>
                <Route path={coreClientRoute.newPassword} exact>
                    <NewPasswordPage logo={mexpIcon} />
                </Route>
                <Route>
                    <Header />
                    <AppContentContainer>
                        <Switch>
                            <Route
                                path={[
                                    ...taskViewRoutes,
                                    clientRoute.campaigns,
                                    clientRoute.subjects,
                                    clientRoute.categories,
                                    clientRoute.templatesOfExpertise,
                                    clientRoute.expertiseTasks,
                                    clientRoute.expertiseList,
                                    clientRoute.pfTemplateList,
                                    clientRoute.expertiseTasksForCurator,
                                    coreClientRoute.console,
                                    coreClientRoute.users,
                                    coreClientRoute.notFound,
                                    coreClientRoute.notAllowed,
                                    clientRoute.kafkaEvents,
                                ]}
                            >
                                <Authentication>
                                    <Switch>
                                        <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
                                            <ExpertiseTaskViewRoutesPages withSubjectFilesTab={withSubjectFilesTab} />
                                        </Route>
                                        <Route path={clientRoute.campaigns}>
                                            <CampaignPages />
                                        </Route>
                                        <Route path={clientRoute.subjects}>
                                            <SubjectPages />
                                        </Route>
                                        <Route path={clientRoute.categories}>
                                            <CategoriesOfSubjectsPages />
                                        </Route>
                                        <Route path={clientRoute.templatesOfExpertise}>
                                            <TemplatesOfExpertisePages />
                                        </Route>
                                        <Route path={clientRoute.expertiseTasks}>
                                            <ExpertiseTasksPages />
                                        </Route>
                                        <Route path={clientRoute.expertiseList}>
                                            <ExpertisePages />
                                        </Route>
                                        <Route path={clientRoute.pfTemplateList}>
                                            <PfTemplateListPage />
                                        </Route>
                                        <Route path={clientRoute.expertiseTasksForCurator}>
                                            <Redirect to={clientRoute.expertiseTasks} />
                                        </Route>

                                        <Route path={coreClientRoute.console}>
                                            <ConsolePage />
                                        </Route>
                                        <Route path={clientRoute.kafkaEvents}>
                                            <KafkaEventsRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.users} exact>
                                            <UserListPage />
                                        </Route>
                                        <Route path={clientRoute.user}>
                                            <UserPage />
                                        </Route>
                                        <Route path={coreClientRoute.notFound} exact>
                                            <NotificationPage
                                                errorCode={ErrorCode.notFound}
                                                notificationText={notFoundErrorMessage}
                                                withActionButtons={true}
                                            />
                                        </Route>
                                        <Route path={coreClientRoute.notAllowed} exact>
                                            <NotificationPage
                                                errorCode={ErrorCode.notAllowed}
                                                notificationText={notAllowedErrorMessage}
                                                withActionButtons={true}
                                            />
                                        </Route>
                                    </Switch>
                                </Authentication>
                            </Route>

                            <Route path={coreClientRoute.registrationConfirm} exact>
                                <RegistrationConfirmPage />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoSuccess}>
                                <NotificationPage
                                    icon={emailOutlinedIcon}
                                    notificationText={registrationConfirmationMessage}
                                />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoExpired}>
                                <NotificationPage icon={updateIcon} notificationText={registrationInfoExpiredMessage} />
                            </Route>
                            <Route path={coreClientRoute.emailNotification}>
                                <NotificationPage
                                    icon={emailOutlinedIcon}
                                    notificationText={loginEditingNotificationMessage}
                                />
                            </Route>
                            <Route path={coreClientRoute.recoveryPasswordInfoSuccess}>
                                <NotificationPage icon={emailOutlinedIcon} notificationText={recoveryLinkSentMessage} />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoNotFound}>
                                <NotificationPage
                                    icon={notInterestedIcon}
                                    notificationText={confirmingEmailNotFoundInfo}
                                    withActionButtons
                                    customActionButtons={ConfirmingEmailNotFoundPageBtn}
                                />
                            </Route>

                            <Route path={clientRoute.root} exact>
                                <Authentication>
                                    <RedirectByRole />
                                </Authentication>
                            </Route>
                            <Route>
                                <Redirect to={coreClientRoute.notFound} />
                            </Route>
                        </Switch>
                    </AppContentContainer>
                    <Footer />
                </Route>
            </Switch>
            <NotificationComponent />
        </Box>
    );
});
