import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getTaskBreadcrumbs = (intl: IntlShape, taskId: string, taskIdentifier: string): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.expertiseTasks,
            routeTitle: formatMessage({ id: 'breadcrumbs.tasks' }),
        },
        {
            to: taskId ? generatePath(clientRoute.expertiseTask, { id: taskId }) : '',
            routeTitle: taskIdentifier,
        },
        {
            to: clientRoute.expertiseSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
