import { Dialog, DialogContent } from '@mui/material';
import { ActionItemModalProps } from '@platform/front-types';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    ServerErrorHelper,
} from '@platform/front-ui';
import {
    disableSubmitOnEnterKeyPress,
    stopPropagation,
    useAntiDoubleClick,
    useFlag,
    useYup,
} from '@platform/front-utils';
import { LangFieldGeneralProps, MultiLangFieldGroup, titlesYupSchema } from '@platform/multi-lang-field';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore, useUserPageContext } from '../../../../../../../hooks';
import { FullNameEditModel } from '../../../../../../../models';
import { FullNameFields, FullNameGroupFields, FullNameSettings } from '../../../../../../../types';

export const UserPageFullNameModal = observer((props: ActionItemModalProps): JSX.Element => {
    const { open, onCancel, hideMenu } = props;
    const { userStore } = useStore();
    const intl = useIntl();
    const { formatMessage } = intl;

    const { userModel } = useUserPageContext();
    const { id, load } = userModel;

    const [isNeedFullName, setIsNeedFullNameTrue, setIsNeedFullNameFalse] = useFlag(true);

    const model = useMemo(() => new FullNameEditModel(userStore, id), [userStore, id]);
    const { loadFullName, serverErrors, setFullName, lastName, middleName, firstName } = model;
    const { dropServerFormErrors, serverFormErrors } = serverErrors;
    const { fullName } = FullNameFields;
    const { middleName: middleNameField, lastName: lastNameField, firstName: firstNameField } = FullNameGroupFields;
    const serverFullNameError = serverFormErrors[fullName];

    useEffect(() => {
        if (isNeedFullName && open) {
            loadFullName().then(setIsNeedFullNameFalse);
        }
    }, [open]);

    const onClose = (): void => {
        onCancel();
        dropServerFormErrors();
    };

    const initialValues: FullNameSettings = {
        lastName,
        firstName,
        middleName,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        lastName: titlesYupSchema(Yup, true).min(1),
        firstName: titlesYupSchema(Yup, true).min(1),
        middleName: titlesYupSchema(Yup, false),
    });

    const onSuccess = (): Promise<void> => {
        return load().then(() => {
            onClose();
            hideMenu && hideMenu();
        });
    };

    const onSubmit = (values: FullNameSettings): Promise<void> => {
        return setFullName(values, onSuccess).then(setIsNeedFullNameTrue);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);

    const fieldGroup: LangFieldGeneralProps[] = [
        {
            fieldName: lastNameField,
            label: formatMessage({ id: 'user.fullNameFields.lastName' }),
        },
        {
            fieldName: firstNameField,
            label: formatMessage({ id: 'user.fullNameFields.firstName' }),
        },

        {
            fieldName: middleNameField,
            label: formatMessage({ id: 'user.fullNameFields.middleName' }),
            isRequired: false,
        },
    ];

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={open} onKeyDown={stopPropagation}>
            <DialogTitle onCloseClick={onClose}>
                <FormattedMessage id="user.settings.fullName" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <MultiLangFieldGroup fieldGroup={fieldGroup} groupFieldName={fullName} ruEngLangs={true} />
                        <ServerErrorHelper serverError={serverFullNameError} />
                    </DialogContent>
                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
