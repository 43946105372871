import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../../components';
import { CampaignControlPanelLeft as CampaignControlPanelLeftInj } from './CampaignControlPanelLeft';
import { CampaignControlPanelRight as CampaignControlPanelRightInj } from './CampaignControlPanelRight';

export const CampaignControlPanel = observer((): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], CampaignControlPanel);
    const [CampaignControlPanelLeft] = di([CampaignControlPanelLeftInj], CampaignControlPanel);
    const [CampaignControlPanelRight] = di([CampaignControlPanelRightInj], CampaignControlPanel);

    const leftSide = <CampaignControlPanelLeft />;
    const rightSide = <CampaignControlPanelRight />;

    return <BottomControlPanel left={leftSide} right={rightSide} />;
});
