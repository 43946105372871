import { Box, Dialog, DialogContent, Grid, MenuItem, Typography } from '@mui/material';
import { NewPasswordDTO, NewPasswordFields, PasswordField } from '@platform/front-core';
import { DialogActions, DialogCancelButtonConfig, DialogSubmitButtonConfig, DialogTitle } from '@platform/front-ui';
import { stopPropagation, useAntiDoubleClick, useFlag, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore } from '../../../../hooks';
import { UserInfoMessageValues } from '../../../../types';

export type UserAssignPasswordDialogProps = {
    tabIndex: number;
    id: string;
    userInfoMessageValues: UserInfoMessageValues;
    hideMenu: () => void;
};

const initialValues: NewPasswordDTO = {
    newPassword: '',
    repeatPassword: '',
};

export const UserAssignPasswordDialog = observer((props: UserAssignPasswordDialogProps): JSX.Element => {
    const { id, tabIndex, userInfoMessageValues, hideMenu } = props;
    const rootStore = useStore();
    const { userStore } = rootStore;
    const { newPassword, repeatPassword } = NewPasswordFields;
    const [isModalOpen, openModal, closeModal] = useFlag();
    const intl = useIntl();
    const { formatMessage } = intl;
    const { Yup } = useYup();

    const newPasswordLabel = formatMessage({ id: 'authentication.newPassword' });
    const repeatPasswordLabel = formatMessage({ id: 'authentication.repeatPassword' });
    const passwordsNotMatchErrorMessage = formatMessage({ id: 'authentication.passwordsMustMatch' });
    const commonSaveText = formatMessage({ id: 'common.save' });

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().min(8).required(),
        repeatPassword: Yup.string()
            .min(8)
            .required()
            .oneOf([Yup.ref('newPassword'), null], passwordsNotMatchErrorMessage),
    });

    const closeAssignPasswordDialog = (): void => {
        closeModal();
        hideMenu();
    };

    const submitHandler = (formValues: NewPasswordDTO): Promise<void> => {
        const { newPassword } = formValues;
        return userStore.assignUserPassword(id, newPassword).then(closeAssignPasswordDialog);
    };

    const [isSending, endIcon, onSubmit] = useAntiDoubleClick(submitHandler);

    const cancelButton: DialogCancelButtonConfig = {
        onClick: closeAssignPasswordDialog,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: commonSaveText,
    };

    return (
        <React.Fragment>
            <MenuItem dense component="button" tabIndex={tabIndex} onClick={openModal}>
                <FormattedMessage id="user.passwordAssignment" />
            </MenuItem>
            <Dialog fullWidth maxWidth="sm" open={isModalOpen} onKeyDown={stopPropagation}>
                <DialogTitle onCloseClick={closeAssignPasswordDialog}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="user.editPasswordDialogTitle" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                    disableClearable
                >
                    <Form>
                        <DialogContent>
                            <Grid container spacing={2} direction="column" justifyContent="center">
                                <Grid item>
                                    <FormattedMessage
                                        id="user.phrases.editPasswordDialogInfo"
                                        values={userInfoMessageValues}
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        name={newPassword}
                                        label={newPasswordLabel}
                                        required
                                        isWithHelperText
                                    />
                                </Grid>
                                <Grid item>
                                    <PasswordField name={repeatPassword} label={repeatPasswordLabel} required />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                    </Form>
                </Formik>
            </Dialog>
        </React.Fragment>
    );
});
