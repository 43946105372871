import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../components';
import { ExpertiseControlPanelLeft as ExpertiseControlPanelLeftInj } from './ExpertiseControlPanelLeft';
import { ExpertiseControlPanelRight as ExpertiseControlPanelRightInj } from './ExpertiseControlPanelRight';

export const ExpertiseControlPanel = observer((): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], ExpertiseControlPanel);
    const [ExpertiseControlPanelLeft] = di([ExpertiseControlPanelLeftInj], ExpertiseControlPanel);
    const [ExpertiseControlPanelRight] = di([ExpertiseControlPanelRightInj], ExpertiseControlPanel);

    const leftSide = <ExpertiseControlPanelLeft />;
    const rightSide = <ExpertiseControlPanelRight />;

    return <BottomControlPanel left={leftSide} right={rightSide} />;
});
