import { RenderTransitionDialogProps, TransitionsModel } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { ExpertiseTaskTransitionCode } from '../../types';
import {
    ExpertiseTaskInviteSentToAcceptDialog as ExpertiseTaskInviteSentToAcceptDialogInj,
    ExpertiseTaskInviteToRejectDialog as ExpertiseTaskInviteToRejectDialogInj,
} from './invite';
import { LifeCycleTransitionConfirmationDialog as LifeCycleTransitionConfirmationDialogInj } from './LifeCycleTransitionConfirmationDialog';
import { ExpertiseTaskRejectWithReasonDialog as ExpertiseTaskRejectWithReasonDialogInj } from './reject';

export type ExpertiseTaskTransitionDialogProps = RenderTransitionDialogProps & {
    expertiseTaskTransitionsModel: TransitionsModel;
};

export const TransitionDialog = observer((props: ExpertiseTaskTransitionDialogProps): JSX.Element => {
    const { expertiseTaskTransitionsModel } = props;
    const [ExpertiseTaskInviteSentToAcceptDialog] = di([ExpertiseTaskInviteSentToAcceptDialogInj], TransitionDialog);
    const [ExpertiseTaskInviteToRejectDialog] = di([ExpertiseTaskInviteToRejectDialogInj], TransitionDialog);
    const [LifeCycleTransitionConfirmationDialog] = di([LifeCycleTransitionConfirmationDialogInj], TransitionDialog);
    const [ExpertiseTaskRejectWithReasonDialog] = di([ExpertiseTaskRejectWithReasonDialogInj], TransitionDialog);

    const { formatMessage } = useIntl();

    const { expertiseTaskStore } = useStore();
    const { sendToReject, sendToRework } = expertiseTaskStore;
    const { getTransitions } = expertiseTaskTransitionsModel;

    const { dialogComponentCode } = props.transition.params;

    const returnForRevisionMessage = formatMessage({ id: 'expertiseTask.actions.returnForRevision' });
    const rejectMessage = formatMessage({ id: 'expertiseTask.invites.reject' });

    switch (dialogComponentCode) {
        case ExpertiseTaskTransitionCode.expertiseTaskInviteSentToAccept:
            return <ExpertiseTaskInviteSentToAcceptDialog {...props} />;
        case ExpertiseTaskTransitionCode.expertiseTaskInviteToReject:
            return <ExpertiseTaskInviteToRejectDialog {...props} />;
        case ExpertiseTaskTransitionCode.taskCheckToTaskRework:
            return (
                <ExpertiseTaskRejectWithReasonDialog
                    expertiseTaskFormatMessage={returnForRevisionMessage}
                    sendMethod={sendToRework}
                    getTransitions={getTransitions}
                    {...props}
                />
            );
        case ExpertiseTaskTransitionCode.expertiseTaskConclusionReject:
            return (
                <ExpertiseTaskRejectWithReasonDialog
                    expertiseTaskFormatMessage={rejectMessage}
                    sendMethod={sendToReject}
                    getTransitions={getTransitions}
                    {...props}
                />
            );
        default:
            return <LifeCycleTransitionConfirmationDialog {...props} />;
    }
});
