import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../components';
import { TemplateOfExpertiseControlPanelLeft as TemplateOfExpertiseControlPanelLeftInj } from './TemplateOfExpertiseControlPanelLeft';
import { TemplateOfExpertiseControlPanelRight as TemplateOfExpertiseControlPanelRightInj } from './TemplateOfExpertiseControlPanelRight';

export const TemplateOfExpertiseControlPanel = observer((): JSX.Element => {
    const [TemplateOfExpertiseControlPanelLeft] = di(
        [TemplateOfExpertiseControlPanelLeftInj],
        TemplateOfExpertiseControlPanel,
    );
    const [TemplateOfExpertiseControlPanelRight] = di(
        [TemplateOfExpertiseControlPanelRightInj],
        TemplateOfExpertiseControlPanel,
    );
    const [BottomControlPanel] = di([BottomControlPanelInj], TemplateOfExpertiseControlPanel);

    const leftSide = <TemplateOfExpertiseControlPanelLeft />;

    const rightSide = <TemplateOfExpertiseControlPanelRight />;

    return <BottomControlPanel left={leftSide} right={rightSide} />;
});
