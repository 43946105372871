import { action, makeObservable, observable } from 'mobx';
import { CampaignExpertise, CampaignStatisticDTO, CampaignSubjects, CampaignTasks } from '../../types';

export const campaignStatisticModelObservables = {
    subjects: observable,
    expertises: observable,
    tasks: observable,

    load: action.bound,
};

export class CampaignStatisticModel {
    subjects: CampaignSubjects = {
        total: 0,
        active: 0,
        withExpertise: 0,
        withoutExpertise: 0,
    };
    expertises: CampaignExpertise = {
        total: 0,
        active: 0,
        done: 0,
        canceled: 0,
    };
    tasks: CampaignTasks = {
        total: 0,
        withoutExpert: 0,
        responseWaiting: 0,
        startWaiting: 0,
        active: 0,
        overdue: 0,
        done: 0,
        notCompleted: 0,
    };

    constructor() {
        makeObservable(this, campaignStatisticModelObservables);
    }

    load(dto: CampaignStatisticDTO): void {
        this.subjects = dto.subjects;
        this.expertises = dto.expertise;
        this.tasks = dto.tasks;
    }
}
