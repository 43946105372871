import { ModalProps } from '@platform/front-types';
import { ActionMenuItem, ConfirmationDialog } from '@platform/front-ui';
import { PromiseVoidFunction, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

export type DeleteActionMenuItemProps = Omit<ModalProps, 'open' | 'onCancel'> & {
    id?: string;
    onConfirm: PromiseVoidFunction;
    onOpen?: VoidFunction;
    onClose?: VoidFunction;
    title: ReactNode;
    message?: ReactNode;
};

export const DeleteActionMenuItem = observer((props: DeleteActionMenuItemProps): JSX.Element => {
    const { onConfirm, onOpen, onClose, title, message, id = 'delete', ...dialogProps } = props;
    const [isDialogOpen, openDialog, closeDialog] = useFlag();

    const onDialogOpen = (): void => {
        openDialog();
        onOpen && onOpen();
    };

    const onDialogClose = (): void => {
        closeDialog();
        onClose && onClose();
    };

    const onDialogConfirm = (): Promise<void> => {
        return onConfirm().finally(() => {
            closeDialog();
            onClose && onClose();
        });
    };

    return (
        <React.Fragment>
            <ActionMenuItem messageId="common.delete" onClick={onDialogOpen} />
            <ConfirmationDialog
                id={id}
                open={isDialogOpen}
                onCancel={onDialogClose}
                onConfirm={onDialogConfirm}
                title={title}
                message={message}
                keepMounted
                {...dialogProps}
            />
        </React.Fragment>
    );
});
