import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { registryPageStyles } from '@platform/front-core';
import { useAntiDoubleClick } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { CategoryOfSubjectsTableRow } from '../../../types';
import { CategoriesOfSubjectsRegistryActionsBtns as CategoriesOfSubjectsRegistryActionsBtnsInj } from './CategoryOfSubjectsRegistryActionsBtns';
import { CategoryOfSubjectsRegistryTable as CategoryOfSubjectsRegistryTableInj } from './CategoryOfSubjectsRegistryTable';

export const categoriesOfSubjectsListPageSetRowActionsInj: TTableRowActions<CategoryOfSubjectsTableRow> = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [CategoriesOfSubjectsRegistryActionsBtns] = di(
        [CategoriesOfSubjectsRegistryActionsBtnsInj],
        CategoriesOfSubjectsListPage,
    );

    return (
        <CategoriesOfSubjectsRegistryActionsBtns
            categoriesOfSubjectsTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const CategoriesOfSubjectsListPage = observer((): JSX.Element => {
    const [CategoryOfSubjectsRegistryTable] = di([CategoryOfSubjectsRegistryTableInj], CategoriesOfSubjectsListPage);

    const { categoriesOfSubjectsStore, history } = useStore();

    const createCategoryOfSubjects = useCallback(async (): Promise<void> => {
        const id = await categoriesOfSubjectsStore.createCategoryOfSubjects();
        history.push(generatePath(clientRoute.categoryCreate, { id }));
    }, [history, generatePath]);

    const [isLoading, endIcon, handleSubmit] = useAntiDoubleClick(createCategoryOfSubjects);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="category.categoryListTitle" />
                        </Typography>
                        <Grid item>
                            <Button
                                disabled={isLoading}
                                endIcon={endIcon}
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                <FormattedMessage id="category.createCategory" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <CategoryOfSubjectsRegistryTable setRowActions={categoriesOfSubjectsListPageSetRowActionsInj} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
