import { Alert, Box, Link } from '@mui/material';
import { Form, FormApi, FormioSidebarStore, validateEditPage } from '@platform/formiojs-react';
import { AuthorizationCheckQueryOrWithoutCheck } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useStore } from '../../hooks';
import { AuthorizedRoute as AuthorizedRouteInj } from '../AuthorizedRoute';

type TotObjectFormRouteParams = {
    id: string;
};

export type TotObjectFormProps = {
    formName: string;
    formioSidebarStore?: FormioSidebarStore;

    editPath: string;
    readPath: string;
    createPath: string;
    editAuthProps: AuthorizationCheckQueryOrWithoutCheck;

    showReadonlyValidation: boolean;

    formDTO: FormDTO;
    formApi?: FormApi;
    onFormReady(form: FormApi): void;
    onFormChange?: (isChanged: boolean) => void;
    hideReadonlyValidation(): void;
};

const alertLinkStyles: CSSProperties = { display: 'inline' };

export const TotObjectForm = observer((props: TotObjectFormProps): JSX.Element => {
    const [AuthorizedRoute] = di([AuthorizedRouteInj], TotObjectForm);

    const {
        editPath,
        createPath,
        readPath,
        showReadonlyValidation,
        editAuthProps,
        formName,
        onFormReady,
        onFormChange,
        hideReadonlyValidation,
        formDTO,
        formApi,
    } = props;
    const { formioSidebarStore, coreRootStore } = useStore();
    const { intlStore } = coreRootStore;
    const history = useHistory();
    const { id } = useParams<TotObjectFormRouteParams>();

    const onFormReadyCallback = (form: FormApi): void => {
        formioSidebarStore && formioSidebarStore.initSidebarItems(formName, form.form);
        validateAfterReadonly();

        onFormReady(form);
    };

    const onFormChangeCallback = (isChanged: boolean): void => {
        formApi && formioSidebarStore && formioSidebarStore.updateItemsVisibility(formName, formApi.form);
        onFormChange && onFormChange(isChanged);
    };

    const goToEditPageAndValidate = (): void => {
        history.push(generatePath(editPath, { id }));
    };

    // todo: отрефакторить эту компоненту: здесь есть renderLink, renderAlert которые судя по всему уже не нужны
    const renderLink = (...chunks: string[]): JSX.Element => {
        return (
            <Link onClick={goToEditPageAndValidate} href="#" style={alertLinkStyles}>
                {chunks}
            </Link>
        );
    };

    const renderAlert = (): JSX.Element => {
        return (
            <Box mb={5}>
                <Alert elevation={0} variant="filled" severity="error">
                    <FormattedMessage
                        id="common.readonlyValidationMsg"
                        values={{
                            a: renderLink,
                        }}
                    />
                </Alert>
            </Box>
        );
    };

    const validateAfterReadonly = (): void => {
        // Этот метод вызывается, когда после валидации в текстовом виде переходишь в режим редактирования.
        // Это улучшение по сравнению с демо стендом, т.к. в редактирование в данном случае переходят как раз для того,
        // чтобы исправить ошибки
        if (showReadonlyValidation) {
            validateEditPage(formApi, formioSidebarStore, formName).finally(() => {
                hideReadonlyValidation();
            });
        }
    };

    const isViewing = !!useRouteMatch({ path: readPath, exact: true });
    const isEditing = !!useRouteMatch(editPath);
    const isCreating = !!useRouteMatch(createPath);

    return (
        <React.Fragment>
            <Switch>
                <AuthorizedRoute path={[editPath, createPath]} key={editPath} exact {...editAuthProps} />
                <Route path={readPath} key={readPath}>
                    {showReadonlyValidation && renderAlert()}
                </Route>
            </Switch>
            {(isViewing || isEditing || isCreating) && (
                <Form
                    formDTO={formDTO}
                    multiLang={true}
                    readOnly={isViewing}
                    intlStore={intlStore}
                    ownerEntityId={id}
                    onFormReady={onFormReadyCallback}
                    setFormIsChanged={onFormChangeCallback}
                />
            )}
        </React.Fragment>
    );
});
