import { Grid } from '@mui/material';
import { AuthorizationCheck, ObjectContentLayoutProps, ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ServiceInfoPanel as ServiceInfoPanelInj } from '../../../components';
import { ExpertisePageContext, ExpertisePageContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { ExpertiseModel, ExpertiseTasksListByExpertiseModel, PlanEntryListModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { ExpertisePlan as ExpertisePlanInj } from '../../templates-of-expertise';
import { ExpertiseSyslogPage as ExpertiseSyslogPageInj } from '../expertise-syslog';
import { ExpertiseControlPanel as ExpertiseControlPanelInj } from './expertise-control-panel';
import { ExpertiseHeader as ExpertiseHeaderInj } from './expertise-header';
import { ExpertiseTasksTable as ExpertiseTasksTableInj } from './ExpertiseTasksTable';

export type ExpertisePageRouteParams = {
    id: string;
};

export const ExpertisePage = observer((): JSX.Element => {
    const [ExpertiseSyslogPage] = di([ExpertiseSyslogPageInj], ExpertisePage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], ExpertisePage);
    const [ExpertisePlan] = di([ExpertisePlanInj], ExpertisePage);
    const [ExpertiseControlPanel] = di([ExpertiseControlPanelInj], ExpertisePage);
    const [ExpertiseHeader] = di([ExpertiseHeaderInj], ExpertisePage);
    const [ExpertiseTasksTable] = di([ExpertiseTasksTableInj], ExpertisePage);

    const rootStore = useStore();
    const { expertiseStore, coreRootStore } = rootStore;
    const { locale } = useIntl();
    const { id } = useParams<ExpertisePageRouteParams>();

    const { permissionsStore } = coreRootStore;
    const { entities, permissions, systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const model = useMemo<ExpertiseModel>(
        (): ExpertiseModel => new ExpertiseModel(id, expertiseStore),
        [id, expertiseStore],
    );
    const planEntryListModel = useMemo<PlanEntryListModel>(
        (): PlanEntryListModel => new PlanEntryListModel(id, expertiseStore, rootStore),
        [id, expertiseStore, rootStore],
    );
    const expertiseTasksListByExpertiseModel = useMemo<ExpertiseTasksListByExpertiseModel>(
        (): ExpertiseTasksListByExpertiseModel => new ExpertiseTasksListByExpertiseModel(id, rootStore),
        [id, rootStore],
    );

    const { load, metaInfo } = model;

    useEffect(() => {
        load();
    }, [id, locale]);

    const pageContextValue: ExpertisePageContextType = {
        model,
        planEntryListModel,
        expertiseTasksListByExpertiseModel,
    };

    const header = <ExpertiseHeader />;

    const content: JSX.Element = (
        <Grid container direction="column" wrap="nowrap" overflow="auto">
            <Grid item padding={2}>
                <Grid item>
                    <AuthorizationCheck {...administrationQuery}>
                        <Grid item>
                            <ServiceInfoPanel metaInfoModel={metaInfo} withForm={false} elevation={1} />
                        </Grid>
                    </AuthorizationCheck>
                    <Grid item>
                        <ExpertisePlan
                            createPath={clientRoute.expertisePlanEntryCreate}
                            editPath={clientRoute.expertisePlanEntryEdit}
                            ownerPagePath={clientRoute.expertise}
                            ownerEntityCode={entities.expertise}
                            editPermission={permissions.expertise.EditPlan}
                            model={planEntryListModel}
                        />
                    </Grid>
                    <Grid item>
                        <ExpertiseTasksTable />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    const footer = <ExpertiseControlPanel />;

    const contentLayout: ObjectContentLayoutProps = {
        content,
        contentWrapperProps: {
            overflow: 'auto',
        },
        footer,
    };

    const objectLayoutProps: ObjectLayoutProps = {
        header,
        contentLayout,
    };

    return (
        <ExpertisePageContext.Provider value={pageContextValue}>
            <Switch>
                <Route path={clientRoute.expertiseSyslog}>
                    <ExpertiseSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout {...objectLayoutProps} />
                </Route>
            </Switch>
        </ExpertisePageContext.Provider>
    );
});
