import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    logoAlt: 'Сколково logo',
    productDescription:
        'Решение для поиска, отбора и интенсивного развития лучших производственных и цифровых технологий',
    title: 'Цифровая платформа MineTech',
};

export default startPage;
