import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import React, { ReactNode } from 'react';

export const renderAutoCompleteInput = (
    label: string,
    required: boolean,
    errorText?: string,
): ((params: AutocompleteRenderInputParams) => ReactNode) => {
    return (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            required={required}
            label={label}
            variant="outlined"
            error={!!errorText}
            helperText={errorText}
        />
    );
};
