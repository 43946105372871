import { DialogContent, Grid, TextField as MUITextField } from '@mui/material';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    FieldLabel,
    FieldWithServerError,
} from '@platform/front-ui';
import { CodeTitle } from '@platform/front-utils';
import { Form, FormikProps, FormikValues } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, CheckboxWithLabel, TextField } from 'formik-mui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { userMessages } from '../../../../customization';

const roles = 'roles';

const getAutocompleteOptionLabel = (option: CodeTitle): string => {
    return option.title;
};

const getAutocompleteOptionSelected = (option: CodeTitle, value: CodeTitle): boolean => {
    return option.code === value.code;
};

const renderInput = (formik: FormikProps<FormikValues>) => {
    return function AutocompleteInput(params: AutocompleteRenderInputParams): JSX.Element {
        const { formatMessage } = useIntl();

        const touchedRoles = formik.touched[roles];
        const errorsRoles = formik.errors[roles];

        const error = touchedRoles && !!errorsRoles;
        const helperText = touchedRoles && errorsRoles;

        const textFieldLabel = <FieldLabel required text={formatMessage({ id: 'user.fields.roles' })} />;

        return (
            <MUITextField
                {...params}
                name={roles}
                error={error}
                helperText={helperText}
                label={textFieldLabel}
                variant="outlined"
            />
        );
    };
};

export const userAddForm = (
    userRoleList: CodeTitle[],
    setModalIsClosed: VoidFunction,
    submitButton: DialogSubmitButtonConfig,
) => {
    return function UserAddFormikForm(formik: FormikProps<FormikValues>): JSX.Element {
        const { formatMessage } = useIntl();

        const checkBoxLabel = {
            label: <FormattedMessage {...userMessages.langUser} />,
        };

        const autoCompleteInput = renderInput(formik);

        const emailLabel = <FieldLabel required text={formatMessage({ id: 'authentication.email' })} />;
        const lastNameRequiredLabel = <FieldLabel required text={formatMessage({ id: 'authentication.lastName' })} />;
        const firstNameRequiredLabel = <FieldLabel required text={formatMessage({ id: 'authentication.firstName' })} />;
        const middleNameLabel = formatMessage({ id: 'authentication.middleName' });
        const firstNameLabel = formatMessage({ id: 'authentication.firstName' });
        const lastNameLabel = formatMessage({ id: 'authentication.lastName' });

        const cancelButton: DialogCancelButtonConfig = {
            onClick: setModalIsClosed,
        };

        return (
            <Form>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <FieldWithServerError name="email" label={emailLabel} />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError
                                multiple
                                name="roles"
                                component={Autocomplete}
                                options={userRoleList}
                                getOptionSelected={getAutocompleteOptionSelected}
                                getOptionLabel={getAutocompleteOptionLabel}
                                renderInput={autoCompleteInput}
                            />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError name="lastName" component={TextField} label={lastNameRequiredLabel} />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError
                                name="firstName"
                                component={TextField}
                                label={firstNameRequiredLabel}
                            />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError name="middleName" component={TextField} label={middleNameLabel} />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="englishUser"
                                Label={checkBoxLabel}
                            />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError name="enLastName" component={TextField} label={lastNameLabel} />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError name="enFirstName" component={TextField} label={firstNameLabel} />
                        </Grid>
                        <Grid item>
                            <FieldWithServerError name="enMiddleName" component={TextField} label={middleNameLabel} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
            </Form>
        );
    };
};
