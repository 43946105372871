import { PromiseVoidFunction, promiseVoidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { CampaignModel } from '../models';

export type CampaignPageContextType = {
    model: CampaignModel;
    onSubmit: PromiseVoidFunction;
    validateForm: PromiseVoidFunction;
};

export const CampaignPageContext = createContext<CampaignPageContextType>({
    model: {} as CampaignModel,
    // todo: Выпилить, когда будет рефакторинг форм формио
    onSubmit: promiseVoidFunction,
    validateForm: promiseVoidFunction,
});
