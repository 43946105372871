import { Dialog, DialogContent } from '@mui/material';
import { inputWithPlaceholderProps, useLocale } from '@platform/front-core';
import { ActionItemModalProps } from '@platform/front-types';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    FieldLabel,
    FieldWithServerError,
} from '@platform/front-ui';
import {
    disableSubmitOnEnterKeyPress,
    ServerErrorsModel,
    stopPropagation,
    useAntiDoubleClick,
    useYup,
} from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserPageContext } from '../../../../../../../hooks';
import { LoginFields, LoginSettings } from '../../../../../../../types';

export const UserPageLoginModal = observer((props: ActionItemModalProps): JSX.Element => {
    const { open, onCancel, hideMenu } = props;
    const intl = useIntl();
    const { formatMessage } = intl;

    const serverErrors = useMemo(() => new ServerErrorsModel<LoginFields>(), [ServerErrorsModel]);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { userModel } = useUserPageContext();
    const { login, setLogin } = userModel;
    const { login: LoginField } = LoginFields;

    const onClose = (): void => {
        onCancel();
        dropServerFormErrors();
    };

    const initialValues: LoginSettings = {
        login,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        login: Yup.string().required(),
    });

    const onSubmit = async (values: LoginSettings): Promise<void> => {
        dropServerFormErrors();
        return setLogin(values)
            .then(() => {
                onClose();
                hideMenu && hideMenu();
            })
            .catch(setServerFormErrors);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);
    const titleLabel = <FieldLabel required text={formatMessage({ id: 'user.fields.login' })} />;
    const placeholder = useLocale('user.fields.login')[0];
    const helperText = formatMessage({ id: 'user.phrases.loginHelperText' });

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    return (
        <Dialog fullWidth={true} maxWidth="xs" open={open} onKeyDown={stopPropagation}>
            <DialogTitle onCloseClick={onClose}>
                <FormattedMessage id="user.settings.login" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <FieldWithServerError
                            name={LoginField}
                            label={titleLabel}
                            placeholder={placeholder}
                            {...inputWithPlaceholderProps}
                            helperText={helperText}
                            serverError={serverFormErrors[LoginField]}
                        />
                    </DialogContent>
                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
