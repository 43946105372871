import { Box } from '@mui/material';
import {
    defaultInfoSidebarWidth,
    Loader,
    ObjectContentLayoutProps,
    ObjectLayout,
    ObjectLayoutProps,
} from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { UserPageContext } from '../../../contexts';
import { useStore } from '../../../hooks';
import { UserCardModel } from '../../../models';
import { UserPageContextType } from '../../../types';
import { UserSyslogPage as UserSyslogPageInj } from '../user-syslog';
import {
    UserPageHeader as UserPageHeaderInj,
    UserPageMainContent as UserPageMainContentInj,
    UserPageSidebar as UserPageSidebarInj,
} from './components';

export type UserPageRouteParams = {
    id: string;
};

export type UserPageProps = {
    breadcrumbs?: JSX.Element;
};

const infoSidebarWrapperSx: SxStyle = {
    padding: 2,
    // Неизвестно почему, но width не работает. Без этих свойств
    // секция "пляшет" с разной шириной.
    maxWidth: defaultInfoSidebarWidth,
    minWidth: defaultInfoSidebarWidth,
};

export const UserPage = observer((props: UserPageProps): JSX.Element => {
    const [UserSyslogPage] = di([UserSyslogPageInj], UserPage);
    const [UserPageHeader] = di([UserPageHeaderInj], UserPage);
    const [UserPageMainContent] = di([UserPageMainContentInj], UserPage);
    const [UserPageSidebar] = di([UserPageSidebarInj], UserPage);

    const { breadcrumbs } = props;
    const { id } = useParams<UserPageRouteParams>();
    const { userStore, coreRootStore } = useStore();
    const { intlStore } = coreRootStore;
    const model = useMemo(() => new UserCardModel(userStore, id), [userStore, id]);
    const { formInfo, isLoading } = model;

    useEffect(() => {
        model.load();
    }, [id, intlStore.locale]);

    const contextValue: UserPageContextType = {
        userModel: model,
    };

    const header = <UserPageHeader breadcrumbs={breadcrumbs} />;

    const infoSidebar: ObjectLayoutProps['infoSidebar'] = {
        elements: {
            contentElement: <UserPageSidebar />,
            wrapperSx: infoSidebarWrapperSx,
        },
    };

    const contentLayout: ObjectContentLayoutProps = {
        content: (
            <React.Fragment>
                {isLoading ? (
                    <Box margin="auto">
                        <Loader />
                    </Box>
                ) : (
                    <React.Fragment>{formInfo && <UserPageMainContent formDTO={formInfo} />}</React.Fragment>
                )}
            </React.Fragment>
        ),
    };

    return (
        <UserPageContext.Provider value={contextValue}>
            <Switch>
                <Route path={clientRoute.userSyslog}>
                    <UserSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout header={header} contentLayout={contentLayout} infoSidebar={infoSidebar} />
                </Route>
            </Switch>
        </UserPageContext.Provider>
    );
});
