import { FormDTO, LinkType, UserPersonDTO } from '@platform/front-types';
import { CodeTitle, IdTitle } from '@platform/front-utils';
import { TTableRow } from '@platform/ttable';
import { MetaInfoDTO } from './metaInfo';

export type Contacts = {
    phone?: string;
    email?: string;
};

export type Curator = UserPersonDTO & {
    contacts: Contacts;
};

// dto страниц редактирования часть dto просмотра задания на экспертизу
export type ExpertiseTaskDTO = {
    id: string;
    identifier: string;
    expertise: IdTitle;
    metaInfo: MetaInfoDTO;
    formInfo?: FormDTO;
    expert?: UserPersonDTO;
    curator?: Curator;
    activeInviteId?: string;
    expertCandidate?: UserPersonDTO;
    deadline?: string; //date
    viewPoint?: CodeTitle;
    started?: string; //date
};

export type ExpertiseTaskRegistryRow = TTableRow & {
    customData: {
        number: LinkType;
        subject: IdTitle;
        expertise: IdTitle;
        expert: LinkType;
        curator: LinkType;
        expertCandidate?: LinkType;
        taskType: CodeTitle;
    };
};

export enum ExpertiseTaskSidebarTabValue {
    common = 'common',
    service = 'service',
}

export enum ExpertiseTaskViewSwitchVariant {
    task = 'task',
    report = 'report',
    all = 'all',
}

export enum ExpertiseTaskViewTab {
    task = 'task',
    subject = 'subject',
}

export type ExpertiseTaskViewTabSettings = {
    tabCode: ExpertiseTaskViewTab;
    title: JSX.Element;
    path: string;
};

export type ExpertiseTaskEditDateValues = {
    date: Date | null;
};

export type ExpertiseTasksRow = {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expertName: string;
    expertCandidateName: string;
    acceptance?: Date;
    deadline?: Date;
    planAcceptanceDays?: number;
    planAcceptance?: Date;
    planDeadlineDays: number;
    planDeadline?: Date;
    completed?: Date;
    state: string;
    taskType?: CodeTitle;
};

// dto записи в блоке заданий на экспертизу на странице экспертизы
export type ExpertiseTasksRowDTO = {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expert?: UserPersonDTO;
    expertCandidate?: UserPersonDTO;
    acceptance?: string;
    deadline?: string;
    planAcceptanceDays?: number;
    planAcceptance?: string;
    planDeadlineDays: number;
    planDeadline?: string;
    completed?: string;
    state: string;
    taskType?: CodeTitle;
};

export type ExpertiseTaskSubjectDTO = {
    subjectId: string;
    subjectIdentifier: string;
    commonFormInfo: FormDTO;
    hiddenFormInfo?: FormDTO;
};

export type ExpertiseTaskViewDTO = {
    taskInfo: ExpertiseTaskDTO;
    reportsInfo?: ReportsRowDTO[];
};

export type ReportsRowDTO = {
    report: IdTitle;
    deadline?: string; //date
    completed?: string; //date
};

export type ReportsRow = {
    report: IdTitle;
    deadline?: Date;
    completed?: Date;
};
