import { AuthorizationCheck } from '@platform/front-core';
import { TTable, TTableRow, TTableVisibilitySettings } from '@platform/ttable';
import { AxiosInstance } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ttableDefaultPaths } from '../../../constants';
import { useStore } from '../../../hooks';
import { SelectionOfExpertListModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { RegistryType } from '../../../types';
import { SelectionOfExpertTableActions } from './SelectionOfExpertTableActions';

export type SelectionOfExpertTableProps = {
    tableModel: SelectionOfExpertListModel;
};

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
    },
    column: {
        isRowActionsFirst: true,
    },
};

const registryCode = RegistryType.experts;

export const SelectionOfExpertTable = observer((props: SelectionOfExpertTableProps): JSX.Element => {
    const { tableModel } = props;
    const { coreRootStore, userStore } = useStore();
    const { catalogStore, api, permissionsStore } = coreRootStore;
    const { id } = tableModel;
    const { formatMessage } = useIntl();
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const params = {
        taskId: id,
    };

    const setRowActions = useCallback(
        (row: TTableRow): JSX.Element => {
            return <SelectionOfExpertTableActions row={row} tableModel={tableModel} />;
        },
        [tableModel],
    );

    const defaultPath = useMemo(() => ttableDefaultPaths.getExpertiseTask(id), [id]);
    const tableTitle = formatMessage({ id: 'expertiseTask.selectionOfExpert.title' });

    return (
        <AuthorizationCheck {...administrationQuery}>
            {(allowed: boolean): JSX.Element => (
                <TTable
                    tableTitle={tableTitle}
                    registryCode={registryCode}
                    visibleSettings={visibleSettings}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    defaultPath={defaultPath}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                    client={api.mainInfoClient as AxiosInstance}
                    mainApiParams={params}
                />
            )}
        </AuthorizationCheck>
    );
});
