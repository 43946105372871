import { ApiStore } from '@platform/front-core';
import { FullSubmissionWithAdditionalInfo, TransitionDTO } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore, IdTitle } from '@platform/front-utils';
import { AxiosPromise } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { ApiConfigs } from '../apiConfigs';
import { NewSubjectDTO, SubjectDTO } from '../types';
import { RootStore } from './RootStore';

export const subjectStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,

    downloadSubjectFiles: action.bound,
    loadSubject: action.bound,
    loadSubjectsSelectList: action.bound,
    getSubjectNumber: action.bound,
    saveForms: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    getTransitions: action.bound,
    loadCampaignSelectOptions: action.bound,
    loadCategorySelectOptions: action.bound,
    createSubject: action.bound,
    deleteSubject: action.bound,
};

export class SubjectStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, subjectStoreObservables);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.apiConfigs = this.api.apiConfigs as ApiConfigs;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
    }

    downloadSubjectFiles(subjectId: string): AxiosPromise<Blob> {
        return this.api.userActionClient(this.apiConfigs.downloadSubjectFiles(subjectId));
    }

    loadSubject(id: string): Promise<SubjectDTO> {
        return this.api.mainInfoClient(this.apiConfigs.subject(id)).then((r) => r.data);
    }

    loadSubjectsSelectList(): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.subjectsSelectList).then((r) => r.data);
    }

    getSubjectNumber(campaignId?: string): Promise<string> {
        return this.api.client(this.apiConfigs.subjectNumber(campaignId)).then((r) => r.data);
    }

    saveForms(
        subjectId: string,
        commonSubmission: FullSubmissionWithAdditionalInfo,
        hiddenSubmission?: FullSubmissionWithAdditionalInfo,
    ): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.saveSubjectForms(subjectId, commonSubmission, hiddenSubmission))
            .then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, subjectId: string): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.subjectLifeCycleTransition(transitionId, subjectId))
            .then((r) => r.data);
    }

    getTransitions(subjectId: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.getSubjectLifeCycleTransitions(subjectId)).then((r) => r.data);
    }

    loadCampaignSelectOptions(): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.campaignSelectOptions).then((r) => r.data);
    }

    loadCategorySelectOptions(): Promise<IdTitle[]> {
        return this.api.client(this.apiConfigs.categorySelectOptions).then((r) => r.data);
    }

    createSubject(dto: NewSubjectDTO): Promise<string> {
        return this.api.userActionClient(this.apiConfigs.createSubject(dto)).then((r) => r.data.id);
    }

    deleteSubject(id: string, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.deleteSubject(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }
}

export const getSubjectStore = (): any => {
    const [_SubjectStore] = di([SubjectStore], getSubjectStore);
    return _SubjectStore;
};
