import { observer } from 'mobx-react-lite';
import React, { useMemo, useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { PlanEntryListModel, PlanEntryModel } from '../../../models';
import { PlanEntryFormDialog as PlanEntryFormDialogInj } from './PlanEntryFormDialog';

export type EditExpertisePlanEntryFormDialogProps = {
    planEntryId: string;
    ownerPagePath: string;
    listModel: PlanEntryListModel;
};

export const EditExpertisePlanEntryFormDialog = observer(
    (props: EditExpertisePlanEntryFormDialogProps): JSX.Element => {
        const [PlanEntryFormDialog] = di([PlanEntryFormDialogInj], EditExpertisePlanEntryFormDialog);
        const { ownerPagePath, listModel, planEntryId } = props;
        const rootStore = useStore();
        const { taskFormSelectData, expertisePlanStore } = listModel;
        const { expertiseTaskFormByCode, loadPlanEntry } = expertisePlanStore;

        const planEntryModel = useMemo(
            () => new PlanEntryModel(taskFormSelectData, expertiseTaskFormByCode, rootStore),
            [taskFormSelectData, expertiseTaskFormByCode],
        );

        useEffect(() => {
            loadPlanEntry(planEntryId).then(planEntryModel.load);
        }, [planEntryModel, planEntryId]);

        return (
            <PlanEntryFormDialog planEntryModel={planEntryModel} listModel={listModel} ownerPagePath={ownerPagePath} />
        );
    },
);
