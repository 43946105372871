import { Typography } from '@mui/material';
import { ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useSubjectPageContext } from '../../../../hooks';
import { objectWithoutBreadcrumbsHeaderContainerSx } from '../../../../utils';
import { SubjectHeaderLeft as SubjectHeaderLeftInj } from './SubjectHeaderLeft';

export const SubjectHeader = observer((): JSX.Element => {
    const [SubjectHeaderLeft] = di([SubjectHeaderLeftInj], SubjectHeader);

    const { model } = useSubjectPageContext();
    const { title } = model;

    const objectHeaderLayoutProps: ObjectHeaderLayoutProps = {
        mainContentContainerProps: {
            sx: objectWithoutBreadcrumbsHeaderContainerSx,
        },
        titleBlockAndMenu: {
            titleBlock: <Typography variant="subtitle2">{title}</Typography>,
        },
        leftBottomBlock: {
            element: <SubjectHeaderLeft />,
        },
    };

    return <ObjectHeaderLayout {...objectHeaderLayoutProps} />;
});
