import { recordToLang } from '@platform/front-utils';
import { multiLangFieldRu } from '@platform/multi-lang-field';
import { multilevelCatalog_ru } from '@platform/multilevel-catalog';
import { ttable_ru } from '@platform/ttable';
import authentication from './authentication';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import category from './category';
import common from './common';
import console from './console';
import expertise from './expertise';
import expertiseTask from './expertiseTask';
import headerLinks from './headerLinks';
import homePage from './homePage';
import { kafkaEvents } from './kafkaEvents';
import pfTemplate from './pfTemplate';
import startPage from './startPage';
import subject from './subject';
import syslog from './syslog';
import templatesOfExpertise from './templatesOfExpertise';
import user from './user';
import validation from './validation';

const lang: Record<string, string> = recordToLang(
    {
        authentication,
        breadcrumbs,
        campaign,
        category,
        common,
        console,
        expertise,
        expertiseTask,
        headerLinks,
        homePage,
        kafkaEvents,
        multiLangField: multiLangFieldRu,
        multilevelCatalog: multilevelCatalog_ru,
        pfTemplate,
        productTitle: 'TOT EXP',
        startPage,
        subject,
        syslog,
        templatesOfExpertise,
        ttable: ttable_ru,
        user,
        validation,
    },
    false,
);

export default lang;
