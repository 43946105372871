import { AuthorizationCheck, AuthorizationCheckProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

export type AuthorizedRouteProps = AuthorizationCheckProps & RouteProps;

export const AuthorizedRoute = observer((props: AuthorizedRouteProps): JSX.Element => {
    const { permCode, entityCode, entityId, pendingElement, onAllowed, isWithoutCheck, ...other } = props;

    const routeProps = other as RouteProps;

    if (isWithoutCheck) {
        return <Route {...routeProps} />;
    }

    return (
        <AuthorizationCheck
            entityCode={entityCode}
            permCode={permCode}
            entityId={entityId}
            pendingElement={pendingElement}
            onAllowed={onAllowed}
            isWithRedirect
        >
            <Route {...routeProps} />
        </AuthorizationCheck>
    );
});
