import { BreadcrumbsLinksType } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';

export const getCampaignBreadcrumbs = (
    intl: IntlShape,
    campaignId: string,
    campaignTitle: string,
): BreadcrumbsLinksType[] => {
    const { formatMessage } = intl;

    return [
        {
            to: clientRoute.campaigns,
            routeTitle: formatMessage({ id: 'campaign.campaignListTitle' }),
        },
        {
            to: campaignId ? generatePath(clientRoute.campaign, { id: campaignId }) : '',
            routeTitle: campaignTitle,
        },
        {
            to: clientRoute.campaignSyslog,
            routeTitle: formatMessage({ id: 'syslog.title' }),
        },
    ];
};
