import { ApiStore, CoreRootStore } from '@platform/front-core';
import { FullSubmissionWithAdditionalInfo } from '@platform/front-types';
import { AsyncCheckActionType, AsyncCheckStore, CodeTitle, TotLocale } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { ApiConfigs as LocalApiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { UserCardModel } from '../models';
import {
    EditStatusDTO,
    FullNameFields,
    FullNameSettingsDTO,
    LoginFields,
    LoginSettingsDTO,
    RolesFields,
    RolesSettingsDTO,
    UserDTO,
    UserForCreate,
} from '../types';

export const userStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,
    roleListWithoutAdministration: observable,

    getUserFullName: action.bound,
    getRoleListWithoutAdministration: action.bound,
    loginAsUser: action.bound,
    loadUser: action.bound,
    saveUser: action.bound,
    userRoleList: action.bound,
    deleteUser: action.bound,
    editUserStatus: action.bound,
    assignUserPassword: action.bound,
    createUser: action.bound,

    setRoles: action.bound,
    setUserLang: action.bound,
    setFullName: action.bound,
};

export class UserStore {
    roleListWithoutAdministration: CodeTitle[] = [];
    protected rootStore: CoreRootStore;
    protected api: ApiStore;
    protected apiConfigs: LocalApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: CoreRootStore) {
        makeObservable(this, userStoreObservables);
        const { api } = rootStore;
        this.rootStore = rootStore;
        this.api = api;
        this.apiConfigs = api.apiConfigs as LocalApiConfigs;
        this.asyncCheckStore = rootStore.asyncCheckStore;
    }

    loginAsUser(id: string): Promise<void> {
        const { currentUserStore, history } = this.rootStore;
        return this.api
            .client(this.apiConfigs.loginAsUser(id))
            .then(currentUserStore.getInfo)
            .then(() => {
                history.push(generatePath(currentUserStore.redirectPath));
            });
    }

    getUserFullName(id: string): Promise<FullNameSettingsDTO> {
        return this.api.client(this.apiConfigs.userFullName(id)).then((r) => r.data);
    }

    async getRoleListWithoutAdministration(): Promise<void> {
        const { data } = await this.api.client(this.apiConfigs.getRoleListWithoutAdministration);
        this.roleListWithoutAdministration = data;
    }

    loadUser(id: string): Promise<UserDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadUser(id)).then((r) => {
            return r.data;
        });
    }

    saveUser(id: string, submission: FullSubmissionWithAdditionalInfo): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.saveUser(id, submission)).then((r) => r.data);
    }

    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(this.apiConfigs.userRoleList).then((r) => r.data);
    }

    deleteUser(id: string): Promise<void> {
        return this.api.client(this.apiConfigs.deleteUser(id)).then((r) => r.data);
    }

    editUserStatus(id: string, data: EditStatusDTO, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.editUserStatus(id, data))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    saveUserHead(user: UserCardModel): Promise<void> {
        const { id, roles, login, lang } = user;
        return this.api
            .client(
                this.apiConfigs.saveUserHead(id, {
                    email: login,
                    lang,
                    roles: roles.map((_) => _.code),
                }),
            )
            .then((r) => r.data);
    }

    assignUserPassword(id: string, password: string): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.assignUserPassword(id, password)).then((r) => r.data);
    }

    createUser(user: UserForCreate): Promise<string | void> {
        return this.api
            .userActionClient(this.apiConfigs.createUser(user))
            .then((r) => r.data)
            .then((userId: string) => {
                this.rootStore.history.push(
                    generatePath(clientRoute.user, {
                        id: userId,
                    }),
                );
            });
    }

    setLogin(id: string, dto: LoginSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setLogin(id, dto), Object.values(LoginFields))
            .then((r) => r.data);
    }

    setRoles(id: string, dto: RolesSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setRoles(id, dto), Object.values(RolesFields))
            .then((r) => r.data);
    }

    setUserLang(id: string, lang: TotLocale): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.setUserLang(id, lang)).then((r) => r.data);
    }

    setFullName(id: string, dto: FullNameSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setFullName(id, dto), Object.values(FullNameFields))
            .then((r) => r.data);
    }
}

export const getUserStore = (): any => {
    const [_UserStore] = di([UserStore], getUserStore);
    return _UserStore;
};
