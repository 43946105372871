import { GridProps } from '@mui/material';
import { ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { objectWithoutBreadcrumbsHeaderContainerSx } from '../../../../utils';
import { TemplateOfExpertiseHeaderLeft as TemplateOfExpertiseHeaderLeftInj } from './TemplateOfExpertiseHeaderLeft';
import { TemplateOfExpertiseHeaderTitle as TemplateOfExpertiseHeaderTitleInj } from './TemplateOfExpertiseHeaderTitle';

export const TemplateOfExpertiseHeader = observer((): JSX.Element => {
    const [TemplateOfExpertiseHeaderTitle] = di([TemplateOfExpertiseHeaderTitleInj], TemplateOfExpertiseHeader);
    const [TemplateOfExpertiseHeaderLeft] = di([TemplateOfExpertiseHeaderLeftInj], TemplateOfExpertiseHeader);

    const titleBlock = <TemplateOfExpertiseHeaderTitle />;
    const leftBottomBlockElement = <TemplateOfExpertiseHeaderLeft />;

    const titleBlockAndMenu: ObjectHeaderLayoutProps['titleBlockAndMenu'] = {
        titleBlock,
    };

    const leftBottomBlock: ObjectHeaderLayoutProps['leftBottomBlock'] = {
        element: leftBottomBlockElement,
    };

    const mainContentContainerProps: GridProps = {
        sx: objectWithoutBreadcrumbsHeaderContainerSx,
    };

    return (
        <ObjectHeaderLayout
            mainContentContainerProps={mainContentContainerProps}
            titleBlockAndMenu={titleBlockAndMenu}
            leftBottomBlock={leftBottomBlock}
        />
    );
});
