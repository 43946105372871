import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Fade, Grid, Paper, Popper } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { CommonInfoLine } from '@platform/front-ui';
import { getOrElse } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Contacts } from '../../../types';

export type ExpertiseTaskCuratorInfoProps = {
    name?: string;
    contacts: Contacts;
};

const commonInfoLineContainerSx: SxStyle = {
    direction: 'row',
    alignItems: 'baseline',
    wrap: 'nowrap',
};

export const ExpertiseTaskCuratorInfo = observer((props: ExpertiseTaskCuratorInfoProps): JSX.Element => {
    const { name, contacts } = props;
    const { phone, email } = contacts;
    const { formatMessage } = useIntl();

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();

    const curatorNameTitle = formatMessage({ id: 'expertiseTask.curator.name' });
    const curatorPhoneTitle = formatMessage({ id: 'expertiseTask.curator.phone' });
    const curatorEmailTitle = formatMessage({ id: 'expertiseTask.curator.email' });
    const curatorNameValue = getOrElse(name);
    const curatorPhoneValue = getOrElse(phone);
    const curatorEmailValue = getOrElse(email);

    const setAnchorEl = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElement(event.currentTarget);
    };

    const deleteAnchorEl = (): void => {
        setAnchorElement(null);
    };

    return (
        <Box onPointerEnter={setAnchorEl} onPointerLeave={deleteAnchorEl} lineHeight={0}>
            <InfoOutlinedIcon />
            <Popper open={!!anchorElement} anchorEl={anchorElement} transition style={{ zIndex: 1102 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Box component={Paper} p={3}>
                            <Grid container direction="column">
                                <Grid item container spacing={2} sx={commonInfoLineContainerSx}>
                                    <CommonInfoLine title={curatorNameTitle} value={curatorNameValue} />
                                </Grid>
                                <Grid item container spacing={2} sx={commonInfoLineContainerSx}>
                                    <CommonInfoLine title={curatorPhoneTitle} value={curatorPhoneValue} />
                                </Grid>
                                <Grid item container spacing={2} sx={commonInfoLineContainerSx}>
                                    <CommonInfoLine title={curatorEmailTitle} value={curatorEmailValue} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
});
