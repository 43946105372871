import { Grid, Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { CommonInfoLine } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { headerLeftStyles } from '../../../../constants';
import { useStore, useTemplateOfExpertisePageContext } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { getOrElse } from '../../../../utils';

export const TemplateOfExpertiseHeaderLeft = (): JSX.Element => {
    const { model } = useTemplateOfExpertisePageContext();
    const { metaInfo } = model;
    const { created, stateTitle } = metaInfo;

    const { id } = useParams<RouteParamsDefault>();
    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { formatMessage } = useIntl();
    const createdValue = getOrElse(created, <FormattedDate value={created} />);
    const createdLabel = formatMessage({ id: 'templatesOfExpertise.fields.created' });
    const stateValue = getOrElse(stateTitle, stateTitle, '-');
    const stateLabel = formatMessage({ id: 'templatesOfExpertise.fields.state' });
    const syslogPath = generatePath(clientRoute.templateOfExpertiseSyslog, {
        id,
    });
    const syslogLabel = formatMessage({ id: 'syslog.single.nominative' });

    return (
        <Grid item container sx={headerLeftStyles.container}>
            <CommonInfoLine title={createdLabel} value={createdValue} />
            <CommonInfoLine title={stateLabel} value={stateValue} />
            <AuthorizationCheck {...administrationQuery}>
                <Link component={NavLink} to={syslogPath}>
                    {syslogLabel}
                </Link>
            </AuthorizationCheck>
        </Grid>
    );
};
