import { Box, Container, Grid } from '@mui/material';
import { registryPageStyles } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { SubjectTableRow } from '../../../types';
import { CreateSubjectButton as CreateSubjectButtonInj } from './subject-create-dialog';
import { SubjectRegistryActionsBtns as SubjectRegistryActionsBtnsInj } from './SubjectRegistryActionsBtns';
import { SubjectRegistryTable as SubjectRegistryTableInj } from './SubjectRegistryTable';

export const subjectListPageSetRowActionsInj: TTableRowActions<SubjectTableRow> = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [SubjectRegistryActionsBtns] = di([SubjectRegistryActionsBtnsInj], SubjectListPage);
    return (
        <SubjectRegistryActionsBtns
            subjectTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const SubjectListPage = observer((): JSX.Element => {
    const [CreateSubjectButton] = di([CreateSubjectButtonInj], SubjectListPage);
    const [SubjectRegistryTable] = di([SubjectRegistryTableInj], SubjectListPage);
    const [subjectListPageSetRowActions] = di([subjectListPageSetRowActionsInj], SubjectListPage);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="subject.subjectListTitle" />
                        </Typography>
                        <Grid item>
                            <CreateSubjectButton />
                        </Grid>
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <SubjectRegistryTable setRowActions={subjectListPageSetRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
