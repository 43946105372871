import { Dialog, DialogContent } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
} from '@platform/front-ui';
import { IdTitle, stopPropagation, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore } from '../hooks';
import { ExpertiseEditCuratorModel } from '../models';
import { CuratorSettings } from '../types';

export type EditCuratorModalProps = ModalProps & {
    curatorUserId: string;
    onSubmit(currentCurator: IdTitle): Promise<void>;
    hideMenu?: VoidFunction;
};

export const EditCuratorModal = observer((props: EditCuratorModalProps) => {
    const { open, onCancel, onSubmit: handlerSubmit, curatorUserId, hideMenu } = props;

    const { coreRootStore } = useStore();
    const expertiseEditCuratorModel = useMemo(() => new ExpertiseEditCuratorModel(coreRootStore), [coreRootStore]);
    const { currentCurator, curatorOptions, setCurrentCurator } = expertiseEditCuratorModel;

    const intl = useIntl();
    const { formatMessage } = intl;

    useEffect(() => {
        if (curatorOptions.length !== 0 && open) {
            setCurrentCurator(curatorUserId);
        }
    }, [curatorUserId, curatorOptions, open]);

    const initialValues: CuratorSettings = {
        curator: currentCurator,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object().shape({
        curator: Yup.object().required().nullable(),
    });

    const onSubmit = (values: CuratorSettings): Promise<void> => {
        return handlerSubmit(values.curator as IdTitle).then(() => {
            onCancel();
            hideMenu && hideMenu();
        });
    };

    const [isSending, endIcon, submitHandler] = useAntiDoubleClick(onSubmit);
    const label = formatMessage({ id: 'expertise.fields.curator' });
    const noOptionsText = formatMessage({ id: 'common.noOptions' });

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={open} onKeyDown={stopPropagation}>
            <DialogTitle onCloseClick={onCancel}>
                <FormattedMessage id="expertise.changeCurator" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
                enableReinitialize
                disableClearable
            >
                <Form>
                    <DialogContent>
                        <AutocompleteField
                            fieldName="curator"
                            label={label}
                            options={curatorOptions}
                            required
                            noOptionsText={noOptionsText}
                        />
                    </DialogContent>

                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
