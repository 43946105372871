import { IdTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { RootStore, SubjectStore } from '../../stores';

export const createSubjectModelObservables = {
    rootStore: observable,
    subjectStore: observable,

    categoryOptions: observable,
    campaignOptions: observable,
    campaignOptionsLoaded: observable,
    identifier: observable,

    loadCategorySelectOptions: action.bound,
    loadCampaignOptions: action.bound,
    getSubjectNumber: action.bound,
    setCategoryOptions: action.bound,
    setCampaignOptions: action.bound,
    setCampaignOptionsLoaded: action.bound,
    setIdentifier: action.bound,
};

export class CreateSubjectModel {
    protected rootStore: RootStore;
    protected subjectStore: SubjectStore;

    categoryOptions: IdTitle[] = [];
    campaignOptions: IdTitle[] = [];
    campaignOptionsLoaded = false;
    identifier = '';

    constructor(rootStore: RootStore) {
        makeObservable(this, createSubjectModelObservables);
        this.rootStore = rootStore;
        this.subjectStore = rootStore.subjectStore;
        this.loadCategorySelectOptions();
        this.getSubjectNumber();
    }

    loadCategorySelectOptions(): void {
        this.subjectStore.loadCategorySelectOptions().then(this.setCategoryOptions);
    }

    loadCampaignOptions(): void {
        if (!this.campaignOptionsLoaded) {
            this.subjectStore.loadCampaignSelectOptions().then((options) => {
                this.setCampaignOptions(options);
                this.setCampaignOptionsLoaded();
            });
        }
    }

    getSubjectNumber(): void {
        this.subjectStore.getSubjectNumber().then(this.setIdentifier);
    }

    setCategoryOptions(categoryOptions: IdTitle[]): void {
        this.categoryOptions = categoryOptions;
    }

    setCampaignOptions(campaignOptions: IdTitle[]): void {
        this.campaignOptions = campaignOptions;
    }

    setCampaignOptionsLoaded(): void {
        this.campaignOptionsLoaded = true;
    }

    setIdentifier(identifier: string): void {
        this.identifier = identifier;
    }
}
