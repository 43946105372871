import { Link, Tooltip } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { DynamicEllipsisBox, DynamicEllipsisBoxProps, FileDownloadProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { PrintFormFileDTO } from '../../../../../../types';

export type ExpertiseTaskPrintFormsTableTooltipFileProps = {
    file: PrintFormFileDTO;
    templateTitle: string;
    downloadPf(file: PrintFormFileDTO): () => void;
};

const tooltipSx: SxStyle = {
    paddingLeft: '16px',
    maxWidth: '100%',
};

const fileNameEllipsisProps: FileDownloadProps['nameEllipsisProps'] = {
    startTimeout: 500,
};

const pfWithoutGeneratedFileSx: Partial<DynamicEllipsisBoxProps> = {
    maxRows: 1,
    isWithTooltip: false,
};

export const ExpertiseTaskPrintFormsTableTooltipFile = observer(
    (props: ExpertiseTaskPrintFormsTableTooltipFileProps) => {
        const { file, templateTitle, downloadPf } = props;
        const { formatMessage } = useIntl();
        const onClickHandler = downloadPf(file);
        const tooltipTitle = formatMessage({ id: 'common.downloadFile' }, { fileName: file.filename });

        const pfWithoutGeneratedFileEllipsisBoxProps: DynamicEllipsisBoxProps = {
            ...pfWithoutGeneratedFileSx,
            ...fileNameEllipsisProps,
            text: templateTitle,
        };

        return (
            <Tooltip sx={tooltipSx} title={tooltipTitle}>
                <Link noWrap component="button" underline="none" onClick={onClickHandler}>
                    <DynamicEllipsisBox {...pfWithoutGeneratedFileEllipsisBoxProps} />
                </Link>
            </Tooltip>
        );
    },
);
