export enum CatalogCode {
    ReasonsOfRejectInvite = 'ReasonsOfRejectInvite',
    ScienceFields = 'OECD',
}

export const CatalogItemCodes = {
    [CatalogCode.ReasonsOfRejectInvite]: {
        Other: '99',
    },
};
