import { Grid, Paper } from '@mui/material';
import { AuthorizationCheck, ObjectContentLayoutProps, ObjectLayout } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ServiceInfoPanel as ServiceInfoPanelInj, TotObjectForm as TotObjectFormInj } from '../../../components';
import { CampaignPageContext, CampaignPageContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { CampaignModel } from '../../../models';
import { PermissionsStore } from '../../../stores';
import { CampaignSyslogPage as CampaignSyslogPageInj } from '../campaign-syslog';
import {
    CampaignControlPanel as CampaignControlPanelInj,
    CampaignExpertisesPanel as CampaignExpertisesPanelInj,
    CampaignHeader as CampaignHeaderInj,
    CampaignSubjectsPanel as CampaignSubjectsPanelInj,
    CampaignTasksPanel as CampaignTasksPanelInj,
} from './components';

export const CampaignPage = observer((): JSX.Element => {
    const [CampaignSyslogPage] = di([CampaignSyslogPageInj], CampaignPage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], CampaignPage);
    const [CampaignExpertisesPanel] = di([CampaignExpertisesPanelInj], CampaignPage);
    const [CampaignHeader] = di([CampaignHeaderInj], CampaignPage);
    const [CampaignSubjectsPanel] = di([CampaignSubjectsPanelInj], CampaignPage);
    const [CampaignTasksPanel] = di([CampaignTasksPanelInj], CampaignPage);
    const [CampaignControlPanel] = di([CampaignControlPanelInj], CampaignPage);
    const [TotObjectForm] = di([TotObjectFormInj], CampaignPage);

    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();

    const { campaignStore, coreRootStore } = useStore();
    const { locale } = useIntl();
    const model = useMemo(() => new CampaignModel(id, campaignStore), [id]);

    const { permissionsStore } = coreRootStore;
    const { systemConfig, expertiseCampaignConfig } = permissionsStore as PermissionsStore;
    const editExpertiseCampaignQuery = expertiseCampaignConfig.edit();
    const administrationQuery = systemConfig.administration();

    const {
        load,
        metaInfo,
        campaignStatisticModel,
        campaignLinksModel,
        loadCampaignStatistic,
        loadCampaignLinks,
        formApi,
        formDTO,
        setFormApi,
        setIsFormChanged,
        isShowValidation,
        dropIsShowValidation,
    } = model;

    const { subjects, expertises, tasks } = campaignStatisticModel;

    useEffect(() => {
        load();
    }, [id, locale]);

    useEffect(() => {
        loadCampaignStatistic();
        loadCampaignLinks();
    }, [id]);

    const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
        const isFormValid = (formApi && formApi.validate()) || false;
        if (isFormValid) {
            return onSuccess ? onSuccess() : Promise.resolve();
        }
        return Promise.reject();
    };

    const onSubmit = useCallback(async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            return formApi
                .submit(false)
                .then(() => {
                    return campaignStore.editCampaign(id, formApi.getSubmissionWithAdditionalInfo());
                })
                .then(() => {
                    load().then(() => history.push(generatePath(clientRoute.campaign, { id })));
                });
        }
        return Promise.reject();
    }, [formApi, campaignStore.editCampaign, id, history]);

    const pageContextValue: CampaignPageContextType = {
        model,
        onSubmit,
        validateForm,
    };

    const header: JSX.Element = <CampaignHeader />;

    const content: JSX.Element = (
        <Grid container direction="column" wrap="nowrap" overflow="auto">
            <Grid item p={2}>
                <AuthorizationCheck {...administrationQuery}>
                    <ServiceInfoPanel metaInfoModel={metaInfo} withForm={false} elevation={1} />
                </AuthorizationCheck>
                <Grid component={Paper} mb={3}>
                    {!!formDTO && (
                        <TotObjectForm
                            formName="template"
                            editPath={clientRoute.campaignEdit}
                            createPath={clientRoute.campaignCreate}
                            readPath={clientRoute.campaign}
                            formDTO={formDTO}
                            onFormReady={setFormApi}
                            onFormChange={setIsFormChanged}
                            showReadonlyValidation={isShowValidation}
                            hideReadonlyValidation={dropIsShowValidation}
                            editAuthProps={editExpertiseCampaignQuery}
                        />
                    )}
                </Grid>
                <Route path={clientRoute.campaign} exact>
                    <CampaignSubjectsPanel subjects={subjects} links={campaignLinksModel.subjects} />
                    <CampaignExpertisesPanel expertises={expertises} links={campaignLinksModel.expertises} />
                    <CampaignTasksPanel tasks={tasks} links={campaignLinksModel.tasks} />
                </Route>
            </Grid>
        </Grid>
    );

    const footer: JSX.Element = <CampaignControlPanel />;

    const contentLayout: ObjectContentLayoutProps = {
        content,
        footer,
    };

    return (
        <CampaignPageContext.Provider value={pageContextValue}>
            <Switch>
                <Route path={clientRoute.campaignSyslog}>
                    <CampaignSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout header={header} contentLayout={contentLayout} />
                </Route>
            </Switch>
        </CampaignPageContext.Provider>
    );
});
