import EditOutlined from '@mui/icons-material/EditOutlined';
import { Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import {
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../components';
import { useCategoryOfSubjectsPageContext, useError } from '../../../hooks';

export const CategoryOfSubjectsControlPanelLeft = (): JSX.Element => {
    const [ErrorDialog] = di([ErrorDialogInj], CategoryOfSubjectsControlPanelLeft);
    const [ErrorMessage] = di([ErrorMessageInj], CategoryOfSubjectsControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], CategoryOfSubjectsControlPanelLeft);

    const { model } = useCategoryOfSubjectsPageContext();
    const history = useHistory();
    const { deleteCategory, id, title } = model;
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
    const { formatMessage } = useIntl();

    // todo: userActionClient должен перехватывать эту ошибку - проверить!
    const handleDeleteCategory = (): Promise<void> => {
        return deleteCategory()
            .then(() => {
                history.push(generatePath(clientRoute.categories));
            })
            .catch((error) => {
                const errorText = ErrorMessage(error);
                setErrorIsOpen(errorText);
            });
    };

    const deleteButtonDialogMessageText = formatMessage({ id: 'category.confirmDeletionInfoText' }, { title });
    const confirmDeletion = formatMessage({ id: 'common.confirmDeletion' });
    const tooltipTitle = formatMessage({ id: 'category.actions.editCategory' });

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Switch>
                    <Route key={clientRoute.category} path={clientRoute.category} exact>
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-category"
                                title={confirmDeletion}
                                message={deleteButtonDialogMessageText}
                                onConfirm={handleDeleteCategory}
                                tooltipTitleId="category.actions.deleteCategory"
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={tooltipTitle} placement="top-end">
                                <IconButton
                                    component={NavLink}
                                    to={generatePath(clientRoute.categoryEdit, {
                                        id,
                                    })}
                                >
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Route>
                </Switch>
            </Grid>
        </React.Fragment>
    );
};
