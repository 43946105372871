import { Toolbar, styled, ToolbarProps } from '@mui/material';
import { StyledComponent } from '@mui/styles';
import { grey } from '@platform/front-ui';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export const ErrorIcon: StyledComponent<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = styled(
    'div',
)`
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.error.main};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TableToolbar: StyledComponent<ToolbarProps> = styled(Toolbar)`
    border-bottom: 1px solid ${grey[200]};
    padding-left: 16px !important;
    padding-right: ${({ theme }) => theme.spacing(6)}px !important;
`;
