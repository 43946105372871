import { LocalesTree } from '@platform/front-utils';

const subject: LocalesTree = {
    actions: {
        deleteSubject: 'Удалить объект',
        editSubject: 'Редактировать объект',
    },
    allFiles: 'Все документы по {title}',
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    confirmDeletionInfoText: 'Вы действительно хотите удалить объект экспертизы "{title}"?',
    createSubject: 'Создать объект',
    downloadAllFiles: 'Скачать все файлы',
    fields: {
        author: 'Автор',
        campaign: 'Кампания',
        category: 'Категория',
        created: 'Дата создания',
        expertise: 'Экспертиза',
        form: 'Форма',
        identifier: 'Номер',
        lastModified: 'Последнее изменение',
        lifecycle: 'Жизненный цикл',
        state: 'Состояние',
        title: 'Наименование',
        withoutExpertise: 'Объекты без экспертизы',
    },
    newSubject: 'Новый объект',
    registryPagingInfo:
        'Всего {count, number} объект{count, plural, one {} few {а} other {ов}}. ' +
        'Отображены c {from, number} по {to, number}',
    subjectListTitle: 'Объекты экспертизы',
    subjects: 'Объекты',
    withoutExpertise: 'Без экспертизы',
};

export default subject;
