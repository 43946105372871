import { AuthorizationCheck } from '@platform/front-core';
import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { DeleteActionMenuItem as DeleteActionMenuItemInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { TemplatesOfExpertiseTableRow } from '../../../../types';

export type TemplatesOfExpertiseRegistryActionsBtnsProps = {
    templatesOfExpertiseTableRow: TemplatesOfExpertiseTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const TemplatesOfExpertiseRegistryActionsBtns = observer(
    (props: TemplatesOfExpertiseRegistryActionsBtnsProps): JSX.Element => {
        const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], TemplatesOfExpertiseRegistryActionsBtns);

        const { templatesOfExpertiseTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
        const { id, customData } = templatesOfExpertiseTableRow;

        const { templatesOfExpertiseStore, coreRootStore } = useStore();
        const { permissionsStore } = coreRootStore;
        const { templateExpertiseConfig } = permissionsStore as PermissionsStore;
        const deleteTemplateExpertiseQuery = templateExpertiseConfig.delete();

        const { formatMessage } = useIntl();

        const title = formatMessage({ id: 'common.confirmDeletion' });
        const message = formatMessage(
            { id: 'templatesOfExpertise.confirmDeletionInfoText' },
            { title: customData.title.title },
        );

        const renderActionItems = useCallback(
            (hideMenu?: () => void): ReactNode[] => {
                const deleteTemplate = async (): Promise<void> => {
                    try {
                        toggleIsRowBlocked();
                        await templatesOfExpertiseStore.delete(id, true);
                        reloadData();
                        hideMenu && hideMenu();
                    } catch {
                        rowErrorHandler();
                    }
                };

                return [
                    <DeleteActionMenuItem
                        id="delete"
                        key="delete"
                        title={title}
                        message={message}
                        onConfirm={deleteTemplate}
                    />,
                ];
            },
            [templatesOfExpertiseTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler],
        );

        return (
            <AuthorizationCheck {...deleteTemplateExpertiseQuery}>
                <MenuButton renderMenuItems={renderActionItems} />
            </AuthorizationCheck>
        );
    },
);
