import { createContext } from 'react';
import { ExpertiseModel, ExpertiseTasksListByExpertiseModel, PlanEntryListModel } from '../models';

export type ExpertisePageContextType = {
    model: ExpertiseModel;
    planEntryListModel: PlanEntryListModel;
    expertiseTasksListByExpertiseModel: ExpertiseTasksListByExpertiseModel;
};

export const ExpertisePageContext = createContext<ExpertisePageContextType>({
    model: {} as ExpertiseModel,
    planEntryListModel: {} as PlanEntryListModel,
    expertiseTasksListByExpertiseModel: {} as ExpertiseTasksListByExpertiseModel,
});
