import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { ExpertiseTaskEditPage as ExpertiseTaskEditPageInj } from './expertise-task-page';
import { ExpertiseTaskListPage as ExpertiseTaskListPageInj } from './ExpertiseTaskListPage';

export const ExpertiseTasksPages = observer((): JSX.Element => {
    const [ExpertiseTaskEditPage] = di([ExpertiseTaskEditPageInj], ExpertiseTasksPages);
    const [ExpertiseTaskListPage] = di([ExpertiseTaskListPageInj], ExpertiseTasksPages);
    return (
        <Switch>
            <Route path={clientRoute.expertiseTaskEdit} key={clientRoute.expertiseTaskEdit} exact>
                <ExpertiseTaskEditPage />
            </Route>
            <Route>
                <ExpertiseTaskListPage />
            </Route>
        </Switch>
    );
});
