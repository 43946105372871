import { LocalesTree } from '@platform/front-utils';

const expertiseTask: LocalesTree = {
    actions: {
        delete: 'Delete task',
        edit: 'Edit task',
        returnForRevision: 'Return for revision',
    },
    changeOfDueDate: 'Change of due date',
    changeStartDate: 'Change start date',
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise task № {number}?',
    create: 'Create task',
    curator: {
        email: 'Email',
        name: 'Name',
        phone: 'Phone',
    },
    descriptionPanel: {
        commonTabLabel: 'Common',
        serviceTabLabel: 'Service',
        title: 'Task description',
    },
    editDeadline: 'Change due date',
    editStarted: 'Change start date',
    errorText: 'To continue, select an expert from the list',
    expertCandidate: '{expert} (picked)',
    expertiseTasksTable: {
        datePlan: ' (plan)',
        daysPlan: 'd (plan)',
        fields: {
            actualDateOfTheExecution: 'The actual date of the execution',
            deadline: 'Deadline',
            expert: 'Expert',
            number: 'Task number',
            planEntry: 'Plan entry',
            responseTime: 'The response time',
            stateTitle: 'State',
        },
    },
    fields: {
        campaign: 'Campaign',
        created: 'Created',
        curator: 'Task manager',
        deadline: 'Due date',
        expert: 'Expert',
        expertise: 'Expertise',
        expertiseNumber: 'Expertise',
        expertisePlanEntry: 'Expertise plan entry',
        lifecycleStages: 'Lifecycle stages',
        number: 'Number',
        started: 'Start date',
        state: 'State',
        taskType: 'Type of task',
        viewPoint: 'Point of view',
        withoutExpert: 'Tasks without expert',
    },
    headerActions: {
        collapseDescriptionTooltip: 'Collapse description',
        collapseHeader: 'Collapse header',
        expandDescriptionTooltip: 'Expand description',
        expandHeader: 'Expand header',
        toggleDescription: 'Description',
    },
    invites: {
        accept: 'Accept task',
        agree: 'I agree to terms conducting expertise',
        agreement:
            'When evaluating grant proposal, expert should be guided by the following conditions:\n' +
            '• Competence\n' +
            'By agreeing to conduct an evaluation, the expert confirms that he/she has the necessary knowledge and experience to conduct a quality evaluation in the subject area.\n' +
            '• No conflicts of interests\n' +
            'If the expert is affiliated with the applicant, then he/she must immediately notify the examination curator about it.\n' +
            '• Confidentiality\n' +
            'The expert must ensure the confidentiality of any information contained in the documents submitted for evaluation. The expert is not entitled to disclose any information to third parties.\n' +
            '• Independence\n' +
            'The expert is not entitled to discuss the examination process with any third parties (including applicant), except for the examination curator.\n' +
            'The expert declares no conflict of interest.\n' +
            '\n' +
            'Conflict of interests occurs in cases when the expert:\n' +
            '• participated in the preparation of the research proposal,\n' +
            '• is in a labor relationship with the applicant,\n' +
            '• performed work or services for the applicant (within last 5 years),\n' +
            '• stands to gain or lose financially if the proposal approved,\n' +
            '• someone with whom the expert has a close relationship is in a labor relationship with the applicant,\n' +
            '• someone with whom the expert has a close relationship performed work or services for the applicant (within last 5 years),\n' +
            '• someone with whom the expert has a close relationship stands to gain or lose financially if the proposal approved,\n' +
            '• is influenced by other circumstances that hinder the objective evaluation of the proposal.',
        otherReason: 'Other',
        reject: 'Reject task',
        rejectReason: 'Reject reason',
    },

    listConfirmDeletionInfoText: 'Are you sure you want to delete the expertise task?',
    listTitle: 'Expertise tasks',
    localConclusions: {
        fields: {
            completed: 'Completed',
            deadline: 'Deadline',
            report: 'Report',
        },
        title: 'Expert reports',
    },
    new: 'New task',
    printFormsTable: {
        conclusion: 'Expert evaluation report',
        createAction: 'Generate file',
        generationError: 'An error occurred while generating the print form',
        noPrintForms: 'No printed forms',
        title: 'Printed forms',
        updateAction: 'Update file',
    },
    registryPagingInfo:
        'Total {count, number} task{count, plural, one {} other {s}}. Displayed from {from, number} to {to, number}',
    reportSaveReminder: "Don't forget to save progress of your work.",
    requiredFieldsAreNotFilled: 'Please fill in all required fields',
    saveWarning: 'Don’t forget to press the “Save” button!',
    selectExpertButton: 'Select an expert',
    selectionOfExpert: {
        select: 'Select',
        title: 'Selection of expert',
    },
    switchView: {
        all: 'Task and report',
        report: 'Report',
        task: 'Task',
    },
    tabs: {
        subject: 'Object',
        task: 'Task',
    },
    taskReportError: 'Expertise task report was filled out incorrectly',
    title: 'Expertise task № {number}',
};

export default expertiseTask;
