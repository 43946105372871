import { CodeTitle, IdTitle } from '@platform/front-utils';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { ExpertiseStore, RootStore } from '../../stores';
import {
    ExpertiseByCampaignDTO,
    ExpertiseCreateDTO,
    ExpertiseCreateType,
    PlanEntryDetailsDTO,
    TemplateDetailsDTO,
} from '../../types';

export const expertiseCreateModelObservables = {
    expertiseStore: observable,
    intl: observable,
    history: observable,

    templateId: observable,
    processId: observable,
    processTitle: observable,
    planEntries: observable,

    onSubmitByTemplate: action.bound,
    onSubmitByCampaign: action.bound,
    loadPlanEntryDetails: action.bound,
    loadSubjects: action.bound,
    loadTemplates: action.bound,
    loadLifeCycles: action.bound,
};

export class ExpertiseCreateModel {
    protected expertiseStore: ExpertiseStore;
    protected intl: IntlShape;
    protected history: History;

    subjects: IdTitle[] = [];
    templates: IdTitle[] = [];
    lifeCycles: CodeTitle[] = [];

    templateId = '';
    processId = '';
    processTitle = '';
    planEntries: PlanEntryDetailsDTO[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this, expertiseCreateModelObservables);
        this.intl = rootStore.coreRootStore.intlStore.intl;
        this.history = rootStore.history;
        this.expertiseStore = rootStore.expertiseStore;
    }

    onSubmitByTemplate = (formValues: ExpertiseCreateType): Promise<void> => {
        const { expertiseSubjects, expertiseTemplates, expertiseLifeCycles } = formValues;
        let data: ExpertiseCreateDTO = {} as ExpertiseCreateDTO;

        if (expertiseSubjects) {
            if (expertiseTemplates) {
                data = {
                    subjectId: expertiseSubjects.id,
                    templateId: expertiseTemplates.id,
                };
            } else if (expertiseLifeCycles) {
                data = {
                    subjectId: expertiseSubjects.id,
                    processCode: expertiseLifeCycles.code,
                };
            }
        }

        return this.expertiseStore.createExpertise(data).then((id) => {
            this.history.push(generatePath(clientRoute.expertise, { id }));
        });
    };

    onSubmitByCampaign = (formValues: ExpertiseCreateType, campaignId: string): Promise<void> => {
        const { expertiseTemplates } = formValues;

        if (expertiseTemplates) {
            const data: ExpertiseByCampaignDTO = {
                templateId: expertiseTemplates.id,
                campaignId,
            };
            this.expertiseStore.createExpertiseByCampaign(data).then(() => {
                this.history.push(generatePath(clientRoute.campaign, { id: campaignId }));
            });
        }
        return Promise.resolve();
    };

    loadPlanEntryDetails(dto: TemplateDetailsDTO): void {
        this.templateId = dto.id;
        this.processId = dto.process.title;
        this.processTitle = dto.process.title;
        this.planEntries = dto.planEntries;
    }

    loadSubjects(subjects: IdTitle[]): void {
        this.subjects = subjects.filter((subject) => subject.title !== '');
    }

    loadTemplates(templates: IdTitle[]): void {
        this.templates = templates;
    }

    loadLifeCycles(lifeCycles: CodeTitle[]): void {
        this.lifeCycles = lifeCycles;
    }
}
