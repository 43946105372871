import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { PlanEntryListModel, PlanEntryModel } from '../../../models';
import { PlanEntryFormDialog as PlanEntryFormDialogInj } from './PlanEntryFormDialog';

export type NewExpertisePlanEntryFormDialogProps = {
    ownerPagePath: string;
    listModel: PlanEntryListModel;
};

export const NewExpertisePlanEntryFormDialog = observer((props: NewExpertisePlanEntryFormDialogProps): JSX.Element => {
    const [PlanEntryFormDialog] = di([PlanEntryFormDialogInj], NewExpertisePlanEntryFormDialog);

    const { ownerPagePath, listModel } = props;
    const rootStore = useStore();
    const { taskFormSelectData, ownerId, expertisePlanStore } = listModel;
    const { expertiseTaskFormByCode, createPlanEntry } = expertisePlanStore;

    const planEntryModel = useMemo(
        () => new PlanEntryModel(taskFormSelectData, expertiseTaskFormByCode, rootStore),
        [taskFormSelectData, expertiseTaskFormByCode],
    );

    useEffect(() => {
        createPlanEntry(ownerId).then(planEntryModel.loadCreated);
    }, [planEntryModel, ownerId]);

    return <PlanEntryFormDialog planEntryModel={planEntryModel} listModel={listModel} ownerPagePath={ownerPagePath} />;
});
