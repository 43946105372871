import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ErrorIcon as ErrorIconInj } from '../ExpertiseTaskPrintForms.styled';

export type ExpertiseTaskPrintFormsTableErrorTooltip = {
    errorCode: string;
};

export const ExpertiseTaskPrintFormsTableErrorTooltip = observer(
    (props: ExpertiseTaskPrintFormsTableErrorTooltip): JSX.Element => {
        const [ErrorIcon] = di([ErrorIconInj], ExpertiseTaskPrintFormsTableErrorTooltip);

        const { errorCode } = props;
        const { formatMessage } = useIntl();

        return (
            <Tooltip title={formatMessage({ id: errorCode })}>
                <div>
                    <ErrorIcon>!</ErrorIcon>
                </div>
            </Tooltip>
        );
    },
);
