import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import { ConfirmationDialog } from '@platform/front-ui';
import { PromiseVoidFunction, useFlag, VoidFunction } from '@platform/front-utils';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

export type TotObjectDeleteButtonProps = Omit<ModalProps, 'open' | 'onCancel'> & {
    tooltipTitleId: string;
    id: string;
    onConfirm: PromiseVoidFunction;
    onOpen?: VoidFunction;
    onClose?: VoidFunction;
    title: ReactNode;
    message?: ReactNode;
};

export const TotObjectDeleteButton = (props: TotObjectDeleteButtonProps): JSX.Element => {
    const { tooltipTitleId, id, onConfirm, onOpen, onClose, title, message, ...dialogProps } = props;

    const [isDialogOpen, openDialog, closeDialog] = useFlag();
    const { formatMessage } = useIntl();

    const onDialogOpen = (): void => {
        openDialog();
        onOpen && onOpen();
    };

    const onDialogClose = (): void => {
        closeDialog();
        onClose && onClose();
    };

    const onDialogConfirm = (): Promise<void> => {
        return onConfirm().finally(() => {
            closeDialog();
            onClose && onClose();
        });
    };

    const tooltipTitle = formatMessage({ id: tooltipTitleId });

    return (
        <React.Fragment>
            <Tooltip title={tooltipTitle} placement="top-end">
                <IconButton onClick={onDialogOpen}>
                    <DeleteOutlinedIcon />
                </IconButton>
            </Tooltip>
            <ConfirmationDialog
                id={id}
                keepMounted
                onCancel={onDialogClose}
                onConfirm={onDialogConfirm}
                open={isDialogOpen}
                title={title}
                message={message}
                {...dialogProps}
            />
        </React.Fragment>
    );
};
