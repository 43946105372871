import { RenderTransitionDialogProps } from '@platform/front-core';
import { ConfirmationDialog } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';

export const LifeCycleTransitionConfirmationDialog = observer((props: RenderTransitionDialogProps): JSX.Element => {
    const { transition, isOpen, onClose, onConfirm } = props;

    const { formatMessage } = useIntl();

    const { toStateTitle, params } = transition;
    const { title } = params;
    const confirmationDialogMessage = formatMessage({ id: 'common.lifeCycleConfirmText' }, { toState: toStateTitle });
    const lifeCycleConfirmTitle = formatMessage({ id: 'common.lifeCycleConfirmTitle' });

    return (
        <ConfirmationDialog
            id="confirm-transition"
            onCancel={onClose}
            onConfirm={onConfirm}
            message={confirmationDialogMessage}
            open={isOpen}
            title={lifeCycleConfirmTitle}
            keepMounted
            confirmText={title}
        />
    );
});
