import mexpLogoEn from '../../resources/images/mexp-logo_en.svg';
import mexpLogoRu from '../../resources/images/mexp-logo_ru.svg';
import { ExpertiseFeatures } from '../../types';

const config: ExpertiseFeatures = {
    customLogo: false,
    footerLogo: false,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: 'TOT EXP logo',
                image: mexpLogoEn,
                style: { height: '32px', width: '147px' },
            },
            ru: {
                alt: 'TOT EXP логотип',
                image: mexpLogoRu,
                style: { height: '32px', width: '157px' },
            },
        },
    },
    modularNavigation: true,
    phoneNumber: true,
    userAdding: true,
    globalSearch: true,
};

export default config;
