import { Grid, Typography } from '@mui/material';
import React from 'react';

export type CategoryOfSubjectsPageHeaderTitleProps = {
    title: string;
};

export const CategoryOfSubjectsPageHeaderTitle = (props: CategoryOfSubjectsPageHeaderTitleProps): JSX.Element => {
    const { title } = props;
    return (
        <Grid item>
            <Typography variant="subtitle2">{title}</Typography>
        </Grid>
    );
};
