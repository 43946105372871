import { Button } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useStore } from '../../../../hooks';
import { PermissionsStore } from '../../../../stores';
import { NewSubjectDTO } from '../../../../types';
import { SubjectCreateDialog as SubjectCreateDialogInj } from './SubjectCreateDialog';

export const CreateSubjectButton = observer((): JSX.Element => {
    const [SubjectCreateDialog] = di([SubjectCreateDialogInj], CreateSubjectButton);

    const rootStore = useStore();
    const [isDialogOpen, openDialog, closeDialog] = useFlag();
    const { subjectStore, history, coreRootStore } = rootStore;

    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const addExpertiseSubjectQuery = systemConfig.addExpertiseSubject();

    const createSubject = (dto: NewSubjectDTO): Promise<void> => {
        return subjectStore.createSubject(dto).then((id) => {
            closeDialog();
            history.push(generatePath(clientRoute.subjectCreate, { id }));
        });
    };

    return (
        <AuthorizationCheck {...addExpertiseSubjectQuery}>
            <SubjectCreateDialog open={isDialogOpen} onCancel={closeDialog} onSubmit={createSubject} />
            <Button color="primary" variant="contained" onClick={openDialog}>
                <FormattedMessage id="subject.createSubject" />
            </Button>
        </AuthorizationCheck>
    );
});
