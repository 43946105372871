export enum RegistryType {
    account = 'account',
    campaign = 'campaign',
    category = 'category',
    expertise = 'expertise',
    experts = 'experts',
    logging = 'logging/entity',
    pfTemplate = 'pf-template',
    subject = 'subject',
    task = 'task',
    templateExpertise = 'template-expertise',
    kafkaEvent = 'kafka-event',
}
