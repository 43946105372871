import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    loginAnonymously: 'Войти без регистрации',
    logoAlt: 'TOT Expertise logo',
    productDescription: 'Контролируйте этапы организации экспертизы и оценки объектов от заявки до заключения',
    title: 'Система управления процессами экспертизы',
};

export default startPage;
