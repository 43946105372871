import { Dialog, DialogContent } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    FieldWithServerError,
    TotFormikDatePicker,
} from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { ExpertiseTaskEditDateValues } from '../../../types';

export type ExpertiseTaskEditDateDialogProps = ModalProps & {
    onSubmit: (date: Date) => Promise<void>;
    currentDate?: Date;
    label: string;
    title: React.ReactNode;
    maxValue?: Date | undefined;
    minValue?: Date | undefined;
};

const getDateWithoutTime = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const ExpertiseTaskEditDateDialog = observer((props: ExpertiseTaskEditDateDialogProps): JSX.Element => {
    const { open, onCancel, onSubmit, currentDate, label, title, minValue, maxValue } = props;

    const intl = useIntl();
    const { formatMessage } = intl;

    const { Yup } = useYup();

    const initialValues: ExpertiseTaskEditDateValues = {
        date: currentDate ? currentDate : null,
    };

    const schema = Yup.object().shape({
        date: Yup.date()
            .required()
            .nullable()
            .test('maxDate', formatMessage({ id: 'validation.maxDate' }), (value) => {
                if (value && maxValue) {
                    const valueWithoutTime = getDateWithoutTime(value);
                    const maxValueWithoutTime = getDateWithoutTime(maxValue);
                    if (valueWithoutTime.getTime() >= maxValueWithoutTime.getTime()) {
                        return false;
                    }
                }
                return true;
            })
            .test('minDate', formatMessage({ id: 'validation.minDate' }), (value) => {
                if (value && minValue) {
                    const valueWithoutTime = getDateWithoutTime(value);
                    const minValueWithoutTime = getDateWithoutTime(minValue);
                    if (valueWithoutTime.getTime() <= minValueWithoutTime.getTime()) {
                        return false;
                    }
                }
                return true;
            }),
    });

    const submit = (values: ExpertiseTaskEditDateValues): Promise<void> => {
        return onSubmit(values.date as Date);
    };

    const [isLoading, endIcon, handleSubmitClick] = useAntiDoubleClick(submit);

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isLoading,
        endIcon,
        text: formatMessage({ id: 'common.save' }),
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open}>
            <DialogTitle onCloseClick={onCancel}>{title}</DialogTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={handleSubmitClick}
            >
                <Form>
                    <DialogContent>
                        <FieldWithServerError component={TotFormikDatePicker} name="date" label={label} required />
                    </DialogContent>
                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
