import { LocalesTree } from '@platform/front-utils';

const category: LocalesTree = {
    actions: {
        deleteCategory: 'Delete category',
        editCategory: 'Edit category',
    },
    blocks: {
        serviceInfo: 'Service information',
    },
    categories: 'Categories',
    categoryListTitle: 'Categories of objects of expertise',
    confirmDeletionInfoText: 'Are you sure you want to delete the category "{title}"?',
    createCategory: 'Create category',
    fields: {
        author: 'Author',
        created: 'Created',
        form: 'Form',
        lastModified: 'Last modified',
        title: 'Title',
    },
    pagingInfo: 'Total {count, number} categories. Displayed from {from, number} to {to, number}',
};

export default category;
