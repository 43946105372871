import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    loginAnonymously: 'Login without registration',
    logoAlt: 'TOT Expertise logo',
    productDescription:
        'Control stages of expertise organization process and objects assessment from application to conclusion',
    title: 'Expertise processes management system',
};

export default startPage;
