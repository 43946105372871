import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box, Grid, Link, Typography } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { campaignPanelAccordionStyles, campaignPanelStyles } from '../constants';
import { MetaInfoModel } from '../models';
import { getOrElse } from '../utils';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from './tot-object';

export type ServiceInfoPanelProps = {
    metaInfoModel: MetaInfoModel;
    withForm?: boolean;
    elevation?: number;
};

export const ServiceInfoPanel = observer((props: ServiceInfoPanelProps): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], ServiceInfoPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], ServiceInfoPanel);

    const { formatMessage, formatDate } = useIntl();
    const { withForm = true, elevation = 0, metaInfoModel } = props;

    const processLinkComponent = (
        <Link target="_blank" href={metaInfoModel.processLink?.url}>
            {metaInfoModel.processLink?.label}
        </Link>
    );

    const formLinksComponents = metaInfoModel.formLinks.map((item) => {
        return (
            <Link target="_blank" key={item.url} href={item.url}>
                {item.label}
            </Link>
        );
    });

    const expandIcon = <ExpandMoreIcon />;
    const serviceInfoTitle = formatMessage({ id: 'common.serviceInfo' });
    const authorTitle = formatMessage({ id: 'common.serviceInfoFields.author' });
    const lastModifiedTitle = formatMessage({ id: 'common.serviceInfoFields.lastModified' });
    const lastModifiedDate = formatDate(metaInfoModel.modified);
    const lastModifiedValue = getOrElse(metaInfoModel.modified, lastModifiedDate);
    const lifecycleTitle = formatMessage({ id: 'common.serviceInfoFields.lifeCycle' });
    const lifecycleValue = getOrElse(metaInfoModel.processLink, processLinkComponent);
    const formTitle = formatMessage({ id: 'common.serviceInfoFields.form' });
    const formValue = getOrElse(metaInfoModel.formLinks, formLinksComponents);

    return (
        <Box sx={campaignPanelAccordionStyles.accordionContainer}>
            <Accordion defaultExpanded elevation={elevation} sx={campaignPanelAccordionStyles.accordion}>
                <TotObjectPanelSummary expandIcon={expandIcon}>
                    <Typography variant="subtitle2">{serviceInfoTitle}</Typography>
                </TotObjectPanelSummary>
                <TotObjectPanelDetails>
                    <Grid container direction="column" sx={campaignPanelStyles.panelDetailsList}>
                        <Grid container direction="column">
                            <CommonInfoLine title={authorTitle} value={metaInfoModel.author.name} isColumnDirection />
                        </Grid>
                        <Grid container direction="column">
                            <CommonInfoLine title={lastModifiedTitle} value={lastModifiedValue} isColumnDirection />
                        </Grid>
                        <Grid container direction="column">
                            <CommonInfoLine title={lifecycleTitle} value={lifecycleValue} isColumnDirection />
                        </Grid>
                        {withForm && (
                            <Grid container direction="column">
                                <CommonInfoLine title={formTitle} value={formValue} isColumnDirection />
                            </Grid>
                        )}
                    </Grid>
                </TotObjectPanelDetails>
            </Accordion>
        </Box>
    );
});
