import {
    AccordionDetails,
    AccordionDetailsProps,
    AccordionSummary,
    AccordionSummaryProps,
    styled,
} from '@mui/material';
import { StyledComponent } from '@mui/styles';

export const TotObjectPanelSummary: StyledComponent<AccordionSummaryProps> = styled(AccordionSummary)`
    padding: ${({ theme }): string => `${theme.spacing(1.75)} ${theme.spacing(2)}`};
    height: 20px;
    & > div {
        margin: 0px !important;
    }
`;

export const TotObjectPanelDetails: StyledComponent<AccordionDetailsProps> = styled(AccordionDetails)`
    padding: ${({ theme }): string => `0px ${theme.spacing(3)}`};
    padding-top: ${({ theme }): string => `${theme.spacing(0.5)}`};
    display: flex;
`;
