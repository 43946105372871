import { Autocomplete, Dialog, DialogContent, DialogTitle, Grid, Portal, TextField } from '@mui/material';
import { RenderTransitionDialogProps } from '@platform/front-core';
import { SxStyle } from '@platform/front-types';
import { DialogActions, DialogCancelButtonConfig, DialogSubmitButtonConfig } from '@platform/front-ui';
import { CodeTitle, CodeTitleNull, getTitle, useAntiDoubleClick, useFlag } from '@platform/front-utils';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { CatalogCode, CatalogItemCodes } from '../../../types';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../renderAutoCompleteInput';

const dialogContentGridSx: SxStyle = {
    pt: 1,
};

export const ExpertiseTaskInviteToRejectDialog = observer((props: RenderTransitionDialogProps): JSX.Element => {
    const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], ExpertiseTaskInviteToRejectDialog);

    const { transition, objectId, onSuccessTransition, onClose, isOpen } = props;
    const { expertiseTaskInviteStore, coreRootStore } = useStore();
    const { catalogStore } = coreRootStore;
    const { locale, formatMessage } = useIntl();
    // todo: проверить
    const [reasonsCatalog, setReasonsCatalog] = useState<CodeTitle[]>([]);
    const [reason, setReason] = useState<CodeTitleNull>(null);
    const [otherReason, setOtherReason] = useState<string>('');
    const [validationStarted, setValidationStarted] = useFlag();

    useEffect(() => {
        catalogStore.getCatalog(CatalogCode.ReasonsOfRejectInvite, locale, false).then(setReasonsCatalog);
    }, [locale]);

    const isOtherReason = useMemo<boolean>((): boolean => {
        return !!reason && reason.code === CatalogItemCodes.ReasonsOfRejectInvite.Other;
    }, [reason]);

    const reasonText = useMemo<string>((): string => {
        return reason && !isOtherReason ? reason.title : otherReason;
    }, [reason, isOtherReason, otherReason]);

    const isValid = useMemo<boolean>((): boolean => !!reasonText, [reasonText]);

    const onSubmit = async (): Promise<void> => {
        setValidationStarted();
        if (isValid) {
            return expertiseTaskInviteStore
                .rejectTaskInvite(transition.id, objectId, reasonText)
                .then(onSuccessTransition)
                .finally(onClose);
        }
    };

    const [isLoading, endIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    const onChangeReason = (event: ChangeEvent<{}>, value: CodeTitleNull): void => {
        setReason(value);
        setOtherReason('');
    };

    const onChangeOtherReason = (event: ChangeEvent<HTMLInputElement>): void => {
        setOtherReason(event.target.value);
    };

    const reasonError = useMemo<string>((): string => {
        const isRequiredValidation: boolean = validationStarted && !reason;

        return isRequiredValidation ? formatMessage({ id: 'validation.required' }) : '';
    }, [validationStarted, reason, locale]);

    const otherReasonError = useMemo<string>((): string => {
        const isRequiredValidation: boolean = validationStarted && isOtherReason && !otherReason;

        return isRequiredValidation ? formatMessage({ id: 'validation.required' }) : '';
    }, [validationStarted, isOtherReason, otherReason]);

    const renderInput = renderAutoCompleteInput(
        formatMessage({ id: 'expertiseTask.invites.rejectReason' }),
        true,
        reasonError,
    );

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButton: DialogSubmitButtonConfig = {
        onClick: onSubmitHandler,
        disabled: isLoading,
        text: formatMessage({ id: 'expertiseTask.invites.reject' }),
        endIcon,
    };

    const noOptionsText = formatMessage({ id: 'common.noOptions' });
    const otherReasonLabel = formatMessage({ id: 'expertiseTask.invites.otherReason' });

    return (
        <Portal>
            <Dialog fullWidth maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isOpen}>
                <DialogTitle id="confirmation-dialog-title">
                    <FormattedMessage id="common.lifeCycleConfirmTitle" />
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2} sx={dialogContentGridSx}>
                        <Grid item>
                            <Autocomplete
                                onChange={onChangeReason}
                                getOptionLabel={getTitle}
                                options={toJS(reasonsCatalog)}
                                value={reason}
                                renderInput={renderInput}
                                noOptionsText={noOptionsText}
                            />
                        </Grid>
                        {isOtherReason && (
                            <Grid item>
                                <TextField
                                    label={otherReasonLabel}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    required
                                    onChange={onChangeOtherReason}
                                    value={otherReason}
                                    error={!!otherReasonError}
                                    helperText={otherReasonError}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
            </Dialog>
        </Portal>
    );
});
