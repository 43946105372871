import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTemplateOfExpertisePageContext } from '../../../../hooks';

export const TemplateOfExpertiseHeaderTitle = (): JSX.Element => {
    const { model } = useTemplateOfExpertisePageContext();
    const { title } = model;

    return (
        <Grid item>
            <Typography variant="subtitle2">{title}</Typography>
        </Grid>
    );
};
