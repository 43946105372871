import { LocalesTree } from '@platform/front-utils';
import instructionSrc from '../../instructions/userInstruction_ru.docx';

const common: LocalesTree = {
    amountOfDays: '{count, number} {count, plural, one {день} few {дня} other {дней}}',
    formNotFound: 'Форма не найдена',
    fromToNumber: 'от {from, number} до {to, number}',
    instructionFileName: 'Инструкция.docx',
    instructionSrc: instructionSrc,
    invalidDateMessage: 'Недопустимый формат даты',
    noOptions: 'Нет доступных вариантов',
    pageLeaveMessage: 'Вы уверены, что хотите покинуть страницу?',
    pagingInfo: 'Отображено c {from, number} по {to, number}. Всего элементов {count, number}',
    readonlyValidationMsg: 'Форма заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    serviceInfo: 'Служебная информация',
    serviceInfoFields: {
        author: 'Автор',
        created: 'Дата создания',
        form: 'Форма',
        lastModified: 'Последнее изменение',
        lifeCycle: 'Жизненный цикл',
        state: 'Состояние',
    },
    withoutNumber: 'Без номера',
};

export default common;
