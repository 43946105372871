import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../../components';

export type ExpertiseTaskServiceInfoWrapperProps = PropsWithChildren<{
    withAccordion: boolean;
}>;

export const ExpertiseTaskServiceInfoWrapper = (props: ExpertiseTaskServiceInfoWrapperProps): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], ExpertiseTaskServiceInfoWrapper);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], ExpertiseTaskServiceInfoWrapper);

    const { withAccordion, children } = props;
    return withAccordion ? (
        <Accordion defaultExpanded elevation={0}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="common.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>{children}</TotObjectPanelDetails>
        </Accordion>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};
