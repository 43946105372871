import { makeSxStyles } from '@platform/front-ui';

// todo: стили нужны для всех компонентов, которые используют Paper, стоит вынести и обсудить с Яной дальнейшие действия по дизайну страниц
export const campaignPanelAccordionStyles = makeSxStyles({
    accordionContainer: {
        marginBottom: (theme) => theme.spacing(3) + ' !important',
    },
    accordion: {
        borderRadius: '0px !important',
        padding: 0,
    },
});
