import { Button, Grid, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useHistory, useLocation } from 'react-router';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../hooks';
import { PermissionsStore } from '../../../../../../stores';
import { SelectionOfExpertDialog as SelectionOfExpertDialogInj } from '../../../../selection-of-expert-dialog';

export const ExpertiseTaskHeaderSelectExpertDialogButton = observer((): JSX.Element => {
    const [SelectionOfExpertDialog] = di([SelectionOfExpertDialogInj], ExpertiseTaskHeaderSelectExpertDialogButton);
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation();

    const { model } = useExpertiseTaskViewPageContext();
    const { id, reloadTaskModel, taskModel } = model;
    const { metaInfo } = taskModel;

    const [isSelectExpertDialogOpen, openSelectExpertDialog, closeSelectExpertDialog] = useFlag();

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const expertiseTaskSetExpertQuery = expertiseTaskConfig.setExpert(id);

    const removeQueryParams = (): void => {
        history.push({
            pathname: location.pathname,
        });
    };

    const closeDialogAndRemoveQueryParams = (): void => {
        removeQueryParams();
        closeSelectExpertDialog();
    };

    const onSuccessSubmit = (): void => {
        closeDialogAndRemoveQueryParams();
        reloadTaskModel();
    };

    const selectExpertButtonText = formatMessage({ id: 'expertiseTask.selectExpertButton' });

    return (
        <AuthorizationCheck key={metaInfo.stateTitle} {...expertiseTaskSetExpertQuery}>
            <Grid item>
                <Tooltip title={selectExpertButtonText}>
                    <Button variant="contained" color="secondary" onClick={openSelectExpertDialog}>
                        {selectExpertButtonText}
                    </Button>
                </Tooltip>
                {isSelectExpertDialogOpen && (
                    <SelectionOfExpertDialog
                        isOpen={isSelectExpertDialogOpen}
                        onClose={closeDialogAndRemoveQueryParams}
                        onSuccessSubmit={onSuccessSubmit}
                    />
                )}
            </Grid>
        </AuthorizationCheck>
    );
});
