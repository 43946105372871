import { FormApi } from '@platform/formiojs-react';
import { IntlStore, NotificationStore } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { IdTitle } from '@platform/front-utils';
import { AxiosPromise } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { RootStore, SubjectStore } from '../../stores';
import { SubjectDTO } from '../../types';
import { MetaInfoModel } from '../metaInfo';

export const subjectModelObservables = {
    subjectStore: observable,
    notificationStore: observable,
    intlStore: observable,

    id: observable,
    title: observable,
    identifier: observable,
    categoryTitle: observable,
    campaign: observable,
    expertise: observable,
    metaInfo: observable,
    isShowCommonValidation: observable,
    isShowHiddenValidation: observable,

    commonFormDTO: observable,
    commonFormApi: observable,
    hiddenFormDTO: observable,
    hiddenFormApi: observable,
    isCommonFormChanged: observable,
    isHiddenFormChanged: observable,

    load: action.bound,
    loadCard: action.bound,
    deleteSubject: action.bound,
    downloadAllFiles: action.bound,

    setCommonFormApi: action.bound,
    setIsCommonFormChanged: action.bound,
    setHiddenFormApi: action.bound,
    setIsHiddenFormChanged: action.bound,
    setMainFields: action.bound,
    dropIsShowCommonValidation: action.bound,
    dropIsShowHiddenValidation: action.bound,
    setIsShowCommonValidation: action.bound,
    setIsShowHiddenValidation: action.bound,
};

export class SubjectModel {
    protected subjectStore: SubjectStore;
    protected notificationStore: NotificationStore;
    protected intlStore: IntlStore;

    id = '';
    title = '';
    identifier = '';
    categoryTitle = '';
    campaign?: IdTitle;
    expertise?: IdTitle;
    metaInfo: MetaInfoModel = new MetaInfoModel();

    commonFormDTO?: FormDTO;
    commonFormApi?: FormApi;
    isCommonFormChanged = false;
    isShowCommonValidation = false;

    hiddenFormDTO?: FormDTO;
    hiddenFormApi?: FormApi;
    isHiddenFormChanged = false;
    isShowHiddenValidation = false;

    constructor(id: string, rootStore: RootStore) {
        makeObservable(this, subjectModelObservables);
        const { coreRootStore, subjectStore } = rootStore;
        const { notificationStore, intlStore } = coreRootStore;

        this.id = id;
        this.subjectStore = subjectStore;
        this.notificationStore = notificationStore;
        this.intlStore = intlStore;
    }

    load(): Promise<void> {
        return this.subjectStore
            .loadSubject(this.id)
            .then((dto) => {
                if (!dto.commonFormInfo || !dto.hiddenFormInfo) {
                    this.notificationStore.onError(this.intlStore.intl.formatMessage({ id: 'common.formNotFound' }));
                }
                return dto;
            })
            .then(this.setMainFields);
    }

    loadCard(dto: SubjectDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.categoryTitle = dto.category?.title;
        this.metaInfo.load(dto.metaInfo);

        const { campaign, expertise } = dto;
        campaign && (this.campaign = campaign);
        expertise && (this.expertise = expertise);
    }

    deleteSubject(): Promise<void> {
        return this.subjectStore.deleteSubject(this.id);
    }

    downloadAllFiles(): AxiosPromise<Blob> {
        return this.subjectStore.downloadSubjectFiles(this.id);
    }

    setCommonFormApi(formApi: FormApi): void {
        this.commonFormApi = formApi;
    }

    setIsCommonFormChanged(formIsChanged: boolean): void {
        this.isCommonFormChanged = formIsChanged;
    }

    setHiddenFormApi(formApi: FormApi): void {
        this.hiddenFormApi = formApi;
    }

    setIsHiddenFormChanged(formIsChanged: boolean): void {
        this.isHiddenFormChanged = formIsChanged;
    }

    setMainFields(dto: SubjectDTO): void {
        this.loadCard(dto);
        this.commonFormDTO = dto.commonFormInfo;
        this.hiddenFormDTO = dto.hiddenFormInfo;
    }

    dropIsShowCommonValidation(): void {
        this.isShowCommonValidation = false;
    }

    dropIsShowHiddenValidation(): void {
        this.isShowHiddenValidation = false;
    }

    setIsShowCommonValidation(value: boolean): void {
        this.isShowCommonValidation = value;
    }

    setIsShowHiddenValidation(value: boolean): void {
        this.isShowHiddenValidation = value;
    }
}
