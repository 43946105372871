import { LocalesTree } from '@platform/front-utils';
import instructionSrc from '../../instructions/userInstruction_ru.docx';

const common: LocalesTree = {
    amountOfDays: '{count, number} day{count, plural, one {} other {s}}',
    formNotFound: 'Form not found',
    fromToNumber: 'from {from, number} to {to, number}',
    instructionFileName: 'Instruction.docx',
    instructionSrc: instructionSrc,
    invalidDateMessage: 'Invalid date format',
    noOptions: 'No options',
    pageLeaveMessage: 'Are you sure you want to leave this page?',
    pagingInfo: '{from, number}-{to, number} of {count, number}',
    readonlyValidationMsg: 'The form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    serviceInfo: 'Service information',
    serviceInfoFields: {
        author: 'Author',
        created: 'Created',
        form: 'Form',
        lastModified: 'Last modified',
        lifeCycle: 'Lifecycle',
        state: 'State',
    },
    withoutNumber: 'Without a number',
};

export default common;
