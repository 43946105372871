import { TableCell, TableHead, TableRow } from '@mui/material';
import { Typography } from '@platform/front-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ExpertisePlanTableHead = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.number" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.planCountTasks" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.acceptanceDays" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.deadlineDays" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.reportForm" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.taskForm" />
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        <FormattedMessage id="templatesOfExpertise.plan.fields.processTitle" />
                    </Typography>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
