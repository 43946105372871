import { Box, Grid } from '@mui/material';
import { Form } from '@platform/formiojs-react';
import { AuthorizationCheck } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { FilesDownload as FilesDownloadInj } from '../../../../components';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../hooks';
import { ExpertiseTaskSubjectModel } from '../../../../models';
import { PermissionsStore } from '../../../../stores';

export type ExpertiseTaskSubjectProps = {
    taskId?: string;
    buttonsSpacing?: boolean;
    isOnlyFiles?: boolean;
};

export const ExpertiseTaskSubject = observer((props: ExpertiseTaskSubjectProps): JSX.Element => {
    const [FilesDownload] = di([FilesDownloadInj], ExpertiseTaskSubject);

    const { taskId, buttonsSpacing, isOnlyFiles } = props;
    const { expertiseTaskStore, subjectStore, coreRootStore } = useStore();
    const { intlStore } = coreRootStore;
    const { formatMessage } = useIntl();
    const { id } = useParams<RouteParamsDefault>();
    const finalTaskId = taskId || id;
    const model = useMemo(() => new ExpertiseTaskSubjectModel(finalTaskId), [finalTaskId]);
    const { load, subjectId, subjectIdentifier, commonFormDTO, hiddenFormDTO, setCommonFormApi, setHiddenFormApi } =
        model;
    const filename = `${formatMessage({ id: 'subject.allFiles' }, { title: subjectIdentifier })}.zip`;

    const { permissionsStore } = coreRootStore;
    const { expertiseSubjectConfig } = permissionsStore as PermissionsStore;
    const downloadFilesQuery = expertiseSubjectConfig.downloadFiles(subjectId);

    const { stateTitle } = useExpertiseTaskViewPageContext().model.taskModel.metaInfo;

    useEffect(() => {
        if (isOnlyFiles) {
            expertiseTaskStore.loadTaskSubjectView(finalTaskId, true).then(load);
        } else {
            expertiseTaskStore.loadTaskSubject(finalTaskId).then(load);
        }
    }, [expertiseTaskStore, finalTaskId, isOnlyFiles, stateTitle]);

    const downloadAllFiles = useCallback(() => {
        const { subjectId } = model;
        return subjectStore.downloadSubjectFiles(subjectId);
    }, [model, subjectStore.downloadSubjectFiles]);

    const filesDownloadBtnText = formatMessage({ id: 'subject.downloadAllFiles' });

    return (
        <React.Fragment>
            {subjectId && (
                <AuthorizationCheck {...downloadFilesQuery}>
                    <Grid item container justifyContent="flex-end">
                        <Grid item>
                            <Box {...(buttonsSpacing && { mt: 4, mr: 4 })}>
                                <FilesDownload
                                    onDownloadClick={downloadAllFiles}
                                    filename={filename}
                                    buttonText={filesDownloadBtnText}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </AuthorizationCheck>
            )}
            {!!commonFormDTO && (
                <Form
                    formDTO={commonFormDTO}
                    multiLang
                    readOnly
                    intlStore={intlStore}
                    ownerEntityId={finalTaskId}
                    onFormReady={setCommonFormApi}
                />
            )}
            {!!hiddenFormDTO && (
                <Form
                    formDTO={hiddenFormDTO}
                    multiLang
                    readOnly
                    intlStore={intlStore}
                    ownerEntityId={finalTaskId}
                    onFormReady={setHiddenFormApi}
                />
            )}
        </React.Fragment>
    );
});
