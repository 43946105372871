import { Box, Button, Container, Grid } from '@mui/material';
import { AuthorizationCheck, registryPageStyles } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { userMessages } from '../../../customization';
import { useStore } from '../../../hooks';
import { PermissionsStore } from '../../../stores';
import { UserTableRow } from '../../../types';
import { UserAddDialog as UserAddDialogInj } from './user-add';
import {
    UserRegistryActionsBtns as UserRegistryActionsBtnsInj,
    UserRegistryTable as UserRegistryTableInj,
} from './user-registry';

export const userListPageSetRowActionsInj: TTableRowActions<UserTableRow> = (row, reloadData, toggleIsRowBlocked) => {
    const [UserRegistryActionsBtns] = di([UserRegistryActionsBtnsInj], UserListPage);

    return (
        <UserRegistryActionsBtns userTableRow={row} reloadData={reloadData} toggleIsRowBlocked={toggleIsRowBlocked} />
    );
};

export const UserListPage = observer((): JSX.Element => {
    const [UserAddDialog] = di([UserAddDialogInj], UserListPage);
    const [UserRegistryTable] = di([UserRegistryTableInj], UserListPage);
    const [userListPageSetRowActions] = di([userListPageSetRowActionsInj], UserListPage);

    const [isModalOpen, openModal, closeModal] = useFlag();
    const [userAdding] = useFeature('userAdding');

    const { userStore, coreRootStore } = useStore();
    const { permissionsStore } = coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;
    const accountAdministrationQuery = systemConfig.accountAdministration();

    const { getRoleListWithoutAdministration, createUser, roleListWithoutAdministration } = userStore;

    useEffect(() => {
        getRoleListWithoutAdministration();
    }, []);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <UserAddDialog
                open={isModalOpen}
                setModalIsClosed={closeModal}
                createUser={createUser}
                userRoleList={roleListWithoutAdministration}
            />
            <Box pt={2}>
                <Grid container spacing={2} flexDirection="column">
                    <Grid item container justifyContent="space-between">
                        <Typography variant="subtitle2" component="h1" alignSelf="center">
                            <FormattedMessage id="user.plural.nominative" />
                        </Typography>
                        {userAdding && (
                            <AuthorizationCheck {...accountAdministrationQuery}>
                                <Grid item>
                                    <Button variant="contained" onClick={openModal} color="primary">
                                        <FormattedMessage {...userMessages.add} />
                                    </Button>
                                </Grid>
                            </AuthorizationCheck>
                        )}
                    </Grid>

                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <UserRegistryTable setRowActions={userListPageSetRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
