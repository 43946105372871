import { Dialog, DialogContent } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
} from '@platform/front-ui';
import { IdTitle, stopPropagation, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import { NewTask } from '../../types';

export type ExpertiseTaskCreateDialogProps = ModalProps & {
    onSubmit: (planEntryId: string) => Promise<void>;
};

export type CreateTaskDialogRouteParams = {
    id: string;
};

const initialValues: NewTask = {
    expertisePlanEntry: null,
};

export const ExpertiseTaskCreateDialog = observer((props: ExpertiseTaskCreateDialogProps): JSX.Element => {
    const { open, onCancel, onSubmit } = props;
    const [expertisePlanEntriesOptions, setExpertisePlanEntriesOptions] = useState<IdTitle[]>([]);

    const { expertiseTaskStore } = useStore();
    const { id } = useParams<CreateTaskDialogRouteParams>();
    const intl = useIntl();
    const { formatMessage } = intl;

    useEffect(() => {
        expertiseTaskStore.loadExpertisePlanEntriesSelectOptions(id).then(setExpertisePlanEntriesOptions);
    }, []);

    const { Yup } = useYup();
    const schema = Yup.object().shape({
        expertisePlanEntry: Yup.object().nullable().required(),
    });

    const onSubmitCallback = (values: NewTask): Promise<void> => {
        return onSubmit((values.expertisePlanEntry as IdTitle).id);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCallback);

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: formatMessage({ id: 'expertiseTask.create' }),
    };

    const expertisePlanEntryLabel = formatMessage({ id: 'expertiseTask.fields.expertisePlanEntry' });
    const noOptionsText = formatMessage({ id: 'common.noOptions' });

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onKeyDown={stopPropagation}>
            <DialogTitle onCloseClick={onCancel}>
                <FormattedMessage id="expertiseTask.new" />
            </DialogTitle>

            <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={handleSubmit}>
                <Form>
                    <DialogContent>
                        <AutocompleteField
                            fieldName="expertisePlanEntry"
                            required
                            disableClearable
                            options={expertisePlanEntriesOptions}
                            label={expertisePlanEntryLabel}
                            noOptionsText={noOptionsText}
                        />
                    </DialogContent>

                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});
