import EditOutlined from '@mui/icons-material/EditOutlined';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { TotObjectDeleteButton as TotObjectDeleteButtonInj } from '../../../../../components';
import { useCampaignPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';

export const CampaignControlPanelLeft = observer((): JSX.Element => {
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], CampaignControlPanelLeft);

    const history = useHistory();
    const { formatMessage } = useIntl();

    const { model } = useCampaignPageContext();
    const { id, title, metaInfo, deleteCampaign } = model;
    const { stateTitle } = metaInfo;

    const { permissionsStore } = useStore().coreRootStore;
    const { expertiseCampaignConfig } = permissionsStore as PermissionsStore;
    const deleteExpertiseCampaignQuery = expertiseCampaignConfig.delete(id);
    const editExpertiseCampaignQuery = expertiseCampaignConfig.edit(id);

    const handleDeleteCampaign = (): Promise<void> => {
        return deleteCampaign().then(() => {
            history.push(clientRoute.campaigns);
        });
    };

    const confirmDeletion = formatMessage({ id: 'common.confirmDeletion' });
    const confirmDeletionInfoText = formatMessage({ id: 'campaign.confirmDeletionInfoText' }, { title });
    const tooltipTitle = formatMessage({ id: 'campaign.editCampaign' });

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center" key={stateTitle}>
            <Route path={clientRoute.campaign} exact>
                <AuthorizationCheck {...deleteExpertiseCampaignQuery}>
                    <Grid item>
                        <TotObjectDeleteButton
                            id="delete-campaign"
                            title={confirmDeletion}
                            message={confirmDeletionInfoText}
                            onConfirm={handleDeleteCampaign}
                            tooltipTitleId="campaign.actions.deleteCampaign"
                        />
                    </Grid>
                </AuthorizationCheck>
                <AuthorizationCheck {...editExpertiseCampaignQuery}>
                    <Grid item>
                        <Tooltip title={tooltipTitle} placement="top-end">
                            <IconButton
                                component={NavLink}
                                to={generatePath(clientRoute.campaignEdit, {
                                    id,
                                })}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </Grid>
    );
});
