import { GridProps } from '@mui/material';
import { ObjectHeaderLayout } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useCategoryOfSubjectsPageContext } from '../../../hooks';
import { objectWithoutBreadcrumbsHeaderContainerSx } from '../../../utils';
import { CategoryOfSubjectsPageHeaderLeft as CategoryOfSubjectsPageHeaderLeftInj } from './CategoryOfSubjectsPageHeaderLeft';
import { CategoryOfSubjectsPageHeaderTitle as CategoryOfSubjectsPageHeaderTitleInj } from './CategoryOfSubjectsPageHeaderTitle';

export const CategoryOfSubjectsHeader = observer((): JSX.Element => {
    const [CategoryOfSubjectsPageHeaderLeft] = di([CategoryOfSubjectsPageHeaderLeftInj], CategoryOfSubjectsHeader);
    const [CategoryOfSubjectsPageHeaderTitle] = di([CategoryOfSubjectsPageHeaderTitleInj], CategoryOfSubjectsHeader);

    const { title, metaInfo } = useCategoryOfSubjectsPageContext().model;
    const { created, modified } = metaInfo;
    const titleBlockAndMenu = { titleBlock: <CategoryOfSubjectsPageHeaderTitle title={title} /> };
    const leftBottomBlock = { element: <CategoryOfSubjectsPageHeaderLeft created={created} modified={modified} /> };
    const mainContentContainerProps: GridProps = {
        sx: objectWithoutBreadcrumbsHeaderContainerSx,
    };

    return (
        <ObjectHeaderLayout
            mainContentContainerProps={mainContentContainerProps}
            titleBlockAndMenu={titleBlockAndMenu}
            leftBottomBlock={leftBottomBlock}
        />
    );
});
