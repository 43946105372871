import { Box, Dialog, DialogContent } from '@mui/material';
import { ModalProps, UserStatus } from '@platform/front-types';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    FieldWithServerError,
} from '@platform/front-ui';
import { PromiseVoidFunction, stopPropagation, useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { usersConfirmStatusMessages, usersConfirmStatusTextMessages } from '../../../../customization';
import { useStore } from '../../../../hooks';
import { actionCodeByUserStatus } from '../../../../utils';

export type UserBlockDialogProps = ModalProps & {
    id: string;
    login: string;
    name: string;
    userStatusCode: UserStatus;
    reloadData: VoidFunction;
    onFinally?: VoidFunction;
    toggleIsRowBlocked?: VoidFunction;
    rowErrorHandler?: VoidFunction;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const UserBlockDialog = observer((props: UserBlockDialogProps): JSX.Element => {
    const {
        id,
        login,
        name,
        userStatusCode,
        open,
        reloadData,
        onCancel,
        onFinally,
        toggleIsRowBlocked,
        rowErrorHandler,
    } = props;

    const intl = useIntl();
    const { formatMessage } = intl;
    const { Yup } = useYup();

    const schema = Yup.object().shape({
        message: Yup.string().required(),
    });
    const { userStore, coreRootStore } = useStore();
    const { intlStore } = coreRootStore;

    const userStatus = actionCodeByUserStatus[userStatusCode];

    const onClose = (): void => {
        onCancel();
        toggleIsRowBlocked && toggleIsRowBlocked();
    };

    const onSubmit = useCallback(
        async (formValue?: FormikValues) => {
            onCancel();
            await userStore
                .editUserStatus(
                    id,
                    {
                        action: userStatus,
                        reason: formValue?.message,
                    },
                    true,
                )
                .then(reloadData)
                .catch(rowErrorHandler)
                .finally(() => {
                    onFinally && onFinally();
                });
        },
        [id, userStatusCode, userStore, reloadData],
    );

    const title = userStatus ? intlStore.formatMessageFromDefineMessage(usersConfirmStatusMessages[userStatus]) : '';
    const text = userStatus
        ? intlStore.formatMessageFromDefineMessage({
              ...usersConfirmStatusTextMessages[userStatus],
              values: {
                  ...usersConfirmStatusTextMessages[userStatus].values,
                  login,
                  name,
              },
          })
        : '';

    const fieldLabel = formatMessage({ id: 'user.blockReason' });
    const isActiveUser = userStatusCode === UserStatus.active;
    const [isSubmitting, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButton: DialogSubmitButtonConfig = {
        disabled: isSubmitting,
        text: formatMessage({ id: 'common.confirm' }),
        type: 'submit',
        endIcon,
        onClick: !isActiveUser ? (handleSubmit as PromiseVoidFunction) : undefined,
    };

    const titleId = id + '-title';

    return (
        <Dialog fullWidth={true} maxWidth="sm" aria-labelledby={titleId} open={open} onKeyDown={stopPropagation}>
            <DialogTitle id={titleId} onCloseClick={onCancel}>
                {title}
            </DialogTitle>
            {isActiveUser ? (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <DialogContent>
                            <Box pb={2}>{text}</Box>
                            <FieldWithServerError
                                name="message"
                                label={fieldLabel}
                                multiline
                                rows={5}
                                fullWidth
                                required
                            />
                        </DialogContent>
                        <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                    </Form>
                </Formik>
            ) : (
                <React.Fragment>
                    <DialogContent>
                        <Box>{text}</Box>
                    </DialogContent>
                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </React.Fragment>
            )}
        </Dialog>
    );
});
