import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Grid, Link, Typography } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../../components';
import { campaignPanelAccordionStyles } from '../../../constants';
import { useSubjectPageContext } from '../../../hooks';
import { getOrElse } from '../../../utils';

export const SubjectServiceInfo = observer((): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], SubjectServiceInfo);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], SubjectServiceInfo);

    const { model } = useSubjectPageContext();
    const { metaInfo, categoryTitle } = model;
    const { processLink, formLinks, modified, author } = metaInfo;

    const { formatMessage, formatDate } = useIntl();

    const lifecycleLink = getOrElse(
        processLink,
        <Link target="_blank" href={processLink?.url}>
            {processLink?.label}
        </Link>,
    );
    const formLinksComponent = getOrElse(
        formLinks,
        formLinks.map((item) => {
            return (
                <Link key={item.url} target="_blank" href={item.url}>
                    {item.label}
                </Link>
            );
        }),
    );

    const expandIcon = <ExpandMoreIcon />;
    const authorTitle = formatMessage({ id: 'subject.fields.author' });
    const lastModifiedTitle = formatMessage({ id: 'subject.fields.lastModified' });
    const categoryFieldTitle = formatMessage({ id: 'subject.fields.category' });
    const lifecycleTitle = formatMessage({ id: 'subject.fields.lifecycle' });
    const formTitle = formatMessage({ id: 'subject.fields.form' });

    return (
        <Accordion defaultExpanded elevation={1} sx={campaignPanelAccordionStyles.accordion}>
            <TotObjectPanelSummary expandIcon={expandIcon}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="subject.blocks.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column" spacing={1} pb={2.25}>
                    <CommonInfoLine title={authorTitle} value={author.name} isColumnDirection />
                    <CommonInfoLine
                        title={lastModifiedTitle}
                        value={getOrElse(modified, formatDate(modified))}
                        isColumnDirection
                    />
                    <CommonInfoLine title={categoryFieldTitle} value={getOrElse(categoryTitle)} isColumnDirection />
                    <CommonInfoLine title={lifecycleTitle} value={lifecycleLink} isColumnDirection />
                    <CommonInfoLine title={formTitle} value={formLinksComponent} isColumnDirection />
                </Grid>
            </TotObjectPanelDetails>
        </Accordion>
    );
});
