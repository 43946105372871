import { Grid, Link } from '@mui/material';
import { AuthorizationCheck, ObjectHeaderLayout } from '@platform/front-core';
import { CommonInfoLine, makeSxStyles, Typography } from '@platform/front-ui';
import { getOrElse } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import {
    ExpertiseTaskHeaderActionsButton as ExpertiseTaskHeaderActionsButtonInj,
    ExpertiseTaskHeaderBreadcrumbs as ExpertiseTaskHeaderBreadcrumbsInj,
    ExpertiseTaskHeaderLeftBottomBlock as ExpertiseTaskHeaderLeftBottomBlockInj,
} from './components';

const sxStyles = makeSxStyles({
    lifecyclesStagesLinkContainer: {
        mt: 0.5,
    },
    lifecyclesWrapper: {
        mt: 1,
    },
    statusWrapper: {
        height: '40px',
    },
});

export const ExpertiseTaskHeader = observer((): JSX.Element => {
    const [ExpertiseTaskHeaderActionsButton] = di([ExpertiseTaskHeaderActionsButtonInj], ExpertiseTaskHeader);
    const [ExpertiseTaskHeaderBreadcrumbs] = di([ExpertiseTaskHeaderBreadcrumbsInj], ExpertiseTaskHeader);
    const [ExpertiseTaskHeaderLeftBottomBlock] = di([ExpertiseTaskHeaderLeftBottomBlockInj], ExpertiseTaskHeader);

    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel } = model;
    const { identifier, metaInfo } = taskModel;
    const { stateTitle, processLink } = metaInfo;
    const { systemConfig } = useStore().coreRootStore.permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    const { formatMessage } = useIntl();

    const stateValue = getOrElse(stateTitle);

    const headerTitle = formatMessage({ id: 'expertiseTask.title' }, { number: identifier });
    const stateTitleText = formatMessage({ id: 'expertiseTask.fields.state' });
    const lifecycleLinkText = formatMessage({ id: 'expertiseTask.fields.lifecycleStages' });

    const headerBreadcrumbs = <ExpertiseTaskHeaderBreadcrumbs taskIdentifier={identifier} />;

    const headerTitleBlockAndMenu = {
        titleBlock: (
            <Grid container item wrap="nowrap" alignItems="center">
                <Grid item>
                    <Typography component="h1" variant="subtitle2" isWithoutAutoCapitalize>
                        {headerTitle}
                    </Typography>
                </Grid>
            </Grid>
        ),
        menu: { element: <ExpertiseTaskHeaderActionsButton /> },
    };

    const headerLeftBottomBlock = {
        element: <ExpertiseTaskHeaderLeftBottomBlock />,
        containerProps: {
            sx: sxStyles.lifecyclesWrapper,
        },
    };

    const headerRightBlocks = {
        topBlock: (
            <Grid container item alignItems="center" sx={sxStyles.statusWrapper}>
                <CommonInfoLine title={stateTitleText} value={stateValue} />
            </Grid>
        ),
        bottomBlock: (
            <Grid container item alignItems="center" sx={sxStyles.lifecyclesStagesLinkContainer}>
                {processLink && (
                    <AuthorizationCheck {...administrationQuery}>
                        <Link href={processLink.url} target="_blank">
                            {lifecycleLinkText}
                        </Link>
                    </AuthorizationCheck>
                )}
            </Grid>
        ),
    };

    return (
        <ObjectHeaderLayout
            breadcrumbs={headerBreadcrumbs}
            titleBlockAndMenu={headerTitleBlockAndMenu}
            rightBlocks={headerRightBlocks}
            leftBottomBlock={headerLeftBottomBlock}
        />
    );
});
