import { FileDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { PfTemplateStore } from '../../stores';

export const pfTemplateModelObservables = {
    pfTemplateStore: observable,

    fileDTO: observable,

    getFileInfo: action.bound,
    setFileDTO: action.bound,
};

export class PfTemplateModel {
    pfTemplateStore: PfTemplateStore;

    acceptedFiles = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    fileDTO?: FileDTO = undefined;

    constructor(pfTemplateStore: PfTemplateStore, id?: string) {
        makeObservable(this, pfTemplateModelObservables);
        this.pfTemplateStore = pfTemplateStore;
        id && this.getFileInfo(id);
    }

    getFileInfo(id: string): Promise<void> {
        const { getFileInfo } = this.pfTemplateStore;
        return getFileInfo(id).then((res) => this.setFileDTO(res.file));
    }

    setFileDTO(file: FileDTO): void {
        this.fileDTO = file;
    }
}
