import { ExpertiseFeatures } from '../../types';
import enLogo from '../../resources/images/misis-psal-logo_en.svg';
import ruLogo from '../../resources/images/misis-psal-logo_ru.svg';

const config: ExpertiseFeatures = {
    headerLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: 'National University of Science and Technology MISIS',
                image: enLogo,
                style: { height: '80px', maxWidth: '125px' },
            },
            ru: {
                alt: 'НИТУ "МИСиС"',
                image: ruLogo,
                style: { height: '80px', maxWidth: '125px' },
            },
        },
    },
    phoneNumber: true,
    userAdding: true,
    userAssignPassword: true,
    withSubjectFilesTab: false,
};

export default config;
