import { Grid } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { IdTitle, RouteParamsDefault, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router';
import { EditCuratorModal as EditCuratorModalInj } from '../../../../../../../components';
import { useExpertiseTaskViewPageContext, useStore } from '../../../../../../../hooks';
import { PermissionsStore } from '../../../../../../../stores';
import { ExpertiseTaskAuthEditField as ExpertiseTaskAuthEditFieldInj } from '../../../../expertise-task-edit-mode';
import { ExpertiseTaskCuratorInfo as ExpertiseTaskCuratorInfoInj } from '../../../../ExpertiseTaskCuratorInfo';
import { expertiseTaskSidebarStyles } from '../../expertiseTaskSidebarStyles';

export const ExpertiseTaskViewCuratorField = observer((): JSX.Element => {
    const [ExpertiseTaskAuthEditField] = di([ExpertiseTaskAuthEditFieldInj], ExpertiseTaskViewCuratorField);
    const [EditCuratorModal] = di([EditCuratorModalInj], ExpertiseTaskViewCuratorField);
    const [ExpertiseTaskCuratorInfo] = di([ExpertiseTaskCuratorInfoInj], ExpertiseTaskViewCuratorField);

    const { model } = useExpertiseTaskViewPageContext();
    const { taskModel, reloadTaskModel } = model;
    const { curator, curatorContacts } = taskModel;
    const { name } = curator;

    const { id } = useParams<RouteParamsDefault>();
    const { expertiseTaskStore, coreRootStore } = useStore();
    const { changeCurator } = expertiseTaskStore;
    const { formatMessage } = useIntl();

    const { permissionsStore } = coreRootStore;
    const { permissions } = permissionsStore as PermissionsStore;

    const [isModalOpen, openModal, closeModal] = useFlag();

    const curatorTitle = formatMessage({ id: 'expertiseTask.fields.curator' });

    const submitNewCurator = async (currentCurator: IdTitle): Promise<void> => {
        await changeCurator(id, currentCurator.id);
        reloadTaskModel();
        closeModal();
    };

    return (
        <Grid container sx={expertiseTaskSidebarStyles.editableFieldContainer}>
            <CommonInfoLine title={curatorTitle} value={name} isColumnDirection />
            <Grid item container sx={expertiseTaskSidebarStyles.editableFieldToolbar}>
                <ExpertiseTaskCuratorInfo name={name} contacts={curatorContacts} />
                <ExpertiseTaskAuthEditField
                    toggleIsOpenDialog={openModal}
                    messageId="expertise.changeCurator"
                    permCode={permissions.expertiseTask.UpdateTaskCurator}
                    entityId={id}
                />
            </Grid>
            <EditCuratorModal
                open={isModalOpen}
                onCancel={closeModal}
                curatorUserId={curator.asIdTitle.id}
                onSubmit={submitNewCurator}
            />
        </Grid>
    );
});
