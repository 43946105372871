import { ClientBreadcrumbsSettings } from '@platform/front-core';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { ExpertiseRouteParams, ExpertiseRouteTitles } from '../types';

export const getBreadcrumbsNames = (
    intl: IntlShape,
    pageTitles: ExpertiseRouteTitles,
): ClientBreadcrumbsSettings['names'] => {
    const taskIdentifier = pageTitles?.taskIdentifier || '';
    return {
        expertiseTasks: intl.formatMessage({ id: 'breadcrumbs.tasks' }),
        expertiseTask: taskIdentifier,
    };
};

export const getBreadcrumbsRoutes = (params: ExpertiseRouteParams): ClientBreadcrumbsSettings['routes'] => {
    const { id } = params;

    return {
        expertiseTasks: () => clientRoute.expertiseTasks,
        expertiseTask: () => (id ? generatePath(clientRoute.expertiseTask, { id }) : ''),
    };
};
