import { TabContext, TabList, TabPanel, TabPanelProps } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { AuthorizationCheckQueryOrWithoutCheck, useFetchAllPermissions } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../../hooks';
import { PermissionsStore } from '../../../../../stores';
import { ExpertiseTaskSidebarTabValue } from '../../../../../types';
import {
    ExpertiseTaskSidebarCommonTabValue as ExpertiseTaskSidebarCommonTabValueInj,
    ExpertiseTaskSidebarServiceTabValue as ExpertiseTaskSidebarServiceTabValueInj,
} from './components';
import { expertiseTaskSidebarStyles } from './expertiseTaskSidebarStyles';

export const ExpertiseTaskSidebar = observer((): JSX.Element => {
    const [ExpertiseTaskSidebarCommonTabValue] = di([ExpertiseTaskSidebarCommonTabValueInj], ExpertiseTaskSidebar);
    const [ExpertiseTaskSidebarServiceTabValue] = di([ExpertiseTaskSidebarServiceTabValueInj], ExpertiseTaskSidebar);

    const [sidebarSelectedTab, setSidebarSelectedTab] = useState<ExpertiseTaskSidebarTabValue>(
        ExpertiseTaskSidebarTabValue.common,
    );

    const { permissionsStore } = useStore().coreRootStore;
    const { systemConfig } = permissionsStore as PermissionsStore;

    const allPermissionsQueries = useMemo((): AuthorizationCheckQueryOrWithoutCheck[] => {
        return [systemConfig.administration()];
    }, []);

    const [allPermissions] = useFetchAllPermissions({ allPermissionsQueries });
    const [isAdministrator] = allPermissions;

    const { formatMessage } = useIntl();

    const onTabChange = (event: React.SyntheticEvent, newValue: ExpertiseTaskSidebarTabValue): void => {
        setSidebarSelectedTab(newValue);
    };

    const commonTabLabel = formatMessage({ id: 'expertiseTask.descriptionPanel.commonTabLabel' });
    const serviceTabLabel = formatMessage({ id: 'expertiseTask.descriptionPanel.serviceTabLabel' });

    return (
        <TabContext value={sidebarSelectedTab}>
            <Grid container direction="column" sx={expertiseTaskSidebarStyles.fullHeight}>
                <Grid item>
                    <TabList onChange={onTabChange}>
                        <Tab value={ExpertiseTaskSidebarTabValue.common} label={commonTabLabel} />
                        {isAdministrator && (
                            <Tab value={ExpertiseTaskSidebarTabValue.service} label={serviceTabLabel} />
                        )}
                    </TabList>
                </Grid>
                <Grid container item direction="column" flexGrow={1} sx={expertiseTaskSidebarStyles.infoWrapper}>
                    <Grid item sx={expertiseTaskSidebarStyles.infoInner}>
                        <TabPanel
                            sx={expertiseTaskSidebarStyles.tabPanel as TabPanelProps['sx']}
                            value={ExpertiseTaskSidebarTabValue.common}
                        >
                            <ExpertiseTaskSidebarCommonTabValue />
                        </TabPanel>
                        {isAdministrator && (
                            <TabPanel
                                sx={expertiseTaskSidebarStyles.tabPanel as TabPanelProps['sx']}
                                value={ExpertiseTaskSidebarTabValue.service}
                            >
                                <ExpertiseTaskSidebarServiceTabValue />
                            </TabPanel>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </TabContext>
    );
});
