import { ButtonTypeMap } from '@mui/material';
import { AxiosPromise } from 'axios';
import downloadFile from 'js-file-download';
import React, { ReactNode, useState } from 'react';
import { di } from 'react-magnetic-di';
import { ProgressButton as ProgressButtonInj } from '../buttons';

export type FilesDownloadProps = {
    onDownloadClick(): AxiosPromise<Blob>;
    buttonText: ReactNode;
    filename: string;
    mimeType?: string;
    buttonProps?: ButtonTypeMap['props'];
};

export const FilesDownload = (props: FilesDownloadProps): JSX.Element => {
    const [ProgressButton] = di([ProgressButtonInj], FilesDownload);

    const {
        onDownloadClick,
        filename,
        buttonText,
        mimeType = 'application/zip',
        buttonProps = {
            color: 'secondary',
            variant: 'contained',
        },
    } = props;
    const [downloadFilesInProgress, setDownloadFilesInProgress] = useState<boolean>(false);

    const downloadFiles = (): void => {
        setDownloadFilesInProgress(true);
        onDownloadClick()
            .then((r) => r.data)
            .then((f) => downloadFile(f, filename, mimeType))
            .finally(() => setDownloadFilesInProgress(false));
    };

    return (
        <React.Fragment>
            <ProgressButton
                onClick={downloadFiles}
                buttonText={buttonText}
                isInProgress={downloadFilesInProgress}
                buttonProps={buttonProps}
            />
        </React.Fragment>
    );
};
