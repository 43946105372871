import { PermissionsStore as PermissionsStoreCore } from '@platform/front-core';
import { computed, makeObservable } from 'mobx';
import { di } from 'react-magnetic-di';
import { PermissionEntities, Permissions } from '../../types';
import { entities, permissions } from './EntitiesPermissions';
import {
    AccountPermissions,
    ExpertiseCampaignPermissions,
    ExpertisePermissions,
    ExpertiseSubjectPermissions,
    ExpertiseTaskPermissions,
    getAccountPermissionsConfig,
    getExpertiseCampaignPermissionsConfig,
    getExpertisePermissionsConfig,
    getExpertiseSubjectPermissionsConfig,
    getExpertiseTaskPermissionsConfig,
    getSystemPermissionsConfig,
    getTemplateExpertisePermissionsConfig,
    SystemPermissions,
    TemplateExpertisePermissions,
} from './PermissionsConfigs';

export const permissionsStoreObservables = {
    expertiseSubjectConfig: computed,
    expertiseTaskConfig: computed,
    templateExpertiseConfig: computed,
    expertiseCampaignConfig: computed,
    expertiseConfig: computed,
};

export class PermissionsStore extends PermissionsStoreCore {
    constructor() {
        super();
        makeObservable(this, permissionsStoreObservables);
    }

    get entities(): PermissionEntities {
        return entities;
    }

    get permissions(): Permissions {
        return permissions;
    }

    get systemConfig(): SystemPermissions {
        return getSystemPermissionsConfig(this.entities, this.permissions);
    }

    get accountConfig(): AccountPermissions {
        return getAccountPermissionsConfig(this.entities, this.permissions);
    }

    get expertiseSubjectConfig(): ExpertiseSubjectPermissions {
        return getExpertiseSubjectPermissionsConfig(this.entities, this.permissions);
    }

    get expertiseTaskConfig(): ExpertiseTaskPermissions {
        return getExpertiseTaskPermissionsConfig(this.entities, this.permissions);
    }

    get templateExpertiseConfig(): TemplateExpertisePermissions {
        return getTemplateExpertisePermissionsConfig(this.entities, this.permissions);
    }

    get expertiseCampaignConfig(): ExpertiseCampaignPermissions {
        return getExpertiseCampaignPermissionsConfig(this.entities, this.permissions);
    }

    get expertiseConfig(): ExpertisePermissions {
        return getExpertisePermissionsConfig(this.entities, this.permissions);
    }
}

export const getPermissionsStore = (): any => {
    const [_PermissionsStore] = di([PermissionsStore], getPermissionsStore);
    return _PermissionsStore;
};
