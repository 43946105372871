import { Grid, Paper } from '@mui/material';
import { AuthorizationCheck, ObjectContentLayoutProps, ObjectLayout } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { ServiceInfoPanel as ServiceInfoPanelInj, TotObjectForm as TotObjectFormInj } from '../../components';
import { CategoryOfSubjectsPageContext, CategoryOfSubjectsPageContextType } from '../../contexts';
import { useStore } from '../../hooks';
import { CategoryOfSubjectsModel } from '../../models';
import { PermissionsStore } from '../../stores';
import { CategoryOfSubjectsControlPanel as CategoryOfSubjectsControlPanelInj } from './category-of-subjects-control-panel';
import { CategoryOfSubjectsHeader as CategoryOfSubjectsHeaderInj } from './category-of-subjects-header';
import { CategoriesOfSubjectsSyslogPage as CategoriesOfSubjectsSyslogPageInj } from './category-of-subjects-syslog';

export const CategoryOfSubjectsPage = observer((): JSX.Element => {
    const [CategoriesOfSubjectsSyslogPage] = di([CategoriesOfSubjectsSyslogPageInj], CategoryOfSubjectsPage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], CategoryOfSubjectsPage);
    const [TotObjectForm] = di([TotObjectFormInj], CategoryOfSubjectsPage);
    const [CategoryOfSubjectsControlPanel] = di([CategoryOfSubjectsControlPanelInj], CategoryOfSubjectsPage);
    const [CategoryOfSubjectsHeader] = di([CategoryOfSubjectsHeaderInj], CategoryOfSubjectsPage);

    const { categoriesOfSubjectsStore, history, coreRootStore } = useStore();
    const { permissionsStore } = coreRootStore;

    const { id } = useParams<RouteParamsDefault>();
    const model = useMemo(() => new CategoryOfSubjectsModel(id, categoriesOfSubjectsStore), [id]);
    const { metaInfo, load, setFormApi, formApi, formDTO, isShowValidation, dropIsShowValidation } = model;
    const { locale } = useIntl();

    const { systemConfig } = permissionsStore as PermissionsStore;
    const administrationQuery = systemConfig.administration();

    useEffect(() => {
        load();
    }, [id, locale]);

    const onSubmit = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            return formApi
                .submit(false)
                .then(() => {
                    return categoriesOfSubjectsStore.saveChangeCategoryOfSubjects(
                        id,
                        formApi.getSubmissionWithAdditionalInfo(),
                    );
                })
                .then(() => {
                    load().then(() => {
                        history.push(generatePath(clientRoute.category, { id }));
                    });
                });
        }
        return Promise.reject();
    };

    const pageContextValue: CategoryOfSubjectsPageContextType = {
        model,
        onSubmit,
    };

    const header: JSX.Element = <CategoryOfSubjectsHeader />;

    const content: JSX.Element = (
        <Grid container direction="column" wrap="nowrap" overflow="auto">
            <AuthorizationCheck {...administrationQuery} isWithRedirect>
                <Grid item p={2}>
                    <ServiceInfoPanel metaInfoModel={metaInfo} elevation={1} />
                    <Grid component={Paper} mb={3}>
                        {!!formDTO && (
                            <TotObjectForm
                                formName="category"
                                editPath={clientRoute.categoryEdit}
                                createPath={clientRoute.categoryCreate}
                                readPath={clientRoute.category}
                                onFormReady={setFormApi}
                                showReadonlyValidation={isShowValidation}
                                hideReadonlyValidation={dropIsShowValidation}
                                editAuthProps={administrationQuery}
                                formDTO={formDTO}
                            />
                        )}
                    </Grid>
                </Grid>
            </AuthorizationCheck>
        </Grid>
    );

    const footer: JSX.Element = <CategoryOfSubjectsControlPanel />;

    const contentLayout: ObjectContentLayoutProps = { content, footer };

    return (
        <CategoryOfSubjectsPageContext.Provider value={pageContextValue}>
            <Switch>
                <Route path={clientRoute.categorySyslog}>
                    <CategoriesOfSubjectsSyslogPage />
                </Route>
                <Route>
                    <ObjectLayout contentLayout={contentLayout} header={header} />
                </Route>
            </Switch>
        </CategoryOfSubjectsPageContext.Provider>
    );
});
